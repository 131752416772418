import { CalculationSettings } from "./CalculationModel";
import { Set, SetTypeData } from "./SetsModel";
import { RRule } from "rrule";
import { CompanySettings } from "./UserCompanyModel";


export interface Hold {
  id?: number;
  base_type?: ListType;
  default?: boolean;
  name: string;
  data: HoldData;
  priority?: number;
}

export interface HoldData {
  id?: number | null;
  base_type?: ListType | null;
  name?: string | null;
  description?: string | null;
  uuid?: string;
  L?: number | null;
  W?: number | null;
  H?: number | null;
  WT?: number | null;
  volume?: number | null;
  payload?: number | null;
  tare?: number | null;
  qty?: number | null;
  cost?: number | null;
  door?: HoldDoor | null;
  floor_height?: number | null;
  clearence?: number | null;
  items_bb?: BoundingBox;
  items_count?: number | null;
  max_height?: number | null;
  max_width?: number | null;
  max_length?: number | null;
  max_volume?: number | null;
  no_end_walls?: boolean | null;
  no_roof?: boolean | null;
  no_side_walls?: boolean | null;
  shipments?: string[] | null;
  notes?: string | null;
  axles?: any | null;
  posts?: any | null;
  walls?: any | null;
  parts?: any[] | null;
  bottom_frame?: any | null;
  top_frame?: any | null;
  camera?: any | null;
  setCamera?: any | null;
  set_uuid?: string;
  quotation?: number | null;
  uid?: number;
  legal_limits?: LegalLimits;
  has_hatch?: boolean;
  can_stow_on_hatch?: boolean;
  twd_length?: number;
  twd_height?: number;
  items?: HoldItem[] | null;
  tables?: HoldTables;
  contours?: Contours;
  properties?: SubHoldProperties;
  annotations?: Annotation[]
}

export interface SubHoldProperties {
  not_stackable?: boolean;
  bottom_only?: boolean;
  tiltable?: boolean;
  not_rotatable?: boolean;
}

export interface HoldDataWithReason extends HoldData {
  reason: string;
}

export interface HoldDataWithIndices extends HoldData {
  __indices: Indices;
}

export interface HoldDataPreview extends HoldDataWithPosition {
  preview: boolean
}

export interface HoldDataWithPosition extends HoldDataWithIndices {
  position?: Point;
  rotation?: Rotation;
  position_name?: string;
}

export interface HoldTables {
  front_table_h?: number;
  front_table_l?: number;
  front_table_w?: number;
  front_table_not_stackable?: boolean;
  rear_table_h?: number;
  rear_table_l?: number;
  rear_table_w?: number;
  rear_table_not_stackable?: boolean;
  deck_l?: number;
  deck_z?: number;
  deck_x?: number;
}

export interface HoldInputItem {
  label: string;
  l: number;
  w: number;
  h: number;
  wt: number;
  qty?: number | null;
  not_stackable?: boolean | null;
  not_rotatable?: boolean | null;
  tiltable?: boolean | null;
  orientations?: number;
  bottom_only?: boolean | null;
  palletize?: boolean | null;
  color?: string | null;
  geometry?: Geometry | null;
  shipment_id?: string | null;
  max_layers?: number | null;
  max_load?: number | null;
  free_space?: number | string | null;
  priority?: number | null;
  allowed_containers?: number[] | null;
  metadata?: any | null;
  unit_qty?: number | null;
  class_id?: string | null;
  from_container?: HoldData | null;
}

export interface HoldItem extends Partial<HoldInputItem> {
  /// SI Units
  L?: number | null;
  W?: number | null;
  H?: number | null;
  WT?: number | null;
  pos?: Point;
  rotation?: Rotation;
  itemIndices?: number[];
  bb?: {
    max: { x: number; y: number; z: number };
    min: { x: number; y: number; z: number };
  };
  // overrides
  label?: string | null;
  // user units
  l?: number | null;
  w?: number | null;
  h?: number | null;
  wt?: number | null;
  coordinates?: Coordinates;
  destination?: string | null;
}

export interface Coordinates {
  lat: number;
  lng: number;
}

export type HoldItemType = HoldInputItem | HoldItem;

export interface UnloadedItem extends HoldItem {
  id?: string;
  uid: string;
  reason: string;
}

export interface Cargo {
  data: HoldInputItem;
  id: number;
  name: string;
  sku?: string;
  length_dim?: LengthDim;
  weight_dim?: WeightDim;
}

export interface Contours {
  front_top_contour_l?: number;
  front_top_contour_h?: number;
  front_bottom_contour_l?: number;
  front_bottom_contour_h?: number;
  rear_top_contour_l?: number;
  rear_top_contour_h?: number;
  rear_bottom_contour_l?: number;
  rear_bottom_contour_h?: number;
  side1_top_contour_l?: number;
  side1_top_contour_h?: number;
  side1_bottom_contour_l?: number;
  side1_bottom_contour_h?: number;
  side2_top_contour_l?: number;
  side2_top_contour_h?: number;
  side2_bottom_contour_l?: number;
  side2_bottom_contour_h?: number;
  bottom_spacer_l?: number;
  bottom_spacer_h?: number;
  auto_bottom_spacer_h?: number;
}

export interface LoadRule {
  items: string[] | ItemSelector;
  in_containers_with_ids: number[];
  condition:
  | "max_qty"
  | "cannot_be_loaded_in"
  | "must_be_loaded_in"
  | "can_only_support"
  | "must_be_loaded_first";
  value: any;
}

export interface ItemSelector {
  property: string;
  comparison: "eq" | "gt" | "lt" | "ne";
  value: any;
}

export interface HoldType {
  position_name?: string;
  rotation?: Rotation;
  position?: Point;
  container_type_id: number;
  props?: HoldData;
}

export interface LoadConfigurationData {
  settings: CalculationSettings | null;
  holds: HoldData[] | null;
  pallet_types?: HoldData[] | null;
  set_types?: SetTypeData[] | null;
}

export interface LoadConfiguration {
  id?: number;
  name: string;
  base_type?: ListType;
  default: boolean;
  description?: string;
  data: LoadConfigurationData;
  created_date?: string;
  modified_date?: string;
}

export interface LoadlistResult {
  versions?: Loadplan[];
  app_version?: any;
  locked?: boolean;
}

export interface Loadlist {
  id?: string;
  name: string;
  owner?: string;
  group?: number | null;
  list_type?: ListType;
  presentation_settings?: CompanySettings;
  customer?: string | null;
  logo_url?: string;
  pol?: string | null;
  pod?: string | null;
  notes?: string;
  etd?: string | null;
  public_access?: PublicAccess | null;
  data: HoldInputItem[] | null;
  result?: LoadlistResult | null;
  length_dim?: LengthDim;
  weight_dim?: WeightDim;
  url?: string;
  version?: number | null;
}

export type Annotation = TextboxAnnotation | LineAnnotation

export interface TextboxAnnotation extends AnnotationBase {
  text: string
  fontSize: number // pixels
  fontFamily: string,
  backgroundColor: string
}

export interface LineAnnotation extends AnnotationBase {
  x1: number
  x2: number
  y1: number
  y2: number
  stroke: string
  strokeWidth: number
}

export interface AnnotationBase {
  id: number
  width: number
  height: number
  top: number
  left: number
  angle: number
  scaleX: number
  scaleY: number
}

export interface Loadplan {
  holds: HoldData[];
  notes: string | null;
  settings?: CalculationSettings | null;
  selected_holds?: HoldData[];
  pallet_types?: HoldData[] | null;
  set_types?: SetTypeData[];
  sets?: Set[];
  quotation?: Quotation | null;
  unloaded_items?: UnloadedItem[];
  preset?: Number | null;
}

export interface LoadlistBase {
  id?: string;
  group?: number | null;
  name: string;
  list_type?: ListType;
  customer?: string | null;
  pol?: string | null;
  pod?: string | null;
  notes?: string;
  created_date?: string;
  modified_date?: string;
  etd?: string | null;
  public_access?: PublicAccess | null;
  url?: string;
}

export interface KeyValue {
  [key: string]: string | number;
}

export interface Currency {
  name: string;
  code: string;
  symbol: string;
}

export interface Quotation {
  currency?: Currency;
  quotationsForTypes?: KeyValue;
  quotationsForHolds?: KeyValue;
}

export interface PresetInfo {
  id: number;
  name: string;
}

export interface LoadlistGroupProperties {
  color?: string;
  start_date?: string;
  rrule?: string;
  default_type?: ListType;
  default_preset?: PresetInfo;
}

export interface LoadlistGroup {
  id?: number;
  name: string;
  private?: boolean;
  owner?: number;
  data?: LoadlistGroupProperties;
}

export interface HoldsLibraryParams {
  holdsType?: ListType;
  holdsLibrary: HoldData[];
  holdSearchString: string;
  holdsCount: number;
  holdsPage: number;
  holdsLibraryType: string;
}

export interface UpdateLoadplanHoldsParams {
  index?: number;
  replace?: number;
  holds?: HoldData[];
}

export interface MoveItemsInLoadplanParams {
  settings: CalculationSettings;
  from_container: HoldData;
  to_container: HoldData | null;
  from_indices: number[];
  to_index: number;
  hideProgress: boolean;
}

export interface RemoveItemsInContainerParams {
  from_container: HoldData;
  from_indices: number[];
}
export interface RemoveItemsInLoadplanParams {
  settings: CalculationSettings;
  containers: RemoveItemsInContainerParams[];
  hideProgress: boolean;
}

export interface InsertItemsInLoadplanParams {
  settings: CalculationSettings;
  to_container: HoldData;
  to_index: number;
  hideProgress: boolean;
  length_dim?: string;
  weight_dim?: string;
}

export interface Indices {
  start: number;
  end: number;
}

export interface LegalLimits {
  weight?: number;
  height?: number;
  max_length_with_extension?: number;
  extended?: number;
}

export interface Point {
  x: number;
  y: number;
  z: number;
}

export interface BoundingBox {
  min: Point;
  max: Point;
}

export interface HoldDoor {
  H: number;
  W: number;
  longside?: boolean | null;
}

export class HoldPart {
  id?: number;
  type: "wall" | "corner" | "frame" | "floor" | "custom";
  L?: number;
  H?: number;
  W?: number;
  pos: Point;
  stick_to: ("front" | "rear" | "side1" | "side2" | "floor" | "roof")[];
  not_stackable?: boolean;

  constructor(data?: any) {
    this.id = Date.now();
    this.type = data.type;
    this.L = data.L;
    this.W = data.W;
    this.H = data.H;
    this.pos = data.pos || { x: 0, y: 0, z: 0 };
    this.stick_to = data.stick_to || [];
    this.not_stackable = !!data.not_stackable;
  }
}

export interface CargoLayer {
  previous_cargoes: number[],
  cargoes: number[];
  value: any;
  objects: { label: string; count: number; color: string }[];
};

export type ListType = "SEA" | "ROAD" | "AIR" | "PALL";
export type LengthDim = "MM" | "CM" | "DM" | "M" | "IN" | "FT";
export type Geometry = "box" | "pipe" | "drum" | "cylinder" | "hollow_cylinder";
export type WeightDim = "KG" | "MT" | "LB";
export type PublicAccess = "RO" | "RW";
export type Rotation = [number, number, number, string];