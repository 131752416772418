





















import { HoldDataWithIndices } from "@/models/LoadlistModel";
import Vue, { PropType } from "vue";
import MapBoxMapVue from "../Custom/MapBoxMap.vue";
export default Vue.extend({
  name: "map-modal",
  components: {
    MapBoxMapVue,
  },
  data: function() {
    return {};
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pol: String,
    pod: String,
    hold: Object as PropType<HoldDataWithIndices>,
    withLine: Boolean,
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    holdLocations(): any[] {
      return this.hold?.items
        .flatMap((i) => i.from_container?.items || [i])
        .filter((i) => i.coordinates)
        .reverse()
        .map((i) => {
          return {
            color: i.color,
            label: i.label,
            destination: i.destination,
            ...i.coordinates,
          };
        });
    },
  },
  mounted(): void {},
  methods: {},
});
