import { APIResponse, APIResponseTyped } from "@/models/APIModel";
import {
  HoldData,
  InsertItemsInLoadplanParams,
  ListType,
  LoadlistGroup,
  Loadlist,
  MoveItemsInLoadplanParams,
  RemoveItemsInLoadplanParams,
} from "@/models/LoadlistModel";
import { News } from "@/models/NewsModel";
import axios from "axios";

const debug = process.env.NODE_ENV !== "production";
const API_BASE_URL = process.env.VUE_APP_API_HOST || "";
const API_URL = API_BASE_URL + "/api/2";

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  // timeout: 10000,
});
instance.defaults.headers.common["Client-Version"] =
  process.env.VUE_APP_VERSION;

export default {
  instance: instance,
  url: API_BASE_URL,
  // AUTH
  login(username: string, password: string): Promise<APIResponse> {
    return instance.get("/login/").then((_) => {
      return instance.post("/login/", {
        username: username,
        password: password,
      });
    });
  },
  magicLink(data: { email: string }): Promise<APIResponse> {
    return instance.get("/magiclink/").then((_) => {
      return instance.post("/magiclink/", data);
    });
  },
  logout(): Promise<APIResponse> {
    return instance.get("/logout/");
  },
  register(data: any): Promise<APIResponse> {
    return instance.post("/signup/", data);
  },
  changePassword(input: string): Promise<APIResponse> {
    return instance.put("/password_change/", input);
  },
  takeout(id: any): Promise<APIResponse> {
    return instance.get("/user/export/", {
      responseType: "arraybuffer",
    });
  },
  ssoGetAuthUrl(domain: string): Promise<APIResponse> {
    return instance.get("/saml2_get_url/?domain=" + domain);
  },
  ssoLogin(code: string): Promise<APIResponse> {
    return instance.post("/saml2_auth/", { code: code });
  },
  // NEWS
  getNews(params: { page: number }): Promise<APIResponseTyped<News>> {
    return instance.get("/news/", { params: params });
  },
  // LOADLISTGROUP
  getLoadlistGroups(): Promise<APIResponseTyped<LoadlistGroup>> {
    return instance.get("/loadlistgroups/");
  },
  createLoadlistGroup(loadlistgroup: LoadlistGroup): Promise<APIResponse> {
    return instance.post("/loadlistgroups/", loadlistgroup);
  },
  deleteLoadlistGroup(id: number): Promise<APIResponse> {
    return instance.delete("/loadlistgroups/" + id + "/");
  },
  updateLoadlistGroup(loadlistgroup: LoadlistGroup): Promise<APIResponse> {
    return instance.put(
      "/loadlistgroups/" + loadlistgroup.id + "/",
      loadlistgroup
    );
  },
  // LOADLIST
  getLoadlists(params: {
    page?: number;
    search?: string;
    group?: number;
    default?: boolean;
    ordering?: string;
    etd_after?: string;
    etd_before?: string;
    size?: number;
  }): Promise<APIResponse> {
    return instance.get("/loadlist/", { params: params });
  },
  createLoadlist(loadlist: {
    name: string;
    list_type: ListType;
    group: number | null;
    data: HoldData[];
    notes?: string;
    result?: any;
    etd?: string;
    length_dim?: string;
    weight_dim?: string;
  }): Promise<APIResponse> {
    return instance.post("/loadlist/", loadlist);
  },
  copyLoadlist(
    id: string,
    template: {
      name: string;
      list_type: ListType;
      data: any[];
      notes: string;
      with_loadplan: boolean;
    }
  ): Promise<APIResponse> {
    return instance.post("/loadlist/" + id + "/copy/", template);
  },
  deleteLoadlist(id: string): Promise<APIResponse> {
    return instance.delete("/loadlist/" + id + "/");
  },
  getLoadlist(id: string): Promise<APIResponse> {
    return instance.get("/loadlist/" + id + "/");
  },
  saveLoadlist(loadlist: Loadlist): Promise<APIResponse> {
    return instance.put("/loadlist/" + loadlist.id + "/", loadlist);
  },
  patchLoadlist(loadlist: {
    id: string;
    result?: any;
    group?: string;
    version?: number | null;
  }): Promise<APIResponse> {
    return instance.patch("/loadlist/" + loadlist.id + "/", loadlist);
  },
  calculateLoadlist(data: any): Promise<APIResponse> {
    return instance.post("/calculate/?from_app=true", data);
  },
  moveItemsLoadlist(data: MoveItemsInLoadplanParams): Promise<APIResponse> {
    return instance.post("/calculate/move_items/?from_app=true", data);
  },
  insertItemsLoadlist(data: InsertItemsInLoadplanParams): Promise<APIResponse> {
    return instance.post("/calculate/insert_items/?from_app=true", data);
  },
  removeItemsLoadlist(data: RemoveItemsInLoadplanParams): Promise<APIResponse> {
    return instance.post("/calculate/remove_items/?from_app=true", data);
  },
  // HOLDS
  getHolds(params: any): Promise<APIResponse> {
    return instance.get("/holds/", { params: params });
  },
  getUserHolds() {
    return instance.get("/holds/ours/");
  },
  getHold(id: any): Promise<APIResponse> {
    return instance.get("/holds/" + id + "/");
  },
  deleteHold(id: any): Promise<APIResponse> {
    return instance.delete("/holds/" + id + "/");
  },
  createHold(hold: any): Promise<APIResponse> {
    return instance.post("/holds/", hold);
  },
  updateHold(hold: any): Promise<APIResponse> {
    return instance.put("/holds/" + hold.id + "/", hold);
  },
  // SETS
  getSets(params: any): Promise<APIResponse> {
    return instance.get("/sets/", { params: params });
  },
  getUserSets() {
    return instance.get("/sets/ours/");
  },
  getSet(id: any): Promise<APIResponse> {
    return instance.get("/sets/" + id + "/");
  },
  deleteSet(id: any): Promise<APIResponse> {
    return instance.delete("/sets/" + id + "/");
  },
  createSet(set: any): Promise<APIResponse> {
    return instance.post("/sets/", set);
  },
  updateSet(set: any): Promise<APIResponse> {
    return instance.put("/sets/" + set.id + "/", set);
  },
  // CARGOES
  getCargoes(): Promise<APIResponse> {
    return instance.get("/cargo/?no_page");
  },
  deleteCargo(id: any): Promise<APIResponse> {
    return instance.delete("/cargo/" + id + "/");
  },
  createCargo(cargo: any): Promise<APIResponse> {
    return instance.post("/cargo/", cargo);
  },
  createCargoes(cargoes: any): Promise<APIResponse> {
    return instance.post("/cargo/bulk_create/", cargoes);
  },
  deleteCargoes(cargoes: any): Promise<APIResponse> {
    return instance.post("/cargo/bulk_delete/", cargoes);
  },
  updateCargo(cargo: any): Promise<APIResponse> {
    return instance.put("/cargo/" + cargo.id + "/", cargo);
  },
  // USERS
  getMe(): Promise<APIResponse> {
    return instance.get("/user/profile/");
  },
  getUsers(company: any): Promise<APIResponse> {
    return instance.get("/company/" + company + "/users/");
  },
  getUser(user: any): Promise<APIResponse> {
    return instance.get("/user/" + user.id + "/");
  },
  deleteUser(id: any, transferUserId?: any): Promise<APIResponse> {
    return instance.delete("/user/" + id + "/?transfer_to=" + transferUserId);
  },
  updateUser(user: any): Promise<APIResponse> {
    return instance.patch("/user/" + user.id + "/", user);
  },
  acceptPrivacyPolicy(user: any): Promise<APIResponse> {
    return instance.patch("/user/" + user.id + "/accept_privacy_policy/");
  },
  getPrivacyPolicy(): Promise<APIResponse> {
    return instance.get("/user/privacy/");
  },
  passwordReset(data: any): Promise<APIResponse> {
    return instance.post("/password_reset/", data);
  },
  passwordResetConfirm(data: any): Promise<APIResponse> {
    return instance.post("/password_reset_confirm/", data);
  },
  hasActiveStripeSubscription(): Promise<APIResponse> {
    return instance.get("/user/stripe_active_subscription/");
  },
  startStripeSubscription(price_id: any): Promise<APIResponse> {
    return instance.post("/user/stripe_start_subscription/", {
      price_id: price_id,
    });
  },
  stripePortal(): Promise<APIResponse> {
    return instance.get("/user/stripe_portal/");
  },
  getAuthToken(): Promise<APIResponse> {
    return instance.get("/user/get_auth_token/");
  },
  createAuthToken(): Promise<APIResponse> {
    return instance.post("/user/create_auth_token/");
  },
  deleteAuthToken(): Promise<APIResponse> {
    return instance.delete("/user/delete_auth_token/");
  },
  //COMPANIES
  getCompany(id: any): Promise<APIResponse> {
    return instance.get("/company/" + id + "/");
  },
  getCompanies(params?: any): Promise<APIResponse> {
    return instance.get("/company/", { params: params });
  },
  getCompanyStats(id: any): Promise<APIResponse> {
    return instance.get("/company/" + id + "/stats/");
  },
  updateCompany(id: any, data: any): Promise<APIResponse> {
    return instance.patch("/company/" + id + "/", data);
  },
  updateCompanySettings(id: any, data: any): Promise<APIResponse> {
    return instance.patch("/company/" + id + "/update_settings/", data);
  },
  getSubscriptionPlans(price_id: any): Promise<APIResponse> {
    return instance.get("/subscription_plans/", {
      params: { price_id: price_id },
    });
  },
  inviteUser(data: any): Promise<APIResponse> {
    return instance.post("/company/" + data.company + "/invite/", data);
  },
  addUser(company_id: number, data: any): Promise<APIResponse> {
    return instance.post("/company/" + company_id + "/add_user/", data);
  },
  deleteUserFromCompany(
    company_id: number,
    user_id: number,
    transferUserId: any
  ): Promise<APIResponse> {
    return instance.delete(
      "/company/" +
      company_id +
      "/remove_user/" +
      user_id +
      "/?transfer_to=" +
      transferUserId
    );
  },
  updateUserFromCompany(
    company_id: number,
    user_id: number,
    data: any
  ): Promise<APIResponse> {
    return instance.patch(
      "/company/" + company_id + "/update_user/" + user_id + "/",
      data
    );
  },
  //LOADCONFIGURATIONS
  getLoadConfigurations(params: any): Promise<APIResponse> {
    return instance.get("/configurations/", { params: params });
  },
  getLoadConfiguration(id: any): Promise<APIResponse> {
    return instance.get("/configurations/" + id + "/");
  },
  deleteLoadConfiguration(id: any): Promise<APIResponse> {
    return instance.delete("/configurations/" + id + "/");
  },
  createLoadConfiguration(data: any): Promise<APIResponse> {
    return instance.post("/configurations/", data);
  },
  updateLoadConfiguration(data: any): Promise<APIResponse> {
    return instance.patch("/configurations/" + data.id + "/", data);
  },
  getDefaultLoadConfiguration(base_type: any): Promise<APIResponse> {
    return instance.get("/configurations/default?base_type=" + base_type);
  },
  // Appdata
  getDefaultAppData(): Promise<APIResponse> {
    return instance.get("/equipment/default/");
  },
  getUserAppData(): Promise<APIResponse> {
    return instance.get("/equipment/user/");
  },
};
