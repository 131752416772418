













































































































































































































































































































































































































































































































import Vue from "vue";
import { SceneManager, STATES, VIEWS } from "@/graphics/sceneManager";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import FileSaver from "file-saver";

import InteractiveViewGuide from "@/components/Modals/InteractiveViewGuide.vue";
import plannerItemsComponent from "@/components/Custom/PlannerItems.vue";
import confirmModal from "@/components/Modals/Confirm.vue";
import {
  HoldData,
  HoldInputItem,
  UpdateLoadplanHoldsParams,
  Loadlist,
  Loadplan,
  HoldItem,
  HoldDataWithIndices,
  HoldDataWithPosition,
} from "@/models/LoadlistModel";
import { CalcData } from "@/models/CalculationModel";
import { States, Views, ViewSettings } from "@/models/GraphicsModel";
import { Scene, Vector3 } from "three";
import { LoadlistStore, UserCompanyStore } from "@/store/index";
import { AugmentedSet } from "@/models/augmented/set";
import { AugmentedHold } from "@/models/augmented/hold";
import { ContainerClickInfo } from "@/models/SetsModel";
import containerUtils from "@/misc/containerUtils";

interface HoldItemWithIndex extends HoldItem {
  index: number;
}

export default Vue.extend({
  name: "interactive",
  components: {
    sceneComponent,
    InteractiveViewGuide,
    plannerItemsComponent,
    confirmModal,
  },
  data() {
    return {
      sceneKey: 1,
      showPlannerSheet: true,
      hold: null as HoldData,
      cog: null as Vector3,
      holdIndex: Number(this.$route.params.hold),
      itemIndex: Number(this.$route.params.item),
      set: null as AugmentedSet,
      currentInteractionState: null as States,
      toggleGrid: false,
      selectedItems: null as HoldItemWithIndex[],
      showQuickGuide: false,
      showNotesDialog: false,
      showResetDialog: false,
      showHull: true,
      showSnackbar: false,
      showSearch: false as number | boolean,
      searchInput: undefined as string,
      snackBarText: "",
      viewSettings: undefined as ViewSettings,
      loadedItemsByMouse: null as number,
      showAllItemsTimer: null as number,
      isLoading: false,
      originalHold: null as HoldData,
      selectedHold: null as AugmentedHold,
      clickedCoordinates: { x: 10, y: 10 },
      dirty: true,
    };
  },
  computed: {
    augmentedHold(): AugmentedHold {
      return new AugmentedHold(this.hold, this.cog);
    },
    holdWithIndices(): HoldDataWithIndices {
      return {
        ...this.hold,
        __indices: { start: this.holdIndex, end: this.holdIndex },
      };
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    loadplan_version(): number {
      return LoadlistStore.loadplan_version;
    },
    length_dim(): string {
      return UserCompanyStore.length_dim;
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    interactiveStates(): {
      MOVE: string;
      MEASURE_TAPE: string;
      SELECT_BOX: string;
    } {
      return STATES;
    },
    views(): {
      TOP: string;
      SIDE: string;
      SIDE2: string;
      FRONT: string;
      THREED: string;
      CUSTOM: string;
    } {
      return VIEWS;
    },
    interactionState: {
      get(): States {
        return this.currentInteractionState;
      },
      set(s: States): void {
        this.emitSceneEvent("interactive-state", s);
      },
    },
    showNestedInteractiveView(): boolean {
      return (
        Array.isArray(this.selectedItems) &&
        this.selectedItems.length === 1 &&
        !!this.selectedItems[0]?.from_container
      );
    },
  },
  watch: {
    "loadplan.holds"(): void {
      this.updateScene();
    },
    "$route.params.hold"(a): void {
      let index = Number(a);
      if (!isNaN(index)) {
        this.holdIndex = index;
        this.originalHold = Object.freeze(
          JSON.parse(JSON.stringify(this.loadplan.holds[index]))
        );
      } else {
        this.holdIndex = undefined;
      }
      this.updateScene();
    },
    "$route.params.item"(a): void {
      let index = Number(a);
      if (!isNaN(index)) {
        this.itemIndex = index;
      } else {
        this.itemIndex = undefined;
      }
      this.updateScene();
    },
  },
  created(): void {
    // Create backup hold
    if (!isNaN(this.holdIndex)) {
      this.originalHold = Object.freeze(
        JSON.parse(JSON.stringify(this.loadplan.holds[this.holdIndex]))
      );
    }
    this.updateScene();

    SceneManager.eventBus.on("select-cargoes", this.selectItem);
    SceneManager.eventBus.on("removed-cargo", this.removeCargo);
    SceneManager.eventBus.on("measured-distance", this.measuredDistance);
    SceneManager.eventBus.on("cog-updated", this.updateCog);
    SceneManager.eventBus.on("open-container", this.goToContainer);
    SceneManager.eventBus.on("drop-on-container", this.cargoDropOnSet);
    SceneManager.eventBus.on("select-container", this.selectContainer);
    SceneManager.eventBus.on("search", this.toggleSearch);
    SceneManager.eventBus.on("save", this.save);
    SceneManager.eventBus.on("notes", this.showNotes);
    SceneManager.eventBus.on("select-multiple", this.selectMultiple);
    SceneManager.eventBus.on("ruler", this.showRuler);
    SceneManager.eventBus.on("tape", this.showMeasuringTape);

    SceneManager.eventBus.on(
      "interaction-state-changed",
      this.changedInteractionState
    );
  },
  beforeRouteUpdate(to: never, from: never, next: any) {
    if (isNaN(this.itemIndex)) this.save();
    this.clearScene();
    next();
  },
  beforeRouteLeave(to: never, from: never, next: any) {
    if (isNaN(this.itemIndex)) this.save();
    this.clearScene();
    next();
  },
  beforeDestroy(): void {
    SceneManager.eventBus.off("select-cargoes", this.selectItem);
    SceneManager.eventBus.off("removed-cargo", this.removeCargo);
    SceneManager.eventBus.off("measured-distance", this.measuredDistance);
    SceneManager.eventBus.off("cog-updated", this.updateCog);
    SceneManager.eventBus.off("open-container", this.goToContainer);
    SceneManager.eventBus.off("drop-on-container", this.cargoDropOnSet);
    SceneManager.eventBus.off("select-container", this.selectContainer);
    SceneManager.eventBus.off("search", this.toggleSearch);
    SceneManager.eventBus.off("save", this.save);
    SceneManager.eventBus.off("notes", this.showNotes);
    SceneManager.eventBus.off("select-multiple", this.selectMultiple);
    SceneManager.eventBus.off("ruler", this.showRuler);
    SceneManager.eventBus.off("tape", this.showMeasuringTape);
    SceneManager.eventBus.off(
      "interaction-state-changed",
      this.changedInteractionState
    );

    this.clearScene();
  },
  methods: {
    updateScene(fromBackup = false): void {
      if (!isNaN(this.holdIndex)) {
        if (
          !isNaN(this.itemIndex) &&
          this.loadplan.holds[this.holdIndex].items?.[this.itemIndex]
            ?.from_container
        ) {
          this.hold = JSON.parse(
            JSON.stringify(
              fromBackup
                ? this.originalHold
                : this.loadplan.holds[this.holdIndex].items[this.itemIndex]
                    .from_container
            )
          );
        } else {
          this.hold = JSON.parse(
            JSON.stringify(
              fromBackup
                ? this.originalHold
                : this.loadplan.holds[this.holdIndex]
            )
          );
        }
      } else if (this.$route.params.set !== undefined) {
        const setUuid = this.$route.params.set;
        const set = this.loadplan.sets.find((s) => s.uuid == setUuid);
        this.set = new AugmentedSet(set, this.loadplan.holds);
        this.hold = undefined;
      }
      this.sceneKey++;
    },
    toggleSearch(): void {
      this.showSearch = this.showSearch === 0 ? false : 0;
      if (this.showSearch !== 0) {
        this.emitSceneEvent("show-all-cargoes", null);
      }
    },
    showNotes(): void {
      this.showNotesDialog = true;
    },
    showRuler(): void {
      (this.$refs.rulerButton as any)?.$el.click();
    },
    showMeasuringTape(): void {
      (this.$refs.tapeButton as any)?.$el.click();
    },
    selectMultiple(): void {
      (this.$refs.selectButton as any)?.$el.click();
    },
    save(): void {
      if (this.loadplan && this.hold) {
        SceneManager.saveContainerState();

        if (!isNaN(this.itemIndex)) {
          const parentHoldData = JSON.parse(
            JSON.stringify(this.loadplan.holds[this.holdIndex])
          ) as HoldData;

          parentHoldData.items.splice(this.itemIndex, 1);
          const nestedItem = containerUtils.createNestedItemFromHold(this.hold);
          nestedItem.qty = 1;

          this.calculateLoadplan({
            items: [nestedItem],
            containers: [parentHoldData],
          }).then((solution) => {
            if (!solution.unloaded_items.length) {
              this.updateLoadplanHolds({
                index: this.holdIndex,
                replace: 1,
                holds: solution.containers,
              });
              this.$router.go(-1);
            }
          });
        } else {
          this.updateLoadplanHolds({
            index: this.holdIndex,
            replace: 1,
            holds: [JSON.parse(JSON.stringify(this.hold))],
          });
        }
      } else if (this.set) {
        let indices = this.set.holdIndices;
        this.updateLoadplanHolds({
          index: indices.start,
          replace: indices.end - indices.start + 1,
          holds: JSON.parse(JSON.stringify(this.set.rendering)),
        });
      }
      this.saveLoadlistResult();
      this.dirty = false;
      setTimeout(() => {
        this.dirty = true;
      }, 1000);
    },
    emitSceneEvent(event: string, data?: any): void {
      SceneManager.eventBus.emit(event, data);
    },
    clearScene(): void {
      clearTimeout(this.showAllItemsTimer);
      this.viewSettings = undefined;
      SceneManager.clearScene();
    },
    saveCamera(): void {
      this.viewSettings = SceneManager.getViewSettings();
      if (this.viewSettings.pos[0]) {
        this.viewSettings.view = "custom";
      }
    },
    measuredDistance(d: number): void {
      this.snackBarText = "Distance: " + this.$options.filters.toLength(d);
      this.showSnackbar = true;
    },
    selectItem(selected: HoldItemWithIndex[]): void {
      this.selectedItems = selected;
    },
    dragOver(e: any): void {
      e.preventDefault();
    },
    getLoadItems(e: DragEvent): HoldInputItem[] {
      let loadItems: any = null;
      try {
        loadItems = JSON.parse(e.dataTransfer.getData("text"));
      } catch {
        return [];
      }
      return loadItems?.unloaded || [];
    },
    cargoDropOnSet(data: { containerIndex: number; e: DragEvent }): void {
      let hold = this.set.rendering.find(
        (c) => c.__indices.start == data.containerIndex
      );
      if (hold) {
        let loadItems = this.getLoadItems(data.e);
        if (loadItems.length) {
          this.loadCargo(loadItems, hold)
            .then((hold) => {
              let index = this.set.rendering.findIndex(
                (h) => h.__indices.start == data.containerIndex
              );
              if (index >= 0) {
                this.saveCamera();
                let current = this.set.rendering[index];

                this.set.rendering[index] = { ...current, ...hold };
                this.updateLoadplanHolds({
                  index: data.containerIndex,
                  replace: 1,
                  holds: [this.set.rendering[index]],
                });
              }
            })
            .catch(() => {});
        }
      }
    },
    cargoDrop(e: DragEvent): void {
      let loadItems: HoldInputItem[] = this.getLoadItems(e);
      if (loadItems.length) {
        this.loadCargo(loadItems, this.hold)
          .then((hold) => {
            if (!isNaN(this.itemIndex)) return;

            this.updateLoadplanHolds({
              index: this.holdIndex,
              replace: 1,
              holds: [hold],
            });
          })
          .catch((r) => {
            if (r == "not loaded") {
              this.loadItemManually(loadItems[0]);
            }
          });
      }
    },
    loadCargo(loadItems: HoldItem[], hold: HoldData): Promise<HoldData> {
      SceneManager.saveContainerState();
      return this.calculateLoadplan({
        items: loadItems,
        containers: [hold],
      }).then((solution) => {
        const numberOfLoadedItems =
          solution.containers?.[0]?.items_count - hold.items_count;
        this.loadedItemsByMouse = numberOfLoadedItems;
        if (numberOfLoadedItems == 0) {
          return Promise.reject("not loaded");
        }
        return solution.containers[0];
        // this.loadedItemsByMouse = numberOfLoadedItems;
        // if (numberOfLoadedItems) {
        //   this.updateLoadplanHolds({
        //     index: holdIndex,
        //     replace: 1,
        //     holds: solution.containers,
        //   });
        // }
        // return this.loadedItemsByMouse > 0;
      });
    },
    changedInteractionState(state: States): void {
      this.currentInteractionState = state;
    },
    updateCog(cog: Vector3): void {
      this.cog = cog;
    },
    selectContainer(containerEvent: ContainerClickInfo): void {
      if (containerEvent) {
        const holdIndex = this.set.rendering.findIndex(
          (h) => h.__indices.start == containerEvent.containerIndex
        );
        if (holdIndex >= 0) {
          const hold = JSON.parse(
            JSON.stringify(this.set.rendering[holdIndex])
          ) as HoldDataWithPosition;
          hold.position_name = hold.position_name || `${holdIndex + 1}`;
          this.selectedHold = new AugmentedHold(hold);
        } else {
          this.selectedHold = null;
        }
      }
    },

    selectCoordinates(e: any): void {
      if (e && !e.defaultPrevented) {
        const padding = 10;
        this.clickedCoordinates = { x: e.layerX, y: e.layerY + padding };
        const w = (this.$refs.containerOverlay as any)?.offsetWidth;
        if (this.clickedCoordinates.x + w > window.innerWidth) {
          this.clickedCoordinates.x -= w + padding;
        } else {
          this.clickedCoordinates.x += padding;
        }
      }
    },
    goToContainer(containerIndex: number, itemIndex?: number): void {
      this.selectedHold = null;
      this.$router.push({
        name: "detail",
        params: {
          version: String(this.loadplan_version),
          hold: String(containerIndex),
          item: !isNaN(itemIndex) ? String(itemIndex) : undefined,
        },
      });
    },
    setCanvasDataUrl(e: any): void {
      e.target.dataset.imagedata = SceneManager.getCanvasCopy().toDataURL(
        "image/png"
      );
    },
    downloadImage(e: any): void {
      let name = `${this.loadlist.name} - ${
        this.hold ? this.hold.name : this.set.set.name
      }`;
      FileSaver.saveAs(
        SceneManager.getCanvasCopy().toDataURL("image/png"),
        name
      );
    },
    loadItemManually(item: HoldInputItem): void {
      if (!isNaN(this.itemIndex)) return;
      const firstItem: HoldItem = {
        ...item,
        ...{
          pos: {
            x:
              this.hold.L + (item.l * this.$toSI(this.loadlist.length_dim)) / 2,
            y: (item.w * this.$toSI(this.loadlist.length_dim)) / 2,
            z: (item.h * this.$toSI(this.loadlist.length_dim)) / 2,
          },
          L: item.l * this.$toSI(this.loadlist.length_dim),
          W: item.w * this.$toSI(this.loadlist.length_dim),
          H: item.h * this.$toSI(this.loadlist.length_dim),
          WT: item.wt * this.$toSI(this.loadlist.weight_dim),
          rotation: [0, 0, 0, "XYZ"],
        },
      };

      this.updateLoadplanHolds({
        index: this.holdIndex,
        replace: 1,
        holds: [
          {
            ...this.hold,
            WT: this.hold.WT + firstItem.WT,
            volume: this.hold.volume + firstItem.L * firstItem.W * firstItem.H,
            items: [...this.hold.items, firstItem],
          },
        ],
      });
    },
    removeItem(hold: HoldData, itemIndex: number) {
      const item = hold.items[itemIndex];
      hold.WT = Math.max(hold.WT - item.WT, 0);
      hold.volume = Math.max(
        hold.volume -
          (item.from_container
            ? item.from_container.volume
            : item.L * item.W * item.H),
        0
      );
      hold.items.splice(itemIndex, 1);
    },
    removeCargo(itemsIndices: number[]): void {
      if (!isNaN(this.itemIndex)) return;
      SceneManager.saveContainerState();
      let hold = JSON.parse(JSON.stringify(this.hold)) as HoldData;
      itemsIndices.forEach((itemIndex) => {
        this.removeItem(hold, itemIndex);
      });
      this.updateLoadplanHolds({
        index: this.holdIndex,
        replace: 1,
        holds: [hold],
      });

      SceneManager.saveContainerState();
    },
    renderDone(): void {
      if (this.loadedItemsByMouse > 0 && this.hold) {
        let r = new Array(this.loadedItemsByMouse)
          .fill(this.hold.items.length)
          .map((x, i) => x - this.loadedItemsByMouse + i);
        this.loadedItemsByMouse = null;
        this.emitSceneEvent("highlight-cargo-by-index", r);
        this.showAllItemsTimer = setTimeout(() => {
          this.emitSceneEvent("show-all-cargoes", null);
        }, 1000);
      }
    },
    searchForCargo(name?: string): void {
      if (!name) {
        this.emitSceneEvent("show-all-cargoes", null);
        return;
      }

      let searchSpace = this.hold ? [this.hold] : this.set.rendering;
      let r: number[] = [];
      let items_count = 0;
      for (let i = 0; i < searchSpace.length; i++) {
        let hold = searchSpace[i];

        r = hold.items.reduce(
          (acc: number[], el: HoldItem, i: number) =>
            el.label.toLowerCase().includes(name.toLowerCase())
              ? [...acc, i + items_count]
              : acc,
          r
        );
        items_count += hold.items.length;
      }
      this.emitSceneEvent("highlight-cargo-by-index", r);
    },
    resizeSceneContainer(): void {
      console.log("resize-scene-container");
    },
    togglePlannerSheet(): void {
      this.showPlannerSheet = !this.showPlannerSheet;
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"));
      });
    },
    updateLoadplanHolds(options: UpdateLoadplanHoldsParams): void {
      LoadlistStore.updateLoadplanHolds(options);
    },
    calculateLoadplan(
      calcData: CalcData
    ): Promise<{
      containers: HoldData[];
      unloaded_items: HoldItem[];
    }> {
      return LoadlistStore.calculateLoadplan(calcData);
    },
    saveLoadlistResult(): void {
      LoadlistStore.saveLoadlistResult();
    },
  },
});
