






















































































import Vue, { PropType } from "vue";
import SceneComponent from "@/components/Custom/SceneComponent.vue";
import {
  HoldInputItem,
  HoldData,
  HoldItem,
  Rotation,
} from "@/models/LoadlistModel";

interface Orientation {
  label: string;
  value: number;
  rotation: Rotation;
  valid_for_cylinders?: boolean;
}

interface OrientationAlternative {
  hold: HoldData;
  orientation: Orientation;
}

export default Vue.extend({
  props: {
    value: Number,
    visible: Boolean,
    readOnly: Boolean,
    item: {
      type: Object as PropType<HoldInputItem>,
    },
  },
  data() {
    return {
      showOrientationsDialog: false,
    };
  },
  components: {
    SceneComponent,
  },
  computed: {
    text(): string {
      return (
        this.alternatives.find((alt) => alt.value === this.internalValue)
          ?.text || this.alternatives[this.alternatives.length - 1].text
      );
    },
    geometryMask(): number {
      switch (this.item?.geometry) {
        case "cylinder":
        case "hollow_cylinder":
          return 13; // Don't allow the non-visible orientations to be set
        default:
          return 63; // Allow all orientations to be set
      }
    },
    alternatives(): { value: number; text: string }[] {
      switch (this.item?.geometry) {
        case "cylinder":
        case "hollow_cylinder":
          return [
            { value: 1, text: "Standing" },
            { value: 4, text: "Laying (crosswise)" },
            { value: 8, text: "Laying (lengthwise)" },
            { value: 0, text: "Custom..." },
          ];

        default:
          return [
            { value: 1, text: "Longship" },
            { value: 3, text: "Rotatable" },
            { value: 63, text: "Tiltable" },
            { value: 0, text: "Custom..." },
          ];
      }
    },
    internalValue(): number {
      if (this.value !== undefined) return this.value;
      if (this.item.not_rotatable) return 1;
      if (this.item.tiltable) return 63;

      return 3;
    },
    chunkedRotationItems(): OrientationAlternative[][] {
      return this.rotatedItems.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
    },
    orientations(): Orientation[] {
      return [
        {
          label: "L x W x H",
          rotation: [0, 0, 0, "XYZ"],
          value: 1,
          valid_for_cylinders: true,
        },
        {
          label: "W x L x H",
          rotation: [0, 0, Math.PI * 0.5, "XYZ"],
          value: 2,
        },
        {
          label: "L x H x W",
          rotation: [Math.PI * 0.5, 0, 0, "XYZ"],
          value: 4,
          valid_for_cylinders: true,
        },
        {
          label: "H x L x W",
          rotation: [Math.PI * 0.5, Math.PI * 0.5, 0, "XYZ"],
          value: 8,
          valid_for_cylinders: true,
        },
        {
          label: "H x W x L",
          rotation: [0, Math.PI * 0.5, 0, "XYZ"],
          value: 16,
        },
        {
          label: "W x H x L",
          rotation: [Math.PI * 0.5, 0, Math.PI * 0.5, "XYZ"],
          value: 32,
        },
      ];
    },
    rotatedItems(): OrientationAlternative[] {
      let item = JSON.parse(JSON.stringify(this.item as HoldItem));

      let max = Math.max(item.l, item.w, item.h);

      if (item.l && item.w && item.h) {
        item.L = item.l / max;
        item.W = item.w / max;
        item.H = item.h / max;
      } else {
        item.L = 2;
        item.W = 1;
        item.H = 0.5;
      }

      item.tiltable = false;
      item.orientations = item.orientations || 3;
      item.label = item.label || "";
      item.color = item.color || "#ff0000";
      item.pos = { x: item.L * 0.5, y: item.W * 0.5, z: item.H * 0.5 };
      item.qty = 1;

      return this.orientations
        .filter(
          (i) =>
            !["cylinder", "hollow_cylinder"].includes(item.geometry) ||
            i.valid_for_cylinders
        )
        .map((v) => {
          return {
            hold: {
              name: v.label,
              items: [{ ...item, rotation: v.rotation }],
              L: item.L,
              W: item.W,
              H: item.H,
              no_end_walls: true,
              no_side_walls: true,
              no_roof: true,
            } as HoldData,
            orientation: v,
          };
        });
    },
  },
  watch: {},
  methods: {
    valueComperator(a: number, b: any): boolean {
      switch (this.item?.geometry) {
        case "cylinder":
        case "hollow_cylinder":
          switch (this.internalValue) {
            case undefined:
            case 1:
              return a === 1;
            case 4:
              return a === 4;
            case 8:
              return a === 8;
            default:
              return a === 0;
          }
        default:
          switch (this.internalValue) {
            case undefined:
            case 3:
              return a === 3;
            case 1:
              return a === 1;
            case 63:
              return a === 63;
            default:
              return a === 0;
          }
      }
    },
  },
});
