var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('container-library-component',{attrs:{"holdType":_vm.$route.params.type},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var containers = ref.paginatedItems;
return [_c('v-expansion-panels',{staticClass:"unselectable",attrs:{"focusable":""}},_vm._l((containers),function(hold){return _c('v-expansion-panel',{key:hold.id,on:{"click":function($event){return _vm.$vuetify.goTo($event.target)}}},[_c('v-expansion-panel-header',[_c('span',[_c('strong',[_c('span',[(!hold.default)?_c('v-icon',[_vm._v("mdi-account")]):_vm._e(),_vm._v(" "+_vm._s(hold.name)+" ")],1)]),_vm._v(" - ("+_vm._s(_vm._f("toLength")(hold.data.L))+" x "+_vm._s(_vm._f("toLength")(hold.data.W))+" "),(hold.data.H > 0)?_c('span',[_vm._v("x "+_vm._s(_vm._f("toLength")(hold.data.H)))]):_vm._e(),_vm._v(" ) ")])]),_c('v-expansion-panel-content',[_c('scene-component',{attrs:{"hold-object":hold.data,"canvas-width":300,"canvas-height":150}}),(!hold.default && _vm.user.is_editor)?_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'modify_hold',
                  params: {
                    hold_id: hold.id,
                    type: hold.base_type,
                  },
                })}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1):_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'modify_hold',
                  params: {
                    hold_id: hold.id,
                    type: hold.base_type,
                  },
                })}}},[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")]),_vm._v("View ")],1)],1)],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }