var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.displayConfiguration)?_c('div',[_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v("Drag rows up/down to change the loading order")]),_c('v-toolbar',{staticClass:"my-1 ml-2 no-padding",attrs:{"dense":"","elevation":"0"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":""},on:{"click":_vm.newGroup}},on),[_c('v-icon',[_vm._v("mdi-folder-plus-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Add group")])],1)]}}],null,false,2283096152)},[_c('span',[_vm._v("Add new group to set")])])],1),_c('draggable',_vm._b({staticClass:"slots",attrs:{"group":"slot"},on:{"start":function($event){_vm.dragSlot=true},"end":function($event){_vm.dragSlot=false},"change":_vm.reorder},model:{value:(_vm.set.data.slots),callback:function ($$v) {_vm.$set(_vm.set.data, "slots", $$v)},expression:"set.data.slots"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.set.data.slots),function(slot,slot_index){return _c('div',{key:JSON.stringify(slot.name),staticClass:"outline-row"},[_c('v-col',{staticClass:"py-0 px-0",staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-height":"56px"}},[_c('v-icon',{staticClass:"draggable"},[_vm._v("mdi-drag")]),(_vm.expandedGroups.indexOf(slot_index) < 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandedGroups.push(slot_index)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.expandedGroups.splice(_vm.expandedGroups.indexOf(slot_index), 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v(_vm._s(_vm.editingName == slot_index ? '' : (slot.name || ("Group " + (slot_index + 1))) + " (" + (slot.configurations[_vm.displayConfiguration[slot_index]].group.length) + ")"))]),(_vm.editingName != slot_index)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-auto",attrs:{"color":"rgba(0,0,0,0.25)","icon":"","x-small":""},on:{"click":function($event){return _vm.editSlotName(slot_index)}}},on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_vm._v(" Edit group name ")]):_vm._e(),(_vm.editingName == slot_index)?_c('v-text-field',{ref:'slot-name-'+slot_index,refInFor:true,staticStyle:{"max-width":"200px"},attrs:{"value":slot.name,"rules":_vm.nameRules,"required":""},on:{"keydown":function($event){return _vm.keyDownSlotName($event, slot_index)},"blur":function($event){return _vm.blurSlotName($event, slot)}}}):_vm._e(),(slot.configurations.length > 1)?_c('v-select',{staticStyle:{"max-width":"180px"},attrs:{"items":slot.configurations.map(function (_, i) {
                return {
                  text: ("Configuration " + (i + 1)),
                  value: i,
                };
              }),"hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.changeConfiguration(slot_index)}},model:{value:(_vm.displayConfiguration[slot_index]),callback:function ($$v) {_vm.$set(_vm.displayConfiguration, slot_index, $$v)},expression:"displayConfiguration[slot_index]"}}):_vm._e()],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.$emit('addToSlot', slot_index)}}},on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Container")])],1)]}}],null,true)},[_vm._v(" Add new container to this group ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","disabled":slot_index === _vm.set.data.slots.length - 1},on:{"click":function($event){return _vm.shiftDown(slot_index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-down-thin-circle-outline")])],1),_c('v-list-item-content',[_vm._v(" Shift down ")])],1),_c('v-list-item',{attrs:{"link":"","disabled":slot_index == 0},on:{"click":function($event){return _vm.shiftUp(slot_index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-up-thin-circle-outline")])],1),_c('v-list-item-content',[_vm._v(" Shift up ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.newConfiguration(slot_index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus-circle-multiple-outline")])],1),_c('v-list-item-content',[_vm._v(" New configuration ")])],1),_c('v-list-item',{attrs:{"disabled":slot.configurations.length == 1,"link":""},on:{"click":function($event){slot.configurations.splice(
                  _vm.displayConfiguration[slot_index],
                  1
                );
                _vm.displayConfiguration[slot_index] = 0;
                _vm.rerender()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-minus-circle-multiple-outline")])],1),_c('v-list-item-content',[_vm._v(" Remove configuration ")])],1),_c('v-list-item',{attrs:{"link":"","disabled":slot.configurations[_vm.displayConfiguration[slot_index]].group
                  .length < 2},on:{"click":function($event){return _vm.splitGroup(slot_index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-split-horizontal")])],1),_c('v-list-item-content',[_vm._v(" Split group ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.moveModel.slotIndex = slot_index
              _vm.showMoveModal = true}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cursor-move")])],1),_c('v-list-item-content',[_vm._v(" Move group ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteGroup(slot_index)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1),_c('v-list-item-content',[_vm._v(" Delete group ")])],1)],1)],1)],1),(_vm.expandedGroups.indexOf(slot_index) >= 0)?_c('div',{staticClass:"pb-2"},[_c('draggable',_vm._b({staticClass:"containers",attrs:{"group":"containers"},on:{"start":function($event){_vm.dragContainer=true},"end":function($event){_vm.dragContainer=false},"change":_vm.reorder},model:{value:(slot.configurations[_vm.displayConfiguration[slot_index]].group),callback:function ($$v) {_vm.$set(slot.configurations[_vm.displayConfiguration[slot_index]], "group", $$v)},expression:"slot.configurations[displayConfiguration[slot_index]].group"}},'draggable',_vm.dragOptions,false),_vm._l((slot.configurations[
            _vm.displayConfiguration[slot_index]
          ].group),function(ht,ht_index){return _c('v-row',{key:(slot_index + "-" + ht_index),staticClass:"outline-row pt-1 item",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{staticClass:"draggable"},[_vm._v("mdi-drag")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"9","md":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"my-auto"},[_c('label',[_vm._v(_vm._s(_vm.position_name(slot_index, ht_index))+".")])]),_c('div',{staticClass:"ml-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.containerOf(ht).name)+" "+_vm._s(ht.props ? "*" : "")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("toLength")(_vm.containerOf(ht).L,false))+" x "+_vm._s(_vm._f("toLength")(_vm.containerOf(ht).W,false))+" x "+_vm._s(_vm._f("toLength")((_vm.containerOf(ht).H || _vm.containerOf(ht).max_height),true))+" ")])])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2"}},[_c('v-row',{staticClass:"justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","ripple":false},on:{"click":function($event){return _vm.openContainerDetails(slot_index, ht)}}},on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Open container details")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","ripple":false},on:{"click":function($event){return _vm.removeContainer(slot_index, ht_index)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove from group")])])],1)],1)],1)}),1)],1):_vm._e()])],1)}),0),(_vm.showMoveModal)?_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showMoveModal),callback:function ($$v) {_vm.showMoveModal=$$v},expression:"showMoveModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Move Group")]),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',[_c('v-row',[_c('length-input-field',{staticClass:"mx-2",attrs:{"label":"X (Sideways)"},model:{value:(_vm.moveModel.x),callback:function ($$v) {_vm.$set(_vm.moveModel, "x", $$v)},expression:"moveModel.x"}})],1)],1),_c('v-col',[_c('v-row',[_c('length-input-field',{staticClass:"mx-2",attrs:{"label":"Y (Depth)"},model:{value:(_vm.moveModel.y),callback:function ($$v) {_vm.$set(_vm.moveModel, "y", $$v)},expression:"moveModel.y"}})],1)],1),_c('v-col',[_c('v-row',[_c('length-input-field',{staticClass:"mx-2",attrs:{"label":"Z (Up/Down)"},model:{value:(_vm.moveModel.z),callback:function ($$v) {_vm.$set(_vm.moveModel, "z", $$v)},expression:"moveModel.z"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.moveGroup(_vm.moveModel.slotIndex);
            _vm.showMoveModal = false;}}},[_vm._v("Move")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.showMoveModal = false}}},[_vm._v("Cancel")])],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }