






















































import API from "@/API";
import Vue from "vue";

export default Vue.extend({
  name: "password_reset",
  data() {
    return {
      isLoading: false,
      email: "",
      formSent: false,
    };
  },
  computed: {},
  methods: {
    reset() {
      this.isLoading = true;
      API.passwordReset({
        email: this.email.trim(),
      })
        .then(() => {
          this.isLoading = false;
          this.formSent = true;
        })
        .catch(() => {
          this.formSent = true;
          this.isLoading = false;
        });
    },
  },
});
