import dimTools from "@/misc/dimTools";
import { UserCompanyStore } from "@/store/index";
import Vue from "vue";
export default {
  toLength: Vue.filter("toLength", function toLength(
    value: number,
    showUnit = true
  ): string {
    const unit: string = UserCompanyStore.length_dim;
    value *= dimTools.fromSI(unit);
    return `${dimTools.roundForDim(value, unit)}${showUnit ? " " + unit : ""}`;
  }),
  toVolume: Vue.filter("toVolume", function toVolume(
    value: number,
    showUnit = true
  ): string {
    let unit: string = UserCompanyStore.length_dim;

    if (unit !== "IN") {
      unit = "M";
    } else {
      unit = "FT";
      value *= Math.pow(dimTools.fromSI(unit), 3);
    }
    return `${Math.round(value * 1000) / 1000} ${
      showUnit ? unit + "\u00B3" : ""
    }`;
  }),
  toWeight: Vue.filter("toWeight", function toWeight(
    value: number,
    showUnit = true
  ): string {
    const unit: string = UserCompanyStore.weight_dim;
    value *= dimTools.fromSI(unit);
    return `${Math.round(value)} ${showUnit ? unit : ""}`;
  }),
  roundTwoDecimals: Vue.filter("roundTwoDecimals", function roundTwoDecimals(
    val: number
  ): number {
    return Math.round(val * 100) / 100;
  }),
  percentage: Vue.filter("percentage", function percentage(
    val: number
  ): number {
    return Math.round(val * 100);
  }),
  snakeToTitleCase: Vue.filter("snakeToTitleCase", function snakeToTitleCase(
    value: string
  ): string {
    if (!value) return "";
    return value
      .split("_")
      .map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
      })
      .join(" ");
  }),
};
