

















































































































































































































/* eslint-disable */
import Vue from "vue";
import Vuetify from "vuetify/lib";
import newListModal from "@/components/Modals/NewList.vue";
import { HoldInputItem, Loadlist, UnloadedItem } from "@/models/LoadlistModel";
import { LoadlistStore } from "@/store/index";
import ItemUtils from "@/misc/itemUtils";

export default Vue.extend({
  name: "unloadedItems",
  components: {
    newListModal,
  },
  data() {
    return {
      show: false,
      isMini: true,
      isAsc: false,
      checkedItems: [],
      itemsContainerHeight: "500",
      orderBy: "label",
      orderByItems: [
        {
          title: "Name",
          value: "label",
        },
        {
          title: "Length",
          value: "l",
        },
        {
          title: "Width",
          value: "w",
        },
        {
          title: "Height",
          value: "h",
        },
        {
          title: "Weight",
          value: "wt",
        },
        {
          title: "Consignment",
          value: "shipment_id",
        },
        {
          title: "Priority",
          value: "priority",
        },
      ],
      selectAllToggle: true,
      searchField: "",
      showSnackbar: false,
      snackBarText: "",
      showAddNewLoadlistModal: false,
      showRemoveUnloadedItemsDialog: false,
      highlightWarningSymbol: false,
    };
  },
  watch: {
    unloaded_items_length: {
      handler: function(newVal: number, oldVal: number): void {
        if (oldVal < newVal) {
          this.checkedItems = [];
          this.highlightWarningSymbol = true;
          setTimeout(() => {
            this.highlightWarningSymbol = false;
          }, 1000);
        }
        if (oldVal === 0 && newVal > 0) {
          this.expand(null);
        }

        this.show = newVal > 0;
      },
      immediate: true,
    },
  },
  computed: {
    unloaded_items_ordered(): any[] {
      const sortItems = require("vuetify/lib/util/helpers.js").sortItems;
      let ordered = sortItems(
        [...this.unloaded_items],
        [this.orderBy],
        [!this.isAsc]
      );
      if (this.searchField) {
        return ordered.filter(
          (item: any) =>
            item.label.toLowerCase().indexOf(this.searchField.toLowerCase()) >
            -1
        );
      }
      return ordered;
    },
    unloaded_items_length(): number {
      return this.unloaded_items.length;
    },
    selected_items(): any[] {
      return this.unloaded_items_ordered.filter(
        (i) => this.checkedItems.indexOf(i.uid) > -1
      );
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    unloaded_items(): UnloadedItem[] {
      return LoadlistStore.unloaded_items;
    },
    is_calculating(): boolean {
      return LoadlistStore.is_calculating;
    },
  },
  methods: {
    expand(e: any): void {
      this.isMini = false;
    },
    selectAll(e: any): void {
      if (this.selectAllToggle)
        this.checkedItems = this.unloaded_items_ordered.map((i) => i.uid);
      else this.checkedItems = [];
      this.selectAllToggle = !this.selectAllToggle;
    },
    selectItem(item: any): void {
      // check
      const index = this.checkedItems.indexOf(item);
      if (index >= 0) {
        this.checkedItems.splice(index, 1);
      } else {
        this.checkedItems.push(item);
      }
    },
    dragStart(item: any, e: any): void {
      if (this.checkedItems.indexOf(item) < 0) this.checkedItems.push(item);
      let loadItems = this.selected_items as HoldInputItem[];
      if (e.altKey)
        loadItems = loadItems.map((i) => {
          return {
            ...i,
            qty: 1,
          };
        });
      e.dataTransfer.setData("text", JSON.stringify({ unloaded: loadItems }));
    },
    createNewLoadlist(): void {
      (this.$refs.newListModal as any).extendLoadlist({
        data: JSON.parse(JSON.stringify(this.selected_items)),
        length_dim: this.loadlist.length_dim,
        weight_dim: this.loadlist.weight_dim,
      });
      this.showAddNewLoadlistModal = true;
    },
    removeUnloadedItems() {
      LoadlistStore.setLoadlistProperty({
        key: "data",
        value: ItemUtils.removeItemsFromLoadlist(
          this.loadlist.data,
          JSON.parse(JSON.stringify(this.selected_items))
        ),
      });
    },
    onResize(): void {
      const itemsList = this.$refs.itemsList as HTMLElement;
      const navBar = this.$el.firstChild as HTMLElement;
      if (itemsList && navBar) {
        let top = itemsList.getBoundingClientRect().top;
        let bottom = navBar.getBoundingClientRect().bottom;
        const valueString = (bottom - top).toString();
        this.itemsContainerHeight = valueString;
      }
    },
  },
});
