
















































































import API from "@/API";
import Vue from "vue";

export default Vue.extend({
  name: "password_reset_confirm",
  data() {
    return {
      isLoading: false,
      new_password1: "",
      new_password2: "",
      formSent: false,
      errorMessages: [],
    };
  },
  computed: {},
  methods: {
    reset_confirm() {
      this.isLoading = true;
      API.passwordResetConfirm({
        new_password1: this.new_password1,
        new_password2: this.new_password2,
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      })
        .then(() => {
          this.isLoading = false;
          this.formSent = true;
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.errorMessages = Object.values(e.response.data).flat();
          }
          this.isLoading = false;
        });
    },
  },
});
