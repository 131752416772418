





































































import Vue, { PropType } from "vue";
import containerLibraryComponent from "@/components/Custom/ContainerLibrary.vue";
import setsLibraryComponent from "@/components/Custom/SetsLibrary.vue";

import sceneComponent from "@/components/Custom/SceneComponent.vue";
import { UserCompanyStore } from "@/store/index";
import { ListType } from "@/models/LoadlistModel";
import { User } from "@/models/UserCompanyModel";
export default Vue.extend({
  name: "equipment",
  components: {
    containerLibraryComponent,
    setsLibraryComponent,
    sceneComponent,
  },
  data() {
    return {};
  },
  computed: {
    user(): User {
      return UserCompanyStore.user;
    },
    base_type(): ListType {
      return (this.$route.params.type as ListType) || "SEA";
    },
  },
  methods: {},
  filters: {},
});
