<template>
  <v-text-field
    v-bind="this.$attrs"
    :value="displayValue"
    min="0"
    v-on="inputListeners"
    :prefix="prefix"
    type="number"
  ></v-text-field>
</template>

<script>
export default {
  name: "rotation-input-field",
  data() {
    return {
      displayValue: undefined,
    };
  },
  props: {
    value: Number,
    sup: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    prefix() {
      return "[degrees]";
    },
    inputListeners() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      return {
        ...this.$listeners,
        input: function (event) {
          vm.$emit("input", vm.toInternalValue(event));
        },
        change: function (event) {
          vm.$emit("change", vm.toInternalValue(event));
        },
      };
    },
  },
  watch: {
    value: {
      handler: function () {
        if (!isNaN(this.value))
          this.displayValue = Math.round((this.value * 180) / Math.PI);
      },
      immediate: true,
    },
  },
  methods: {
    toInternalValue(val) {
      const v = (val * Math.PI) / 180;
      return isNaN(v) ? null : v;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
