import ItemUtils from "@/misc/itemUtils";
import containerUtils from "./containerUtils";
import { LoadlistStore } from "@/store/index";
import router from "@/router";
import { SceneManager } from "@/graphics/sceneManager";
import { HoldData, UnloadedItem } from "@/models/LoadlistModel";

export default {
  loadFromUnloaded(
    toHoldIndex: number,
    toPosition: number,
    unloadedItems: UnloadedItem[]
  ): Promise<number | void> {
    let toHold: HoldData = LoadlistStore.loadplan.holds[toHoldIndex];

    // Update hold with camera props if needed
    if (router.currentRoute.name === "detail")
      toHold = { ...toHold, camera: SceneManager.getViewSettings() };

    const toItems = ItemUtils.bundledItems(toHold.items);

    const toIndex = (toItems[toPosition] || [
      {
        itemIndices: [toHold.items_count],
      },
    ])[0].itemIndices[0];

    const calcData = {
      settings: LoadlistStore.loadplan.settings,
      to_container: toHold,
      to_index: toIndex || 0,
      items: unloadedItems,
      hideProgress: true,
    };

    return LoadlistStore.insertItemsInLoadplan(calcData).then(
      (solution: any) => {
        const newToHold = solution.containers[0];
        if (containerUtils.compareContainers(newToHold, toHold)) return -1;

        LoadlistStore.updateLoadplanHolds({
          index: toHoldIndex,
          replace: 1,
          holds: [newToHold],
        });
      }
    );
  },

  removeLoadedItem(
    fromHoldIndex: number,
    fromIndices: number[]
  ): Promise<void> {
    let fromHold = LoadlistStore.loadplan.holds[fromHoldIndex];

    // Update hold with camera props if needed
    if (router.currentRoute.name === "detail")
      fromHold = { ...fromHold, camera: SceneManager.getViewSettings() };

    const calcData = {
      settings: LoadlistStore.loadplan.settings,
      containers: [
        {
          from_container: fromHold,
          from_indices: fromIndices,
        },
      ],
      hideProgress: true,
    };
    return LoadlistStore.removeItemsInLoadplan(calcData).then(
      (solution: any) => {
        const fromContainer: HoldData = solution.containers[0];
        LoadlistStore.updateLoadplanHolds({
          index: fromHoldIndex,
          replace: 1,
          holds: [fromContainer],
        });
      }
    );
  },

  moveLoadedItems(
    fromHoldIndex: number,
    fromItemIndices: number[],
    toHoldIndex: number,
    toPosition: number,
    forceMove: boolean
  ): Promise<number | void> {
    const reorder = toHoldIndex === fromHoldIndex; //  are we moving things within a single container
    let fromHold = LoadlistStore.loadplan.holds[fromHoldIndex];
    const toHold = LoadlistStore.loadplan.holds[toHoldIndex];
    const toItems = ItemUtils.bundledItems(toHold.items);

    const toIndex = (toItems[toPosition] || [
      {
        itemIndices: [toHold.items_count],
      },
    ])[0].itemIndices[0];

    // Update hold with camera props if needed
    if (router.currentRoute.name === "detail")
      fromHold = { ...fromHold, camera: SceneManager.getViewSettings() };

    const moveData = {
      settings: LoadlistStore.loadplan.settings,
      from_container: fromHold,
      to_container: reorder ? undefined : toHold,
      from_indices: fromItemIndices,
      to_index: toIndex || 0,
      hideProgress: true,
    };
    return LoadlistStore.moveItemsInLoadplan(moveData).then((solution: any) => {
      if (!forceMove && !!solution.unloaded_items.length) return -1;
      let newFromHold = solution.containers.find(
        (c: HoldData) => c.uuid == fromHold.uuid
      );
      if (!newFromHold) {
        newFromHold = solution.containers[0];
      }

      // Use UUIDs, if they don't exist, use the index of the array
      if (!reorder) {
        const newToHold = toHold.uuid
          ? solution.containers.find((c: HoldData) => c.uuid === toHold.uuid)
          : solution.containers[1];

        // If the old hold is the same as the new, return an error
        if (containerUtils.compareContainers(newToHold, toHold)) return -1;

        LoadlistStore.updateLoadplanHolds({
          index: toHoldIndex,
          replace: 1,
          holds: [newToHold],
        });
      }
      LoadlistStore.updateLoadplanHolds({
        index: fromHoldIndex,
        replace: 1,
        holds: [newFromHold],
      });
    });
  },
};
