































































































import itemProperties, { ItemProperty } from "@/misc/itemProperties";
import { HoldInputItem, ItemSelector, Loadlist } from "@/models/LoadlistModel";
import { LoadlistStore } from "@/store";

import Vue, { PropType } from "vue";
import LengthInputField from "@/components/Custom/LengthInputField.vue";
import WeightInputField from "@/components/Custom/WeightInputField.vue";
export default Vue.extend({
  components: { LengthInputField, WeightInputField },
  name: "item-selector",
  data: function() {
    return {
      properties: itemProperties
        .props()
        .filter(
          (p) =>
            (p.type == "integer" ||
              p.type === "float" ||
              p.type === "string" ||
              ["class_id", "shipment_id"].includes(p.key)) &&
            !p.additional &&
            !p.readOnly &&
            p.key !== "orientations"
        )
        .map((p) => {
          return { value: p.key, ...p };
        }),

      comparisons: [
        { text: "Equals", value: "eq" },
        { text: "Not equals", value: "ne" },
        { text: "Less than", value: "lt" },
        { text: "Greater than", value: "gt" },
      ],
      selector: this.value || {
        property: "label",
        comparison: "eq",
        value: [],
      },
    };
  },
  props: {
    items: Array as PropType<HoldInputItem[]>,
    value: Object as PropType<ItemSelector>, // string[] | ItemSelector
  },
  watch: {
    selector: {
      handler(v) {
        this.$emit("input", v);
      },
      deep: true,
    },
  },
  computed: {
    isOptionsField(): boolean {
      return this.property?.type === "string";
    },
    property(): ItemProperty {
      return this.properties.find((p) => p.key == this.selector.property);
    },
    lengthDim(): string {
      return this.loadlist?.length_dim;
    },
    weightDim(): string {
      return this.loadlist?.weight_dim;
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },

    valueOptions(): string[] {
      if (this.isOptionsField) {
        return this.items
          .map(
            (v) => v[this.selector.property as keyof HoldInputItem] as string
          )
          .filter((v) => v)
          .sort()
          .filter((v, index, a) => index === 0 || a[index - 1] !== v);
      }
      return [];
    },
    matchCount(): number {
      let dim = this.property.dimension;
      let converter = dim
        ? (v: number) =>
            v * this.$toSI(dim === "length" ? this.lengthDim : this.weightDim)
        : (v: any) => v;

      return this.items
        .map((i) => i[this.selector.property as keyof HoldInputItem])
        .filter((v) => {
          let value = converter(v);

          let eq = this.selector.value == value;
          if (this.selector.value instanceof Array) {
            eq = this.selector.value.includes(value);
          }
          if (this.selector.comparison === "eq") return eq;
          if (this.selector.comparison === "ne") return !eq;
          if (this.selector.comparison === "gt")
            return value > this.selector.value;
          else return value < this.selector.value;
        }).length;
    },
  },
  mounted(): void {},
  methods: {
    propChange(v: any): void {
      if (this.isOptionsField) {
        this.selector.value = [];
      } else {
        this.selector.value = 0;
      }
    },
  },
});
