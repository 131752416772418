import Vue from "vue";
import API from "@/API";
import { Mutex } from "async-mutex";
import { CalcData } from "@/models/CalculationModel";
import {
  HoldData,
  InsertItemsInLoadplanParams,
  MoveItemsInLoadplanParams,
  RemoveItemsInLoadplanParams,
  UnloadedItem,
  UpdateLoadplanHoldsParams,
  LoadlistGroup,
  Loadlist,
  Loadplan,
  LoadConfiguration,
} from "@/models/LoadlistModel";
import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";
import { UserCompanyStore } from "../index";
import { Set, SetTypeData } from "@/models/SetsModel";
import { getSerializerError } from "@/misc/errorUtils";
import itemUtils from "@/misc/itemUtils";

const APP_VERSION = process.env.VUE_APP_VERSION;
const mutex = new Mutex();

const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

@Module({
  name: "LoadlistModule",
})
export default class LoadlistModule extends VuexModule {
  private _hold_uid = 0;
  private _is_calculating = false;
  private _loadlist: Loadlist | null = null;
  private _loadplan_version = 0;
  private _calculation_info_message: string | null = null;
  private _groups: LoadlistGroup[] = [];
  private _unloaded_items: UnloadedItem[] = [];
  private _result_has_changed = false;

  @Mutation
  setLoadlist(loadlist: Loadlist | null): void {
    if (loadlist) {
      try {
        loadlist.result.app_version = APP_VERSION;
        for (let i = 0; i < loadlist.result.versions.length; i++) {
          for (let j = 0; j < loadlist.result.versions[i].holds.length; j++) {
            this._hold_uid++;
            loadlist.result.versions[i].holds[j].uid = this._hold_uid;
          }
          Object.freeze(loadlist.result.versions[i].holds);
        }
      } catch (e) {
        console.error("Wrong load list format", e);
      }
    }
    this._loadlist = loadlist;
    this._loadplan_version = 0;
    this._unloaded_items = [];
    this._result_has_changed = false;
  }
  @Mutation
  setLoadlistProperty(payload: { key: string; value: any }): void {
    Vue.set(this._loadlist, payload.key, payload.value);
  }
  @Mutation
  setLoadlistResultProperty(payload: { key: string; value: any }): void {
    Vue.set(this._loadlist.result, payload.key, payload.value);
  }
  @Mutation
  setLoadplanVersion(version: number): void {
    this._loadplan_version = version;
  }
  @Mutation
  setCalculationInfoMessage(message: string | null): void {
    this._calculation_info_message = message;
  }
  @Mutation
  setLoadplanProperty(payload: { key: string; value: any }): void {
    Vue.set(
      this._loadlist.result.versions[this._loadplan_version],
      payload.key,
      payload.value
    );
  }
  @Mutation
  setLoadlistGroups(payload: LoadlistGroup[]): void {
    this._groups = payload;
  }
  @Mutation
  setCalculationStatus(payload: boolean): void {
    this._is_calculating = payload;
  }
  @Mutation
  addLoadplanVersionMutation(payload: Loadplan): void {
    this._loadlist.result.versions.push(payload);
  }
  @Mutation
  removeLoadplanVersionMutation(index: number): void {
    this._loadlist.result.versions.splice(index, 1);
  }
  @Mutation
  changeLoadplanHolds(payload: UpdateLoadplanHoldsParams): void {
    let clone = [
      ...this._loadlist.result.versions[this._loadplan_version].holds,
    ];
    clone.splice(
      payload.index ?? 0,
      payload.replace ?? 0,
      ...(payload?.holds?.map((i) => {
        i.uid = ++this._hold_uid;
        return i;
      }) || [])
    );
    Object.freeze(clone);
    this._loadlist.result.versions[this._loadplan_version].holds = clone;
  }
  @Mutation
  changeLoadplanSets(payload: {
    index?: number;
    replace?: number;
    sets?: Set[];
  }): void {
    const version = this._loadlist.result.versions[this._loadplan_version];
    if (version.sets) {
      version.sets.splice(
        payload.index ?? 0,
        payload.replace ?? 0,
        ...(payload?.sets || [])
      );
    } else {
      version.sets = payload.sets;
    }
  }

  @Mutation
  setUnloadedItems(data: UnloadedItem[]): void {
    this._unloaded_items = data;
  }
  @Mutation
  setUnloadedItemsWithReasons(data: UnloadedItem[]): void {
    const version = this._loadlist.result?.versions[this._loadplan_version];
    if (version) version.unloaded_items = data;
  }
  @Mutation
  updateUnloadedItemsWithReasons(data: UnloadedItem[]): void {
    const version = this?._loadlist?.result?.versions[this._loadplan_version];
    if (version) {
      if (!version.unloaded_items) {
        version.unloaded_items = []
        return
      }

      if (data.length) {
        for (let item of data) {
          const index = version.unloaded_items.findIndex(i => i.id === item.id)
          if (index > -1) {
            if (version.unloaded_items[index].reason != item.reason)
              version.unloaded_items[index] = item
          } else
            version.unloaded_items.push(item)
        }
      } else {
        version.unloaded_items = version.unloaded_items.filter(i => this._unloaded_items.some(j => itemUtils.compareItems(i, j)))
      }
    };
  }
  @Mutation
  setLoadlistResultHasChanged(value: boolean): void {
    this._result_has_changed = value;
  }

  get is_calculating(): boolean {
    return this._is_calculating;
  }
  get loadlistGroups(): LoadlistGroup[] {
    return this._groups;
  }
  get loadlist(): Loadlist {
    return this._loadlist;
  }
  get loadplan_version(): number {
    return this._loadplan_version;
  }
  get readonly(): boolean {
    return (
      !UserCompanyStore.is_authenticated && this.loadlist.public_access === "RO"
    );
  }
  get calculation_info_message(): string {
    return this._calculation_info_message;
  }
  get loadplan(): Loadplan | undefined {
    return this.loadlist?.result?.versions?.[this._loadplan_version];
  }
  get loadplans(): Loadplan[] | undefined {
    return this.loadlist?.result?.versions;
  }
  get logo_url(): string | null {
    if (this.loadlist?.logo_url) {
      if (isValidHttpUrl(this.loadlist.logo_url)) {
        return this.loadlist.logo_url;
      } else if (
        process.env.VUE_APP_API_HOST &&
        this.loadlist.logo_url.startsWith("/")
      ) {
        return process.env.VUE_APP_API_HOST + this.loadlist.logo_url;
      }
    }
    return null;
  }
  get loadplan_holds(): HoldData[] | undefined {
    return this.loadplan?.holds;
  }
  get unloaded_items(): UnloadedItem[] {
    return this._unloaded_items;
  }
  get unloaded_items_with_reasons(): UnloadedItem[] {
    return this.loadplan?.unloaded_items || [];
  }
  get loadlist_result_has_changed(): boolean {
    return this._result_has_changed;
  }
  get is_locked(): boolean {
    return !!this.loadlist?.result?.locked;
  }

  @Action({ rawError: true })
  syncGroups(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      API.getLoadlistGroups()
        .then((response) => {
          this.setLoadlistGroups(response.data.results ?? []);
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  @Action({ rawError: true })
  clearLoadlistData(): void {
    this.setLoadlistGroups([]);
    this.setLoadlist(null);
  }
  @Action({ rawError: true })
  clearLoadlist(): void {
    this.setLoadlist(null);
  }
  @Action({ rawError: true })
  getLoadlist(id: string): Promise<undefined> {
    this.setLoadlist(null);
    if (!id) return;
    return new Promise((resolve, reject) => {
      API.getLoadlist(id)
        .then((response) => {
          this.setLoadlist(response.data);
          if (!this.loadplan) {
            this.addLoadplanVersion();
          }
          resolve(undefined);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  @Action({ rawError: true })
  async saveLoadlist(): Promise<undefined> {
    return await mutex.runExclusive(async () => {

      return new Promise((resolve, reject) => {
        if (
          !this.loadlist ||
          (!UserCompanyStore.is_authenticated &&
            this.loadlist.public_access !== "RW")
        )
          return reject(undefined);


        API.saveLoadlist(this.loadlist)
          .then((r) => {
            // update the version number of our loadlist so we can save again and again :)
            if (this?.loadlist?.id === r.data.id)
              this.setLoadlistProperty({ key: "version", value: r.data.version });
            resolve(undefined);
          })
          .catch((error) => {
            if (error.response?.status == 409) {
              this.getLoadlist(this.loadlist.id);
            }
            reject(error);
          });

      });
    });

  }
  @Action({ rawError: true })
  async saveLoadlistResult(): Promise<unknown> {
    return await mutex.runExclusive(async () => {
      return new Promise((resolve, reject) => {
        if (
          !this.loadlist ||
          (!UserCompanyStore.is_authenticated &&
            this.loadlist.public_access !== "RW")
        ) return reject(undefined);
        API.patchLoadlist({
          id: this.loadlist.id,
          result: this.loadlist.result,
          version: this.loadlist.version,
        })
          .then((r) => {
            if (this?.loadlist?.id === r.data.id)
              this.setLoadlistProperty({ key: "version", value: r.data.version });
            resolve(undefined);
          })
          .catch((error) => {
            // We have a conflict
            if (error.response?.status == 409) {
              this.getLoadlist(this.loadlist.id);
            }
            reject(error);
          });
      });
    });

  }
  @Action({ rawError: true })
  addLoadplanVersion(): void {
    const loadplanTemplate: Loadplan = {
      holds: <HoldData[]>[],
      notes: "",
      settings: {},
      selected_holds: <HoldData[]>[],
      pallet_types: <HoldData[]>[],
      set_types: <SetTypeData[]>[],
      sets: <Set[]>[]
    };
    this.addLoadplanVersionMutation(loadplanTemplate);
    this.setLoadplanVersion(this._loadlist.result.versions.length - 1);
  }
  @Action({ rawError: true })
  removeLoadplanVersion(): void {
    this.removeLoadplanVersionMutation(this._loadplan_version);
    this.setLoadplanVersion(this._loadlist.result.versions.length - 1);
    this.setLoadlistResultHasChanged(true);
  }
  @Action({ rawError: true })
  useLoadConfiguration(configuration: LoadConfiguration): void {
    this.setLoadplanProperty({
      key: "preset",
      value: configuration.id,
    });
    if (configuration?.data?.settings)
      this.setLoadplanProperty({
        key: "settings",
        value: JSON.parse(JSON.stringify(configuration?.data?.settings)),
      });
    this.setLoadplanProperty({
      key: "selected_holds",
      value: JSON.parse(JSON.stringify(configuration?.data?.holds || [])),
    });
    this.setLoadplanProperty({
      key: "pallet_types",
      value: JSON.parse(
        JSON.stringify(configuration?.data?.pallet_types || [])
      ),
    });
    this.setLoadplanProperty({
      key: "set_types",
      value: JSON.parse(JSON.stringify(configuration?.data?.set_types || [])),
    });
  }
  @Action({ rawError: true })
  calculateLoadplan(
    payload: CalcData
  ): Promise<{
    containers: HoldData[];
    unloaded_items: UnloadedItem[];
    sets: Set[];
  }> {
    if (this.is_calculating) return;

    if (!payload.hideProgress) {
      this.setCalculationStatus(true);
      this.setCalculationInfoMessage(null);
    }

    // const set_types = payload.set_types?.map(setType => setType.data) ?? []
    const calcData = {
      settings: this.loadplan.settings,
      length_dim: this._loadlist.length_dim,
      weight_dim: this._loadlist.weight_dim,
      pallet_types: this.loadplan.pallet_types,
      ...payload,
      // set_types,
    };
    // this.setUnloadedItemsWithReasons([]);
    return new Promise((resolve, reject) => {
      API.calculateLoadlist(calcData)
        .then((response) => {
          this.setCalculationStatus(false);
          this.handleCalculationErrorMessages(response.data.status);

          if (
            Array.isArray(response.data.solutions) &&
            response.data.solutions.length
          ) {
            this.updateUnloadedItemsWithReasons(
              response.data.solutions[0].unloaded_items
            );
            resolve(response.data.solutions[0]);
          } else {
            reject();
          }
        })
        .catch((error) => {
          this.setCalculationStatus(false);

          if (error && error.response && error.response.data) {
            this.setCalculationInfoMessage(
              getSerializerError(error.response.data)
            );
          }
          reject();
        });
    });
  }
  @Action({ rawError: true })
  moveItemsInLoadplan(payload: MoveItemsInLoadplanParams): Promise<unknown> {
    //FIX
    if (this.is_calculating) return;
    // this.setUnloadedItemsWithReasons([]);

    if (!payload.hideProgress) {
      this.setCalculationStatus(true);
      this.setCalculationInfoMessage(null);
    }

    return new Promise((resolve, reject) => {
      API.moveItemsLoadlist(payload)
        .then((response) => {
          this.setCalculationStatus(false);
          this.handleCalculationErrorMessages(response.data.status);

          if (
            Array.isArray(response.data.solutions) &&
            response.data.solutions.length
          ) {
            resolve(response.data.solutions[0]);
          } else {
            reject();
          }
        })
        .catch((error) => {
          this.setCalculationStatus(false);

          if (error && error.response && error.response.data) {
            this.setCalculationInfoMessage(
              getSerializerError(error.response.data)
            );
          }
          reject();
        });
    });
  }
  @Action({ rawError: true })
  insertItemsInLoadplan(
    payload: InsertItemsInLoadplanParams
  ): Promise<unknown> {
    if (this.is_calculating) return;
    // this.setUnloadedItemsWithReasons([]);

    if (!payload.hideProgress) {
      this.setCalculationStatus(true);
      this.setCalculationInfoMessage(null);
    }
    payload.length_dim = this._loadlist.length_dim;
    payload.weight_dim = this._loadlist.weight_dim;

    if (!payload.hideProgress) {
      this.setCalculationStatus(true);
      this.setCalculationInfoMessage(null);
    }
    payload.length_dim = this._loadlist.length_dim;
    payload.weight_dim = this._loadlist.weight_dim;

    return new Promise((resolve, reject) => {
      API.insertItemsLoadlist(payload)
        .then((response) => {
          this.setCalculationStatus(false);

          this.handleCalculationErrorMessages(response.data.status);

          if (
            Array.isArray(response.data.solutions) &&
            response.data.solutions.length
          ) {
            resolve(response.data.solutions[0]);
          } else {
            reject();
          }
        })
        .catch((error) => {
          this.setCalculationStatus(false);

          if (error && error.response && error.response.data) {
            this.setCalculationInfoMessage(
              getSerializerError(error.response.data)
            );
          }
          reject();
        });
    });
  }
  @Action
  removeItemsInLoadplan(
    payload: RemoveItemsInLoadplanParams
  ): Promise<unknown> {
    if (this.is_calculating) return;
    // this.setUnloadedItemsWithReasons([]);

    if (!payload.hideProgress) {
      this.setCalculationStatus(true);
      this.setCalculationInfoMessage(null);
    }

    return new Promise((resolve, reject) => {
      API.removeItemsLoadlist(payload)
        .then((response) => {
          this.setCalculationStatus(false);

          this.handleCalculationErrorMessages(response.data.status);

          if (
            Array.isArray(response.data.solutions) &&
            response.data.solutions.length
          ) {
            resolve(response.data.solutions[0]);
          } else {
            reject();
          }
        })
        .catch((error) => {
          this.setCalculationStatus(false);

          if (error && error.response && error.response.data) {
            this.setCalculationInfoMessage(
              getSerializerError(error.response.data)
            );
          }
          reject();
        });
    });
  }
  @Action
  resetLoadlistResults(): void {
    this.setLoadlistProperty({
      key: "result",
      value: { versions: [], app_version: APP_VERSION },
    });
    this.addLoadplanVersion();
    API.getDefaultLoadConfiguration(this.loadlist.list_type)
      .then((response) => {
        this.useLoadConfiguration(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  @Action
  updateLoadplanHolds(payload: UpdateLoadplanHoldsParams): void {
    if (payload) {
      this.changeLoadplanHolds(payload);
      this.setLoadlistResultHasChanged(true);
    }
  }
  @Action
  updateLoadplanSets(payload: {
    replace?: number;
    index?: number;
    sets?: Set[];
  }): void {
    if (payload) {
      this.changeLoadplanSets(payload);
      this.setLoadlistResultHasChanged(true);
    }
  }
  @Action
  handleCalculationErrorMessages(payload: string): void {
    switch (payload) {
      case "couldNotLoadAllItems":
        this.setCalculationInfoMessage("Could not load all items");
        break;
      case "couldNotLoadAnyItems":
        this.setCalculationInfoMessage(
          "Items cannot be fitted into provided containers"
        );
        break;
      case "tooSmallItems":
        this.setCalculationInfoMessage(
          "One or more items is too small. Maybe you have set the wrong cargo dimensions?"
        );
        break;
      case "tooManyItems":
        this.setCalculationInfoMessage(
          "Too many items provided. Try to split the list into multiple ones"
        );
        break;
      case "noItems":
        this.setCalculationInfoMessage("No items provided");
        break;
      case "couldNotPalletizeSomeItems":
        this.setCalculationInfoMessage(
          "Some items could not be fitted onto the provided pallets. Loading them without pallets."
        );
        break;
    }
  }
}
