






























import Vue, { PropType } from "vue";
import CargoLibraryComponent from "@/components/CargoLibrary/CargoLibrary.vue";
import {
  Cargo,
  LengthDim,
  WeightDim,
  HoldInputItem,
} from "@/models/LoadlistModel";

export default Vue.extend({
  components: { "cargo-library": CargoLibraryComponent },
  props: {
    visible: Boolean,
    value: Array as PropType<HoldInputItem[]>,
    lengthDim: String as PropType<LengthDim>,
    weightDim: String as PropType<WeightDim>,
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      convertDimensions: this.lengthDim && this.weightDim,
    };
  },
  methods: {
    addItems(items: Cargo[]): void {
      if (items.length > 0) {
        this.$emit("input", [
          ...this.value,
          ...items.map((item) => {
            let l = item.data.l;
            let w = item.data.w;
            let h = item.data.h;
            let wt = item.data.wt;
            //lengthDim and weightDim needs to be set for this to run
            if (this.convertDimensions) {
              if (item.length_dim && this.lengthDim != item.length_dim) {
                // convert lengths
                let lengthFactor =
                  this.$toSI(item.length_dim) / this.$toSI(this.lengthDim);
                l *= lengthFactor;
                w *= lengthFactor;
                h *= lengthFactor;
              }
              if (item.weight_dim && this.weightDim != item.weight_dim) {
                // convert weights
                let weightFactor =
                  this.$toSI(item.weight_dim) / this.$toSI(this.weightDim);
                wt *= weightFactor;
              }
            }
            return { ...item.data, l, w, h, wt };
          }),
        ]);
      }
    },
    removeItems(removed: Cargo[]): void {
      if (removed.length > 0) {
        this.$emit(
          "input",
          this.value.filter(
            (item) => removed.findIndex((r) => r.data.label === item.label) < 0
          )
        );
      }
    },
  },
});
