



























































































































































import Vue, { PropType } from "vue";
import { ListType, Hold, HoldData } from "@/models/LoadlistModel";
import { EquipmentStore, LoadlistStore } from "@/store";
import ContainerLibrary from "../Custom/ContainerLibrary.vue";
import { SetTypeData } from "@/models/SetsModel";
export default Vue.extend({
  components: { ContainerLibrary },
  name: "load-in-modal",
  props: {
    containerIdsForItem: {
      type: Array as PropType<number[]>,
    },
    baseTypeForContainerID: {
      type: String as PropType<ListType>,
      default: "SEA",
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      tab: null as { key: string; text: string },
    };
  },

  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    allContainers(): HoldData[] {
      return EquipmentStore.holds
        .map((h) => h.data)
        .concat(this.selectedSets.flatMap((set) => set.container_types));
    },
    selectedContainers(): HoldData[] {
      return [
        ...LoadlistStore.loadplan.selected_holds,
        ...LoadlistStore.loadplan.pallet_types,
      ];
    },
    selectedSets(): SetTypeData[] {
      return LoadlistStore.loadplan.set_types;
    },
    tabs(): { key: string; text: string }[] {
      return [
        { key: "loadlist_equipment", text: "Loadlist Equipment" },
        { key: "library", text: "Library" },
      ];
    },
  },

  methods: {
    addLoadInId(id: number): void {
      this.$emit("addLoadInId", id);
    },
    removeLoadInId(id: number): void {
      this.$emit("removeLoadInId", id);
    },
  },
});
