import {
  ColumnMappings,
  ExcelImportItem,
  DimensionsValueText,
  ExcelImportData,
  Dimensions,
  ValueRow,
} from "@/models/InputDataModel";
import {
  Alignment,
  BorderStyle,
  Cell,
  CellValue,
  Fill,
  Row,
  Workbook,
  Worksheet,
} from "exceljs";
import { InputDataTable, TablesData } from "@/models/InputDataModel";
import {
  Cargo,
  HoldInputItem,
  LengthDim,
  Loadlist,
  WeightDim,
} from "@/models/LoadlistModel";

const labelVariants = [
  "item",
  "cargo",
  "desc",
  "content",
  "name",
  "kollinummer",
  "package",
];
const lengthVariants = [
  "length",
  "lwh",
  "lxwxh",
  "l*w*h",
  "länge",
  "dimension",
];
const widthVariants = ["width", "weite", "breite"];
const heightVariants = ["height", "höhe"];
const weightVariants = [
  "gross",
  "g.w",
  "gw",
  "gr.wt",
  "grwt",
  "weight",
  "(kg",
  " kg",
  "brutto",
  "gewicht",
  "mt",
];
const qtyVariants = ["quantity", "qty", "qnty", "q'ty", "qua", "pcs", "units"];
const stackableVariants = ["stack", "stapel", "stackable"];
const truthyAnswers = ["y", "yes", "true"];
const stackableTruthyAnswers = [...truthyAnswers, "stack"];
const bottomOnlyVariants = ["bottom", "floor"];
const tiltableVariants = ["tiltable"];
const longshipVariants = ["longship", "lengthwise"];
const layersVariants = ["layers"];
const shipmentVariants = ["shipment", "group", "consignment"];
const priorityVariants = ["prio"];
const geometryVariants = ["geometry", "shape"];
const palletizeVariants = ["palletize", "pall", "preload"];
const unitQuantityVariants = ["unit quantity", "units per box"];
const classVariants = ["class"];
const loadInVariants = ["load in"];
const orientationVariants = ["orientation"];
const destinationVariants = ["destination"];
const weightDimText = "Weight dimension";
const lengthDimText = "Length dimension";

const motToContainerName = (t: string) => {
  switch (t) {
    case "PALL":
      return "pallet";
    case "ROAD":
      return "trailer";
    case "AIR":
      return "ULD";
    default:
      return "container";
  }
};

const weightDimensionMap = new Map([
  ["Kilograms", "KG"],
  ["Tonnes", "MT"],
  ["Pounds", "LB"],
]);
const lengthDimensionMap = new Map([
  ["Millimeters", "MM"],
  ["Centimeters", "CM"],
  ["Decimeters", "DM"],
  ["Meters", "M"],
  ["Inches", "IN"],
  ["Feet", "FT"],
]);

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const fontName = "Calibri";

const headerFill: Fill = {
  type: "pattern",
  pattern: "solid",
  fgColor: {
    argb: "FF5c9ce6",
  },
};

const dimensionsBorderMedium = {
  top: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
  left: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
  right: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
};
const allBordersMedium = {
  top: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
  bottom: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
  right: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
  left: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
};
const bottomBorderMedium = {
  bottom: { style: "medium" as BorderStyle, color: { argb: "FF000000" } },
};
const topThinBorder = {
  top: { style: "thin" as BorderStyle, color: { argb: "FF000000" } },
};
const alignCenterMiddle: Partial<Alignment> = {
  horizontal: "center",
  vertical: "middle",
  wrapText: true,
};
const boldFontStyling = { name: fontName, bold: true };
const headerFontStyling = { name: fontName, bold: true, size: 12 };
const titleFontStyling = { name: fontName, bold: true, size: 14 };

export default class ExcelService {
  loadlistToXlsx(
    workbook: Workbook,
    params: { loadlist: Loadlist; tablesJson: InputDataTable[] }
  ): Workbook {
    const worksheet = workbook.addWorksheet("Sheet1");
    const table = params.tablesJson[0];
    const headers = Object.values(table.headerRows[table.headerRows.length - 1])
      .map((header) => header.value)
      .filter((v) => v !== "");

    worksheet.columns = headers.map((headerName) => {
      return {
        key: headerName,
        width: 20,
        style: { font: { name: fontName } },
      };
    });

    const headerRow = worksheet.addRow(headers);
    headerRow.font = headerFontStyling;
    headerRow.border = bottomBorderMedium;

    const valuesRows = table.valuesRows;
    valuesRows.forEach((valuesRow) => {
      if (valuesRow.values[0] != null) {
        worksheet.addRow(valuesRow.values.slice(1));
      } else {
        const totalRow = worksheet.addRow(valuesRow.values.slice(1));
        totalRow.font = boldFontStyling;
        totalRow.border = bottomBorderMedium;
      }
    });
    return workbook;
  }

  cargoToXlsx(
    workbook: Workbook,
    params: {
      items: Cargo[];
      headers: { key: string; value: string; dimension?: string }[];
      toLength: (value: number, dim: LengthDim) => number;
      toWeight: (value: number, dim: WeightDim) => number;
    }
  ): Workbook {
    const worksheet = workbook.addWorksheet("Sheet1");
    const headers = params.headers;

    worksheet.columns = headers.map((headerName) => {
      return {
        key: headerName.key,
        width: 20,
        style: { font: { name: fontName } },
      };
    });

    const headerRow = worksheet.addRow(headers.map((h) => h.value));
    headerRow.font = headerFontStyling;
    headerRow.border = bottomBorderMedium;

    params.items
      .map((item) =>
        headers.map((h) => {
          let v = item.data[h.key as keyof HoldInputItem];
          if (h.dimension == "length") {
            return params.toLength(v as number, item.length_dim);
          } else if (h.dimension == "weight") {
            return params.toWeight(v as number, item.weight_dim);
          }
          return v;
        })
      )
      .forEach((item) => {
        worksheet.addRow(item);
      });
    return workbook;
  }

  // adds loadplan info to top of excel file. Returns current row.
  private addLoadplanInfoToXlsx(
    worksheet: Worksheet,
    row: number,
    loadlist: Loadlist,
    headersLength: number,
    summary: string[]
  ): number {
    const nameRow = worksheet.getRow(row);
    nameRow.font = titleFontStyling;
    nameRow.values = [loadlist.name];
    worksheet.mergeCells(
      nameRow.getCell(1).address,
      nameRow.getCell(headersLength).address
    );

    row += 1;

    if (loadlist.pol) {
      const loadingRow = worksheet.getRow(row);
      loadingRow.values = [`Port of Loading: ${loadlist.pol}`];
      worksheet.mergeCells(
        loadingRow.getCell(1).address,
        loadingRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.pod) {
      const destinationRow = worksheet.getRow(row);
      destinationRow.values = [`Port of Destination: ${loadlist.pod}`];
      worksheet.mergeCells(
        destinationRow.getCell(1).address,
        destinationRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.customer) {
      const customerRow = worksheet.getRow(row);
      customerRow.values = [`Customer: ${loadlist.customer}`];
      worksheet.mergeCells(
        customerRow.getCell(1).address,
        customerRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.etd) {
      const etdRow = worksheet.getRow(row);
      etdRow.values = [`ETD: ${loadlist.etd}`];
      worksheet.mergeCells(
        etdRow.getCell(1).address,
        etdRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.notes) {
      const notesRow = worksheet.getRow(row);
      notesRow.values = [`Notes: ${loadlist.notes}`];
      worksheet.mergeCells(
        notesRow.getCell(1).address,
        notesRow.getCell(headersLength).address
      );
      row += 1;
    }

    if (summary.length) {
      row += 1;
      const summaryRow = worksheet.getRow(row);
      summaryRow.font = headerFontStyling;
      summaryRow.values = ["Summary"];
      worksheet.mergeCells(
        summaryRow.getCell(1).address,
        summaryRow.getCell(headersLength).address
      );
      row += 1;
      summary.forEach((line) => {
        const r = worksheet.getRow(row);
        r.values = [line];
        worksheet.mergeCells(
          r.getCell(1).address,
          r.getCell(headersLength).address
        );
        row += 1;
      });
    }
    return row;
  }
  // adds header rows. Returns last header row.
  private addLoadplanHeaderRowsToXlsx(
    worksheet: Worksheet,
    tablesData: TablesData[],
    headers: string[],
    row: number
  ): Row {
    const tableInfo = tablesData[0];
    if (tableInfo.tableJson.headerRows.length > 1) {
      // get values for first header row
      const values: string[] = [];
      tableInfo.tableJson.headerRows[0].forEach((header) => {
        for (let i = 0; i < header.colspan; i++) {
          values.push(header.value);
        }
      });

      // set values for first header row
      const headerRowFirstLine = worksheet.getRow(row);
      headerRowFirstLine.values = values;
      let cell = 0;

      tableInfo.tableJson.headerRows[0].forEach((header) => {
        // merge cells in first header row
        if (header.colspan > 1) {
          worksheet.mergeCells(
            `${letters[cell]}${row}:${letters[cell + header.colspan - 1]}${row}`
          );
        }
        if (header.value) {
          worksheet.getCell(
            `${letters[cell]}${row}`
          ).border = dimensionsBorderMedium;
        }
        cell += header.colspan;
      });
      row += 1;
    }

    // set values for second header row
    const headerRowSecondLine = worksheet.getRow(row);
    headerRowSecondLine.values = headers;

    headerRowSecondLine.eachCell((cell, colNumber) => {
      const columnLetter = worksheet.getColumn(colNumber).letter;
      const aboveCell = worksheet.getCell(
        `${columnLetter}${headerRowSecondLine.number - 1}`
      );
      // merge available cells with first header row
      if (!aboveCell.value) {
        aboveCell.value = cell.value;
        worksheet.mergeCells(`${aboveCell.address}:${cell.address}`);
      }
    });

    return headerRowSecondLine;
  }

  // adds total row at the bottom
  private addFinalTotalRow(
    worksheet: Worksheet,
    tablesData: TablesData[],
    row: number
  ) {
    let i = 0;
    let sumRow: (string | number)[] = [];

    for (i = 0; i < tablesData.length; i++) {
      // sumRow = tablesData[i].tableJson.totalRow;
      if (
        !tablesData[i].tableJson.unloaded &&
        tablesData[i].tableJson.totalRow?.values
      ) {
        sumRow = Array.apply(
          null,
          Array(tablesData[i].tableJson.totalRow.values.length)
        );
        sumRow[0] = "Total";
        break;
      }
    }

    for (let index = 0; index < tablesData.length; index++) {
      if (
        tablesData[index].tableJson.unloaded ||
        !tablesData[index].tableJson.totalRow
      ) {
        continue;
      }
      const tableData = tablesData[index];
      const total = tableData.tableJson.totalRow;
      total.values?.forEach((currValue, i) => {
        const prevValue = sumRow[i];
        const value = Number(currValue) * tableData.containerCount;
        if (!value) {
          return;
        }
        if (prevValue === null || isNaN(Number(prevValue))) {
          sumRow[i] = value;
        } else {
          sumRow[i] = Number(prevValue) + value;
        }
      });
    }
    const insertedRow = worksheet.insertRow(row, sumRow);
    insertedRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.font = boldFontStyling;
    });
  }

  private addValueRowsToXlsx(
    worksheet: Worksheet,
    tablesData: TablesData[],
    headers: string[],
    row: number,
    containerColumnName: string
  ) {
    tablesData.forEach((tableInfo) => {
      const valuesRows = tableInfo.tableJson.valuesRows;
      const startMergeRow = row + 1;
      valuesRows.forEach((valuesRow, index) => {
        const values = valuesRow.values;
        values[headers.indexOf(containerColumnName)] =
          index === 0 ? tableInfo.containerTitleText : "";
        values[headers.indexOf("Notes")] = index === 0 ? tableInfo.notes : "";
        if (tableInfo.quotation) {
          values[headers.indexOf("Quotation")] =
            index === 0 ? tableInfo.quotation : "";
        }

        if (values[1] != null) {
          // indent if pallet row
          if (!valuesRow.isFirstChild) {
            values[1] = " ↳ " + values[1];
          }
          row += 1;
          worksheet.insertRow(row, values);
        }
      });

      // adds total row for container
      if (!tableInfo.tableJson.unloaded) {
        row += 1;
        const totalRow = tableInfo.tableJson.totalRow;
        const insertedTotalRow = worksheet.insertRow(row, totalRow.values);
        insertedTotalRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.font = boldFontStyling;
          cell.border = topThinBorder;
        });
      }

      // group container rows
      const mergeStart = startMergeRow;
      const mergeEnd = row;
      const mergedCells = [
        this.mergeCells(
          worksheet,
          mergeStart,
          headers.indexOf(containerColumnName) + 1,
          mergeEnd,
          headers.indexOf(containerColumnName) + 1
        ),
        this.mergeCells(
          worksheet,
          mergeStart,
          headers.indexOf("Notes") + 1,
          mergeEnd,
          headers.indexOf("Notes") + 1
        ),
      ];

      // adds quotation if present
      if (headers.indexOf("Quotation") >= 0) {
        mergedCells.push(
          this.mergeCells(
            worksheet,
            mergeStart,
            headers.indexOf("Quotation") + 1,
            mergeEnd,
            headers.indexOf("Quotation") + 1
          )
        );
      }

      // adds border to bottom row of merged cells
      worksheet.getRow(row).eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          ...cell.border,
          bottom: bottomBorderMedium.bottom,
        };
      });

      // Highlight the unloaded items with a red-ish fill
      if (tableInfo.tableJson.unloaded) {
        mergedCells[0].fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "d77e6a" },
          bgColor: { argb: "d77e6a" },
        };
        mergedCells[0].font = boldFontStyling;
      }
    });
    return row;
  }

  private mergeCells(
    worksheet: Worksheet,
    top: number,
    left: number,
    bottom: number,
    right: number
  ): Cell {
    worksheet.mergeCells(top, left, bottom, right);
    return worksheet.getCell(top, left);
  }

  private styleHeaderRows(worksheet: Worksheet, headerRow: Row): void {
    [worksheet.getRow(headerRow.number - 1), headerRow].forEach((row) =>
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = allBordersMedium;
        cell.fill = headerFill;
        cell.font = headerFontStyling;
      })
    );
  }

  // additional styling for cells
  private styleCells(
    worksheet: Worksheet,
    headerRow: Row,
    lastRow: number
  ): void {
    worksheet
      .getRows(headerRow.number, lastRow - headerRow.number)
      .forEach((row) => (row.height = 16));
    this.styleHeaderRows(worksheet, headerRow);
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
        const columnLength = cell.value ? cell.value.toString().length + 2 : 10;
        // calculates max column length
        if (rowNumber >= headerRow.number - 1) {
          cell.alignment = alignCenterMiddle;
          if (columnLength > maxLength) {
            maxLength = columnLength + 2;
          }
        }
        // sets borders for value rows
        if (
          rowNumber > headerRow.number &&
          column.number <= headerRow.values.length
        ) {
          cell.border = {
            ...cell.border,
            left: allBordersMedium.left,
            right: allBordersMedium.right,
          };
        }
        // adds border for last total row
        if (rowNumber === lastRow) {
          cell.border = {
            ...cell.border,
            bottom: allBordersMedium.bottom,
          };
        }
      });
      column.width = maxLength < 6 ? 6 : maxLength;
    });
  }

  loadplanToXlsx(
    workbook: Workbook,
    params: { loadlist: Loadlist; summary: string[]; tablesData: TablesData[] }
  ): Workbook {
    const worksheet = workbook.addWorksheet("Sheet1");
    const loadlist = params.loadlist;
    const tablesData = params.tablesData;
    const containerColumnName =
      motToContainerName(loadlist.list_type)
        .charAt(0)
        .toUpperCase() + motToContainerName(loadlist.list_type).slice(1);
    const headers = this.getHeaders(tablesData, containerColumnName);
    worksheet.columns = headers.map((headerName: string) => {
      return { key: headerName };
    });

    let row = 1;
    row = this.addLoadplanInfoToXlsx(
      worksheet,
      row,
      loadlist,
      headers.length,
      params.summary
    );
    row += 1;
    const headerRow = this.addLoadplanHeaderRowsToXlsx(
      worksheet,
      tablesData,
      headers,
      row
    );
    row = this.addValueRowsToXlsx(
      worksheet,
      tablesData,
      headers,
      headerRow.number,
      containerColumnName
    );
    row += 1;
    this.addFinalTotalRow(worksheet, tablesData, row);
    this.styleCells(worksheet, headerRow, row);
    return workbook;
  }

  private getHeaders(tablesData: TablesData[], containerColumnName: string) {
    let headersList = Object.values(
      tablesData[0].tableJson.headerRows[
      tablesData[0].tableJson.headerRows.length - 1
      ]
    )
      .map((header) => header.value)
      .filter((v) => v !== "");

    headersList = [containerColumnName, ...headersList];
    if (tablesData.some((td) => td.quotation !== undefined)) {
      headersList.push("Quotation");
    }
    headersList.push("Notes");
    return headersList;
  }

  parseLoadlist(workbook: Workbook, mappings: ColumnMappings): ExcelImportData {
    const searchForColumnIndex = (
      rowValues: CellValue[],
      variants: string[]
    ) => {
      const index = rowValues.findIndex(
        (value) =>
          value &&
          variants.some((variant) =>
            value
              .toString()
              .toLowerCase()
              .includes(variant)
          )
      );
      return index >= 0 ? index : undefined;
    };

    const searchForColumnIndexByLetter = (
      rowValues: CellValue[],
      letter: string
    ): number | undefined => {
      const index = rowValues.findIndex(
        (value) => value && value.toString().toLowerCase()[0] === letter
      );
      return index >= 0 ? index : undefined;
    };

    const getNumberRegex = (valueString: string) => {
      const value = valueString.match(/[+-]?([0-9]*[.,])?[0-9]+/);
      return value ? value[0] : undefined;
    };

    const isRowFilteredOut = (mappings: ColumnMappings, row: Row): boolean => {
      if (
        mappings.filter_col !== undefined &&
        mappings.filter_text !== undefined
      ) {
        const valueRow = row.getCell(mappings.filter_col).text.toLowerCase();
        const filterValue = mappings.filter_text.toLowerCase();
        return !valueRow.includes(filterValue);
      }
      return false;
    };

    const columnNameToNumber = (letters: string): number | undefined => {
      return letters
        ? letters.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0)
        : undefined;
    };

    const columnIdToName = (value: any) => {
      // TODO number or string?
      return value === undefined || value === ""
        ? undefined
        : String.fromCharCode(96 + value).toUpperCase();
    };

    const getColumnIdFromColumnName = (
      value: string | number | undefined
    ): number | undefined => {
      // TODO check if method works as expected.
      if (value === undefined) {
        return undefined;
      }
      if (typeof value === "number") {
        return value;
      } else if (isNaN(parseInt(value)) && value.length > 0) {
        return columnNameToNumber(value);
      }
      return undefined;
    };

    const getColumnNamesFromMappings = (
      columnMappings: ColumnMappings
    ): ColumnMappings => {
      return {
        ...columnMappings,
        filter_col: columnIdToName(columnMappings.filter_col),
        label_col: columnIdToName(columnMappings.label_col),
        weight_col: columnIdToName(columnMappings.weight_col),
        length_col: columnIdToName(columnMappings.length_col),
        width_col: columnIdToName(columnMappings.width_col),
        height_col: columnIdToName(columnMappings.height_col),
        qty_col: columnIdToName(columnMappings.qty_col),
        stackable_col: columnIdToName(columnMappings.stackable_col),
        bottom_only_col: columnIdToName(columnMappings.bottom_only_col),
        tiltable_col: columnIdToName(columnMappings.tiltable_col),
        longship_col: columnIdToName(columnMappings.longship_col),
        layers_col: columnIdToName(columnMappings.layers_col),
        shipment_id_col: columnIdToName(columnMappings.shipment_id_col),
        priority_col: columnIdToName(columnMappings.priority_col),
        geometry_col: columnIdToName(columnMappings.geometry_col),
        palletize_col: columnIdToName(columnMappings.palletize_col),
        unitqty_col: columnIdToName(columnMappings.unitqty_col),
        class_col: columnIdToName(columnMappings.class_col),
        load_in_col: columnIdToName(columnMappings.load_in_col),
        orientations_col: columnIdToName(columnMappings.orientations_col),
        destination_col: columnIdToName(columnMappings.destination_col),

      };
    };

    const getDimensions = (worksheet: Worksheet): Dimensions => {
      const includesWeightDim = worksheet
        .getCell("E2")
        .text?.includes(weightDimText);
      const includesLengthDim = worksheet
        .getCell("E3")
        .text?.includes(lengthDimText);
      if (includesWeightDim && includesLengthDim) {
        const weightDimText = worksheet.getCell("F2").text;
        const weightDimValue = weightDimensionMap.get(weightDimText);
        const lengthDimText = worksheet.getCell("F3").text;
        const lengthDimValue = lengthDimensionMap.get(lengthDimText);
        if (weightDimValue && lengthDimValue) {
          return {
            weightDim: {
              value: weightDimValue,
              text: weightDimText,
            } as DimensionsValueText,
            lengthDim: {
              value: lengthDimValue,
              text: lengthDimText,
            } as DimensionsValueText,
          } as Dimensions;
        }
      }
      return null;
    };

    const getHeaderRow = (
      worksheet: Worksheet,
      mappings: ColumnMappings,
      startRow: number
    ) => {
      for (let rowIndex = startRow; rowIndex < worksheet.rowCount; rowIndex++) {
        const row = worksheet.getRow(rowIndex);

        if (!row.hasValues) {
          continue;
        } else {
          mappings.label_col =
            mappings.label_col ||
            searchForColumnIndex(row.values as CellValue[], labelVariants);
          mappings.weight_col =
            mappings.weight_col ||
            searchForColumnIndex(row.values as CellValue[], weightVariants);
          mappings.length_col =
            mappings.length_col ||
            searchForColumnIndex(row.values as CellValue[], lengthVariants);
          mappings.width_col =
            mappings.width_col ||
            searchForColumnIndex(row.values as CellValue[], widthVariants);
          mappings.height_col =
            mappings.height_col ||
            searchForColumnIndex(row.values as CellValue[], heightVariants);
          mappings.qty_col =
            mappings.qty_col ||
            searchForColumnIndex(row.values as CellValue[], qtyVariants);
          mappings.stackable_col =
            mappings.stackable_col ||
            searchForColumnIndex(row.values as CellValue[], stackableVariants);
          mappings.bottom_only_col =
            mappings.bottom_only_col ||
            searchForColumnIndex(row.values as CellValue[], bottomOnlyVariants);
          mappings.tiltable_col =
            mappings.tiltable_col ||
            searchForColumnIndex(row.values as CellValue[], tiltableVariants);
          mappings.longship_col =
            mappings.longship_col ||
            searchForColumnIndex(row.values as CellValue[], longshipVariants);
          mappings.layers_col =
            mappings.layers_col ||
            searchForColumnIndex(row.values as CellValue[], layersVariants);
          mappings.shipment_id_col =
            mappings.shipment_id_col ||
            searchForColumnIndex(row.values as CellValue[], shipmentVariants);
          mappings.priority_col =
            mappings.priority_col ||
            searchForColumnIndex(row.values as CellValue[], priorityVariants);
          mappings.geometry_col =
            mappings.geometry_col ||
            searchForColumnIndex(row.values as CellValue[], geometryVariants);
          mappings.palletize_col =
            mappings.palletize_col ||
            searchForColumnIndex(row.values as CellValue[], palletizeVariants);
          mappings.unitqty_col =
            mappings.unitqty_col ||
            searchForColumnIndex(
              row.values as CellValue[],
              unitQuantityVariants
            );
          mappings.class_col =
            mappings.class_col ||
            searchForColumnIndex(row.values as CellValue[], classVariants);
          mappings.load_in_col =
            mappings.load_in_col ||
            searchForColumnIndex(row.values as CellValue[], loadInVariants);

          mappings.orientations_col =
            mappings.orientations_col ||
            searchForColumnIndex(row.values as CellValue[], orientationVariants);

          mappings.destination_col =
            mappings.destination_col ||
            searchForColumnIndex(row.values as CellValue[], destinationVariants);

          if (
            mappings.length_col === undefined &&
            mappings.width_col === undefined &&
            mappings.height_col === undefined
          ) {
            const lengthIndex = searchForColumnIndexByLetter(
              row.values as CellValue[],
              "l"
            );
            const widthIndex = searchForColumnIndexByLetter(
              row.values as CellValue[],
              "w"
            );
            const heightIndex = searchForColumnIndexByLetter(
              row.values as CellValue[],
              "h"
            );
            if (
              lengthIndex &&
              widthIndex &&
              heightIndex &&
              heightIndex === widthIndex + 1 &&
              widthIndex === lengthIndex + 1
            ) {
              mappings.length_col = lengthIndex;
              mappings.width_col = widthIndex;
              mappings.height_col = heightIndex;
            }
          }
        }

        if (
          mappings.length_col !== undefined &&
          mappings.weight_col !== undefined
        ) {
          mappings.header_row_no =
            mappings.header_row_no > row.number
              ? mappings.header_row_no
              : row.number;
          return mappings;
        }
      }
      return undefined;
    };

    const getItems = (
      worksheet: Worksheet,
      mappings: ColumnMappings
    ): ExcelImportItem[] => {
      const rows = worksheet.getRows(
        mappings.header_row_no + 1,
        worksheet.rowCount - mappings.header_row_no
      );
      const items: ExcelImportItem[] = [];
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];

        if (isRowFilteredOut(mappings, row)) {
          continue;
        }
        const item: ExcelImportItem = {
          label:
            mappings.label_col !== undefined
              ? row.getCell(mappings.label_col).text
              : `${items.length + 1}`,
          l: undefined,
          w: undefined,
          h: undefined,
          wt:
            mappings.weight_col !== undefined
              ? getNumberRegex(row.getCell(mappings.weight_col).text)
              : undefined,
          qty:
            mappings.qty_col !== undefined
              ? row.getCell(mappings.qty_col).text
              : undefined,
          not_stackable:
            mappings.stackable_col !== undefined
              ? !stackableTruthyAnswers.some(
                (ans) =>
                  ans ===
                  row.getCell(mappings.stackable_col).text.toLowerCase()
              )
              : false,
          bottom_only:
            mappings.bottom_only_col !== undefined
              ? truthyAnswers.some(
                (ans) =>
                  ans ===
                  row.getCell(mappings.bottom_only_col).text.toLowerCase()
              )
              : false,
          tiltable:
            mappings.tiltable_col !== undefined
              ? truthyAnswers.some(
                (ans) =>
                  ans ===
                  row.getCell(mappings.tiltable_col).text.toLowerCase()
              )
              : false,
          not_rotatable:
            mappings.longship_col !== undefined
              ? truthyAnswers.some(
                (ans) =>
                  ans ===
                  row.getCell(mappings.longship_col).text.toLowerCase()
              )
              : false,
          shipment_id:
            mappings.shipment_id_col !== undefined
              ? row.getCell(mappings.shipment_id_col).text
              : undefined,
          priority:
            mappings.priority_col !== undefined
              ? getNumberRegex(row.getCell(mappings.priority_col).text)
              : undefined,
          geometry:
            mappings.geometry_col !== undefined
              ? row.getCell(mappings.geometry_col).text
              : undefined,
          palletize:
            mappings.palletize_col !== undefined
              ? truthyAnswers.some(
                (ans) =>
                  ans ===
                  row.getCell(mappings.palletize_col).text.toLowerCase()
              )
              : false,
          unit_qty:
            mappings.unitqty_col !== undefined
              ? getNumberRegex(row.getCell(mappings.unitqty_col).text)
              : undefined,
          layers:
            mappings.layers_col !== undefined
              ? getNumberRegex(row.getCell(mappings.layers_col).text)
              : undefined,
          class:
            mappings.class_col !== undefined
              ? row.getCell(mappings.class_col).text
              : undefined,
          load_in:
            mappings.load_in_col !== undefined
              ? row.getCell(mappings.load_in_col).text
              : undefined,
          orientations:
            mappings.orientations_col !== undefined
              ? row.getCell(mappings.orientations_col).text
              : undefined,
          destination:
            mappings.destination_col !== undefined
              ? row.getCell(mappings.destination_col).text
              : undefined,
        };
        if (
          mappings.width_col !== undefined &&
          mappings.height_col !== undefined
        ) {
          item.l = getNumberRegex(row.getCell(mappings.length_col).text);
          item.w = getNumberRegex(row.getCell(mappings.width_col).text);
          item.h = getNumberRegex(row.getCell(mappings.height_col).text);
        } else {
          const lengthValues = (
            row
              .getCell(mappings.length_col)
              .text?.toLowerCase()
              .split("x") || []
          ).map((v) => v.trim());
          if (lengthValues.length >= 3) {
            item.l = getNumberRegex(lengthValues[0]);
            item.w = getNumberRegex(lengthValues[1]);
            item.h = getNumberRegex(lengthValues[2]);
          }
        }

        if (
          item.l !== undefined &&
          item.w !== undefined &&
          item.h !== undefined &&
          item.wt !== undefined
        ) {
          items.push(item);
        }
      }
      return items;
    };

    const columnMappings = {
      sheet_no: mappings.sheet_no || 1,
      weight_dimension: mappings.weight_dimension,
      length_dimension: mappings.length_dimension,
      header_row_no: mappings.header_row_no || 1,
      filter_text: mappings.filter_text || undefined,
      filter_col: getColumnIdFromColumnName(mappings.filter_col),
      label_col: getColumnIdFromColumnName(mappings.label_col),
      weight_col: getColumnIdFromColumnName(mappings.weight_col),
      length_col: getColumnIdFromColumnName(mappings.length_col),
      width_col: getColumnIdFromColumnName(mappings.width_col),
      height_col: getColumnIdFromColumnName(mappings.height_col),
      qty_col: getColumnIdFromColumnName(mappings.qty_col),
      stackable_col: getColumnIdFromColumnName(mappings.stackable_col),
      bottom_only_col: getColumnIdFromColumnName(mappings.bottom_only_col),
      tiltable_col: getColumnIdFromColumnName(mappings.tiltable_col),
      longship_col: getColumnIdFromColumnName(mappings.longship_col),
      layers_col: getColumnIdFromColumnName(mappings.layers_col),
      shipment_id_col: getColumnIdFromColumnName(mappings.shipment_id_col),
      priority_col: getColumnIdFromColumnName(mappings.priority_col),
      geometry_col: getColumnIdFromColumnName(mappings.geometry_col),
      palletize_col: getColumnIdFromColumnName(mappings.palletize_col),
      unitqty_col: getColumnIdFromColumnName(mappings.unitqty_col),
      class_col: getColumnIdFromColumnName(mappings.class_col),
      load_in_col: getColumnIdFromColumnName(mappings.load_in_col),
      orientations_col: getColumnIdFromColumnName(mappings.orientations_col),
      destination_col: getColumnIdFromColumnName(mappings.destination_col),
    };

    const worksheet = workbook.worksheets[columnMappings.sheet_no - 1];
    const dimensions = getDimensions(worksheet);
    const startRow = dimensions ? 4 : 0;
    const headerRowMappings = getHeaderRow(worksheet, columnMappings, startRow);

    if (headerRowMappings) {
      const items = getItems(worksheet, headerRowMappings);
      return {
        items,
        options: getColumnNamesFromMappings(headerRowMappings),
        dimensions,
      };
    }
    return {
      items: undefined,
      options: getColumnNamesFromMappings(columnMappings),
      dimensions: undefined,
    };
  }
}
