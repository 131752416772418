



























































































































import Vue, { PropType } from "vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import annotationsComponent from "@/components/Custom/Annotations.vue";
import { Set } from "@/models/SetsModel";
import { LoadlistStore } from "@/store";
import { Loadlist, Loadplan } from "@/models/LoadlistModel";
import { AugmentedSet } from "@/models/augmented/set";

export default Vue.extend({
  name: "set-display",
  components: {
    sceneComponent,
    annotationsComponent,
  },
  props: {
    set: Object as PropType<Set>,
    locked: Boolean,
  },
  data() {
    return {
      sceneRendered: false,
    };
  },
  computed: {
    readonly(): boolean {
      return LoadlistStore.readonly;
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    augmentedSet(): AugmentedSet {
      return new AugmentedSet(this.set, this.loadplan.holds);
    },
  },
});
