import { HoldItem } from "@/models/LoadlistModel";
import {
  CylinderBufferGeometry,
  BufferGeometry,
  BoxGeometry,
  Vector2,
  CanvasTexture,
  MeshLambertMaterial,
  BoxBufferGeometry,
  Shape,
  ExtrudeBufferGeometry,
  ExtrudeGeometryOptions,
  DoubleSide,
  Matrix4,
  Quaternion,
  Vector3,
  BufferGeometryUtils,
  ClampToEdgeWrapping,
  RepeatWrapping,
  UVMapping,
  Color,
  HSL
} from "three";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { cloneMaterial, setMaterialOpacity } from "../utils";
import { BaseItem } from "./baseItem";

class Item extends BaseItem {
  constructor(
    item_data: HoldItem,
    index: number,
    geometry: BufferGeometry | CylinderBufferGeometry | BoxGeometry,
    material: MeshLambertMaterial | MeshLambertMaterial[],
    isInteractive: boolean,
    hideLabel: boolean,
    maxPPM: number
  ) {
    super(item_data, index, isInteractive);

    const isCargo = item_data.qty > 0;
    let texture_data = null;

    if (!material) {
      switch (item_data.geometry) {
        case "drum":
        case "pipe":
        case "cylinder":
          let circle_texture = createCylinderCargoTexture(
            item_data,
            isInteractive,
            maxPPM
          );
          const top = new MeshLambertMaterial({ map: circle_texture.top });
          material = [
            new MeshLambertMaterial({ map: circle_texture.side }),
            top,
            top,
          ];
          break;
        case "hollow_cylinder":
          const flat = new MeshLambertMaterial({
            color: item_data.color,
            side: DoubleSide,
          });

          let texture = createHollowCylinderTexture(
            item_data,
            isInteractive,
            maxPPM
          );
          material = [flat, new MeshLambertMaterial({ map: texture })];
          break;
        default:
          if (!isCargo) {
            material = new MeshLambertMaterial({ color: 0x888888 });
            material.transparent = true;
            material.opacity = 0.5;
            material.depthWrite = false;
            if (item_data.label === "spacer")
              material.visible = false
          } else {
            material = new MeshLambertMaterial();
            texture_data = createBoxCargoTexture(
              item_data,
              isInteractive,
              hideLabel,
              maxPPM
            );
            material.map = texture_data.texture;
            texture_data.texture.dispose();
            texture_data.texture = undefined;
          }
      }
    }
    if (!geometry) {
      switch (item_data.geometry) {
        case "pipe":
          geometry = new CylinderBufferGeometry(
            item_data.H * 0.5,
            item_data.W * 0.5,
            item_data.L,
            32
          );
          geometry.rotateZ(Math.PI * 0.5);
          break;
        case "drum":
        case "cylinder":
          geometry = new CylinderBufferGeometry(
            item_data.L * 0.5,
            item_data.W * 0.5,
            item_data.H,
            32
          );
          geometry.rotateX(Math.PI * 0.5);
          break;

        case "hollow_cylinder":
          // THe hardcore way to make a cylinder
          // let geometries = [];
          // let { parts, dims } = hollowCylinderPart(item_data);
          // let ringPart = new BoxBufferGeometry(dims.x, dims.y, dims.z);
          // const r = item_data.L / 2 - dims.x * 0.5;
          // for (let i = 0; i < parts; i++) {
          //   let rotation = (2 * Math.PI * i) / parts;
          //   let quat = new Quaternion();
          //   quat.setFromAxisAngle(new Vector3(0, 0, 1), -rotation);
          //   let offset = new Vector3(
          //     r * Math.sin(rotation + Math.PI / 2),
          //     r * Math.cos(rotation + Math.PI / 2),
          //     0
          //   );
          //   let part = ringPart
          //     .clone()
          //     .applyQuaternion(quat)
          //     .translate(offset.x, offset.y, offset.z);

          //   geometries.push(part);
          // }
          // geometry = mergeBufferGeometries(geometries);
          // break;
          // The soft way to make one
          const shape = new Shape();
          const center = item_data.L / 2;
          shape.moveTo(center, center);
          shape.absarc(0, 0, center, 0, Math.PI * 2, false);
          const hole = new Shape();
          hole.moveTo(center, center);
          hole.absarc(0, 0, item_data.W / 2, 0, Math.PI * 2, false);
          shape.holes.push(hole);
          const options: ExtrudeGeometryOptions = {
            depth: item_data.H,
            bevelEnabled: false,
            steps: 10,
          };
          geometry = new ExtrudeBufferGeometry(shape, options);
          geometry.applyMatrix4(
            new Matrix4().makeTranslation(0, 0, -item_data.H / 2)
          );

          break;
        default:
          if (geometry === undefined) {
            geometry = new BoxBufferGeometry(
              Math.max(item_data.L, 0.001),
              Math.max(item_data.W, 0.001),
              Math.max(item_data.H, 0.001)
            );

            if (texture_data) {
              setCargoTextureUV(geometry, texture_data);
            }
          }
      }
    }
    this.geometry = geometry;
    this.material = material;

    this.type = "Item";
    this.position.set(item_data.pos.x, item_data.pos.y, item_data.pos.z);
    if (Array.isArray(item_data.rotation)) {
      this.rotation.fromArray(item_data.rotation);
    }

    this.updateMatrix();
  }
  setColor(color = 0): void {
    color = color || 0x000000;
    Array.isArray(this.material)
      ? this.material.map((m: MeshLambertMaterial) => m.emissive.setHex(color))
      : (this.material as MeshLambertMaterial).emissive.setHex(color);
  }
  setOpacity(opacity = 1.0): void {
    if (!this.isInteractive) this.material = cloneMaterial(this.material);
    if (Array.isArray(this.material)) {
      this.material.map((m) =>
        setMaterialOpacity(
          m as MeshLambertMaterial,
          opacity,
          this.isInteractive
        )
      );
    } else {
      setMaterialOpacity(
        this.material as MeshLambertMaterial,
        opacity,
        this.isInteractive
      );
    }
  }

  select(): void {
    super.select();
    this.setColor(0x888888);
  }

  deselect(): void {
    super.deselect();
    this.setColor();
  }
}

const MAX_TEXTURE_SIZE = 512;

function getAdjustedSize(x: number) {
  return Math.max(
    Math.min(Math.pow(2, Math.round(Math.log(x) * 1.449)), MAX_TEXTURE_SIZE),
    128
  );
}

function hollowCylinderPart(item_data: HoldItem) {
  let parts = 16;
  let circumference = Math.PI * item_data.L;

  let length = (circumference / parts) * 1.0;
  let thickness = (item_data.L - item_data.W) * 0.5;

  let dims = new Vector3(thickness, length, item_data.H);
  return { parts, dims };
}

function setCargoTextureUV(
  geometry: BoxGeometry | BufferGeometry,
  texture_data: ItemTexture
): void {
  const index = geometry.getIndex();
  const uvAttribute = geometry.getAttribute("uv");

  // Side 2 A
  uvAttribute.setXY(
    index.getX(0),
    texture_data.side2[1].x,
    texture_data.side2[0].y
  );
  uvAttribute.setXY(
    index.getX(1),
    texture_data.side2[0].x,
    texture_data.side2[0].y
  );
  uvAttribute.setXY(
    index.getX(2),
    texture_data.side2[1].x,
    texture_data.side2[1].y
  );
  uvAttribute.setXY(
    index.getX(3),
    texture_data.side2[0].x,
    texture_data.side2[0].y
  );
  uvAttribute.setXY(
    index.getX(4),
    texture_data.side2[0].x,
    texture_data.side2[1].y
  );
  uvAttribute.setXY(
    index.getX(5),
    texture_data.side2[1].x,
    texture_data.side2[1].y
  );
  // Side 2 B
  uvAttribute.setXY(
    index.getX(6),
    texture_data.side2[0].x,
    texture_data.side2[1].y
  );
  uvAttribute.setXY(
    index.getX(7),
    texture_data.side2[1].x,
    texture_data.side2[1].y
  );
  uvAttribute.setXY(
    index.getX(8),
    texture_data.side2[0].x,
    texture_data.side2[0].y
  );
  uvAttribute.setXY(
    index.getX(9),
    texture_data.side2[1].x,
    texture_data.side2[1].y
  );
  uvAttribute.setXY(
    index.getX(10),
    texture_data.side2[1].x,
    texture_data.side2[0].y
  );
  uvAttribute.setXY(
    index.getX(11),
    texture_data.side2[0].x,
    texture_data.side2[0].y
  );

  // Side 1 A
  uvAttribute.setXY(
    index.getX(12),
    texture_data.side1[1].x,
    texture_data.side1[1].y
  );
  uvAttribute.setXY(
    index.getX(13),
    texture_data.side1[1].x,
    texture_data.side1[0].y
  );
  uvAttribute.setXY(
    index.getX(14),
    texture_data.side1[0].x,
    texture_data.side1[1].y
  );
  uvAttribute.setXY(
    index.getX(15),
    texture_data.side1[1].x,
    texture_data.side1[0].y
  );
  uvAttribute.setXY(
    index.getX(16),
    texture_data.side1[0].x,
    texture_data.side1[0].y
  );
  uvAttribute.setXY(
    index.getX(17),
    texture_data.side1[0].x,
    texture_data.side1[1].y
  );

  // Side 1 B
  uvAttribute.setXY(
    index.getX(18),
    texture_data.side1[0].x,
    texture_data.side1[0].y
  );
  uvAttribute.setXY(
    index.getX(19),
    texture_data.side1[0].x,
    texture_data.side1[1].y
  );
  uvAttribute.setXY(
    index.getX(20),
    texture_data.side1[1].x,
    texture_data.side1[0].y
  );
  uvAttribute.setXY(
    index.getX(21),
    texture_data.side1[0].x,
    texture_data.side1[1].y
  );
  uvAttribute.setXY(
    index.getX(22),
    texture_data.side1[1].x,
    texture_data.side1[1].y
  );
  uvAttribute.setXY(
    index.getX(23),
    texture_data.side1[1].x,
    texture_data.side1[0].y
  );

  // box top 2-triangles
  uvAttribute.setXY(
    index.getX(24),
    texture_data.top[0].x,
    texture_data.top[0].y
  );
  uvAttribute.setXY(
    index.getX(25),
    texture_data.top[0].x,
    texture_data.top[1].y
  );
  uvAttribute.setXY(
    index.getX(26),
    texture_data.top[1].x,
    texture_data.top[0].y
  );
  uvAttribute.setXY(
    index.getX(27),
    texture_data.top[0].x,
    texture_data.top[1].y
  );
  uvAttribute.setXY(
    index.getX(28),
    texture_data.top[1].x,
    texture_data.top[1].y
  );
  uvAttribute.setXY(
    index.getX(29),
    texture_data.top[1].x,
    texture_data.top[0].y
  );

  // Box Bottom 2-triangles
  // flipped upside down for when the bottom of the box is exposed
  uvAttribute.setXY(
    index.getX(34),
    texture_data.top[1].x,
    texture_data.top[1].y
  );
  uvAttribute.setXY(
    index.getX(33),
    texture_data.top[1].x,
    texture_data.top[0].y
  );
  uvAttribute.setXY(
    index.getX(35),
    texture_data.top[0].x,
    texture_data.top[1].y
  );

  uvAttribute.setXY(
    index.getX(32),
    texture_data.top[1].x,
    texture_data.top[0].y
  );
  uvAttribute.setXY(
    index.getX(30),
    texture_data.top[0].x,
    texture_data.top[0].y
  );
  uvAttribute.setXY(
    index.getX(31),
    texture_data.top[0].x,
    texture_data.top[1].y
  );
}

const minFontSize = 24;
const maxFontSize = 112;

// To what ratio we can squeeze a container without it looking overly strange
const squeeziness = 0.8;

type ItemTexture = {
  texture: CanvasTexture;
  top: Vector2[];
  side1: Vector2[];
  side2: Vector2[];
};

function createBoxCargoTexture(
  item: HoldItem,
  isInteractive: boolean,
  hideLabel: boolean,
  maxPPM: number = 256
): ItemTexture {
  const cargoCanvasTexture = document.createElement("canvas");
  const ctx = cargoCanvasTexture.getContext("2d");

  let pixelPerMeter = maxPPM / (isInteractive ? 1 : 4);
  const twoSides =
    Math.min(item.L, item.W) / Math.max(item.L, item.W) < squeeziness;

  let H = item.W;
  let W = item.L;
  let posFirstSide = [0, H];
  H += item.H;

  let posSecondSide = [0, 0];
  if (twoSides) {
    if (H + item.H < W + item.W) {
      posSecondSide = [0, H];
      H += item.H;
      // If item.W > item.L
      W = Math.max(W, item.W);
    } else {
      posSecondSide = [W, 0];
      W += item.W;
    }
  }

  const canvasWidth = getAdjustedSize(W * pixelPerMeter);
  const canvasHeight = getAdjustedSize(H * pixelPerMeter);
  pixelPerMeter *= Math.min(
    canvasWidth / (W * pixelPerMeter),
    canvasHeight / (H * pixelPerMeter)
  );

  posFirstSide = posFirstSide.map((i) => i * pixelPerMeter);
  posSecondSide = posSecondSide.map((i) => i * pixelPerMeter);

  // trying to find a ratio to use in normalizing the text sizes
  const ppmRatio = pixelPerMeter / maxPPM;

  const itemLength = item.L * pixelPerMeter;
  const itemWidth = item.W * pixelPerMeter;
  const itemHeight = item.H * pixelPerMeter;
  cargoCanvasTexture.width = canvasWidth;
  cargoCanvasTexture.height = canvasHeight;
  // Background color
  ctx.fillStyle = item.color;
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  // Bottom color
  ctx.fillStyle = "#666666";

  const complementColor = pickTextColorBasedOnBgColorSimple(item.color);

  // Frames
  ctx.strokeStyle = complementColor;
  if (Math.min(itemLength, itemWidth, itemHeight) < 100)
    ctx.lineWidth = 4 * ppmRatio;
  else ctx.lineWidth = 8 * ppmRatio;
  const bottomVisualizerHeight = ctx.lineWidth * 2;

  // Bottom
  ctx.fillRect(
    posFirstSide[0] + ctx.lineWidth,
    posFirstSide[1] + itemHeight - bottomVisualizerHeight - ctx.lineWidth,
    itemLength - 2 * ctx.lineWidth,
    bottomVisualizerHeight
  );

  // Top frame
  ctx.strokeRect(
    ctx.lineWidth * 0.5,
    ctx.lineWidth * 0.5,
    itemLength - ctx.lineWidth,
    itemWidth - ctx.lineWidth
  );

  // Side frame
  ctx.strokeRect(
    posFirstSide[0] + ctx.lineWidth * 0.5,
    posFirstSide[1] + ctx.lineWidth * 0.5,
    itemLength - ctx.lineWidth,
    itemHeight - ctx.lineWidth
  );

  if (twoSides) {
    // Side 2 bottom
    ctx.fillRect(
      posSecondSide[0] + ctx.lineWidth,
      posSecondSide[1] + itemHeight - bottomVisualizerHeight - ctx.lineWidth,
      itemWidth - 2 * ctx.lineWidth,
      bottomVisualizerHeight
    );
    // Side 2 frame
    ctx.strokeRect(
      posSecondSide[0] + ctx.lineWidth * 0.5,
      posSecondSide[1] + ctx.lineWidth * 0.5,
      itemWidth - ctx.lineWidth,
      itemHeight - ctx.lineWidth
    );
  }

  if (item.not_stackable) {
    let warningcolor = new Color(item.color)
    const HSL = warningcolor.getHSL({} as HSL)
    warningcolor.offsetHSL(0, -0.2, HSL.l < 0.5 ? 0.1 : -0.1)


    ctx.strokeStyle = "#" + warningcolor.getHexString()
    ctx.lineWidth *= 2
    const stepSize = ctx.lineWidth * 2
    // kateten där stepsize är hypotenusan
    let l = stepSize / 0.4142
    let x1 = stepSize, y0 = stepSize;
    let x0 = l, y1 = l;
    let breakX = false;
    let breakY = false
    for (let i = 0; i <= Math.hypot(itemLength, itemWidth) && y0 < itemWidth - 2 * ctx.lineWidth && x1 < itemLength - 2 * ctx.lineWidth; i = i + stepSize) {

      ctx.beginPath();
      ctx.moveTo(x0, y0)
      ctx.lineTo(x1, y1)
      ctx.stroke()

      if (!breakX) {
        x0 += l;
        if (x0 >= itemLength - ctx.lineWidth) {
          breakX = true;
          y0 = x0 - itemLength + l - ctx.lineWidth;
          x0 = itemLength - stepSize
        }
      }
      else
        y0 += l

      if (!breakY) {
        y1 += l
        if (y1 >= itemWidth - ctx.lineWidth) {
          breakY = true;
          x1 = y1 - itemWidth + l - ctx.lineWidth
          y1 = itemWidth - stepSize
        }
      }
      else
        x1 += l
    }
  }

  ctx.textAlign = "center";
  ctx.fillStyle = complementColor;

  if (!hideLabel) {
    //Top text
    if (
      itemWidth >= minFontSize * ppmRatio &&
      (Math.max(itemWidth, itemLength) > 77 || Math.max(item.L, item.W) > 0.3)
    ) {

      presentText(ctx, [item.label], itemLength, itemWidth, ppmRatio);
    }
    //Side text
    const labels = item.bottom_only ? [item.label + "⤓"] : [item.label];

    ctx.save();
    ctx.translate(posFirstSide[0], posFirstSide[1]);
    if (
      Math.min(itemLength, itemHeight) >=
      minFontSize * ppmRatio + ctx.lineWidth &&
      (Math.max(itemLength, itemHeight) > 77 || Math.max(item.L, item.H) > 0.3)
    ) {
      presentText(
        ctx,
        labels,
        itemLength,
        itemHeight - bottomVisualizerHeight,
        ppmRatio
      );
      ctx.restore();

      // Side 2 text, same labels as previously
      if (
        twoSides &&
        Math.min(itemWidth, itemHeight) >= minFontSize * ppmRatio &&
        (Math.max(itemWidth, itemHeight) > 77 || Math.max(item.H, item.W) > 0.3)
      ) {
        ctx.save();
        ctx.translate(posSecondSide[0], posSecondSide[1]);
        presentText(
          ctx,
          labels,
          itemWidth,
          itemHeight - bottomVisualizerHeight,
          ppmRatio
        );
        ctx.restore();
      }
    }
  } else {
    ctx.strokeStyle = complementColor;
    if (Math.min(itemLength, itemWidth, itemHeight) < 100)
      ctx.lineWidth = 4 * ppmRatio;
    else ctx.lineWidth = 12 * ppmRatio;
    ctx.beginPath(); // Start a new path
    ctx.moveTo(itemLength * 0.25, itemWidth * 0.5); // Move the pen to (30, 50)
    ctx.lineTo(itemLength * 0.75, itemWidth * 0.5); // Draw a line to (150, 100)
    ctx.stroke(); // Render the path
  }
  // Print the canvas for debugging reasons
  // console.log("printing canvas");
  // let t = cargoCanvasTexture
  // t.style.marginLeft = "100px";
  // document.getElementsByTagName("body")[0].appendChild(cargoCanvasTexture);

  return {
    texture: new CanvasTexture(cargoCanvasTexture),
    top: [
      new Vector2(0, 1),
      new Vector2(itemLength / canvasWidth, 1 - itemWidth / canvasHeight),
    ],
    side1: [
      new Vector2(
        posFirstSide[0] / canvasWidth,
        1 - posFirstSide[1] / canvasHeight
      ),
      new Vector2(
        (posFirstSide[0] + itemLength) / canvasWidth,
        1 - (posFirstSide[1] + itemHeight) / canvasHeight
      ),
    ],
    side2: !twoSides
      ? [
        new Vector2(
          posFirstSide[0] / canvasWidth,
          1 - posFirstSide[1] / canvasHeight
        ),
        new Vector2(
          (posFirstSide[0] + itemLength) / canvasWidth,
          1 - (posFirstSide[1] + itemHeight) / canvasHeight
        ),
      ]
      : [
        new Vector2(
          posSecondSide[0] / canvasWidth,
          1 - posSecondSide[1] / canvasHeight
        ),
        new Vector2(
          (posSecondSide[0] + itemWidth) / canvasWidth,
          1 - (posSecondSide[1] + itemHeight) / canvasHeight
        ),
      ],
  };
}

function createHollowCylinderTexture(
  item: HoldItem,
  isInteractive: boolean,
  maxPPM: number = 256
): CanvasTexture {
  const cargoCanvasTexture = document.createElement("canvas");
  const ctx = cargoCanvasTexture.getContext("2d");

  let pixelPerMeter = maxPPM / (isInteractive ? 1 : 4);
  const twoSides = true;

  let { dims } = hollowCylinderPart(item);
  let H = dims.z;
  let W = dims.y;

  const canvasWidth = getAdjustedSize(W * pixelPerMeter);
  const canvasHeight = getAdjustedSize(H * pixelPerMeter);
  pixelPerMeter *= Math.min(
    canvasWidth / (W * pixelPerMeter),
    canvasHeight / (H * pixelPerMeter)
  );

  // posFirstSide = posFirstSide.map((i) => i * pixelPerMeter);
  // posSecondSide = posSecondSide.map((i) => i * pixelPerMeter);

  // trying to find a ratio to use in normalizing the text sizes
  const ppmRatio = pixelPerMeter / maxPPM;

  const itemLength = dims.x * pixelPerMeter;
  const itemWidth = dims.y * pixelPerMeter;
  const itemHeight = dims.z * pixelPerMeter;
  cargoCanvasTexture.width = canvasWidth;
  cargoCanvasTexture.height = canvasHeight;
  // Background color
  ctx.fillStyle = item.color;
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  // Bottom color
  ctx.fillStyle = "#666666";

  const complementColor = pickTextColorBasedOnBgColorSimple(item.color);

  // Frames
  ctx.strokeStyle = complementColor;

  if (itemHeight < 100) ctx.lineWidth = 8 * ppmRatio;
  else if (itemHeight < 250) ctx.lineWidth = 16 * ppmRatio;
  else ctx.lineWidth = 32 * ppmRatio;

  ctx.lineWidth = Math.round(ctx.lineWidth);

  ctx.strokeStyle = complementColor;
  ctx.moveTo(0, ctx.lineWidth / 2);
  ctx.lineTo(canvasWidth, ctx.lineWidth / 2);
  // ctx.moveTo(0, canvasHeight - ctx.lineWidth / 2);
  // ctx.lineTo(canvasWidth, canvasHeight - ctx.lineWidth / 2);
  ctx.stroke();

  ctx.textAlign = "center";
  ctx.fillStyle = complementColor;

  // Print the canvas for debugging reasons
  // console.log("printing canvas");
  // let t = cargoCanvasTexture
  // t.style.marginLeft = "100px";
  // document
  //   .getElementsByClassName("float-container")[0]
  //   .appendChild(cargoCanvasTexture);

  return new CanvasTexture(cargoCanvasTexture);
}

function createCylinderCargoTexture(
  item: HoldItem,
  isInteractive: boolean,
  maxPPM: number,
  divider: number = 1
): { top: CanvasTexture; side: CanvasTexture } {
  const topCanvas = document.createElement("canvas");
  const ctx = topCanvas.getContext("2d");

  const pixelPerMeter = maxPPM / (isInteractive ? 1 : 4);
  const diameter = item.W;
  const ppmRatio = pixelPerMeter / maxPPM;

  let canvasWidth = getAdjustedSize(diameter * pixelPerMeter);
  let canvasHeight = canvasWidth;

  topCanvas.width = canvasWidth;
  topCanvas.height = canvasWidth;

  const complementColor = pickTextColorBasedOnBgColorSimple(item.color);

  // Background color
  ctx.fillStyle = item.color;
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);
  ctx.fill();

  ctx.beginPath();
  const lineWidth = 4 * ppmRatio;
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = complementColor;

  // Top
  ctx.arc(
    canvasWidth * 0.5,
    canvasWidth * 0.5,
    (canvasWidth - lineWidth) * 0.5,
    0,
    2 * Math.PI,
    false
  );

  ctx.stroke();

  // Side
  const sideCanvas = document.createElement("canvas");
  const ctxSide = sideCanvas.getContext("2d");

  const circumference =
    ((item.geometry == "pipe" ? item.W : item.L) * Math.PI) / divider;
  const height = item.geometry == "pipe" ? item.L : item.H;
  canvasWidth = getAdjustedSize(circumference * pixelPerMeter);

  canvasHeight = getAdjustedSize(height * 4 * pixelPerMeter);
  sideCanvas.width = canvasWidth;
  sideCanvas.height = canvasHeight;
  // Background color
  ctxSide.fillStyle = item.color;
  ctxSide.fillRect(0, 0, canvasWidth, canvasHeight);
  ctxSide.fill();

  // lines
  ctxSide.lineWidth = lineWidth;
  ctxSide.strokeStyle = complementColor;
  ctxSide.moveTo(0, lineWidth / 2);
  ctxSide.lineTo(canvasWidth, lineWidth / 2);
  ctxSide.moveTo(0, canvasHeight - lineWidth / 2);
  ctxSide.lineTo(canvasWidth, canvasHeight - lineWidth / 2);
  ctxSide.stroke();

  // ctxSide.stroke();
  // document.getElementsByClassName("float-container")[0].appendChild(sideCanvas);
  // document.getElementsByTagName("body")[0].prepend(sideCanvas);

  return {
    top: new CanvasTexture(topCanvas),
    side: new CanvasTexture(sideCanvas),
  };
}

function largestFontPossible(
  ctx: CanvasRenderingContext2D,
  label: string,
  maxFont: number,
  minFont: number,
  maxWidth: number
) {
  let fontSize = maxFont;
  ctx.font = "bold " + fontSize + "px roboto";
  const metrics = ctx.measureText(label);
  if (metrics.width * squeeziness > maxWidth) {
    fontSize = Math.max(
      (fontSize * maxWidth) / (metrics.width * squeeziness),
      minFont
    );
  }
  return fontSize;
}

function presentText(
  ctx: CanvasRenderingContext2D,
  labels: string[],
  x: number,
  y: number,
  ppmRatio: number
) {
  // debugger;
  const textPadding = 3 * ppmRatio;
  const hasSymbol = labels.length > 1;
  const maxWidth = x - 2 * textPadding - 2 * ctx.lineWidth;
  const maxHeight = y - 2 * textPadding - 2 * ctx.lineWidth;
  const max = maxFontSize * ppmRatio;
  const min = minFontSize * ppmRatio;
  let fontSize = max;
  // fontSize = fontSize * ppmRatio;
  ctx.font = "bold " + fontSize + "px roboto";
  let index = 0;
  labels = labels
    .map((label) => {
      index += 1;
      if (index > 1) {
        return label;
      }
      label = label.toUpperCase();
      fontSize = largestFontPossible(ctx, label, max, min, maxWidth);

      ctx.font = "bold " + fontSize + "px roboto";
      const metrics = ctx.measureText(label);
      if (metrics.width * squeeziness > maxWidth && label.length > 1) {
        // still too wide, split into lines
        return getLines(ctx, label, maxWidth);
      }
      return label;
    })
    .flat();

  ctx.textBaseline = "top";
  const lineHeightMultiplier = 0.8;
  let lineHeight = fontSize * lineHeightMultiplier;
  let height = labels.length * lineHeight;
  let tooTall = height > maxHeight;
  while (tooTall && fontSize > min) {
    fontSize = Math.max(min, fontSize - 6 * ppmRatio);
    lineHeight = fontSize * lineHeightMultiplier;
    height = labels.length * lineHeight;
    tooTall = height > maxHeight;
  }

  const startingHeight = tooTall
    ? textPadding * 0.5 + ctx.lineWidth
    : (y - height) * 0.5;
  let lineOffset = startingHeight;

  ctx.font = "bold " + fontSize + "px roboto";

  labels.some((line, index) => {
    let metrics = ctx.measureText(line);

    // Can we twist the text if it doesn't fit?
    if (
      (labels.length == 1 || (hasSymbol && labels.length == 2 && index == 0)) && // only twist text that is solo line, with the exception of line + symbol
      maxWidth < maxHeight - lineHeight * (labels.length - 1) &&
      line.length > 1
    ) {
      // and if it seems beneficial to twist it given the proportions
      ctx.save();
      fontSize = largestFontPossible(
        ctx,
        line,
        max,
        min,
        maxHeight - lineHeight * (labels.length - 1)
      );
      if (maxWidth < fontSize * lineHeightMultiplier) {
        fontSize = maxWidth / lineHeightMultiplier;
      }
      ctx.font = "bold " + fontSize + "px roboto";
      metrics = ctx.measureText(line);
      lineHeight = fontSize * lineHeightMultiplier;

      ctx.translate(x / 2 - lineHeight / 2, y / 2);
      ctx.rotate(-Math.PI / 2);
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      const length = Math.min(
        maxHeight - 2 * lineHeight * (labels.length - 1),
        metrics.width
      );
      ctx.fillText(line, 0, 0, length);
      ctx.restore();
      lineOffset = textPadding + ctx.lineWidth;
      return false;
    }
    ctx.fillText(line, x * 0.5, lineOffset, maxWidth);
    lineOffset += lineHeight;
    return lineOffset + lineHeight / 2 >= maxHeight;
  });
}

function getLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number
) {
  const words = text.split(" ");
  const lines = [];
  let currentLine = words[0].substring(0, 20);

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = ctx.measureText(currentLine + " " + word).width;
    if (width * squeeziness < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word.substring(0, 20);
    }
  }
  lines.push(currentLine);
  return lines;
}

function pickTextColorBasedOnBgColorSimple(bgColor: string) {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  // return L > 0.179 ? "#000000" : "#ffffff";
  return L > 0.24 ? "#000000" : "#ffffff";
}

function createSnapPointTexture(color: string = "red") {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const canvasWidth = 16;
  const canvasHeight = 16;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  ctx.fillStyle = "black";
  // ctx.strokeStyle = "black";
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(
    canvasWidth * 0.5,
    canvasHeight * 0.5,
    canvasWidth * 0.5,
    0,
    Math.PI * 2
  );
  //   ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.fillStyle = "white";

  ctx.arc(canvasWidth * 0.5, canvasHeight * 0.5, 6, 0, Math.PI * 2);
  //   ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  ctx.fill();

  ctx.closePath();
  ctx.fill();
  return new CanvasTexture(canvas);
}

export { Item, createBoxCargoTexture, setCargoTextureUV };
