var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize.quiet",value:(_vm.onResize),expression:"onResize",modifiers:{"quiet":true}}]},[(_vm.showAnnotationControls)?_c('v-toolbar',{staticClass:"align-start no-print annotation-toolbar",attrs:{"dense":"","flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":_vm.controlsExpandedChange}},[_c('v-icon',[_vm._v(_vm._s(!_vm.controlsExpanded ? 'mdi-pencil' : 'mdi-pencil-off'))])],1)]}}],null,false,3191238558)},[_c('span',{staticClass:"no-print"},[_vm._v("Edit annotations")])]),(_vm.controlsExpanded)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":_vm.$vuetify.breakpoint.xsOnly,"text":_vm.$vuetify.breakpoint.smAndUp},on:{"click":_vm.addTextBox}},[_c('v-icon',[_vm._v("mdi-form-textbox")]),(_vm.$vuetify.breakpoint.smAndUp)?[_vm._v("Add text")]:_vm._e()],2)]}}],null,false,1519907109)},[_c('span',{staticClass:"no-print"},[_vm._v("Add text annotation")])]):_vm._e(),(_vm.controlsExpanded)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":_vm.$vuetify.breakpoint.xsOnly,"text":_vm.$vuetify.breakpoint.smAndUp},on:{"click":_vm.addLine}},[_c('v-icon',[_vm._v("mdi-vector-line")]),(_vm.$vuetify.breakpoint.smAndUp)?[_vm._v("Add line")]:_vm._e()],2)]}}],null,false,144636505)},[_c('span',{staticClass:"no-print"},[_vm._v("Add line annotation")])]):_vm._e(),(_vm.controlsExpanded)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"disabled":!_vm.selectedAnnotation,"icon":_vm.$vuetify.breakpoint.xsOnly,"text":_vm.$vuetify.breakpoint.smAndUp},on:{"click":_vm.deleteAnnotation}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")]),(_vm.$vuetify.breakpoint.smAndUp)?[_vm._v("Remove")]:_vm._e()],2)]}}],null,false,611727492)},[_c('span',{staticClass:"no-print"},[_vm._v("Remove selected annotation")])]):_vm._e(),(_vm.controlsExpanded)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":_vm.$vuetify.breakpoint.xsOnly,"text":_vm.$vuetify.breakpoint.smAndUp},on:{"click":_vm.saveAnnotations}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")]),(_vm.$vuetify.breakpoint.smAndUp)?[_vm._v("Save")]:_vm._e()],2)]}}],null,false,1221875769)},[_c('span',{staticClass:"no-print"},[_vm._v("Save annotations")])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }