













































import Vue, { PropType } from "vue"
import { Hold, HoldData } from "@/models/LoadlistModel";
import { VCard, VCardText, VRow, VCol, VTooltip, VBtn, VIcon} from "vuetify/lib";

export default Vue.extend({
    name: "select_slot_container_type_row",
    props: {
      holds: Array as PropType<Hold[]>,  
    },
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
      selectHold(hold: HoldData) {
        this.$emit("containerSelected", hold)
      }
    }
})

