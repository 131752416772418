import { Vector3 } from "three";
import { SceneManager } from "./sceneManager";
import { physicsWorker } from "./sceneManager";
import router from "@/router";

// export { setupEventBus };

interface Dictionary<T> {
  [x: string]: T;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventCallback = (a: any) => void;
class EventBus {
  events: Dictionary<EventCallback[]> = {};
  on(eventName: string, fn: EventCallback) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }
  off(eventName: string, fn: EventCallback) {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit(eventName: string, data: any = undefined) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn) => {
        fn(data);
      });
    }
  }
}

const eventBus = new EventBus();

eventBus.on("interactive-state", (state) => {
  SceneManager.setInteractionState(state);
});

eventBus.on("set-view", (view) => {
  SceneManager.setView(view);
});

eventBus.on("highlight-cargo-by-index", (indexes) => {
  SceneManager.cargoScene.showCargoesByIndex(indexes, []);
});

eventBus.on("show-all-cargoes", () => {
  SceneManager.cargoScene.showAll();
});

eventBus.on("remove-cargo", () => {
  if(router.currentRoute.params.item) return
  const unloadedItems = SceneManager.cargoScene.unloadSelectedItems();
  SceneManager.eventBus.emit("removed-cargo", unloadedItems);
  SceneManager.resetState();
});

eventBus.on("reset-state", () => {
  SceneManager.resetState();
});

eventBus.on("toggle-ruler", (length_dim) => {
  SceneManager.cargoScene.toggleRuler(length_dim);
});

eventBus.on("highlight-container", (containerIndex: number) => {
  if (containerIndex >= 0) {
    const containers = SceneManager.cargoScene.getContainers()
    if(containerIndex < containers.length) {
      SceneManager.state.selectedContainer?.setSelectionColor(false)
      SceneManager.state.selectedContainer?.setSelectionVisibility(false)
      SceneManager.state.selectedContainer = containers[containerIndex]
      SceneManager.state.selectedContainer.setSelectionColor(true)
      SceneManager.state.selectedContainer.setSelectionVisibility(true)
    }
  } else {
    SceneManager.state.selectedContainer?.setSelectionColor(false)
    SceneManager.state.selectedContainer?.setSelectionVisibility(false)
  }
})

eventBus.on("rotate-cargo", (axisName) => {
  let axis = new Vector3();
  switch (axisName) {
    case "x":
      axis = new Vector3(1, 0, 0);
      break;
    case "y":
      axis = new Vector3(0, 1, 0);
      break;
    default:
      axis = new Vector3(0, 0, 1);
      break;
  }
  SceneManager.cargoScene.getSelectedItems().forEach((i) => {
    physicsWorker.postMessage({
      event: "rotate",
      itemIndex: i.indexInContainer,
      axis: axis,
    });
  });
});

eventBus.on("undo", () => {
  SceneManager.undo();
});

export { eventBus };
