

































































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor, PropType } from "vue";
import API from "@/API";
import InputTable from "@/components/Custom/CargoInputTable/index.vue";
import PacklistReader from "@/components/Modals/PacklistReader.vue";
import { EquipmentStore } from "@/store/index";
import {
  Cargo,
  Hold,
  HoldData,
  HoldItem,
  LengthDim,
  WeightDim,
} from "@/models/LoadlistModel";
import { CargoStore, UserCompanyStore } from "@/store/index";
import OrientationsPicker from "@/components/Custom/OrientationPicker.vue";
import ItemProperties from "@/misc/itemProperties";
import { User } from "@/models/UserCompanyModel";
import { getSerializerError } from "@/misc/errorUtils";

import SceneComponent from "@/components/Custom/SceneComponent.vue";
import ExcelService from "@/services/excelService";
import { Workbook } from "exceljs";
import FileSaver from "file-saver";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      inputTable: InstanceType<typeof InputTable>;
    };
  }
>).extend({
  name: "cargo-library",
  components: {
    InputTable,
    OrientationsPicker,
    PacklistReader,
    SceneComponent,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preSelected: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  computed: {
    holdsLibrary(): Hold[] {
      return EquipmentStore.holds;
    },
    formTitle(): string {
      return this.editItem.id ? "Edit cargo" : "New cargo";
    },
    user(): User {
      return UserCompanyStore.user;
    },
    preferences(): any {
      return UserCompanyStore.preferences;
    },
    hiddenColumns(): string[] {
      return Array.isArray(this.preferences?.hidden_input_columns)
        ? this.preferences.hidden_input_columns
        : [];
    },
    headers(): {
      value?: string;
      text: string;
      sortable: boolean;
      dimension?: string;
      input?: string;
      values?: any[];
    }[] {
      return [
        {
          text: "",
          value: "id",
          sortable: false,
        },
        {
          text: "SKU",
          value: "sku",
          sortable: false,
        },
        ...(this.editable
          ? [{ text: "Actions", value: "name", sortable: false }]
          : []),
        ...ItemProperties.props()
          .filter(
            (i) =>
              i.key !== "qty" &&
              !i.additional &&
              !this.hiddenColumns.includes(i.key)
          )
          .map((i) => {
            let text = i.text;
            if (i.dimension === "length") {
              text = `${text} [${this.$store.getters.length_dim}]`;
            } else if (i.dimension === "weight") {
              `${text} [${this.$store.getters.weight_dim}]`;
            }
            return {
              value: i.key,
              text: text,
              sortable: false,
              dimension: i.dimension,
              input: i.input,
              values: i.values,
            };
          }),
      ];
    },
    filteredCargoes(): Cargo[] {
      if (this.search) {
        let parts = this.search
          .toLowerCase()
          .split("\n")
          .map((part) => part.trim())
          .filter((p) => p.length);
        if (parts.length) {
          let cargoes = CargoStore.cargoes;
          return parts.flatMap((query) => {
            const results = cargoes
              .map((cargo, index) => {
                return { index, cargo };
              })
              .filter(
                (i) =>
                  i.cargo.name.toLowerCase().includes(query) ||
                  (i.cargo.sku
                    ? i.cargo.sku.toLowerCase().includes(query)
                    : false) ||
                  (i.cargo.data.shipment_id
                    ? i.cargo.data.shipment_id.toLowerCase().includes(query)
                    : false)
              );
            cargoes = cargoes.filter(
              (_, index) => !results.find((c) => c.index == index)
            );
            return results.map((c) => c.cargo);
          });
        }
      }
      return CargoStore.cargoes;
    },
  },
  data() {
    return {
      lengthDims: [
        { text: "Millimeters", value: "MM" },
        { text: "Centimeters", value: "CM" },
        { text: "Decimeters", value: "DM" },
        { text: "Meters", value: "M" },
        { text: "Inches", value: "IN" },
        { text: "Feet", value: "FT" },
      ],
      weightDims: [
        { text: "Kilograms", value: "KG" },
        { text: "Tonnes", value: "MT" },
        { text: "Pounds", value: "LB" },
      ],
      length_dim: undefined,
      weight_dim: undefined,
      conversion: {
        showDialog: false,
        factor: 1.0,
        length: true,
      },
      loading: false,
      selected: [] as Cargo[],
      showEditDialog: false,
      showDeleteDialog: false,
      showExportDialog: false,
      showImportDialog: false,
      showParseExcelModal: false,
      dropFiles: [],
      importObjects: [],
      multisearch: true,
      search: "",
      editItem: null as Cargo,
      hasUsedPreSelected: false,
      errorMessage: "",
      displayError: false,
      dimensionRules: [(v: string) => !!v || "Dimension is required"],
    };
  },
  watch: {
    preSelected: {
      handler(val): void {
        if (!this.hasUsedPreSelected) {
          this.selected = this.filteredCargoes.filter((c) =>
            val.includes(c.name)
          );
          this.hasUsedPreSelected = true;
        }
      },
      immediate: true,
    },
    selected: {
      handler(changed: Cargo[], old): void {
        // Items added
        let sortedChanges = this.filteredCargoes.filter((cargo) =>
          changed.find((c) => c.id === cargo.id)
        );

        const added = sortedChanges.filter((r: Cargo) => old.indexOf(r) < 0);
        if (added.length > 0) {
          this.$emit("addItems", added);
        }
        // Items removed
        const removed = old.filter((r: any) => changed.indexOf(r) < 0);
        if (removed.length > 0) {
          this.$emit("removeItems", removed);
        }
      },
    },
  },
  mounted() {},
  methods: {
    holdForItem(cargo: Cargo): HoldData {
      let item = {
        ...cargo.data,
      } as HoldItem;
      let c = cargo.length_dim ? this.$toSI(cargo.length_dim) : 1.0;
      item.L = c * item.l;
      item.W = c * item.w;
      item.H = c * item.h;
      item.WT = item.wt;
      item.pos = { x: item.L * 0.5, y: item.W * 0.5, z: item.H * 0.5 };
      item.qty = 1;

      const h = {
        items: [item],
        L: item.L,
        W: item.W,
        H: item.H,
        no_end_walls: true,
        no_side_walls: true,
        no_roof: true,
      } as HoldData;

      return h;
    },
    openFileImporter(): void {
      (this.$refs.fileInput as any).click();
    },
    toLength(l: number, dim: LengthDim): number {
      if (dim) {
        return this.$options.filters.toLength(l * this.$toSI(dim), false);
      } else {
        return l;
      }
    },
    toWeight(wt: number, dim: WeightDim): number {
      if (dim) {
        return this.$options.filters.toWeight(wt * this.$toSI(dim), false);
      } else {
        return wt;
      }
    },
    showConvertDialog(dim: LengthDim | WeightDim, item: Cargo, length = true) {
      if (length) {
        if (item.length_dim) {
          this.conversion.factor =
            this.$toSI(item.length_dim) / this.$toSI(dim);
          this.conversion.showDialog = true;
          this.conversion.length = true;
        }
        item.length_dim = dim as LengthDim;
      } else {
        if (item.weight_dim) {
          this.conversion.factor =
            this.$toSI(item.weight_dim) / this.$toSI(dim);
          this.conversion.showDialog = true;
          this.conversion.length = false;
        }
        item.weight_dim = dim as WeightDim;
      }
    },
    deleteItems(): void {
      this.loading = true;
      API.deleteCargoes({ ids: this.selected.map((i) => i.id) })
        .then((response) => {
          this.loading = false;
          CargoStore.downloadCargoes();
          this.selected = [];
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    exportItems(items?: Cargo[]): void {
      this.loading = true;
      const loadExcelJS = () => import("exceljs");
      loadExcelJS()
        .then((exceljs) => {
          const excelService = new ExcelService();

          const workbook = excelService.cargoToXlsx(new exceljs.Workbook(), {
            items: items || CargoStore.cargoes,
            headers: this.headers.slice(2).map((h) => {
              return { key: h.value, value: h.text, dimension: h.dimension };
            }),
            toLength: this.toLength,
            toWeight: this.toWeight,
          });

          this.saveLoadlistFile(
            `Cargo-Library export ${new Date().toDateString()}.xlsx`,
            workbook
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async saveLoadlistFile(
      fileName: string,
      workbook: Workbook
    ): Promise<void> {
      const xls64 = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(
        new Blob([xls64], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        fileName
      );
    },
    save(): void {
      this.loading = true;
      let func = this.editItem.id ? API.updateCargo : API.createCargo;

      func({
        ...this.editItem,
        name: this.editItem.data.label,
      })
        .then((response) => {
          this.loading = false;
          CargoStore.downloadCargoes();
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.errorMessage = getSerializerError(e.response.data);
            this.displayError = true;
          }
          this.loading = false;
        });

      this.showEditDialog = false;
    },
    resetFile(e: any): void {
      e.target.value = null;
    },
    selectedFile(e: any): void {
      if (e.target.files && e.target.files[0]) {
        this.dropFiles = [e.target.files[0]];
      }
      this.showParseExcelModal = true;
    },
    bulkCreate(): void {
      let objects = this.$refs.inputTable.getObjects() as any;
      API.createCargoes(
        objects.map((x: any) => {
          return {
            name: x.label,
            sku: x.sku,
            data: { ...x, qty: undefined },
            length_dim: this.length_dim,
            weight_dim: this.weight_dim,
          };
        })
      )
        .then((_) => {
          this.showImportDialog = false;
          this.loading = false;
          this.importObjects = [];
          CargoStore.downloadCargoes();
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.errorMessage = getSerializerError(e.response.data);
            this.displayError = true;
          }
          this.loading = false;
        });
    },
  },
});
