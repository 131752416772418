export interface ItemProperty {
  key: string;
  type?: string;
  input: string;
  text: string;
  desc: string;
  width: number;
  dimension?: string;
  mot?: string | string[];
  values?: any[];
  required?: boolean;
  summable?: boolean;
  additional?: boolean;
  readOnly?: boolean;
  writeOnly?: boolean;
  cellAction?: () => void;
}

export interface CustomItemProperty {
  name: string;
  desc: string;
  summable: boolean;
}

const layers = [
  { key: undefined, text: "" },
  ...Array(19)
    .fill(1)
    .map((_, i) => {
      return { key: i + 1, text: String(i + 1) };
    })
];

export default {
  props(): ItemProperty[] {
    return [
      {
        key: "label",
        type: "string",
        input: "text",
        text: "Name",
        desc: "Cargo name or identifier",
        width: 300,
      } as ItemProperty,
      {
        key: "l",
        type: "float",
        text: `Length`,
        input: "text",
        desc: "Cargo length",
        dimension: "length",
        width: 100,
        required: true,
      } as ItemProperty,
      {
        key: "w",
        type: "float",
        text: `Width`,
        input: "text",
        desc: "Cargo width - (Diameter for Drums and Pipes)",
        dimension: "length",
        width: 100,
        required: true,
      } as ItemProperty,
      {
        key: "h",
        type: "float",
        text: `Height`,
        input: "text",
        desc: "Cargo height",
        dimension: "length",
        width: 100,
        required: true,
      } as ItemProperty,
      {
        key: "wt",
        type: "float",
        text: `Weight/pc.`,
        input: "text",
        desc: "Cargo weight per piece",
        dimension: "weight",
        width: 150,
        required: false,
        summable: true,
      } as ItemProperty,
      {
        key: "l#additional",
        type: "float",
        text: `Length`,
        input: "text",
        desc: "Cargo length",
        dimension: "length_additional",
        width: 100,
        required: false,
        additional: true,
      } as ItemProperty,
      {
        key: "w#additional",
        type: "float",
        text: `Width`,
        input: "text",
        desc: "Cargo width - (Diameter for Drums and Pipes)",
        dimension: "length_additional",
        width: 100,
        required: false,
        additional: true,
      } as ItemProperty,
      {
        key: "h#additional",
        type: "float",
        text: `Height`,
        input: "text",
        desc: "Cargo height",
        dimension: "length_additional",
        width: 100,
        required: false,
        additional: true,
      } as ItemProperty,
      {
        key: "wt#additional",
        type: "float",
        text: `Weight/pc.`,
        input: "text",
        desc: "Cargo weight per piece",
        dimension: "weight_additional",
        width: 150,
        required: false,
        additional: true,
        summable: true,
      } as ItemProperty,
      {
        key: "qty",
        type: "integer",
        text: "Quantity",
        input: "text",
        desc:
          "Number of cargoes. If left empty - the tool will try to maximize the quantity. If some rows has a quantity set and some do not - the latters will act as fillers",
        width: 80,
        summable: true,
      } as ItemProperty,
      {
        key: "not_stackable",
        type: "bool",
        text: "Not Stackable",
        input: "checkbox",
        desc: "No cargo can be put on top of this one (including itself)",
        width: 130,
      } as ItemProperty,
      {
        key: "bottom_only",
        type: "bool",
        text: "Bottom Only",
        input: "checkbox",
        desc: "This cargo must be loaded on the floor",
        width: 120,
      } as ItemProperty,
      // {
      //   key: "tiltable",
      //   type: "bool",
      //   text: "Tiltable",
      //   input: "checkbox",
      //   desc: "This cargo can be laid down and rotated in any direction",
      //   width: 80,
      // } as ItemProperty,
      {
        key: "orientations",
        type: "integer",
        text: "Orientations",
        input: "orientations_picker",
        desc: "Possible orientations of this cargo",
        values: [
          { text: "Longship", key: 1 },
          { text: "Rotatable", key: 3, default: true },
          { text: "Tiltable", key: 63 },
          { text: "Custom...", key: 0 },
        ],
        width: 130,
      } as ItemProperty,
      // {
      //   key: "not_rotatable",
      //   type: "bool",
      //   text: "Longship",
      //   input: "checkbox",
      //   desc: "This cargo can only be loaded in length direction",
      //   width: 94,
      // } as ItemProperty,
      {
        key: "max_layers",
        type: "integer",
        values: layers,
        text: "Max layers",
        input: "select",
        desc:
          "Number of layers that this cargo can be stacked in. No other cargoes can be stacked on top",
        width: 80,
      } as ItemProperty,
      {
        key: "max_load",
        type: "float",
        text: "Max load",
        input: "text",
        desc:
          "Max weight that this cargo can support on top",
        width: 100,
        dimension: "weight",
      } as ItemProperty,
      {
        key: "geometry",
        type: "string",
        values: [
          { key: "box", text: "Box", default: true },
          { key: "cylinder", text: "Cylinder" },
          { key: "hollow_cylinder", text: "Hollow cylinder" },
        ],
        text: "Geometry",
        input: "select",
        desc:
          "Cargo geometry. Default is box-shaped. If Pipe, the diameters are the Width and Height column, for Drum it's the Length and Width column",
        width: 75,
        writeOnly: true,
      } as ItemProperty,
      {
        key: "color",
        type: "color",
        text: "Color",
        // cellAction: () => {
        //   let cell = this.$refs.sheet.getObjects()[
        //     this.$refs.sheet.primaryCell.row
        //   ];
        //   if (cell.color) this.selectedColor = cell.color;

        //   this.showColorPickerDialog = true;
        // },
        input: "color",
        width: 50,
        desc:
          "The color of the cargo. If not set, it will be set automatically on a random basis",
      } as ItemProperty,
      {
        key: "shipment_id",
        type: "string",
        text: "Group/Consignment",
        input: "text",
        desc:
          "Cargoes with the same identifier (e.g. ABC123) needs to be put in the same container/trailer/pallet",
        width: 160,
      } as ItemProperty,
      {
        key: "class_id",
        type: "string",
        text: "Class",
        input: "text",
        desc:
          "Use classes to attach custom classes. Also used by the segregation table found under settings",
        width: 80,
      },
      {
        key: "palletize",
        type: "bool",
        text: "Preload",
        input: "checkbox",
        desc:
          "Enable this if this cargo should be pre loaded into a secondary equipment (like a pallet) before loading into a primary equipment (like a container)",
        width: 90,
        writeOnly: true,
        mot: ["SEA", "ROAD", "AIR", "PALL"],
      } as ItemProperty,
      {
        key: "allowed_containers",
        type: "list",
        text: "Load in",
        input: "hold_select",
        desc:
          "List of containers / trailers / pallets this cargo must be loaded in. Double click in cell below to go to library",
        width: 120,
        writeOnly: true,
      } as ItemProperty,
      {
        key: "priority",
        type: "integer",
        text: "Priority",
        input: "text",
        desc:
          "A number, indicating the priority of a cargo. For example, A priority of 3 means it will be loaded before a cargo with a priority of 2 - and all cargoes with no priority set.",
        width: 80,
      } as ItemProperty,
      {
        key: "free_space",
        type: "string",
        text: "Margin",
        input: "text",
        desc: "Free space around the cargo. If entered as X;Y - then X will be added to X side and Y to width side",
        width: 100,
        dimension: "length",
      } as ItemProperty,
      {
        key: "unit_qty",
        type: "integer",
        text: "Unit Quantity",
        input: "text",
        desc:
          "Unit quantity. If set, then the Quantity field will internally be divided by this field. Example: Unit quantity set to 5 and Quantity field set to 12 - then 3 pieces of the item will be loaded",
        width: 100,
        summable: true,
      } as ItemProperty,
      {
        key: "chargable_wt",
        type: "float",
        text: "Chargable weight",
        desc:
          "Chargable Weight is the larger of Volume*Shipping Factor or Weight",
        width: 100,
        summable: true,
        readOnly: true,
      } as ItemProperty,
      {
        key: "destination",
        type: "string",
        text: "Destination",
        input: "text",
        desc:
          "Destination of item. Will be used to calculate load order if Settings->Routing is enabled",
        width: 200,
        summable: false,
        mot: ["SEA", "AIR", "ROAD"],
      } as ItemProperty,
    ];
  },
};
