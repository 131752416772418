











import Vue, { PropType } from "vue";
import { UserCompanyStore } from "@/store/index";
export default Vue.extend({
  name: "weight-input-field",
  data() {
    return {
      displayValue: undefined as number,
      errors: [] as string[],
    };
  },
  props: {
    value: Number,
    rules: Array as PropType<((v: number) => boolean | string)[]>,
    weightDim: String,
  },
  computed: {
    weightUnit(): string {
      return this.weightDim || UserCompanyStore.weight_dim;
    },
    inputListeners(): any {
      return {
        ...this.$listeners,
        input: (event: string) => {
          this.$emit("input", this.toInternalValue(event));
        },
        change: (event: string) => {
          this.$emit("change", this.toInternalValue(event));
        },
      };
    },
  },
  watch: {
    value: {
      handler: function(a: number): void {
        if (a !== undefined && !isNaN(a)) {
          this.errors =
            (this.rules
              ?.map((r) => r(a))
              .filter((v) => v !== true) as string[]) || [];
        }
        this.toDisplayValue();
      },
      immediate: true,
    },
  },
  methods: {
    toDisplayValue(): void {
      if (!isNaN(this.value)) {
        this.displayValue = Math.round(
          this.value * this.$fromSI(this.weightUnit) || 0.0
        );
      }
    },
    toInternalValue(valString: string): number | null {
      const valNumber = parseFloat(valString);
      if (!isNaN(valNumber)) {
        const v = valNumber * this.$toSI(this.weightUnit);
        // if (this.noRounding) {
        return v;
        // }
        //Round KG to 1 decimals
        // return Math.round(v * 10) / 10;
      }
      return null;
    },
  },
});
