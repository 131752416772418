










import Vue from "vue";
import API from "@/API";
import { UserCompanyStore } from "@/store";

export default Vue.extend({
  name: "sso-callback",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  mounted() {
    const auth_code = this.$route.query.code as string;

    if (auth_code) {
      this.isLoading = true;
      API.ssoLogin(auth_code)
        .then((data) => {
          this.isLoading = false;
          this.getMe().then((_) => {
            this.$router.push("/news");
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    getMe(): Promise<undefined> {
      return UserCompanyStore.getMe();
    },
  },
});
