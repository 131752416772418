













































































































































































































































































































































































































































































































import Vue, { VueConstructor } from "vue";
import { LoadlistStore, UserCompanyStore } from "@/store/index";
import InputTable from "@/components/Custom/CargoInputTable/index.vue";
import PacklistReader from "@/components/Modals/PacklistReader.vue";
import CargoLibraryImportModal from "@/components/Modals/CargoLibraryImport.vue";
import copyLoadlistModal from "@/components/Modals/CopyLoadlist.vue";
import deleteLoadlistModal from "@/components/Modals/DeleteLoadlist.vue";
import confirmModal from "@/components/Modals/Confirm.vue";
import {
  Loadlist,
  Loadplan,
  HoldInputItem,
  ListType,
  HoldData,
  LoadlistGroup,
} from "@/models/LoadlistModel";
import { Warning } from "@/misc/itemWarnings";
import { User } from "@/models/UserCompanyModel";
import containerUtils from "@/misc/containerUtils";
import API from "@/API";
import { APIResponse } from "@/models/APIModel";
import { RRule } from "rrule";

const LOW_DENSITY_LIMIT_WARNING = 40;
const HIGH_DENSITY_LIMIT_WARNING = 8000;

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      inputTable: InstanceType<typeof InputTable>;
      fileInput: HTMLInputElement;
      form: any;
      dialog: any;
    };
  }
>).extend({
  name: "edit",
  components: {
    InputTable,
    PacklistReader,
    CargoLibraryImportModal,
    copyLoadlistModal,
    deleteLoadlistModal,
    confirmModal,
  },
  data() {
    return {
      isLoading: false,
      dropFiles: [],
      showParseExcelModal: false,
      showLibraryHoldsModal: false,
      showCargoLibrary: false,
      showEtdDialog: false,
      showExpansionPanel: null,
      errorMessage: null,
      validForm: false,
      lengthDims: [
        { text: "MM", value: "MM" },
        { text: "CM", value: "CM" },
        { text: "DM", value: "DM" },
        { text: "M", value: "M" },
        { text: "IN", value: "IN" },
        { text: "FT", value: "FT" },
      ],
      weightDims: [
        { text: "KG", value: "KG" },
        { text: "MT", value: "MT" },
        { text: "LB", value: "LB" },
      ],
      snackbar: false,
      moveItemsInfoSnackbar: false,
      showNoItemsDialog: false,
      showWarningsDialog: false,
      overrideWarnings: false,
      warnings: [],
      showDeleteLoadlistModal: false,
      showCopyLoadlistModal: false,
      showConfirmTypeChangeDialog: false,
      loadlistType: null as ListType,
      prevLoadlistType: null as ListType,
    };
  },
  computed: {
    name: {
      get(): string {
        return LoadlistStore.loadlist.name;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "name",
          value: value,
        });
      },
    },
    objects: {
      get(): HoldInputItem[] {
        return this.loadlist.data;
      },
      set(value: HoldInputItem[]): void {
        LoadlistStore.setLoadlistProperty({
          key: "data",
          value: value,
        });
      },
    },
    length_dim: {
      get(): string {
        return this.loadlist.length_dim;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "length_dim",
          value: value,
        });
      },
    },
    weight_dim: {
      get(): string {
        return this.loadlist.weight_dim;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "weight_dim",
          value: value,
        });
      },
    },
    pol: {
      get(): string {
        return this.loadlist.pol;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "pol",
          value: value,
        });
      },
    },
    pod: {
      get(): string {
        return this.loadlist.pod;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "pod",
          value: value,
        });
      },
    },
    customer: {
      get(): string {
        return this.loadlist.customer;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "customer",
          value: value,
        });
      },
    },
    notes: {
      get(): string {
        return this.loadlist.notes;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "notes",
          value: value,
        });
      },
    },
    etd: {
      get(): string {
        return this.loadlist.etd;
      },
      set(value: string): void {
        LoadlistStore.setLoadlistProperty({
          key: "etd",
          value: value,
        });
      },
    },
    decimalSeparator(): string {
      return (0.1).toLocaleString().substr(1, 1);
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    loadplans(): Loadplan[] {
      return LoadlistStore.loadplans;
    },
    readonly(): boolean {
      return LoadlistStore.readonly;
    },
    isAuthenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    group(): LoadlistGroup | undefined {
      const id = LoadlistStore.loadlist.group;
      if (id !== undefined) {
        return LoadlistStore.loadlistGroups.find((group) => group.id == id);
      }
    },
    hasRecurringSchedule(): boolean {
      const id = LoadlistStore.loadlist.group;
      const group = LoadlistStore.loadlistGroups.find(
        (group) => group.id == id
      );
      return this.etd && !!group?.data?.rrule;
    },
    nextDate(): Date {
      if (!this.group?.data?.rrule || !this.etd) {
        return undefined;
      }
      let rrule = RRule.fromString(this.group.data.rrule);
      let date = new Date(this.etd);
      date.setDate(date.getDate() + 1);
      let nextDate = rrule.after(date, true);
      return nextDate || undefined;
    },
    prevDate(): Date {
      if (!this.group?.data?.rrule || !this.etd) {
        return undefined;
      }
      let rrule = RRule.fromString(this.group.data.rrule);
      let date = new Date(this.etd);
      date.setDate(date.getDate() - 1);
      let prev = rrule.before(date, true);
      return prev || undefined;
    },
  },
  mounted(): void {
    // this.$refs.form.validate();
  },
  beforeRouteLeave(to: never, from: never, next: any) {
    if (
      this.isOwner() &&
      JSON.stringify(this.objects) !=
        JSON.stringify(this.$refs.inputTable.getObjects())
    ) {
      {
        this.objects = this.$refs.inputTable.getObjects();
        this.saveLoadlist()
          .catch(() => {})
          .finally(() => {
            next();
          });
      }
    } else {
      next();
    }
  },
  methods: {
    goToLoadlist(id: string): void {
      this.objects = this.$refs.inputTable.getObjects();

      this.saveLoadlist()
        .then(() => {
          return this.$router.push({
            name: "loadlist",
            params: { id },
          });
        })
        .finally(() => {
          LoadlistStore.getLoadlist(id);
        });
    },
    goToDate(date: Date, list: Loadlist): void {
      if (list) {
        // TODO: save before continuing
        this.goToLoadlist(list.id);
      } else if (this.group.data?.default_type) {
        // We need to create a default loadlist for this date
        API.createLoadlist({
          name: this.group.name,
          group: this.group.id,
          list_type: this.group.data.default_type,
          length_dim:
            UserCompanyStore.preferences?.default_loadlist_length_dim ||
            undefined,
          weight_dim:
            UserCompanyStore.preferences?.default_loadlist_weight_dim ||
            undefined,
          etd: date.toLocaleDateString("en-CA"),
          data: [],
        }).then((data) => {
          this.goToLoadlist(data.data.id);
        });
      } else {
        this.errorMessage = "Could not find or create the loadlist";
        this.snackbar = true;
        // TODO: print that we can't create the loadlist
      }
    },
    goToNextDate(): void {
      const date = this.nextDate;
      if (date) {
        API.getLoadlists({
          group: this.group.id,
          etd_after: this.etd,
          etd_before: date.toLocaleDateString("en-CA"),
        })
          .then((data: APIResponse) => {
            let lists = data.data.results as Loadlist[];
            let next = lists.find((l) => l.id != this.loadlist.id);
            this.goToDate(date, next);
          })
          .catch((error: any) => {
            console.log("Error fetching loadlists");
          });
      }
    },
    goToPrevDate(): void {
      const date = this.prevDate;
      if (date) {
        API.getLoadlists({
          group: this.group.id,
          etd_after: date.toLocaleDateString("en-CA"),
          etd_before: this.etd,
        })
          .then((data: APIResponse) => {
            let lists = data.data.results as Loadlist[];
            let prev = lists.reverse().find((l) => l.id != this.loadlist.id);
            this.goToDate(date, prev);
          })
          .catch((error: any) => {
            console.log("Error fetching loadlists");
            this.isLoading = false;
          });
      }
    },
    selectExcelFile(): void {
      this.$refs.fileInput.click();
    },
    resetFile(e: any): void {
      e.target.value = null;
    },
    selectedFile(e: any): void {
      if (e.target.files && e.target.files[0]) {
        this.dropFiles = [e.target.files[0]];
      }
      this.showParseExcelModal = true;
    },
    gotoSummary(): void {
      this.objects = this.$refs.inputTable.getObjects();
      this.$router.push({ name: "summary" });
    },
    updateLoadlistType(listType: ListType) {
      LoadlistStore.setLoadlistProperty({
        key: "list_type",
        value: listType,
      });
      LoadlistStore.resetLoadlistResults();
    },
    saveAndContinue(): void {
      if (this.$refs.form.validate()) {
        let objects: HoldInputItem[] = this.$refs.inputTable.getObjects();
        if (!objects.length) {
          this.showNoItemsDialog = true;
          return;
        }

        this.warnings = [];
        const low_densities: number[] = [];
        const high_densities: number[] = [];
        const no_weights: number[] = [];

        const shipping_factor = this.loadplan?.settings?.shipping_factor || 200;

        objects.forEach((item, index) => {
          const m3 =
            item.l *
            item.w *
            item.h *
            Math.pow(this.$toSI(this.loadlist.length_dim), 3);

          if (!item.wt) {
            item.wt = m3 * shipping_factor;
            no_weights.push(index);
          }

          const density = (this.$toSI(this.loadlist.weight_dim) * item.wt) / m3;

          if (density > HIGH_DENSITY_LIMIT_WARNING) {
            high_densities.push(index);
          }
          if (density < LOW_DENSITY_LIMIT_WARNING) {
            low_densities.push(index);
          }
        });

        if (high_densities.length) {
          this.warnings.push({
            id: Warning.HIGH_DENSITY,
            indexes: high_densities,
          });
        }
        if (low_densities.length) {
          this.warnings.push({
            id: Warning.LOW_DENSITY,
            indexes: low_densities,
          });
        }
        if (no_weights.length) {
          this.warnings.push({
            id: Warning.NO_WEIGHT,
            indexes: no_weights,
          });
        }

        const no_qty = objects
          .map((i, index) => {
            if (i.qty === undefined) return index;
          })
          .filter((index) => index >= 0);

        if (no_qty.length === objects.length)
          this.warnings.push({ id: Warning.NO_LIMIT, indexes: no_qty });
        else if (no_qty.length)
          this.warnings.push({ id: Warning.FILLER, indexes: no_qty });

        if (this.warnings.length && !this.overrideWarnings) {
          this.showWarningsDialog = true;
        } else {
          this.isLoading = true;
          this.objects = objects;
          this.saveLoadlist()
            .then(() => {
              this.isLoading = false;
              this.$router.push({ name: "setup" });
            })
            .catch((error: any) => {
              if (
                error.response &&
                error.response.data &&
                Array.isArray(error.response.data.data)
              ) {
                for (var i = 0; i < error.response.data.data.length; i++) {
                  let keys = Object.keys(error.response.data.data[i]);
                  if (keys.length) {
                    this.errorMessage = `Row ${i + 1}: ${keys[0]} - ${
                      error.response.data.data[i][keys[0]]
                    }`;
                    this.snackbar = true;
                    break;
                  }
                }
              }

              this.isLoading = false;
            });
        }
      } else {
        this.$vuetify.goTo(this.$refs.form);
      }
    },
    doNotShowInputModeDialog(): void {
      localStorage.setItem("cplDontAskForInputMethod", "true");
    },
    openLink(url: string): void {
      window.open(url, "_blank");
    },
    downloadTemplate(): void {
      const link = document.createElement("a");
      link.href = "/static/templates/load_list_template_v1.3.xlsx";
      link.download = "load_list_template_v1.3.xlsx";
      link.click();
    },
    addItemToObjects(object: HoldInputItem): void {
      this.objects = [...this.objects, object];
    },
    saveEtd(etd: string): void {
      this.$refs.dialog.save(etd);
    },
    saveLoadlist(): Promise<undefined> {
      return LoadlistStore.saveLoadlist();
    },
    isOwner(): boolean {
      if (
        !this.is_authenticated() ||
        this.loadlist?.owner !== this.user().email
      ) {
        return false;
      }
      return true;
    },
    user(): User {
      return UserCompanyStore.user;
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    confirmLoadlistChange(): void {
      this.showConfirmTypeChangeDialog = false;
      this.updateLoadlistType(this.loadlistType);
      this.prevLoadlistType = null;
    },
    discardLoadlistChange(): void {
      this.showConfirmTypeChangeDialog = false;
      this.loadlistType = this.prevLoadlistType;
      this.prevLoadlistType = null;
    },
    listTypeChange(listType: ListType): void {
      this.prevLoadlistType = this.loadlistType;
      this.loadlistType = listType;
      if (this.loadplan.holds?.length) {
        this.showConfirmTypeChangeDialog = true;
      } else {
        this.updateLoadlistType(this.loadlistType);
      }
    },
    moveItemsToLoadlist(data: {
      startRow: number;
      endRow: number;
      id: string;
    }): void {
      for (let i = 0; i < this.loadplans.length; i++) {
        let removeItemsFromHolds: {
          from_container: HoldData;
          from_indices: number[];
        }[] = [];

        // Go through each load plan - and for each container, then check if the selected rows has been loaded - if so then save the indices
        for (let j = 0; j < this.loadplans[i].holds.length; j++) {
          let indices: number[] = [];
          for (let m = data.startRow; m <= data.endRow; m++) {
            indices = [
              ...indices,
              ...containerUtils.getAllCargoIndices(
                this.objects[m],
                this.loadplans[i].holds[j]
              ),
            ];
          }
          if (indices.length)
            removeItemsFromHolds.push({
              from_container: this.loadplans[i].holds[j],
              from_indices: indices,
            });
        }
        // Remove these cargoes from the containers
        if (removeItemsFromHolds.length) {
          LoadlistStore.removeItemsInLoadplan({
            settings: this.loadplans[i].settings,
            containers: removeItemsFromHolds,
            hideProgress: false,
          }).then(
            (solution: any) => {
              solution.containers.forEach((c: HoldData) => {
                let index = this.loadlist.result.versions[i].holds.findIndex(
                  (c2: HoldData) => c.uuid === c2.uuid
                );
                this.loadlist.result.versions[i].holds[index] = c;
              });
            },
            (error) => {}
          );
        }
      }
      API.getLoadlist(data.id).then((response) => {
        let newList = response.data;
        newList.data = [
          ...newList.data,
          ...this.objects.slice(data.startRow, data.endRow + 1),
        ];
        API.saveLoadlist(newList);

        // Remove the selected rows
        LoadlistStore.setLoadlistProperty({
          key: "data",
          value: [
            ...this.objects.slice(0, data.startRow),
            ...this.objects.slice(data.endRow + 1, this.objects.length),
          ],
        });

        this.saveLoadlist();
        this.moveItemsInfoSnackbar = true;
      });
    },
  },
  watch: {
    "loadlist.list_type": {
      handler: function(listType: ListType): void {
        this.loadlistType = listType;
      },
      immediate: true,
    },
  },
});
