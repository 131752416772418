































import Vue from "vue";
import { Loadlist } from "@/models/LoadlistModel";
import { LoadlistStore } from "@/store/index";

interface LoadplanVersion {
  text: number;
  value: number;
}

export default Vue.extend({
  name: "loadplan-version",
  data() {
    return {};
  },
  props: {},
  computed: {
    loadplanVersions(): LoadplanVersion[] {
      return Array.from(
        { length: this.loadlist.result.versions.length },
        (value, key) => ({ text: key + 1, value: key })
      );
    },
    loadplan_version: {
      get(): number {
        return LoadlistStore.loadplan_version;
      },
      set(versionValue: number): void {
        LoadlistStore.setLoadplanVersion(versionValue);
      },
    },
    loadplan_notes: {
      get(): string {
        return LoadlistStore.loadplan.notes;
      },
      set(value: string): void {
        LoadlistStore.setLoadplanProperty({
          key: "notes",
          value: value,
        });
      },
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
  },
  watch: {},
  created() {},
  methods: {
    addLoadplanVersion(): void {
      LoadlistStore.addLoadplanVersion();
    },
    removeLoadplanVersion(): void {
      LoadlistStore.removeLoadplanVersion();
    },
  },
});
