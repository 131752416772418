


















































































import Vue from "vue";
export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
});
