


























/* eslint-disable */
import Vue, { PropType } from "vue";
import { SceneManager, INTERACTIVE_STATES } from "@/graphics/sceneManager";
import { HoldData } from "@/models/LoadlistModel";
import { InteractiveMode, ViewSettings } from "@/models/GraphicsModel.js";
import { AugmentedSet } from "@/models/augmented/set";
import { SetTypeData } from "@/models/SetsModel";

const FOOTER_HEIGHT = 24;

export default Vue.extend({
  name: "scene-component",
  data: function() {
    return {
      renderDone: false,
    };
  },
  props: {
    interactiveMode: {
      type: String,
      default: null,
    },
    holdObject: Object as PropType<HoldData>,
    set: Object as PropType<AugmentedSet>,
    setType: Object as PropType<SetTypeData>,
    canvasWidth: Number,
    canvasHeight: Number,
    customViewSettings: Object as PropType<ViewSettings>,
    enableReuse: Boolean,
    centered: Boolean,
  },
  computed: {
    isFullInteractiveMode(): boolean {
      return this.interactiveMode === INTERACTIVE_STATES.FULL;
    },
  },
  mounted(): void {
    this.renderView();
  },
  methods: {
    renderView(): void {
      if (this.canvasWidth && this.canvasHeight) {
        var height = this.canvasHeight;
        var width = this.canvasWidth;
      } else {
        var rect = this.getElementSize();
        var width = rect.width;
        var height = rect.height;
      }
      let rendering: HoldData[] = [];
      if (this.setType) {
        rendering = AugmentedSet.fromSetType(this.setType).rendering;
      } else if (this.set) {
        rendering = this.set.rendering;
      } else if (this.holdObject) {
        rendering = [this.holdObject];
      }
      if (window.cpl) {
        window.cpl.currentRendering = window.cpl?.currentRendering
          ? window.cpl.currentRendering + 1
          : 1;
      }
      SceneManager.createScene(
        rendering,
        this.interactiveMode as InteractiveMode,
        width,
        height,
        this.customViewSettings,
        this.enableReuse
      )
        .then((el: HTMLCanvasElement) => {
          this.renderDone = true;
          el.className = 'threejs-canvas'
          this.$el.insertBefore(el, this.$el.firstChild);
          this.$emit("renderDone");
          window.cpl.renderCount++;
        })
        .finally(() => {
          if (window.cpl) window.cpl.currentRendering -= 1;
        });
    },
    getElementSize(): { width: number; height: number } {
      let innerHeight = window.innerHeight;
      let rect = this.$el.getBoundingClientRect();
      let availableHeight = innerHeight - rect.top - FOOTER_HEIGHT;
      if (rect.width * 0.5 <= availableHeight)
        return {
          width: rect.width,
          height: rect.width * 0.5,
        };
      return {
        width: availableHeight * 2,
        height: availableHeight,
      };
    },
    onResize(): void {
      if (!!this.interactiveMode) {
        const rect = this.getElementSize();
        SceneManager.resize(rect.width, rect.height);
      }
    },
  },
});
