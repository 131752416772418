var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"outlined":"","type":"info"}},[_vm._v(" Sets are compositions of multiple containers / holds / trailers / pallets - like a "),_c('strong',[_vm._v(_vm._s(_vm.$setNames(_vm.base_type)))])]),_c('sets-library-component',{attrs:{"setType":_vm.$route.params.type},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var sets = ref.paginatedItems;
return [_c('v-expansion-panels',{staticClass:"unselectable",attrs:{"focusable":""}},_vm._l((sets),function(set){return _c('v-expansion-panel',{key:set.id,on:{"click":function($event){return _vm.$vuetify.goTo($event.target)}}},[_c('v-expansion-panel-header',[_c('span',[_c('strong',[_c('span',[(!set.default)?_c('v-icon',[_vm._v("mdi-account")]):_vm._e(),_vm._v(" "+_vm._s(set.name)+" ")],1)])])]),_c('v-expansion-panel-content',[_c('scene-component',{attrs:{"setType":set.data,"canvas-width":300,"canvas-height":150}}),(!set.default && _vm.user.is_editor)?_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'modify_set',
                  params: {
                    set_id: set.id,
                    type: set.base_type,
                  },
                })}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1):_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'modify_set',
                  params: {
                    set_id: set.id,
                    type: set.base_type,
                  },
                })}}},[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")]),_vm._v("View ")],1)],1)],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }