var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-1"},[_c('v-card-title',{staticClass:"d-flex align-baseline"},[_c('p',[_vm._v(_vm._s(_vm.part.type.toUpperCase()))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', _vm.part.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-card-text',[_c('v-row',[(
          !(_vm.part.stick_to.includes('front') && _vm.part.stick_to.includes('rear'))
        )?_c('v-col',[_c('length-input-field',{attrs:{"label":"L"},model:{value:(_vm.part.L),callback:function ($$v) {_vm.$set(_vm.part, "L", $$v)},expression:"part.L"}})],1):_vm._e(),(
          !(
            _vm.part.stick_to.includes('side1') && _vm.part.stick_to.includes('side2')
          )
        )?_c('v-col',[_c('length-input-field',{attrs:{"label":"W"},model:{value:(_vm.part.W),callback:function ($$v) {_vm.$set(_vm.part, "W", $$v)},expression:"part.W"}})],1):_vm._e(),(
          !(_vm.part.stick_to.includes('floor') && _vm.part.stick_to.includes('roof'))
        )?_c('v-col',[_c('length-input-field',{attrs:{"label":"H"},model:{value:(_vm.part.H),callback:function ($$v) {_vm.$set(_vm.part, "H", $$v)},expression:"part.H"}})],1):_vm._e()],1),_c('v-select',{attrs:{"items":['front', 'rear', 'floor', 'roof', 'side1', 'side2'],"menu-props":{ maxHeight: '400' },"label":"Stick to","multiple":""},model:{value:(_vm.part.stick_to),callback:function ($$v) {_vm.$set(_vm.part, "stick_to", $$v)},expression:"part.stick_to"}}),_c('v-row',[(
          !_vm.part.stick_to.includes('front') && !_vm.part.stick_to.includes('rear')
        )?_c('v-col',[_c('length-input-field',{attrs:{"label":"X"},model:{value:(_vm.part.pos.x),callback:function ($$v) {_vm.$set(_vm.part.pos, "x", $$v)},expression:"part.pos.x"}})],1):_vm._e(),(
          !_vm.part.stick_to.includes('side1') && !_vm.part.stick_to.includes('side2')
        )?_c('v-col',[_c('length-input-field',{attrs:{"label":"Y"},model:{value:(_vm.part.pos.y),callback:function ($$v) {_vm.$set(_vm.part.pos, "y", $$v)},expression:"part.pos.y"}})],1):_vm._e(),(
          !_vm.part.stick_to.includes('floor') && !_vm.part.stick_to.includes('roof')
        )?_c('v-col',[_c('length-input-field',{attrs:{"label":"Z"},model:{value:(_vm.part.pos.z),callback:function ($$v) {_vm.$set(_vm.part.pos, "z", $$v)},expression:"part.pos.z"}})],1):_vm._e()],1),(!_vm.part.stick_to.includes('roof'))?_c('v-checkbox',{attrs:{"label":"Not stackable"},model:{value:(_vm.part.not_stackable),callback:function ($$v) {_vm.$set(_vm.part, "not_stackable", $$v)},expression:"part.not_stackable"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }