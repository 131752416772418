var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"tile":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$refs.calendar.title))]):_vm._e()],1),_c('v-sheet',[_c('v-calendar',{key:_vm.key,ref:"calendar",staticStyle:{"border-right":"1px solid"},attrs:{"type":"month","events":_vm.shownEntries,"eventColor":_vm.getEventColor,"eventHeight":_vm.eventHeight,"eventMore":false,"start":_vm.start,"weekdays":"1,2,3,4,5,6,0"},on:{"click:event":_vm.showEvent,"click:date":function($event){return _vm.newList($event)},"change":function($event){return _vm.getEntries($event.start.date, $event.end.date)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{staticClass:"d-flex align-center",style:({
            'border-left': ("solid " + (event.color) + " 3px"),
            margin: '0',
            height: '100%',
            padding: '2px 2px 2px 4px',
            borderRadius: 0,
            opacity:
              new Date(event.start.toDateString()) <
              new Date(new Date().toDateString())
                ? 0.3
                : 1.0,
            background: event.loadlist
              ? _vm.getEventAlternateColor(event)
              : 'repeating-linear-gradient(127deg, #ffffff,#ffffff 2px, #e7e7e7 2px, #e7e7e7 6px)',
          })},[_c('span',{style:({
              color: event.loadlist ? event.color : "#333333",
              'font-weight': 'bold',
            })},[_vm._v(_vm._s(event.name))])])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"z-index":"2147483001"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.hasResults},on:{"click":function($event){return _vm.toggleLoadlistLock()}}},[_vm._v(" "+_vm._s(_vm.selectedLoadlist && _vm.selectedLoadlist.result.locked ? "Unlock loadlist" : "Lock loadlist")+" ")])],1)],1)],1),_c('v-card-text',[(_vm.selectedEvent.loadlist)?_c('div',[_c('loadlist-preview',{attrs:{"loadlist":_vm.selectedLoadlist}})],1):_vm._e()]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Close ")]),_c('v-spacer'),(_vm.selectedEvent.loadlist)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'loadlist',
                params: { id: _vm.selectedEvent.loadlist.id },
              })}}},[_vm._v(" Open ")]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.createLoadlistFromEvent(_vm.selectedEvent)}}},[_vm._v(" Create loadlist ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"380"},model:{value:(_vm.showSelectListTypeModal),callback:function ($$v) {_vm.showSelectListTypeModal=$$v},expression:"showSelectListTypeModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select type of loadlist")]),_c('v-card-text',[_c('v-list',_vm._l((_vm.$list_types),function(tyype){return _c('v-list-item',{key:tyype.value,on:{"click":function($event){_vm.selectedEvent.list_type = tyype.value;
              _vm.createLoadlistFromEvent(_vm.selectedEvent);}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(("$vuetify.icons." + (tyype.value))))]),_vm._v(" "+_vm._s(tyype.text)+" ")],1)],1)}),1)],1)],1)],1),_c('new-list-modal',{attrs:{"visible":_vm.showNewListModal,"group":_vm.groupId,"etd":_vm.newListDate},on:{"close":function($event){_vm.showNewListModal = false},"created":function($event){_vm.$emit('selectedLoadlist', $event);
      _vm.$router.push({ name: 'loadlist', params: { id: $event } });}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }