




























import LoadPresetsTableComponent from "@/components/Custom/LoadPresetsTable.vue";
import { ListType } from "@/models/LoadlistModel";
import { UsageSettings } from "../store/usageSettings";

export default {
  name: "load-presets",
  components: {
    LoadPresetsTableComponent,
  },
  data() {
    return {
      usageSettings: UsageSettings.fetch(),
    };
  },
};
