





































































































































import Vue, { PropType } from "vue";
import plannerItemsComponent from "@/components/Custom/PlannerItems.vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import { HoldDataWithIndices, Indices } from "@/models/LoadlistModel";
import { CalculationSettings } from "@/models/CalculationModel";
import { AugmentedHold } from "@/models/augmented/hold";
const MAX_COLS = 12;
const MIN_COLS = 2;
const CARD_WIDTH = 400;

export default Vue.extend({
  name: "planner-table",
  props: {
    filteredHolds: Array as PropType<HoldDataWithIndices[]>,
    checkedUnits: Array as PropType<Indices[]>,
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: (): CalculationSettings => {
        return null;
      },
    },
  },
  components: {
    plannerItemsComponent,
    sceneComponent,
  },
  data() {
    return {
      loadingContainers: [] as number[],
      tableWidth: 0,
      tableWidthObserver: null as ResizeObserver,
    };
  },
  computed: {
    cols(): number {
      return Math.min(
        MAX_COLS,
        Math.max(
          MIN_COLS,
          Math.floor(
            MAX_COLS -
              MAX_COLS * (1 - 1 / Math.round(this.tableWidth / CARD_WIDTH))
          )
        )
      );
    },
    augmentedHolds(): AugmentedHold[] {
      return this.filteredHolds.map((h) => new AugmentedHold(h));
    },
  },
  mounted() {
    this.tableWidthObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.tableWidth = entry.contentRect.width;
      });
    });
    this.tableWidthObserver.observe(this.$refs.plannerTable as any);
  },
  beforeDestroy() {
    this.tableWidthObserver.disconnect();
  },
  methods: {
    gotoInteractiveView(index: number): void {
      this.$emit("sceneClick", index);
    },
    checkRow(checkedRow: Indices): void {
      this.$emit("toggleSelectedRow", checkedRow);
    },
  },
});
