





























































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import SegregationTableEditor from "./SegregationTableEditor.vue";
import ShippingFactorInput from "./ShippingFactorInput.vue";
import OverhangInput from "./OverhangInput.vue";

import {
  HoldData,
  ListType,
  LoadRule,
  HoldInputItem,
} from "@/models/LoadlistModel";
import { CalculationSettings } from "@/models/CalculationModel";
import { UserCompanyStore } from "@/store";
import CustomRulesEditor from "./CustomRulesEditor/index.vue";

export const DEFAULT_SETTINGS = {
  timeout: 2,
  load_style: null,
  max_containers: null,
  use_containers_in_sets: false,
  error_margin: 0.0,
  allow_heavy_on_light_item: false,
  one_side_overhang: false,
  separate_shipments: false,
  group_shipments: false,
  split_item_types: false,
  rules: null,
  prioritize_cog: false,
  allowed_overhang: 0.0,
  simplified_loading: false,
  segregation_table: null,
  load_whole_shipments_only: false,
  shipping_factor: null,
  center_weight_threshold: null,
} as CalculationSettings;

const DEFAULT_SECONDARY_SETTINGS = {
  load_style: "bottom_up",
} as CalculationSettings;

export default Vue.extend({
  components: {
    SegregationTableEditor,
    ShippingFactorInput,
    OverhangInput,
    CustomRulesEditor,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: () => {
        return { ...DEFAULT_SETTINGS };
      },
    },
    title: {
      type: String,
      default: "Load settings",
    },
    base_type: {
      type: String as PropType<ListType>,
      default: "SEA",
    },
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
  },
  computed: {
    tabs(): { key: string; title: string; show: boolean }[] {
      return [
        {
          key: "general_settings",
          title: "General",
          show: true,
        },
        {
          key: "secondary",
          title: "Secondary",
          show: true,
        },
        {
          key: "custom_rules",
          title: "Custom Rules",
          show: true,
        },
        {
          key: "segregation_table",
          title: "Segregation table",
          show: true,
        },
      ].filter((i) => i.show);
    },
    visibleTabs(): any {
      return this.tabs.filter((t) => t.show);
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
    loading_styles() {
      return [
        { text: "None", value: null },
        { text: "Minimize used length", value: "minimize_length" },
        { text: "Load bottom up", value: "bottom_up" },
      ];
    },
  },
  watch: {
    optimization: {
      handler(o): void {
        this.internal_settings.split_item_types = false;
        this.internal_settings.simplified_loading = false;
        switch (o) {
          case 0:
            this.internal_settings.simplified_loading = true;
            break;
          case 2:
            this.internal_settings.split_item_types = true;
            break;
        }
      },
      immediate: true,
    },
    settings: {
      handler(a: CalculationSettings): void {
        let load_style = undefined;
        if (a.load_style === undefined) {
          if (a.load_from_bottom) load_style = "bottom_up";
          else if (a.load_direction == "shortwall")
            load_style = "minimize_length";
        }
        this.internal_settings = {
          ...this.internal_settings,
          load_style,
          ...JSON.parse(JSON.stringify(a)),
        };
      },
      immediate: true,
    },
    visible(a: boolean): void {
      // Only emit when closing the modal
      if (!a)
        this.$emit(
          "update",
          JSON.parse(JSON.stringify(this.internal_settings))
        );
    },
  },
  data() {
    return {
      internal_settings: {
        timeout: 2,
        load_style: null,
        max_containers: null,
        use_containers_in_sets: false,
        error_margin: 0.0,
        allow_heavy_on_light_item: false,
        one_side_overhang: false,
        separate_shipments: false,
        group_shipments: false,
        single_container_shipment: false,
        rules: null as LoadRule[],
        prioritize_cog: false,
        split_item_types: false,
        allowed_overhang: 0.0,
        simplified_loading: false,
        segregation_table: null,
        load_whole_shipments_only: false,
        shipping_factor: null,
        allow_diagonal: false,
        center_weight_threshold: null,
        routing: false,
      } as CalculationSettings,
      ruleRender: 0,
      active_tab: 0,
      optimization: this.settings.simplified_loading
        ? 0
        : this.settings.split_item_types
        ? 2
        : 1,
    };
  },
  methods: {
    updateSegragationTable(segregation_table: any): void {
      this.$set(this.internal_settings, "segregation_table", segregation_table);
    },
    addRule(rule: LoadRule): void {
      if (!this.internal_settings.rules) this.internal_settings.rules = [];
      this.internal_settings.rules.push(rule);
    },
    removeRule(index: number): void {
      this.internal_settings.rules.splice(index, 1);
      this.ruleRender++;
      if (!this.internal_settings.rules.length)
        this.internal_settings.rules = null;
    },
    addSecondarySettings(): void {
      this.$set(this.internal_settings, "secondary_settings", {
        ...DEFAULT_SETTINGS,
        ...DEFAULT_SECONDARY_SETTINGS,
      });
    },
  },
});
