


















































































import Vue, { PropType } from "vue";
import API from "@/API";
import { LoadlistStore } from "@/store/index";
import {
  HoldData,
  HoldInputItem,
  LengthDim,
  ListType,
  LoadlistGroup,
  LoadlistResult,
  WeightDim,
} from "@/models/LoadlistModel";
import { UserCompanyStore } from "@/store/index";
export default Vue.extend({
  props: {
    visible: Boolean,
    group: {
      type: Number,
      default: null,
    },
    etd: {
      type: String,
      default: null,
    },
    length_dim: {
      type: String as PropType<LengthDim>,
      default: () => {
        return UserCompanyStore.preferences?.default_loadlist_length_dim || "M";
      },
    },
    weight_dim: {
      type: String as PropType<WeightDim>,
      default: () => {
        return (
          UserCompanyStore.preferences?.default_loadlist_weight_dim || "KG"
        );
      },
    },
    // data: {
    //   type: Array as PropType<HoldInputItem[]>,
    //   default: () => {
    //     return [] as HoldInputItem[];
    //   },
    // },
    // result: {
    //   type: Object as PropType<LoadlistResult | null>,
    //   default: () => {
    //     return null as LoadlistResult;
    //   },
    // },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    groups(): LoadlistGroup[] {
      return [
        { name: "(Default)", id: null, private: true, data: {} },
        ...this.loadlistGroups,
      ];
    },
    loadlistGroups(): LoadlistGroup[] {
      return LoadlistStore.loadlistGroups;
    },
  },
  watch: {
    group: {
      handler: function(a): void {
        this.new_loadlist.group = a;
      },
      immediate: true,
    },
    etd: {
      handler: function(a): void {
        this.new_loadlist.etd = a;
      },
      immediate: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showEtdDialog: false,
      nameRules: [
        (v: string) => !!v || "Name is required",
        (v: string) => v.length <= 50 || "Name must be less than 50 characters",
      ],
      newLoadlistValid: false,
      new_loadlist: {
        name: "",
        list_type: (localStorage.getItem("cplLastLoadlistType") ||
          "SEA") as ListType,
        length_dim:
          UserCompanyStore.preferences?.default_loadlist_length_dim ||
          undefined,
        weight_dim:
          UserCompanyStore.preferences?.default_loadlist_weight_dim ||
          undefined,
        group: null as number,
        etd: null as string,
        data: [] as HoldData[],
        notes: "",
      },
    };
  },
  methods: {
    extendLoadlist(o: {
      length_dim?: string;
      weight_dim?: string;
      data?: HoldData[];
      result?: LoadlistResult;
    }): void {
      this.new_loadlist = { ...this.new_loadlist, ...o };
    },
    create(): void {
      this.isLoading = true;

      API.createLoadlist(this.new_loadlist)
        .then((data) => {
          localStorage.setItem(
            "cplLastLoadlistType",
            this.new_loadlist.list_type
          );

          this.isLoading = false;
          this.show = false;

          this.$emit("created", data.data.id);
        })
        .catch((error) => {
          console.log("Error creating loadlist");
          this.isLoading = false;
        });
    },
  },
});
