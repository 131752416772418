















































import Vue, { PropType } from "vue";
import ItemProperties, { ItemProperty } from "@/misc/itemProperties.ts";
import { UserPreferences } from "@/models/UserCompanyModel";
import { UserCompanyStore } from "@/store/index";

export default Vue.extend({
  props: {
    visible: Boolean,
    defaultColumns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    columns(): ItemProperty[] {
      return ItemProperties.props().filter(
        (i: ItemProperty) => !i.additional && !i.writeOnly
      );
    },
    preferences(): UserPreferences {
      return UserCompanyStore.preferences;
    },
  },
  watch: {
    preferences: {
      handler(val: UserPreferences): void {
        if (val?.visible_loadplan_columns)
          this.selectedColumns = JSON.parse(
            JSON.stringify(val.visible_loadplan_columns)
          );
        else
          this.selectedColumns = JSON.parse(
            JSON.stringify(this.defaultColumns)
          );
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      showSnackbar: false,
      selectedColumns: [] as string[],
    };
  },
  methods: {
    update(): void {
      if (UserCompanyStore.is_authenticated)
        this.updateMe({
          visible_loadplan_columns: this.selectedColumns,
        } as UserPreferences).then((_) => (this.showSnackbar = true));
      else this.showSnackbar = true;
      this.$emit("columnsUpdated", this.selectedColumns);
    },
    toggleColumn(key: string): void {
      const index = this.selectedColumns.indexOf(key);
      if (index >= 0) {
        this.selectedColumns.splice(index, 1);
      } else {
        this.selectedColumns.push(key);
      }
    },
    updateMe(preferences: UserPreferences): Promise<null> {
      return UserCompanyStore.updateMe({
        preferences: preferences,
      });
    },
  },
});
