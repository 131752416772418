var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"1200"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-snackbar',{attrs:{"timeout":5000,"bottom":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" Column settings updated ")]),_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Column settings")])],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.key},[_vm._v(" "+_vm._s(item.text)+" ")])})],2),_c('v-card-text',{staticClass:"py-4",staticStyle:{"height":"500px"}},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab-item',{key:item.key},[(item.key === 'visible_columns')?_c('v-card',[_c('v-alert',{attrs:{"color":"info","icon":"fa-lightbulb","outlined":""}},[_vm._v("If you do not want to show all columns / cargo properties you can click on each chip below to hide those columns.")]),_c('v-row',_vm._l((_vm.defaultColumns),function(column){return _c('v-col',{key:column.key,attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-2",attrs:{"label":"","color":_vm.hiddenColumns.includes(column.key)
                          ? 'gray'
                          : 'primary'},on:{"click":function($event){return _vm.toggleColumn(column.key)}}},on),[_vm._v(" "+_vm._s(column.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(column.desc))])])],1)}),1)],1):(item.key === 'custom_columns')?_c('v-card',{attrs:{"flat":""}},[_c('v-alert',{attrs:{"color":"info","icon":"fa-lightbulb","outlined":""}},[_vm._v("Here you can add extra metadata fields which will also be shown in the load plan. Some examples: "),_c('ul',[_c('li',[_vm._v("\"Comment\" field for item specific comments")]),_c('li',[_vm._v("\"PO\" field for purchase order information")])])]),(!_vm.isEditor)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Only users with Editor role can do modify custom columns ")]):_vm._e(),_c('v-form',{ref:"columnForm",refInFor:true,attrs:{"lazy-validation":"","disabled":!_vm.isEditor},model:{value:(_vm.validColumnForm),callback:function ($$v) {_vm.validColumnForm=$$v},expression:"validColumnForm"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Column name","rules":_vm.columnNameRules},model:{value:(_vm.newColumn.name),callback:function ($$v) {_vm.$set(_vm.newColumn, "name", $$v)},expression:"newColumn.name"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.newColumn.desc),callback:function ($$v) {_vm.$set(_vm.newColumn, "desc", $$v)},expression:"newColumn.desc"}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({attrs:{"cols":"2"}},'v-col',attrs,false),on),[_c('v-checkbox',{attrs:{"label":"Summable"},model:{value:(_vm.newColumn.summable),callback:function ($$v) {_vm.$set(_vm.newColumn, "summable", $$v)},expression:"newColumn.summable"}})],1)]}}],null,true)},[_c('span',[_vm._v("If enabled then the rows be summed on the load plan")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":_vm.addColumn}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)],1)],1),_vm._l((_vm.customColumns),function(item,index){return _c('v-row',{key:item.name,attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(item.name))]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(_vm._s(item.desc))]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(item.summable ? "yes" : "no"))]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"red","disabled":!_vm.isEditor},on:{"click":function($event){return _vm.customColumns.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)],1)],1)}),(!_vm.customColumns.length)?_c('v-alert',{attrs:{"color":"info","icon":"mdi-information","outlined":""}},[_vm._v("No fields added ")]):_vm._e()],2):_vm._e()],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_vm._v("Close")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.isEditor},on:{"click":function($event){$event.stopPropagation();return _vm.update.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-autorenew")]),_vm._v("Update")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }