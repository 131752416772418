





































import { HoldData } from "@/models/LoadlistModel";
import { SetType } from "@/models/SetsModel";
import selectContainerTypeComponent from "@/components/SetBuilder/SelectContainerType.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
    name: "set_select_container_dropdown",
    components: {
        selectContainerTypeComponent
    },
    props: {
        set: Object as PropType<SetType>,
        selectedHoldData: Object as PropType<HoldData>
    },
    data() {
        return {
            showChangeContainerTypeDialog: false,
            previousSelectedHoldDatas: [] as HoldData[],
            holdData: null as HoldData
        }
    },
    methods: {
        onContainerTypeSelected(holdData: HoldData) {
            this.showChangeContainerTypeDialog = false
            this.holdData = holdData
            this.updatePreviousHoldDatas(holdData)
            this.$emit("containerTypeSelected", holdData)
        },
        updatePreviousHoldDatas(holdData: HoldData): void {
            this.previousSelectedHoldDatas = [holdData, ...this.set.data.container_types].sort((a,b) => (a.name > b.name) ? 1 : -1)
        },
    },
    watch: {
        selectedHoldData: {
            handler: function(holdData: HoldData | null): void {
                this.holdData = holdData
                if(holdData) {
                    this.updatePreviousHoldDatas(holdData)
                }
            },
            immediate: true
        },
    }
})  
