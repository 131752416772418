var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"no-print mt-2",attrs:{"dense":"","elevation":"1"}},[_c('v-btn',{attrs:{"outlined":"","id":"equipmentLoadConfigurationButton","icon":_vm.$vuetify.breakpoint.xsOnly,"text":_vm.$vuetify.breakpoint.smAndUp},on:{"click":function($event){_vm.showLoadConfigurationsModal = true}}},[_c('v-icon',[_vm._v("mdi-database")]),(_vm.$vuetify.breakpoint.smAndUp)?[_vm._v("Presets")]:_vm._e()],2),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","id":"equipmentLoadSettingsButton"},on:{"click":function($event){_vm.showSettingsDialog = true}}},[_c('v-icon',[_vm._v("mdi-tune")]),_vm._v(" Settings")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.unloadedWithoutReason.length && _vm.has_result && !_vm.isLoading)?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","id":"equipmentAddItemsBtn","ripple":false},on:{"click":_vm.addItemsCalculate}},on),[_vm._v(" Load "+_vm._s(_vm.unloadedWithoutReason.length)+" new items"),_c('v-icon',{attrs:{"right":""}},[_vm._v("fa-plus-circle")])],1):_vm._e()]}}])},[_c('div',[_vm._v(" Load new items into current loadplan, without creating or rearranging "+_vm._s(_vm.typeName)+"s. ")])]),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{attrs:{"disabled":!_vm.selectedHolds.length,"id":"equipmentCalculateBtn","ripple":false,"icon":""},on:{"click":_vm.calculate}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fa-arrow-circle-right")])],1):_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.selectedHolds.length && !_vm.selectedSets.length,"id":"equipmentCalculateBtn","ripple":false},on:{"click":_vm.calculate}},[_vm._v(" Create new Load Plan "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fa-arrow-circle-right")])],1)],1),(_vm.showLoadplanVersions)?_c('v-card',{staticClass:"my-1",attrs:{"id":"equipmentLoadplanVersions"}},[_c('v-card-text',[_c('loadplan-version-component')],1)],1):_vm._e(),_c('v-row',{staticClass:"mb-10 my-1",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"title font-weight-light"},[_vm._v("Equipment library")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.isAuthenticated,"ripple":false},on:{"click":function($event){return _vm.$router.push({
                name: _vm.active_tab.is_set ? 'modify_set' : 'modify_hold',
                params: { type: _vm.active_tab.mot },
              })}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.active_tab_index),callback:function ($$v) {_vm.active_tab_index=$$v},expression:"active_tab_index"}},[_vm._l((_vm.tabs.filter(function (i) { return i.primary; })),function(tab){return _c('v-tab',{key:tab.title},[_c('span',[_vm._v(_vm._s(tab.typename)+"s")])])}),_c('v-divider',{attrs:{"vertical":""}}),_vm._l((_vm.tabs.filter(function (i) { return !i.primary; })),function(tab){return _c('v-tooltip',{key:tab.title,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-tab',_vm._g({},on),[_c('span',[_vm._v(_vm._s(tab.typename))])])]}}],null,true)},[_c('span',[_vm._v("Equipment to use if your cargoes should be pre loaded onto a pallet or crate before being loaded into your primary equipment")])])}),_c('v-tabs-items',{staticClass:"px-1 py-1",model:{value:(_vm.active_tab_index),callback:function ($$v) {_vm.active_tab_index=$$v},expression:"active_tab_index"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.key},[_c(tab.component,{tag:"component",attrs:{"holdType":tab.mot,"setType":tab.mot},scopedSlots:_vm._u([{key:"items",fn:function(ref){
              var items = ref.paginatedItems;
return [_c('v-expansion-panels',{staticClass:"unselectable",attrs:{"focusable":"","multiple":""}},_vm._l((items),function(item){return _c('v-expansion-panel',{key:item.id},[_c('v-expansion-panel-header',{staticClass:"py-1"},[_c('span',[_c('v-btn',{attrs:{"large":"","icon":"","ripple":false,"disabled":tab.disabledItems.some(
                                  function (i) { return i === item.id; }
                                ) || tab.limit_exceeded},nativeOn:{"click":function($event){$event.stopPropagation();return tab.addMethod(item.data)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus-circle")])],1),(!item.default)?_c('v-icon',[_vm._v("mdi-account")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)]),_c('v-expansion-panel-content',[(tab.is_set)?_c('scene-component',{staticClass:"my-2 d-flex justify-center",attrs:{"setType":item.data,"canvas-width":300,"canvas-height":150}}):_c('scene-component',{staticClass:"my-2 d-flex justify-center",attrs:{"hold-object":item.data,"canvas-width":300,"canvas-height":150}}),(item.data.description)?_c('div',{staticClass:"pt-2"},[_c('v-divider'),_c('p',{staticClass:"text-md mt-2"},[_vm._v(" "+_vm._s(item.data.description)+" ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":!tab.disabledItems.some(function (i) { return i === item.id; })},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":tab.disabledItems.some(
                                      function (i) { return i === item.id; }
                                    ),"block":""},on:{"click":function($event){return _vm.$router.push(
                                      tab.is_set
                                        ? {
                                            name: 'modify_set',
                                            params: {
                                              set_id: item.data.id,
                                              type: item.base_type,
                                            },
                                          }
                                        : {
                                            name: 'modify_hold',
                                            params: {
                                              hold_id: item.id,
                                              type: item.base_type,
                                            },
                                          }
                                    )}}},[(!item.default && _vm.user.is_editor)?_c('div',[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1):_c('div',[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")]),_vm._v("Details ")],1)])],1)]}}],null,true)},[_c('span',[_vm._v("You need to remove this from the panel to right in order to continue")])])],1)],1)}),1)]}}],null,true)})],1)}),1)],2)],1)],1)],1),_c('v-col',{attrs:{"text-center":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticStyle:{"margin-top":"140px"},attrs:{"x-large":""}},[_vm._v("fa-exchange-alt")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{attrs:{"id":"equipmentSelectedHolds"}},[_c('v-card-title',[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Selected equipment")])]),_c('v-card-title',[_c('v-chip',{staticClass:"success",attrs:{"small":""}},[_vm._v("Primary")]),_c('v-spacer')],1),(_vm.selectedHolds.length || _vm.selectedSets.length)?_c('v-card-text',{staticClass:"pb-0"},[_c('v-expansion-panels',{staticClass:"unselectable",attrs:{"focusable":""}},[_vm._l((_vm.selectedHolds),function(hold,index){return _c('v-expansion-panel',{key:hold.id,on:{"click":function($event){return _vm.$vuetify.goTo($event.target)}}},[_c('v-expansion-panel-header',{staticClass:"py-1",attrs:{"multiple":""}},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":"","ripple":false},on:{"click":function($event){return _vm.removeContainer(index)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove this equipment")])]),_vm._v(_vm._s(hold.name))],1)]),_c('v-expansion-panel-content',[_c('inline-hold-edit-component',{staticClass:"mt-3",attrs:{"basetype":_vm.loadlist.list_type},scopedSlots:_vm._u([{key:"view",fn:function(ref){
                                    var renderKey = ref.renderKey;
return [_c('scene-component',{key:renderKey,attrs:{"hold-object":hold,"canvas-width":400,"canvas-height":200}})]}}],null,true),model:{value:(_vm.selectedHolds[index]),callback:function ($$v) {_vm.$set(_vm.selectedHolds, index, $$v)},expression:"selectedHolds[index]"}})],1)],1)}),_vm._l((_vm.selectedSets),function(set,setIndex){return _c('v-expansion-panel',{key:set.id,on:{"click":function($event){return _vm.$vuetify.goTo($event.target)}}},[_c('v-expansion-panel-header',{staticClass:"py-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":"","ripple":false},on:{"click":function($event){return _vm.removeSet(setIndex)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove this equipment")])]),_vm._v(_vm._s(set.name))],1)]),_c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('modify-set-component',{attrs:{"basetype":_vm.selectedSets[setIndex].base_type},scopedSlots:_vm._u([{key:"view",fn:function(ref){
                                    var renderKey = ref.renderKey;
return [_c('scene-component',{key:renderKey,attrs:{"setType":set,"canvas-width":800,"canvas-height":300,"customViewSettings":{
                            view: '3d-ortho',
                            containerNumbers: true,
                          }}})]}}],null,true),model:{value:(_vm.selectedSets[setIndex]),callback:function ($$v) {_vm.$set(_vm.selectedSets, setIndex, $$v)},expression:"selectedSets[setIndex]"}})],1)],1)],1)],1)})],2)],1):_vm._e(),_c('v-card-text',_vm._l((_vm.primaryAlerts),function(a){return _c('toggle-alert',{key:a.title,staticClass:"my-2",attrs:{"type":a.level,"outlined":"","body":a.body}},[_vm._v(" "+_vm._s(a.title)+" ")])}),1),(_vm.tabs.find(function (t) { return t.key == 'pallets'; }))?[_c('v-card-title',[_c('v-tooltip',{attrs:{"top":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":""}},on),[_vm._v("Secondary")])]}}],null,false,376059252)},[_c('span',[_vm._v("Secondary equipment will be loaded with cargoes having the \"Preload\" field enabled. The secondary equipment will then be loaded into the primary equipment. Think of if like boxes on pallets in containers")])])],1),(_vm.selectedPallets.length)?_c('v-card-text',{staticClass:"pb-0"},[_c('v-expansion-panels',{staticClass:"unselectable",attrs:{"focusable":"","multiple":""}},_vm._l((_vm.selectedPallets),function(pallet,palletIndex){return _c('v-expansion-panel',{key:pallet.id,on:{"click":function($event){return _vm.$vuetify.goTo($event.target)}}},[_c('v-expansion-panel-header',{staticClass:"py-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":"","ripple":false},on:{"click":function($event){return _vm.removePallet(palletIndex)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove this equipment")])]),_vm._v(_vm._s(pallet.name))],1)]),_c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('inline-hold-edit-component',{attrs:{"basetype":"PALL","showSubHoldProps":""},scopedSlots:_vm._u([{key:"view",fn:function(ref){
                          var renderKey = ref.renderKey;
return [_c('scene-component',{key:renderKey,attrs:{"hold-object":pallet,"canvas-width":400,"canvas-height":200}})]}}],null,true),model:{value:(_vm.selectedPallets[palletIndex]),callback:function ($$v) {_vm.$set(_vm.selectedPallets, palletIndex, $$v)},expression:"selectedPallets[palletIndex]"}})],1)],1)],1)],1)}),1)],1):_vm._e(),_c('v-card-text',_vm._l((_vm.secondaryAlerts),function(a){return _c('toggle-alert',{key:a.title,staticClass:"my-2",attrs:{"type":a.level,"outlined":"","body":a.body}},[_vm._v(" "+_vm._s(a.title)+" ")])}),1)]:_vm._e()],2)],1)],1),_c('load-settings-modal',{attrs:{"visible":_vm.showSettingsDialog,"items":_vm.loadlist.data,"base_type":_vm.loadlist.list_type,"holds":_vm.allHolds,"settings":_vm.loadplan.settings},on:{"update:visible":function($event){_vm.showSettingsDialog=$event},"update":function($event){return _vm.setLoadplanProperty(
        {
          key: 'settings',
          value: $event,
        },
        true
      )}}}),_c('load-configurations-modal',{attrs:{"visible":_vm.showLoadConfigurationsModal},on:{"close":function($event){_vm.showLoadConfigurationsModal = false}}}),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showCostDialog),callback:function ($$v) {_vm.showCostDialog=$$v},expression:"showCostDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Costs - an example")]),_c('v-card-text',{staticClass:"body-1"},[_vm._v(" One of your shipments requires either 2 x 20ft containers or 1 x 40ft container."),_c('br'),_c('br'),_vm._v(" A 40ft container is roughly twice as large as a 20ft container, however it is usually "),_c('strong',[_vm._v("not twice as expensive")]),_vm._v(". A not too uncommon figure is that a 40ft container is 30% more expensive than the 20ft equivalent. We can then set a cost for the 20ft container to, let's say \"10\" and \"13\" for a 40ft container - to take this into consideration. "),_c('br'),_c('br'),_vm._v(" Having no costs associated can lead \"wrong\" results were the tool picks multiple 20ft containers instead of a fewer amount of 40ft containers ")]),_c('v-card-actions'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.showCostDialog = false}}},[_vm._v("Ok, close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }