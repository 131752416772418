

























































































































































































































































































































































































































































































































import Vue from "vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import containerLibraryComponent from "@/components/Custom/ContainerLibrary.vue";
import ItemUtils from "@/misc/itemUtils";
import ContainerUtils from "@/misc/containerUtils";
import Worker from "worker-loader!@/workers/group_holds.worker.ts";
import plannerTableComponent from "@/components/LoadlistDetail/Workspace/Planner/PlannerTable.vue";
import setWorkspace from "@/components/LoadlistDetail/Workspace/Sets/index.vue";
import workspaceTable from "@/components/LoadlistDetail/Workspace/Table/index.vue";
import confirmModal from "@/components/Modals/Confirm.vue";
import { CargoStore, LoadlistStore, UserCompanyStore } from "@/store/index";
import MapModalVue from "@/components/Modals/MapModal.vue";
import newListModal from "@/components/Modals/NewList.vue";

import {
  HoldData,
  HoldDataWithIndices,
  Indices,
  Loadlist,
  Loadplan,
  UnloadedItem,
  UpdateLoadplanHoldsParams,
  HoldItem,
  Cargo,
} from "@/models/LoadlistModel";
import { CalcData } from "@/models/CalculationModel";
import { GroupedWorkerResponse } from "@/models/GroupingModel";
import { GroupedSet, Set } from "@/models/SetsModel";
import { UsageSettings } from "../../../store/usageSettings";
import containerUtils from "@/misc/containerUtils";
import API from "@/API";
const HOLDS_PER_PAGE = 5;

function itemSearch(i: HoldItem, s: string): boolean {
  return (
    i.label.toLowerCase().includes(s) ||
    (i.shipment_id ? i.shipment_id.toLowerCase().includes(s) : false)
  );
}

export default Vue.extend({
  name: "workspace",
  components: {
    MapModalVue,
    sceneComponent,
    workspaceTable,
    containerLibraryComponent,
    plannerTableComponent,
    confirmModal,
    setWorkspace,
    newListModal,
  },
  data() {
    return {
      checkedUnits: [] as Indices[],
      checkedSets: [] as number[],
      groupedHolds: [] as HoldDataWithIndices[],
      groupedSets: [] as GroupedSet[],
      searchInput: "",
      isLoading: false,
      showPrintDialog: false,
      showExpansionPanel: [],
      showAddUnitDialog: false,
      showInfoResultDialog: false,
      setWorkspaceAvailable: false,
      showSplitListModal: false,
      view: undefined,
      showResetDialog: false,
      originalHolds: null,
      worker: null as Worker,
      willPrint: false,
      groupSimilar: true,
      usageSettings: UsageSettings.fetch(),
      mapHold: undefined as HoldData,
      showMapModal: false,
      showAddCargoLibrary: false,
      cargoName: undefined as string,
    };
  },
  watch: {
    "loadplan.set_types": {
      handler: function(a) {
        if (
          this.loadplan.set_types?.length > 0 ||
          (this.loadplan.settings?.use_containers_in_sets &&
            this.loadplan.selected_holds?.length > 1)
        ) {
          this.setWorkspaceAvailable = true;
        }
      },
      immediate: true,
    },
    "loadplan.holds": {
      handler: function(a) {
        if (!this.worker) {
          this.worker = new Worker();
          this.worker.onmessage = this.groupHoldsFromWorker;
        }
        this.worker.postMessage({
          holds: this.loadplan.holds,
          sets: this.loadplan.sets,
        });
      },
      immediate: true,
    },
    view: {
      handler: function(view) {
        if (this.$route.query.view != view) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: { ...this.$route.query, view: view },
          });
          this.usageSettings.defaultWorkspaceView = view;
        }
      },
      immediate: false,
    },
    groupSimilar(grouped: boolean): void {
      this.updateGroups();
      this.usageSettings.groupSimilar = grouped;
    },
  },
  computed: {
    filteredHolds(): HoldDataWithIndices[] {
      let holds = this.groupedHolds;

      if (this.searchInput) {
        holds = holds.filter((h) =>
          h.items.some((i) => {
            return (
              itemSearch(i, this.searchInput.toLowerCase()) ||
              (i.from_container
                ? i.from_container.items.some((i2) =>
                    itemSearch(i2, this.searchInput.toLowerCase())
                  )
                : false)
            );
          })
        );
      }
      return holds;
    },

    notes: {
      get(): string {
        return this.loadplan.notes;
      },
      set(value: string): void {
        LoadlistStore.setLoadplanProperty({
          key: "notes",
          value: value,
        });
      },
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    loadplan_version(): number {
      return LoadlistStore.loadplan_version;
    },
    unloadedItems(): UnloadedItem[] {
      return LoadlistStore.unloaded_items;
    },
    liteVersion(): boolean {
      return UserCompanyStore.lite_version;
    },
  },
  created() {
    this.originalHolds = JSON.parse(JSON.stringify(this.loadplan.holds));
  },
  mounted() {
    this.usageSettings = UsageSettings.fetch();
    if (
      !this.$route.query.print &&
      !localStorage.getItem("cplInfoResultViewV1")
    ) {
      this.showInfoResultDialog = true;
      localStorage.setItem("cplInfoResultViewV1", "true");
    }
    window.addEventListener("afterprint", this.afterPrint);
    this.groupSimilar = !(
      this.$route.query.groupSimilar === "false" ||
      this.usageSettings.groupSimilar === false
    );
    const view = this.$route.query.view
      ? String(this.$route.query.view)
      : this.usageSettings.defaultWorkspaceView;
    this.view = view;
  },
  beforeDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
    window.removeEventListener("afterprint", this.afterPrint);
  },
  methods: {
    resetHolds(): void {
      LoadlistStore.setLoadplanProperty({
        key: "holds",
        value: JSON.parse(JSON.stringify(this.originalHolds)),
      });
    },
    groupHoldsFromWorker(e: GroupedWorkerResponse) {
      this.groupedHolds = e.data.holds;
      this.groupedSets = e.data.sets;
    },
    toggleMapModal(uuid: string): void {
      this.showMapModal = true;
      this.mapHold = this.filteredHolds.find((h) => h.uuid == uuid);
    },
    checkRow(val: Indices): void {
      const index = this.checkedUnits.indexOf(val);
      if (index >= 0) {
        this.checkedUnits.splice(index, 1);
      } else {
        this.checkedUnits.push(val);
      }
    },
    checkSetRow(row: number): void {
      const index = this.checkedSets.indexOf(row);
      if (index >= 0) {
        this.checkedSets.splice(index, 1);
      } else {
        this.checkedSets.push(row);
      }
    },
    checkRows(rows: Indices[]): void {
      let checkedCount = rows.filter(
        (row) => this.checkedUnits.indexOf(row) >= 0
      ).length;
      if (checkedCount === rows.length) {
        rows.forEach((row) => {
          this.checkRow(row);
        });
      } else if (checkedCount === 0) {
        rows.forEach((row) => this.checkedUnits.push(row));
      } else {
        rows
          .filter((row) => this.checkedUnits.indexOf(row) < 0)
          .forEach((row) => this.checkedUnits.push(row));
      }
    },
    print() {
      this.willPrint = true;
      this.$hideChatWidget();
      this.$nextTick(() => {
        setTimeout(() => {
          window.print();
        }, 200);
      });
    },

    afterPrint(): void {
      this.willPrint = false;
    },
    addUnit(unit: HoldData): void {
      const hold = ContainerUtils.createEmpty(unit);
      LoadlistStore.updateLoadplanSets({
        sets: [ContainerUtils.createSetFromHold(hold)],
      });
      this.updateLoadplanHolds({
        holds: [hold],
      });

      this.showAddUnitDialog = false;
    },
    convertUnits(unit: HoldData): void {
      this.isLoading = true;
      let convertItems = [] as HoldItem[];
      let startFromIndex = Infinity;
      this.checkedUnits.forEach((holdIndices) => {
        for (var i = holdIndices.start; i <= holdIndices.end; i++) {
          this.loadplan.holds[i].items
            .filter((i) => i.qty > 0)
            .forEach((item) => {
              convertItems.push({
                ...item,
                qty: 1,
              });
            });
        }
        startFromIndex = Math.min(startFromIndex, holdIndices.start);
      });
      const bundledItems = ItemUtils.bundledItems(convertItems, true).flat();

      const calcData = {
        items: bundledItems,
        container_types: [unit],
      } as CalcData;

      this.calculateLoadplan(calcData).then(
        (solution) => {
          this.removeSelectedUnits();
          LoadlistStore.updateLoadplanSets({ sets: solution.sets });
          this.updateLoadplanHolds({
            index: startFromIndex,
            holds: solution.containers,
          });

          this.showAddUnitDialog = false;
          this.isLoading = false;
        },
        (_) => {
          this.isLoading = false;
        }
      );
    },
    removeSelectedUnits(): void {
      this.removeUnits(this.checkedUnits);
      this.checkedUnits = [];
    },
    removeSelectedSets(): void {
      this.checkedSets.forEach((setIndex) => {
        const units = this.checkedSetToUnits(setIndex);
        this.removeUnits(units);
      });
      this.checkedSets = [];
    },
    removeUnits(units: Indices[]): void {
      units
        .slice()
        .sort((a, b) => (a.start > b.start ? -1 : 1))
        .forEach((val) => {
          for (let i = val.end; i >= val.start; i--) {
            // remove the hold from the set
            // and remove the set if it's empty
            let hold = this.loadplan.holds[i];
            let setIndex = this.loadplan.sets.findIndex(
              (s) => s.uuid == hold?.set_uuid
            );
            if (setIndex >= 0) {
              let set = JSON.parse(
                JSON.stringify(this.loadplan.sets[setIndex])
              ) as Set;
              let holdIndex = set.containers.findIndex(
                (c) => c.uuid == hold.uuid
              );
              if (holdIndex >= 0) {
                set.containers.splice(holdIndex, 1);
                if (set.containers.length === 0) {
                  LoadlistStore.updateLoadplanSets({
                    replace: 1,
                    index: setIndex,
                  });
                } else {
                  LoadlistStore.updateLoadplanSets({
                    replace: 1,
                    index: setIndex,
                    sets: [set],
                  });
                }
              }
            }
            this.updateLoadplanHolds({
              index: i,
              replace: 1,
            });
          }
        });
    },
    checkedSetToUnits(setIndex: number): Indices[] {
      let uuid = this.loadplan.sets[setIndex].uuid;
      const units = this.loadplan.holds
        .map((hold, index) => index)
        .filter((index) => this.loadplan.holds[index].set_uuid === uuid)
        .map((index) => {
          return { start: index, end: index } as Indices;
        });
      return units;
    },

    emptySelectedUnits(): void {
      this.emptyUnits(this.checkedUnits);
      this.checkedUnits = [];
    },
    emptySelectedSets(): void {
      this.checkedSets.forEach((val) => {
        const units = this.checkedSetToUnits(val);
        this.emptyUnits(units);
      });
      this.checkedSets = [];
      this.updateGroups();
    },
    emptyUnits(units: Indices[]): void {
      units.forEach((val) => {
        for (let i = val.end; i >= val.start; i--) {
          let hold = this.loadplan.holds[i];
          let new_hold = ContainerUtils.createEmpty(hold);
          new_hold.uuid = hold.uuid;
          new_hold.set_uuid = hold.set_uuid;
          this.updateLoadplanHolds({
            index: i,
            replace: 1,
            holds: [new_hold],
          });
        }
      });
    },

    addToCargoLibrary(): void {
      this.checkedUnits.forEach((val) => {
        const hold = this.loadplan.holds[val.start];
        this.cargoName = hold.name;
      });
      this.showAddCargoLibrary = true;
    },
    saveCargoLibrary(): void {
      this.checkedUnits.forEach((val) => {
        const hold = this.loadplan.holds[val.start];
        // console.log(hold);
        const item = containerUtils.createNestedItemFromHold(hold);
        // console.log(item);
        item.label = this.cargoName;
        const cargo = {
          name: this.cargoName,
          data: item,
          length_dim: "M",
          weight_dim: "KG",
        } as Cargo;
        API.createCargo(cargo)
          .then((r) => console.log("response:", r))
          .catch((e) => console.log("error", e.response.data))
          .finally(() => {
            this.showAddCargoLibrary = false;
            this.isLoading = false;
            CargoStore.downloadCargoes();
          });
        // console.log(item);
      });
    },
    createNewListFromSelected(): void {
      let moveContainers = [] as HoldData[];
      let moveItems = [] as HoldItem[];
      this.checkedUnits.forEach((holdIndices) => {
        for (var i = holdIndices.start; i <= holdIndices.end; i++) {
          moveContainers.push(this.loadplan.holds[i]);
          this.loadplan.holds[i].items
            .filter((i) => i.qty > 0)
            .forEach((item) => {
              moveItems.push({
                ...item,
                qty: 1,
              });
            });
        }
      });
      const bundledItems = ItemUtils.bundledItems(moveItems, true).flat();

      let filtered_loadlist_data = ItemUtils.removeItemsFromLoadlist(
        this.loadlist.data,
        JSON.parse(JSON.stringify(bundledItems as UnloadedItem[]))
      );

      (this.$refs.splitListModal as any).extendLoadlist({
        data: bundledItems,
        result: {
          versions: [
            {
              ...this.loadplan,
              holds: moveContainers,
            },
          ],
        },
        length_dim: this.loadlist.length_dim,
        weight_dim: this.loadlist.weight_dim,
      });
      this.showSplitListModal = true;

      (this.$refs.splitListModal as any).$once("created", () => {
        LoadlistStore.setLoadlistProperty({
          key: "data",
          value: filtered_loadlist_data,
        });
        this.removeSelectedUnits();
      });
    },
    splittedLoadlistCreated(): void {},
    gotoInteractiveView(index: number): void {
      if (this.liteVersion) {
        return;
      }
      this.$router.push({
        name: "detail",
        params: {
          version: String(this.loadplan_version),
          hold: String(index),
        },
      });
    },
    updateLoadplanHolds(data: UpdateLoadplanHoldsParams): void {
      LoadlistStore.updateLoadplanHolds(data);
    },
    calculateLoadplan(
      calcData: CalcData
    ): Promise<{
      containers: HoldData[];
      unloaded_items: UnloadedItem[];
      sets: Set[];
    }> {
      return LoadlistStore.calculateLoadplan(calcData);
    },
    saveLoadlistResult(): void {
      LoadlistStore.saveLoadlistResult();
    },
    updateGroups(): void {
      if (!this.worker) {
        this.worker = new Worker();
        this.worker.onmessage = this.groupHoldsFromWorker;
      }
      this.worker.postMessage({
        holds: this.loadplan.holds,
        sets: this.loadplan.sets,
        disableGrouping: !this.groupSimilar,
      });
    },
  },
});
