
































import Vue, { PropType } from "vue";
import { Currencies } from "@/misc/currencies";
import { KeyValue } from "@/models/LoadlistModel";
export default Vue.extend({
  name: "quotation",
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    quotations: {
      type: Object as PropType<KeyValue>,
      default: () => ({} as KeyValue),
    },
    holdTypes: {
      type: Array as PropType<{ id: number; name: string }[]>,
      default: () => [] as { id: number; name: string }[],
    },
  },
  data() {
    return {
      isLoading: false,
      currency: Currencies.find((c) => c.code === "USD"),
      currencies: Currencies,
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    updateQuotation(): void {
      this.$emit("update", {
        currency: this.currency,
        quotationsForTypes: this.quotations,
      });
      this.$emit("close", false);
    },
  },
});
