





































































































































import Vue, { PropType, VueConstructor } from "vue";
import sheetComponent from "./Sheet.vue";
import ItemProperties, {
  ItemProperty,
  CustomItemProperty,
} from "@/misc/itemProperties";
import { colorPalette, makeRandomColor } from "@/misc/colorUtils";
import { CompanySettings, UserPreferences } from "@/models/UserCompanyModel";
import { ListType, HoldInputItem, Cargo, Hold } from "@/models/LoadlistModel";
import { CargoStore, UserCompanyStore } from "@/store/index";
import InputTableSettings from "@/components/Modals/InputTableSettings.vue";
import { Warning } from "@/misc/itemWarnings";
import LoadlistsComponent from "@/components/Loadlists/index.vue";
import LoadInDialog from "@/components/Modals/LoadIn.vue";

export interface MenuItem {
  title: string;
  cb: () => void;
  disabled?: boolean;
  selectedRowRequired?: boolean;
}

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      sheet: InstanceType<typeof sheetComponent>;
    };
  }
>).extend({
  name: "input-table",
  components: {
    sheetComponent,
    InputTableSettings,
    LoadlistsComponent,
    LoadInDialog,
  },
  data: function() {
    return {
      isLoading: false,
      showConvertDialog: false,
      showLibraryHoldsModal: false,
      showDecimalSeparatorDialog: false,
      showMoveItemsToLoadlistDialog: false,
      showInputTableSettingsModal: false,
      baseTypeForContainerID: this.mode,
      showDivideWeights: false,
      convertLengthDimOptions: [
        { text: "MM", value: "MM" },
        { text: "CM", value: "CM" },
        { text: "DM", value: "DM" },
        { text: "M", value: "M" },
        { text: "IN", value: "IN" },
        { text: "FT", value: "FT" },
      ],
      convertWeightDimOptions: [
        { text: "KG", value: "KG" },
        { text: "MT", value: "MT" },
        { text: "LB", value: "LB" },
      ],
      convertLengthDimFrom: "",
      convertWeightDimFrom: "",
      decimalSeparator: (0.1).toLocaleString().substr(1, 1),
      selectedRow: null,
    };
  },
  props: {
    objects: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    mode: {
      type: String as PropType<ListType>,
      default: "SEA",
    },
    lengthDim: {
      type: String,
      default: null,
    },
    weightDim: {
      type: String,
      default: null,
    },
    extraHiddenColumns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    warnings: {
      type: Array as PropType<{ id: Warning; indexes: number[] }[]>,
      default: () => [] as { id: Warning; indexes: number[] }[],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraPreColumns: {
      type: Array as PropType<ItemProperty[]>,
      default: () => [] as ItemProperty[],
    },
    disableAutocomplete: Boolean,
  },
  computed: {
    lengthDimHeaderText(): string {
      return "[" + (this.lengthDim ? this.lengthDim.toLowerCase() : "-") + "]";
    },
    weightDimHeaderText(): string {
      return "[" + (this.weightDim ? this.weightDim.toLowerCase() : "-") + "]";
    },
    extraColumns(): ItemProperty[] {
      if (this.companySettings?.extra_columns) {
        return this.companySettings?.extra_columns.map(
          (i: CustomItemProperty) => {
            return {
              key: i.name,
              input: "text",
              text: i.name,
              desc: i.desc,
              width: 150,
              summable: i.summable,
            };
          }
        );
      }
      return [];
    },
    hiddenColumns(): string[] {
      return (Array.isArray(this.preferences?.hidden_input_columns)
        ? this.preferences.hidden_input_columns
        : []
      ).concat(this.extraHiddenColumns);
    },
    getColumns(): ItemProperty[] {
      return [
        ...this.extraPreColumns,
        ...ItemProperties.props()
          .filter(
            (i) =>
              (!i.mot || i.mot.includes(this.mode)) &&
              !this.hiddenColumns.includes(i.key)
          )
          .filter((i) => !i.additional && !i.readOnly)
          .map((i) => {
            switch (i.dimension) {
              case "length":
                i.text = `${i.text} ${this.lengthDimHeaderText}`;
                break;
              case "weight":
                i.text = `${i.text} ${this.weightDimHeaderText}`;
                break;
            }
            switch (i.key) {
              case "allowed_containers":
                i.cellAction = () => {
                  this.showLibraryHoldsModal = true;
                };

                break;
            }
            return i;
          }),
        ...this.extraColumns,
      ];
    },
    getMenuItems(): MenuItem[] {
      return [
        {
          title: "Move to loadlist...",
          cb: () => {
            this.showMoveItemsToLoadlistDialog = true;
          },
          selectedRowRequired: true,
          disabled: !this.lengthDim || !this.weightDim,
        },
        {
          title: "Convert dimensions...",
          cb: () => {
            this.showConvertDialog = true;
          },
          disabled: !this.lengthDim,
          selectedRowRequired: true,
        },
        {
          title: "Divide Weights by Quantities",
          cb: () => {
            const objects: HoldInputItem[] = this.$refs.sheet.getObjects();

            for (
              let i = this.$refs.sheet.selectedCells.start.row;
              i <= this.$refs.sheet.selectedCells.end.row;
              i++
            ) {
              if (!objects[i].wt || !objects[i].qty) continue;
              this.$refs.sheet.setValue(
                i,
                "wt",
                Number(objects[i].wt) / Number(objects[i].qty)
              );
            }
            this.showDivideWeights = true;
          },
          disabled: this.showDivideWeights,
          selectedRowRequired: true,
        },
        {
          title: `Change decimal separator`,
          cb: () => {
            this.showDecimalSeparatorDialog = true;
          },
        },
      ];
    },
    flattenedObjects(): HoldInputItem[] {
      if (this.companySettings?.extra_columns) {
        return this.objects.map((i) => {
          let tmp = {};
          if (i.metadata) {
            for (const [key, value] of Object.entries(i.metadata)) {
              (tmp as any)[key] = value;
            }
          }
          return {
            ...tmp,
            ...i,
          };
        });
      }
      return this.objects;
    },
    preferences(): UserPreferences {
      return UserCompanyStore.preferences;
    },
    companySettings(): CompanySettings {
      return UserCompanyStore.company_settings;
    },
    cargoLibrary(): Cargo[] {
      return CargoStore.cargoes;
    },
    containerIdsForItem(): number[] {
      let row = this.selectedRow;
      return this.$refs.sheet?.getObjects()[row].allowed_containers || [];
    },
    selectedItem(): HoldInputItem {
      let row = this.selectedRow;
      return this.$refs.sheet?.getObjects()[row];
    },
  },
  methods: {
    getObjects(): HoldInputItem[] {
      const palette = colorPalette(this.$refs.sheet.getObjects());

      let consignmentColors: Record<string, string> = {};

      const parseNumber =
        this.decimalSeparator === ","
          ? parseNumberWithCommaDecimal
          : parseNumberWithDotDecimal;

      return this.$refs.sheet
        .getObjects()
        .map((row: HoldInputItem, i: number) => {
          row.metadata = {};
          this.extraColumns.forEach((column) => {
            row.metadata[column.key] =
              (column.summable
                ? parseNumber((row as any)[column.key])
                : (row as any)[column.key]) || null;
            delete (row as any)[column.key];
          });

          const qty = parseInt(row.qty as any, 10);
          if (Object.keys(row.metadata).length === 0) row.metadata = null;
          let parsedRow: HoldInputItem = {
            ...row,
            l: parseNumber(row.l),
            w: parseNumber(row.w),
            h: parseNumber(row.h),
            wt: parseNumber(row.wt),
            label: String(row.label || i + 1).trim(),
            qty: Number.isInteger(qty) ? qty : undefined,
            not_stackable: !!row.not_stackable,
            bottom_only: !!row.bottom_only,
            // tiltable: undefined,
            // not_rotatable: undefined,
            palletize: !!row.palletize,
            orientations: Number(row.orientations) || undefined,
            max_layers: Number(row.max_layers) || null,
            max_load: parseNumber(row.max_load) || null,
            free_space: row.free_space || null,
            shipment_id: row.shipment_id || null,
            class_id: row.class_id || null,
            color:
              !!row.color && !!row.color.length
                ? row.color
                : row.shipment_id &&
                  !!this.preferences.autoGroupColors &&
                  row.shipment_id in consignmentColors
                ? consignmentColors[row.shipment_id]
                : makeRandomColor(palette),
            allowed_containers: row.allowed_containers || null,
            unit_qty: Number(row.unit_qty) || null,
            priority: Number(row.priority) || null,
          };
          if (row.shipment_id && !(row.shipment_id in consignmentColors))
            consignmentColors[row.shipment_id] = parsedRow.color;
          return parsedRow;
        })
        .filter((item: HoldInputItem) => item.l * item.w * item.h > 0);
    },
    addLoadInId(id: number): void {
      this.$refs.sheet.setValue(
        this.$refs.sheet.primaryCell.row,
        "allowed_containers",
        [...this.containerIdsForItem, id]
      );
      this.$refs.sheet.resetSelectedCells();
    },
    removeLoadInId(id: number): void {
      this.$refs.sheet.setValue(
        this.$refs.sheet.primaryCell.row,
        "allowed_containers",
        [...this.containerIdsForItem].filter((i) => i !== id)
      );
      this.$refs.sheet.resetSelectedCells();
    },
    convert(): void {
      let objects: HoldInputItem[] = this.$refs.sheet.getObjects();
      let startRow: number = this.$refs.sheet.selectedCells.start.row;
      let endRow: number = this.$refs.sheet.selectedCells.end.row;
      let lengthFactor =
        this.$toSI(this.convertLengthDimFrom) / this.$toSI(this.lengthDim);
      let weightFactor =
        this.$toSI(this.convertWeightDimFrom) / this.$toSI(this.weightDim);

      for (let i = startRow; i <= endRow; i++) {
        this.$refs.sheet.setValue(
          i,
          "l",
          this.$options.filters.roundTwoDecimals(
            lengthFactor * Number(objects[i].l)
          )
        );
        this.$refs.sheet.setValue(
          i,
          "w",
          this.$options.filters.roundTwoDecimals(
            lengthFactor * Number(objects[i].w)
          )
        );
        this.$refs.sheet.setValue(
          i,
          "h",
          this.$options.filters.roundTwoDecimals(
            lengthFactor * Number(objects[i].h)
          )
        );
        this.$refs.sheet.setValue(
          i,
          "wt",
          this.$options.filters.roundTwoDecimals(
            weightFactor * Number(objects[i].wt)
          )
        );
      }
    },
    moveItemsToLoadlist(id: number): void {
      let startRow: number = this.$refs.sheet.selectedCells.start.row;
      let endRow: number = this.$refs.sheet.selectedCells.end.row;

      this.$emit("moveItemsToLoadlist", {
        startRow,
        endRow,
        id,
      });
    },
  },
});

var regExp_OnlyNumbersAndDotAndComma = /[^0-9.,]/g;
var regExp_NumberWithDotDecimal = /[^0-9.]/g;
var regExp_MatchCommas = /\,/g;
var regExp_AllDots = /\.(?=.*\.)/g;

function parseNumberWithCommaDecimal(a: string | number): number {
  return parseFloat(
    String(a)
      .replace(regExp_OnlyNumbersAndDotAndComma, "")
      .replace(regExp_MatchCommas, ".")
      .replace(regExp_AllDots, "")
  );
}

function parseNumberWithDotDecimal(a: string | number): number {
  return parseFloat(String(a).replace(regExp_NumberWithDotDecimal, ""));
}
