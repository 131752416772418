



























import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "toggle-alert",
  data: function() {
    return {
      open: false,
    };
  },
  props: {
    body: String,
  },
  computed: {
    hasBody(): boolean {
      return !!this.body;
    },
  },
  mounted(): void {},
  methods: {},
});
