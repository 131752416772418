
























































import Vue from "vue";
import containerLibraryComponent from "@/components/Custom/ContainerLibrary.vue";
import setsLibraryComponent from "@/components/Custom/SetsLibrary.vue";

import sceneComponent from "@/components/Custom/SceneComponent.vue";
import { UserCompanyStore } from "@/store/index";
import { ListType } from "@/models/LoadlistModel";
import { User } from "@/models/UserCompanyModel";
import { UsageSettings } from "@/store/usageSettings";

let usageSettings = UsageSettings.fetch();

export default Vue.extend({
  name: "equipment",
  components: {
    containerLibraryComponent,
    setsLibraryComponent,
    sceneComponent,
  },
  data() {
    return {};
  },
  computed: {
    user(): User {
      return UserCompanyStore.user;
    },
    sets_enabled(): boolean {
      return UserCompanyStore?.company?.sets_enabled;
    },
    currentType(): ListType {
      return (this.$route.params.type as ListType) || "SEA";
    },
    showSets: {
      get(): boolean {
        return this.$route.name == "sets";
      },
      set(value: boolean): void {
        this.$router.replace({
          name: value ? "sets" : "containers",
          params: { type: this.currentType },
        });
      },
    },
  },
  mounted() {
    if (!this.$route.params.type) {
      let base_type = (usageSettings.currentBaseType as ListType) || "SEA";
      this.$router.replace({
        name: "containers",
        params: { type: base_type },
      });
    }
  },
  methods: {
    changeListType(type: string) {
      this.$router.push({
        name: "containers",
        params: { type: type },
      });
      usageSettings.currentBaseType = type as ListType;
    },
  },
  filters: {},
});
