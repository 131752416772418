
















































































































































































































































































































import Vue from "vue";

import sceneComponent from "@/components/Custom/SceneComponent.vue";

import {
  HoldData,
  HoldDataWithIndices,
  HoldItem,
  Indices,
  Loadlist,
  Loadplan,
} from "@/models/LoadlistModel";
import { PropType } from "vue";
import { Event } from "three";
import { CalcData, CalculationSettings } from "@/models/CalculationModel";
import { Set, GroupedSet } from "@/models/SetsModel";

import { LoadlistStore, UserCompanyStore } from "@/store";
import { AugmentedHold } from "@/models/augmented/hold";
const HOLDS_PER_PAGE = 5;
export default Vue.extend({
  name: "workspace-table",
  components: {
    sceneComponent,
  },
  props: {
    holds: Array as PropType<HoldDataWithIndices[]>,
    sets: Array as PropType<GroupedSet[]>,
    searchInput: String,
    checkedUnits: Array as PropType<Indices[]>,
    print: Boolean,
    groupSimilar: Boolean,
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: (): CalculationSettings => {
        return null;
      },
    },
  },
  data() {
    return {
      orderBy: "",
      isAsc: false,
      currentResultPage: 1,
      holdsPerResultPage: HOLDS_PER_PAGE,
      draggedOverRow: null,
      spaceInfoMenuItems: [
        { value: "used", text: "Used space" },
        { value: "free", text: "Free space" },
      ],
      selectedSpaceItem: 0,
    };
  },
  watch: {
    orderBy: function(a): void {
      if (this.$route.query.orderby != a)
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$route.query, orderby: a },
        });
    },
    isAsc: function(a): void {
      if (this.$route.query.asc != a)
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$route.query, asc: a },
        });
    },
    groupSimilar: function(a: boolean): void {
      if (this.$route.query.groupSimilar != String(a))
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$route.query, groupSimilar: String(a) },
        });

      this.changeResultPage(1);
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },
    sceneWidth(): number {
      if (this.$vuetify.breakpoint.lgAndDown) return 400;
      return 500;
    },
    sceneHeight(): number {
      return this.sceneWidth * 0.5;
    },
    sortedHolds(): AugmentedHold[] {
      let holds = this.holds;
      if (this.orderBy.length > 0) {
        holds = [...holds].sort((a, b) => {
          return (
            (this.isAsc ? 1 : -1) *
            (a[this.orderBy as keyof HoldDataWithIndices] >
            b[this.orderBy as keyof HoldDataWithIndices]
              ? 1
              : -1)
          );
        });
      }

      return holds
        .slice(
          (this.currentPage - 1) * this.holdsPerPage,
          (this.currentPage - 1) * this.holdsPerPage + this.holdsPerPage
        )
        .map((h) => new AugmentedHold(h));
    },
    holdsPerPage(): number {
      if (this.print) {
        return 1000;
      }
      return HOLDS_PER_PAGE;
    },
    currentPage(): number {
      if (this.print || this.searchInput) {
        return 1;
      }
      return this.currentResultPage;
    },
    noResultPageButtons(): number {
      return Math.ceil(Math.max(this.holds.length / this.holdsPerPage, 1));
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    loadplan_version(): number {
      return LoadlistStore.loadplan_version;
    },
    liteVersion(): boolean {
      return UserCompanyStore.lite_version;
    },
    filteredSets(): Map<string, any> {
      return new Map(
        this.sets
          .filter((s) => s.containers.length > 1)
          .map((s, i) => [s.uuid, { name: `${i + 1} - ${s.name}` }])
      );
    },
    numberOfColumns(): number {
      return 7;
    },
  },
  created(): void {
    if (this.$route.query.page !== undefined) {
      this.currentResultPage = parseInt(this.$route.query.page as string, 10);
    }

    this.isAsc = this.$route.query.asc === "true";
    this.orderBy = (this.$route.query.orderby as string) || "";
  },
  methods: {
    changeResultPage(page: number): void {
      this.currentResultPage = page;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { ...this.$route.query, page: String(page) },
      });
    },
    checkRow(row: Indices): void {
      this.$emit("toggleSelectedRow", row);
    },
    dragstart(e: Event, holdIndex: number) {
      e.dataTransfer.setData(
        "text",
        JSON.stringify({ moveFromIndex: holdIndex })
      );
    },
    dropOnRow(e: Event, holdIndex: number): void {
      this.draggedOverRow = null;
      const draggedData = JSON.parse(e.dataTransfer.getData("text"));

      if (draggedData.moveFromIndex !== undefined) {
        let holds = [...this.loadplan.holds];
        var hold = holds[draggedData.moveFromIndex];
        holds.splice(draggedData.moveFromIndex, 1);
        holds.splice(holdIndex, 0, hold);

        this.updateLoadplanHolds({
          replace: holds.length,
          holds: holds,
        });
      } else if (draggedData?.unloaded?.length) {
        this.calculateLoadplan({
          items: draggedData.unloaded,
          containers: [this.loadplan.holds[holdIndex]],
        }).then((solution) => {
          if (solution?.containers?.length) {
            this.updateLoadplanHolds({
              index: holdIndex,
              replace: 1,
              holds: solution.containers,
            });
          }
        });
      }
    },
    gotoInteractiveView(index: number): void {
      this.$emit("sceneClick", index);
    },
    calculateLoadplan(
      calcData: CalcData
    ): Promise<{
      containers: HoldData[];
      unloaded_items: HoldItem[];
    }> {
      return this.$store.dispatch("calculateLoadplan", calcData);
    },
    updateLoadplanHolds(data: {
      index?: number;
      replace?: number;
      holds?: HoldData[];
    }): void {
      this.$store.dispatch("updateLoadplanHolds", data);
    },
  },
});
