import API from "@/API";
import { Cargo } from "@/models/LoadlistModel";
import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";

@Module({
  name: "CargoModule",
})
export default class CargoModule extends VuexModule {
  cargoes: Cargo[] = [];
  isLoading = false;

  @Mutation
  setCargoes(val: Cargo[]): void {
    this.cargoes = val;
  }
  @Mutation
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  get cargoStoreIsLoading(): boolean {
    return this.isLoading;
  }

  @Action({ rawError: true })
  downloadCargoes(): Promise<unknown> {
    this.setIsLoading(true);

    return new Promise((resolve, reject) => {
      API.getCargoes()
        .then((response) => {
          this.setCargoes(response.data);
          this.setIsLoading(false);
          resolve(undefined);
        })
        .catch((error) => {
          this.setIsLoading(false);
          reject(error);
        });
    });
  }
}
