























import Vue, { PropType } from "vue";
import loadPresetsTableComponent from "@/components/Custom/LoadPresetsTable.vue";
import { HoldData, Loadlist } from "@/models/LoadlistModel";
import { LoadlistStore } from "@/store/index";
import { CalculationSettings } from "@/models/CalculationModel";

export default Vue.extend({
  name: "load-configurations-modal",
  components: {
    loadPresetsTableComponent,
  },
  props: {
    visible: {
      type: Boolean,
    },
    holds: {
      type: Array as PropType<HoldData[]>,
      default: null,
    },
    palletTypes: {
      type: Array as PropType<HoldData[]>,
      default: null,
    },
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: null,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
});
