



































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "overhang-input",
  data() {
    return {
      options: [
        { text: "Percentage", value: true },
        { text: "Length", value: false },
      ],
      isPercentage: false,
      percentageValue: 0,
      absoluteValue: 0,
    };
  },
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: "Allowed overhang",
    },
  },
  watch: {
    isPercentage(a: boolean) {
      this.$emit("input", 0);
    },
    percentageValue(a: number) {
      this.$emit("input", `${a}%`);
    },
    absoluteValue(a: number) {
      this.$emit("input", a);
    },
  },
  created() {
    if (String(this.value)?.includes("%")) {
      this.isPercentage = true;
      this.percentageValue = Number(String(this.value).split("%")[0]);
    } else if (!isNaN(Number(this.value))) {
      this.absoluteValue = Number(this.value);
    }
  },
  methods: {},
});
