




















































import { AugmentedSet } from "@/models/augmented/set";
import Vue, { PropType } from "vue";
import { Loadplan, Loadlist } from "../../models/LoadlistModel";
import LoadlistSummary from "../Custom/LoadlistSummary.vue";
import SceneComponent from "../Custom/SceneComponent.vue";

export default Vue.extend({
  name: "loadlist-preview",
  components: {
    LoadlistSummary,
    SceneComponent,
  },
  props: {
    loadlist: Object as PropType<Loadlist>,
  },
  computed: {
    augmentedSets(): AugmentedSet[] {
      return (
        this.loadplan?.sets?.map(
          (s) => new AugmentedSet(s, this.loadplan.holds)
        ) || []
      );
    },
    loadplan(): Loadplan {
      return this.loadlist?.result?.versions[0];
    },
  },
});
