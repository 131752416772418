const flatColorPalette = [
  /* Turquoise */
  [
    0xd1f2eb,
    0xa3e4d7,
    0x76d7c4,
    0x48c9b0,
    0x1abc9c,
    0x17a589,
    0x148f77,
    0x117864,
    0x0e6251,
  ],
  /* Green Sea */
  [
    0xd0ece7,
    0xa2d9ce,
    0x73c6b6,
    0x45b39d,
    0x16a085,
    0x138d75,
    0x117a65,
    0x0e6655,
    0x0b5345,
  ],
  /* Emerald */
  [
    0xd5f5e3,
    0xabebc6,
    0x82e0aa,
    0x58d68d,
    0x2ecc71,
    0x28b463,
    0x239b56,
    0x1d8348,
    0x186a3b,
  ],
  /* Nephritis */
  [
    0xd4efdf,
    0xa9dfbf,
    0x7dcea0,
    0x52be80,
    0x27ae60,
    0x229954,
    0x1e8449,
    0x196f3d,
    0x145a32,
  ],
  /* Peter River */
  [
    0xd6eaf8,
    0xaed6f1,
    0x85c1e9,
    0x5dade2,
    0x3498db,
    0x2e86c1,
    0x2874a6,
    0x21618c,
    0x1b4f72,
  ],
  /* Belize Hole */
  [
    0xd4e6f1,
    0xa9cce3,
    0x7fb3d5,
    0x5499c7,
    0x2980b9,
    0x2471a3,
    0x1f618d,
    0x1a5276,
    0x154360,
  ],
  /* Amethyst */
  [
    0xebdef0,
    0xd7bde2,
    0xc39bd3,
    0xaf7ac5,
    0x9b59b6,
    0x884ea0,
    0x76448a,
    0x633974,
    0x512e5f,
  ],
  /* Wisteria */
  [
    0xe8daef,
    0xd2b4de,
    0xbb8fce,
    0xa569bd,
    0x8e44ad,
    0x7d3c98,
    0x6c3483,
    0x5b2c6f,
    0x4a235a,
  ],
  /* Wet Asphalt */
  [
    0xd6dbdf,
    0xaeb6bf,
    0x85929e,
    0x5d6d7e,
    0x34495e,
    0x2e4053,
    0x283747,
    0x212f3c,
    0x1b2631,
  ],
  /* Midnight Blue */
  [
    0xd5d8dc,
    0xabb2b9,
    0x808b96,
    0x566573,
    0x2c3e50,
    0x273746,
    0x212f3d,
    0x1c2833,
    0x17202a,
  ],
  /* Sunflower */
  [
    0xfcf3cf,
    0xf9e79f,
    0xf7dc6f,
    0xf4d03f,
    0xf1c40f,
    0xd4ac0d,
    0xb7950b,
    0x9a7d0a,
    0x7d6608,
  ],
  /* Orange */
  [
    0xfdebd0,
    0xfad7a0,
    0xf8c471,
    0xf5b041,
    0xf39c12,
    0xd68910,
    0xb9770e,
    0x9c640c,
    0x7e5109,
  ],
  /* Carrot */
  [
    0xfae5d3,
    0xf5cba7,
    0xf0b27a,
    0xeb984e,
    0xe67e22,
    0xca6f1e,
    0xaf601a,
    0x935116,
    0x784212,
  ],
  /* Pumpkin */
  [
    0xf6ddcc,
    0xedbb99,
    0xe59866,
    0xdc7633,
    0xd35400,
    0xba4a00,
    0xa04000,
    0x873600,
    0x6e2c00,
  ],
  /* Alizarin */
  [
    0xfadbd8,
    0xf5b7b1,
    0xf1948a,
    0xec7063,
    0xe74c3c,
    0xcb4335,
    0xb03a2e,
    0x943126,
    0x78281f,
  ],
  /* Pomegranate */
  [
    0xf2d7d5,
    0xe6b0aa,
    0xd98880,
    0xcd6155,
    0xc0392b,
    0xa93226,
    0x922b21,
    0x7b241c,
    0x641e16,
  ],
  // /* Clouds */
  // [
  //     0xfbfcfc,
  //     0xf7f9f9,
  //     0xf4f6f7,
  //     0xf0f3f4,
  //     0xecf0f1,
  //     0xd0d3d4,
  //     0xb3b6b7,
  //     0x979a9a,
  //     0x7b7d7d,
  // ],
  // /* Silver */
  // [
  //     0xf2f3f4,
  //     0xe5e7e9,
  //     0xd7dbdd,
  //     0xcacfd2,
  //     0xbdc3c7,
  //     0xa6acaf,
  //     0x909497,
  //     0x797d7f,
  //     0x626567,
  // ],

  /* Concrete */
  [
    0xeaeded,
    0xd5dbdb,
    0xbfc9ca,
    0xaab7b8,
    0x95a5a6,
    0x839192,
    0x717d7e,
    0x5f6a6a,
    0x4d5656,
  ],
  // /* Asbestos */
  // [
  //     0xe5e8e8,
  //     0xccd1d1,
  //     0xb2babb,
  //     0x99a3a4,
  //     0x7f8c8d,
  //     0x707b7c,
  //     0x616a6b,
  //     0x515a5a,
  //     0x424949,
  // ],
].flat();

const initialOrderWithTone = [
  { index: 0, tone: 3 }, // Turqoise,
  { index: 10, tone: 3 }, // Sun Flower
  { index: 14, tone: 3 }, // Alizarin
  { index: 6, tone: 3 }, // Amethyst
  { index: 5, tone: 6 }, // Belize Hold
  { index: 15, tone: 4 }, // Pomegranate
  { index: 8, tone: 3 }, // Wet Asphalt
  { index: 0, tone: 6 }, // Green Sea
  { index: 14, tone: 1 }, // Light Alizarin
  { index: 13, tone: 4 }, // Pumpkin
  { index: 2, tone: 3 }, // Emerald
  { index: 4, tone: 4 }, // Peter River
  { index: 9, tone: 4 }, // Midnight Blue
  { index: 3, tone: 5 }, // Nephritis
  { index: 7, tone: 4 }, // Wisteria
  { index: 12, tone: 4 }, // Carrot
];

function colorPalette(objects: { color?: string }[]): number[] {
  const usedColors = new Set(
    objects
      .filter((row) => !!row.color)
      .map((row) => parseInt("0x" + row.color.substring(1), 16))
  );

  let allColors = [...flatColorPalette];
  const palette: number[] = [];
  // const usedColors = new Set();
  // take the initial order
  addColors(allColors, palette, usedColors);

  allColors = allColors.filter((c) => !usedColors.has(c));
  // shuffle the rest
  while (allColors.length > 0) {
    palette.push(
      allColors.splice(Math.floor(Math.random() * allColors.length), 1)[0]
    );
  }
  return palette;
}

function addColors(
  allColors: number[],
  palette: number[],
  usedColors: Set<number>
): void {
  [...initialOrderWithTone]
    .map(({ index, tone }) => {
      return { index, tone, rnd: Math.random() };
    })
    .sort((a, b) => a.rnd - b.rnd)
    .forEach((i) => {
      const c = allColors[i.index * 9 + i.tone];
      if (!usedColors.has(c)) palette.push(c);
      usedColors.add(c);
    });
}

function makeRandomColor(palette: number[]): string {
  if (palette.length === 0) {
    palette.push(...flatColorPalette);
  }
  return (
    "#" +
    palette
      .shift()
      .toString(16)
      .padStart(6, "0")
  );
}

const palette = {
  turquoise: {
    lightest: 0xd1f2eb,
    lighter: 0xa3e4d7,
    light: 0x76d7c4,
    normalLight: 0x48c9b0,
    normal: 0x1abc9c,
    normalDark: 0x17a589,
    dark: 0x148f77,
    darker: 0x117864,
    darkest: 0x0e6251,
  },
  greenSea: {
    lightest: 0xd0ece7,
    lighter: 0xa2d9ce,
    light: 0x73c6b6,
    normalLight: 0x45b39d,
    normal: 0x16a085,
    normalDark: 0x138d75,
    dark: 0x117a65,
    darker: 0x0e6655,
    darkest: 0x0b5345,
  },
  emerald: {
    lightest: 0xd5f5e3,
    lighter: 0xabebc6,
    light: 0x82e0aa,
    normalLight: 0x58d68d,
    normal: 0x2ecc71,
    normalDark: 0x28b463,
    dark: 0x239b56,
    darker: 0x1d8348,
    darkest: 0x186a3b,
  },
  nephritis: {
    lightest: 0xd4efdf,
    lighter: 0xa9dfbf,
    light: 0x7dcea0,
    normalLight: 0x52be80,
    normal: 0x27ae60,
    normalDark: 0x229954,
    dark: 0x1e8449,
    darker: 0x196f3d,
    darkest: 0x145a32,
  },
  peterRiver: {
    lightest: 0xd6eaf8,
    lighter: 0xaed6f1,
    light: 0x85c1e9,
    normalLight: 0x5dade2,
    normal: 0x3498db,
    normalDark: 0x2e86c1,
    dark: 0x2874a6,
    darker: 0x21618c,
    darkest: 0x1b4f72,
  },
  belizeHole: {
    lightest: 0xd4e6f1,
    lighter: 0xa9cce3,
    light: 0x7fb3d5,
    normalLight: 0x5499c7,
    normal: 0x2980b9,
    normalDark: 0x2471a3,
    dark: 0x1f618d,
    darker: 0x1a5276,
    darkest: 0x154360,
  },
  amethyst: {
    lightest: 0xebdef0,
    lighter: 0xd7bde2,
    light: 0xc39bd3,
    normalLight: 0xaf7ac5,
    normal: 0x9b59b6,
    normalDark: 0x884ea0,
    dark: 0x76448a,
    darker: 0x633974,
    darkest: 0x512e5f,
  },
  wisteria: {
    lightest: 0xe8daef,
    lighter: 0xd2b4de,
    light: 0xbb8fce,
    normalLight: 0xa569bd,
    normal: 0x8e44ad,
    normalDark: 0x7d3c98,
    dark: 0x6c3483,
    darker: 0x5b2c6f,
    darkest: 0x4a235a,
  },
  wetAsphalt: {
    lightest: 0xd6dbdf,
    lighter: 0xaeb6bf,
    light: 0x85929e,
    normalLight: 0x5d6d7e,
    normal: 0x34495e,
    normalDark: 0x2e4053,
    dark: 0x283747,
    darker: 0x212f3c,
    darkest: 0x1b2631,
  },
  midnightBlue: {
    lightest: 0xd5d8dc,
    lighter: 0xabb2b9,
    light: 0x808b96,
    normalLight: 0x566573,
    normal: 0x2c3e50,
    normalDark: 0x273746,
    dark: 0x212f3d,
    darker: 0x1c2833,
    darkest: 0x17202a,
  },
  sunflower: {
    lightest: 0xfcf3cf,
    lighter: 0xf9e79f,
    light: 0xf7dc6f,
    normalLight: 0xf4d03f,
    normal: 0xf1c40f,
    normalDark: 0xd4ac0d,
    dark: 0xb7950b,
    darker: 0x9a7d0a,
    darkest: 0x7d6608,
  },
  orange: {
    lightest: 0xfdebd0,
    lighter: 0xfad7a0,
    light: 0xf8c471,
    normalLight: 0xf5b041,
    normal: 0xf39c12,
    normalDark: 0xd68910,
    dark: 0xb9770e,
    darker: 0x9c640c,
    darkest: 0x7e5109,
  },
  carrot: {
    lightest: 0xfae5d3,
    lighter: 0xf5cba7,
    light: 0xf0b27a,
    normalLight: 0xeb984e,
    normal: 0xe67e22,
    normalDark: 0xca6f1e,
    dark: 0xaf601a,
    darker: 0x935116,
    darkest: 0x784212,
  },
  pumpkin: {
    lightest: 0xf6ddcc,
    lighter: 0xedbb99,
    light: 0xe59866,
    normalLight: 0xdc7633,
    normal: 0xd35400,
    normalDark: 0xba4a00,
    dark: 0xa04000,
    darker: 0x873600,
    darkest: 0x6e2c00,
  },
  alizarin: {
    lightest: 0xfadbd8,
    lighter: 0xf5b7b1,
    light: 0xf1948a,
    normalLight: 0xec7063,
    normal: 0xe74c3c,
    normalDark: 0xcb4335,
    dark: 0xb03a2e,
    darker: 0x943126,
    darkest: 0x78281f,
  },
  pomegranate: {
    lightest: 0xf2d7d5,
    lighter: 0xe6b0aa,
    light: 0xd98880,
    normalLight: 0xcd6155,
    normal: 0xc0392b,
    normalDark: 0xa93226,
    dark: 0x922b21,
    darker: 0x7b241c,
    darkest: 0x641e16,
  },
  clouds: {
    lightest: 0xfbfcfc,
    lighter: 0xf7f9f9,
    light: 0xf4f6f7,
    normalLight: 0xf0f3f4,
    normal: 0xecf0f1,
    normalDark: 0xd0d3d4,
    dark: 0xb3b6b7,
    darker: 0x979a9a,
    darkest: 0x7b7d7d,
  },
  silver: {
    lightest: 0xf2f3f4,
    lighter: 0xe5e7e9,
    light: 0xd7dbdd,
    normalLight: 0xcacfd2,
    normal: 0xbdc3c7,
    normalDark: 0xa6acaf,
    dark: 0x909497,
    darker: 0x797d7f,
    darkest: 0x626567,
  },

  concrete: {
    lightest: 0xeaeded,
    lighter: 0xd5dbdb,
    light: 0xbfc9ca,
    normalLight: 0xaab7b8,
    normal: 0x95a5a6,
    normalDark: 0x839192,
    dark: 0x717d7e,
    darker: 0x5f6a6a,
    darkest: 0x4d5656,
  },
  asbestos: {
    lightest: 0xe5e8e8,
    lighter: 0xccd1d1,
    light: 0xb2babb,
    normalLight: 0x99a3a4,
    normal: 0x7f8c8d,
    normalDark: 0x707b7c,
    dark: 0x616a6b,
    darker: 0x515a5a,
    darkest: 0x424949,
  },
};

export { makeRandomColor, colorPalette, palette };

// const materialDesignPalette = [[0xf44336,
//     0xffebee,
//     0xffcdd2,
//     0xef9a9a,
//     0xe57373,
//     0xef5350,
//     0xf44336,
//     0xe53935,
//     0xd32f2f,
//     0xc62828,
//     0xb71c1c,
//     0xff8a80,
//     0xff5252,
//     0xff1744,
//     0xd50000,

// ], [/* Pink */
//     0xe91e63,
//     0xfce4ec,
//     0xf8bbd0,
//     0xf48fb1,
//     0xf06292,
//     0xec407a,
//     0xe91e63,
//     0xd81b60,
//     0xc2185b,
//     0xad1457,
//     0x880e4f,
//     0xff80ab,
//     0xff4081,
//     0xf50057,
//     0xc51162,

// ], [/* Purple */
//     0x9c27b0,
//     0xf3e5f5,
//     0xe1bee7,
//     0xce93d8,
//     0xba68c8,
//     0xab47bc,
//     0x9c27b0,
//     0x8e24aa,
//     0x7b1fa2,
//     0x6a1b9a,
//     0x4a148c,
//     0xea80fc,
//     0xe040fb,
//     0xd500f9,
//     0xaa00ff,

// ], [/* Deep Purple */
//     0x673ab7,
//     0xede7f6,
//     0xd1c4e9,
//     0xb39ddb,
//     0x9575cd,
//     0x7e57c2,
//     0x673ab7,
//     0x5e35b1,
//     0x512da8,
//     0x4527a0,
//     0x311b92,
//     0xb388ff,
//     0x7c4dff,
//     0x651fff,
//     0x6200ea,

// ], [/* Indigo */
//     0x3f51b5,
//     0xe8eaf6,
//     0xc5cae9,
//     0x9fa8da,
//     0x7986cb,
//     0x5c6bc0,
//     0x3f51b5,
//     0x3949ab,
//     0x303f9f,
//     0x283593,
//     0x1a237e,
//     0x8c9eff,
//     0x536dfe,
//     0x3d5afe,
//     0x304ffe,

// ], [/* Blue */
//     0x2196f3,
//     0xe3f2fd,
//     0xbbdefb,
//     0x90caf9,
//     0x64b5f6,
//     0x42a5f5,
//     0x2196f3,
//     0x1e88e5,
//     0x1976d2,
//     0x1565c0,
//     0x0d47a1,
//     0x82b1ff,
//     0x448aff,
//     0x2979ff,
//     0x2962ff,

// ], [/* Light Blue */
//     0x03a9f4,
//     0xe1f5fe,
//     0xb3e5fc,
//     0x81d4fa,
//     0x4fc3f7,
//     0x29b6f6,
//     0x03a9f4,
//     0x039be5,
//     0x0288d1,
//     0x0277bd,
//     0x01579b,
//     0x80d8ff,
//     0x40c4ff,
//     0x00b0ff,
//     0x0091ea,

// ], [/* Cyan */
//     0x00bcd4,
//     0xe0f7fa,
//     0xb2ebf2,
//     0x80deea,
//     0x4dd0e1,
//     0x26c6da,
//     0x00bcd4,
//     0x00acc1,
//     0x0097a7,
//     0x00838f,
//     0x006064,
//     0x84ffff,
//     0x18ffff,
//     0x00e5ff,
//     0x00b8d4,

// ], [/* Teal */
//     0x009688,
//     0xe0f2f1,
//     0xb2dfdb,
//     0x80cbc4,
//     0x4db6ac,
//     0x26a69a,
//     0x009688,
//     0x00897b,
//     0x00796b,
//     0x00695c,
//     0x004d40,
//     0xa7ffeb,
//     0x64ffda,
//     0x1de9b6,
//     0x00bfa5,

// ], [/* Green */
//     0x4caf50,
//     0xe8f5e9,
//     0xc8e6c9,
//     0xa5d6a7,
//     0x81c784,
//     0x66bb6a,
//     0x4caf50,
//     0x43a047,
//     0x388e3c,
//     0x2e7d32,
//     0x1b5e20,
//     0xb9f6ca,
//     0x69f0ae,
//     0x00e676,
//     0x00c853,

// ], [/* Light Green */
//     0x8bc34a,
//     0xf1f8e9,
//     0xdcedc8,
//     0xc5e1a5,
//     0xaed581,
//     0x9ccc65,
//     0x8bc34a,
//     0x7cb342,
//     0x689f38,
//     0x558b2f,
//     0x33691e,
//     0xccff90,
//     0xb2ff59,
//     0x76ff03,
//     0x64dd17,

// ], [/* Lime */
//     0xcddc39,
//     0xf9fbe7,
//     0xf0f4c3,
//     0xe6ee9c,
//     0xdce775,
//     0xd4e157,
//     0xcddc39,
//     0xc0ca33,
//     0xafb42b,
//     0x9e9d24,
//     0x827717,
//     0xf4ff81,
//     0xeeff41,
//     0xc6ff00,
//     0xaeea00,

// ], [/* Yellow */
//     0xffeb3b,
//     0xfffde7,
//     0xfff9c4,
//     0xfff59d,
//     0xfff176,
//     0xffee58,
//     0xffeb3b,
//     0xfdd835,
//     0xfbc02d,
//     0xf9a825,
//     0xf57f17,
//     0xffff8d,
//     0xffff00,
//     0xffea00,
//     0xffd600,

// ], [/* Amber */
//     0xffc107,
//     0xfff8e1,
//     0xffecb3,
//     0xffe082,
//     0xffd54f,
//     0xffca28,
//     0xffc107,
//     0xffb300,
//     0xffa000,
//     0xff8f00,
//     0xff6f00,
//     0xffe57f,
//     0xffd740,
//     0xffc400,
//     0xffab00,

// ], [/* Orange */
//     0xff9800,
//     0xfff3e0,
//     0xffe0b2,
//     0xffcc80,
//     0xffb74d,
//     0xffa726,
//     0xff9800,
//     0xfb8c00,
//     0xf57c00,
//     0xef6c00,
//     0xe65100,
//     0xffd180,
//     0xffab40,
//     0xff9100,
//     0xff6d00,

// ], [/* Deep Orange */
//     0xff5722,
//     0xfbe9e7,
//     0xffccbc,
//     0xffab91,
//     0xff8a65,
//     0xff7043,
//     0xff5722,
//     0xf4511e,
//     0xe64a19,
//     0xd84315,
//     0xbf360c,
//     0xff9e80,
//     0xff6e40,
//     0xff3d00,
//     0xdd2c00,

// ], [/* Brown */
//     0x795548,
//     0xefebe9,
//     0xd7ccc8,
//     0xbcaaa4,
//     0xa1887f,
//     0x8d6e63,
//     0x795548,
//     0x6d4c41,
//     0x5d4037,
//     0x4e342e,
//     0x3e2723,

// ], [/* Grey */
//     0x9e9e9e,
//     0xfafafa,
//     0xf5f5f5,
//     0xeeeeee,
//     0xe0e0e0,
//     0xbdbdbd,
//     0x9e9e9e,
//     0x757575,
//     0x616161,
//     0x424242,
//     0x212121,

// ], [/* Blue Grey */
//     0x607d8b,
//     0xeceff1,
//     0xcfd8dc,
//     0xb0bec5,
//     0x90a4ae,
//     0x78909c,
//     0x607d8b,
//     0x546e7a,
//     0x455a64,
//     0x37474f,
//     0x263238,
// ]];
