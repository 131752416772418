






































































































import Vue, { PropType } from "vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import { mapGetters } from "vuex";
import { VIEWS } from "@/graphics/sceneManager";
import { ViewSettings } from "@/models/GraphicsModel";
import { HoldDataWithIndices } from "@/models/LoadlistModel";
import { GroupedSet } from "@/models/SetsModel";
import { AugmentedSet } from "@/models/augmented/set";
import { CalculationSettings } from "@/models/CalculationModel";

export default Vue.extend({
  name: "set-workspace",
  props: {
    sets: Array as PropType<GroupedSet[]>,
    searchInput: String,
    checkedSets: Array,
    filteredHolds: Array as PropType<HoldDataWithIndices[]>,
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: (): CalculationSettings => {
        return null;
      },
    },
  },
  components: {
    sceneComponent,
  },
  data() {
    return {
      resultsPerPage: 5,
      resultPage: 1,
      renderKey: 0,
    };
  },
  watch: {},
  computed: {
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    augmentedSets(): AugmentedSet[] {
      this.renderKey++;
      return this.sets
        .map((set) => new AugmentedSet(set, this.loadplan.holds))
        .filter((s) => {
          return s.rendering.length > 0;
        });
    },
    sortedSets(): AugmentedSet[] {
      let sets = this.augmentedSets;

      let currentPage = this.resultPage;
      if (this.searchInput) {
        currentPage = 1;
      }

      return sets
        .filter((s) =>
          s.set.containers.some((c) =>
            this.filteredHolds.find((h) => h.uuid == c.uuid)
          )
        )
        .slice(
          (currentPage - 1) * this.resultsPerPage,
          (currentPage - 1) * this.resultsPerPage + this.resultsPerPage
        );
    },
    noResultPageButtons(): number {
      return Math.ceil(Math.max(this.sets.length / this.resultsPerPage, 1));
    },
    ...mapGetters(["loadplan", "loadlist", "loadplan_version", "lite_version"]),
  },
  created(): void {
    if (this.$route.query.print) {
      this.resultsPerPage = 1000;
    } else if (this.$route.query.setpage !== undefined) {
      this.resultPage = parseInt(this.$route.query.setpage as string, 10);
    }
  },
  methods: {
    changeResultPage(page: number): void {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { ...this.$route.query, page: String(page) },
      });
    },

    sceneClick(uuid: string): void {
      if (this.lite_version) {
        return;
      }
      this.$router.push({
        name: "detail_set",
        params: {
          version: this.loadplan_version,
          set: uuid,
        },
      });
    },
    customView(set: AugmentedSet): ViewSettings {
      const orthoView = { view: VIEWS.THREEDORTHO };
      if (set.rendering.length > 1) {
        return { ...orthoView };
      }
      return orthoView;
    },

    checkRow(row: number): void {
      this.$emit("toggleSetRow", row);
    },
  },
  filters: {},
});
