









































































































import Vue, { PropType } from "vue"
import containerLibraryComponent from "@/components/Custom/ContainerLibrary.vue"
import inlineHoldEditComponent from "@/components/Custom/InlineHoldEdit.vue"
import selectContainerTypeTableComponent from "@/components/SetBuilder/SelectContainerTypeTable.vue"
import containerEditComponent from "../Custom/ContainerEditor/index.vue";
import { Hold, HoldData, ListType } from "@/models/LoadlistModel"
import { VDialog, VCard, VCardTitle, VCardText, VRow, VCol, VTooltip, VBtn, VIcon, VCardActions, VAlert, VSpacer } from "vuetify/lib";
import { EquipmentStore } from "@/store";
import { SetType } from "@/models/SetsModel";
import { SceneManager } from "@/graphics/sceneManager";
import { ViewSettings } from "@/models/GraphicsModel";

export default Vue.extend({
    name: "select_slot_container_type",
    props: {
      set: Object as PropType<SetType>,
      positionName: String,
      visible: Boolean       
    },
    components: {
      containerLibraryComponent,
      inlineHoldEditComponent,
      selectContainerTypeTableComponent,
      containerEditComponent
    },
    data() {
        return {
          currentBaseType: this.$route.params.type as ListType,
          showContainerBuilderModal: false,
          showAddInlineContainerModal: false,
          newTempContainer: {
            id: null,
            name: "Custom",
            L: 2,
            W: 2,
            H: 2,
            items: [],
            payload: 1000,
            rotation: [0, 0, 0, 'XYZ'],
            base_type: this.$route.params.type as ListType,
          } as HoldData,
          prevViewSettings: undefined as ViewSettings
        }
    },
    mounted() {
      (this.$refs.containerLibrary as any)?.$refs?.search?.focus()
    },
    computed: {
      show: {
          get(): boolean {
              return this.visible;
          },
          set(value: boolean) {
              if (!value) {
                  this.$emit("close");
              }
          },
      },
      allLibraryContainers(): Hold[] {
        return EquipmentStore.holds;
      },
      temporaryContainers(): Hold[] {
        return this.set.data.container_types.filter(
          (i) => !this.allLibraryContainers.some((x) => x.id === i.id)
        ).map(holdData => ({data: holdData} as Hold));
      }, 
    },
    methods: {
      generateID(): number { // also used in Builder.vue
        return parseInt(String(new Date().getTime()).substr(-8));
      },
      showContainerBuilder() {
        this.newTempContainer.id = this.generateID();
        this.prevViewSettings = SceneManager.getViewSettings()
        this.showContainerBuilderModal = true;
      },
      closeContainerBuilder() {
        this.showContainerBuilderModal = false;
        // reset to previous view settings for set
        SceneManager.setViewSettings(this.prevViewSettings)
        this.$emit('containerSelected', this.newTempContainer)
      },
      updateContainerName(updatedName: string) {
        this.newTempContainer = {
          ...this.newTempContainer,
          name: updatedName
        }
      }
    }
})

