





































































































































































import Vue from "vue";
import setOverviewComponent from "@/components/SetBuilder/SetOverview.vue";
import setBuilderComponent from "@/components/SetBuilder/Builder.vue";
import API from "@/API";
import { EquipmentStore, UserCompanyStore } from "@/store";
import {
  SetSlot,
  SetType,
  SetTypeData,
} from "@/models/SetsModel";
import { User } from "@/models/UserCompanyModel";
import { getSerializerError } from "@/misc/errorUtils";

export default Vue.extend({
  name: "set_entry",
  components: {
    setOverviewComponent,
    setBuilderComponent
  },
  data() {
    return {
      validForm: false,
      isLoading: false,
      showDeleteModal: false,
      showCopyModal: false,
      showSupportModal: false,
      enabledSave: false,
      copyName: "",
      nameError: false,
      nameRules: [
        (v: string) => !!v || "A name is required",
        (v: string) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      set: {
        data: {
          name: null,
          id: null,
          container_types: [],
          slots: [{ configurations: [{ group: [] }], name: "Group 1" }],
          quantity: null,
          payload: null,
          base_type: this.$route.params.type,
        },
        base_type: this.$route.params.type,
        id: null,
        editable: true,
        priority: 20000,
        name: null,
        default: false,
      } as SetType,
      displayConfiguration: [0],
      active_tab: 0,
      showSnackbar: false,
      error_message: undefined as string,
    };
  },
  computed: {
    tabs(): { key: string; title: string }[] {
      return [
        {
          key: "overview",
          title: "Overview",
        },
        {
          key: "builder",
          title: "Builder",
        },
      ];
    },
    isEditable(): boolean {
      return (
        (!this.set.default && this.user.is_editor) ||
        this.$route.query["edit"] == "true"
      );
    },
    user(): User {
      return UserCompanyStore.user;
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
  },
  watch: {
    set: {
      handler: function(val: SetType): void {
        this.enabledSave = true;
      },
      deep: true,
    },
    "set.data.slots": {
      handler: function(slots: SetSlot[]): void {
        this.set.data.container_types = this.set.data.container_types.filter(
          (c) => {
            let foundContainerType = slots.find((slot) =>
              slot.configurations.find((alt) =>
                alt.group.find((g) => g.container_type_id === c.id)
              )
            );
            return foundContainerType;
          }
        );
      },
      deep: true,
    },
  },
  mounted() {
    if (!localStorage.getItem("cplHasSeenSetBuilderSupport")) {
      this.showSupportModal = true;
      localStorage.setItem("cplHasSeenSetBuilderSupport", "true");
    }
    if (this.$route.params.set_id) {
      this.isLoading = true;

      API.getSet(this.$route.params.set_id)
        .then((response) => {
          this.isLoading = false;
          this.set = { ...this.set, ...response.data };
          this.displayConfiguration = this.set.data.slots.map(() => 0);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    } else {
      this.set.data = {
        ...this.set.data,
      };
    }
  },
  methods: {
    onSubmit(event: any) {
      if (this.validForm) {
        this.isLoading = true;
        this.enabledSave = false;
        let finalAssembly = {
          ...this.set,
          name: this.set.data.name,
        };
        // Remove unused keys
        Object.keys(finalAssembly.data)
          .filter(
            (key) =>
              finalAssembly.data[key as keyof SetTypeData] &&
              finalAssembly.data[key as keyof SetTypeData].constructor ===
                Object &&
              !Object.values(finalAssembly.data[key as keyof SetTypeData]).some(
                (v) => {
                  if (Array.isArray(v)) {
                    return v.length;
                  } else {
                    return !!v;
                  }
                }
              )
          )
          .forEach((key) => {
            (finalAssembly.data[key as keyof SetTypeData] as any) = undefined;
          });
        finalAssembly.data.slots.forEach((slot) =>
          slot.configurations.forEach((alt) =>
            alt.group.forEach((ht) => {
              ht.position.x = ht.position.x || 0;
              ht.position.y = ht.position.y || 0;
              ht.position.z = ht.position.z || 0;
            })
          )
        );
        
        const crud_operation = !finalAssembly.id
          ? API.createSet
          : API.updateSet;
        crud_operation(finalAssembly)
          .then((data) => {
            if(!finalAssembly.id) {
              this.set.id = data.data.id
              this.$router.replace({
                name: 'modify_set',
                params: { type: this.set.base_type, set_id: data.data.id },
              });
            }
            return EquipmentStore.getUserSets();
          })
          .then((_) => {
            this.isLoading = false;
            this.enabledSave = false;
          })
          .catch((error) => {
            if (error?.reponse?.data) {
              this.error_message = getSerializerError(error.response.data.data);
            } else {
              this.error_message = "Unknown error when saving";
              console.log(error);
            }
            this.showSnackbar = true;
            this.isLoading = false;
            this.enabledSave = true;
          });
      }
    },
    copySet() {
      let newSet = JSON.parse(JSON.stringify(this.set));

      newSet.id = null;
      newSet.data.id = null;
      newSet.priority = 10000;
      newSet.data.name = this.copyName;
      newSet.name = this.copyName;

      this.isLoading = true;

      API.createSet(newSet)
        .then((data) => {
          this.isLoading = false;

          this.set = { ...this.set, ...data.data };

          this.$router.replace({
            name: "modify_set",
            params: { type: this.set.base_type, set_id: data.data.id },
          });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    deleteSet() {
      this.isLoading = true;

      API.deleteSet(this.set.id)
        .then((_) => {
          return EquipmentStore.getUserSets();
        })
        .then((_) => {
          this.isLoading = false;
          this.$router.go(-1);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
});
