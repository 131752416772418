

































































































































































































































































































































































































































































































































import Vue from "vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import loadSettingsModal from "@/components/Modals/LoadSettings/LoadSettings.vue";
import loadConfigurationsModal from "@/components/Modals/LoadConfigurations.vue";
import containerLibraryComponent from "@/components/Custom/ContainerLibrary.vue";
import setsLibraryComponent from "@/components/Custom/SetsLibrary.vue";
import loadplanVersionComponent from "@/components/Custom/LoadplanVersion.vue";
import inlineHoldEditComponent from "../../Custom/InlineHoldEdit.vue";
import modifySetComponent from "@/components/Custom/InlineSetEdit.vue";
import toggleAlert from "@/components/Custom/ToggleAlert.vue";
import containerUtils from "@/misc/containerUtils";
import {
  HoldData,
  ListType,
  UnloadedItem,
  Loadlist,
  Loadplan,
  HoldItem,
} from "@/models/LoadlistModel";
import { User } from "@/models/UserCompanyModel";
import { CalcData } from "@/models/CalculationModel";
import { LoadlistStore, EquipmentStore, UserCompanyStore } from "@/store/index";
import { SetType, SetTypeData, Set } from "@/models/SetsModel";
import itemUtils from "@/misc/itemUtils";
import { AugmentedSet } from "@/models/augmented/set";

export default Vue.extend({
  name: "options",
  data() {
    return {
      selectedHolds: [] as HoldData[],
      selectedPallets: [] as HoldData[],
      selectedSets: [] as SetTypeData[],
      maxSelectedContainers: 15,
      showSettingsDialog: false,
      showPalletModal: false,
      showLoadConfigurationsModal: false,
      showCostDialog: false,
      showLoadplanVersions: false,
      active_tab_index: 0,
      isLoading: false,
    };
  },
  components: {
    sceneComponent,
    loadSettingsModal,
    containerLibraryComponent,
    setsLibraryComponent,
    loadplanVersionComponent,
    loadConfigurationsModal,
    inlineHoldEditComponent,
    modifySetComponent,
    toggleAlert,
  },
  watch: {
    "loadplan.selected_holds": {
      handler: function(val: HoldData[]): void {
        this.selectedHolds = JSON.parse(JSON.stringify(val || []));
      },
      immediate: true,
    },
    "loadplan.pallet_types": {
      handler: function(val: HoldData[]): void {
        this.selectedPallets = JSON.parse(JSON.stringify(val || []));
      },
      immediate: true,
    },
    "loadplan.set_types": {
      handler: function(val) {
        this.selectedSets = JSON.parse(JSON.stringify(val || []));
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.params.settings == "settings") {
      this.showSettingsDialog = true;
    }
  },
  computed: {
    typeName(): ListType {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    showPalletCard(): boolean {
      return ["SEA", "ROAD", "AIR"].includes(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    has_result(): boolean {
      return this.loadplan?.holds?.length > 0;
    },
    loadplan_version(): number {
      return LoadlistStore.loadplan_version;
    },
    unloaded_items(): UnloadedItem[] {
      return LoadlistStore.unloaded_items;
    },
    user(): User {
      return UserCompanyStore.user;
    },
    isAuthenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    displaySets(): boolean {
      return UserCompanyStore.company.sets_enabled;
    },

    secondaryAlerts(): {
      title: string;
      level: string; //"info" | "warning" | "error";
      body?: string;
    }[] {
      return [
        {
          title: "Secondary equipment is too tall",
          level: "error",
          body: `Your secondary equipment might be too tall for your primary
                equipment. Please select some primary equipment that has a
                larger max height or reduce the max height of the secondary
                equipment`,
          visible: this.hasToTallSecondaryEquipment,
        },
        {
          title: "No secondary equipment",
          level: "error",
          body: `Some cargoes have the "Preload" option enabled but no secondary equipment is
                selected`,
          visible: !this.selectedPallets.length && this.hasPalletizedCargoes,
        },
        {
          title: "No cargoes to preload",
          level: "info",
          body: `Select which items should be preloaded using the
                "Preload" column in 1. Data`,
          visible: this.selectedPallets.length && !this.hasPalletizedCargoes,
        },
        {
          title: "Single layer loading",
          level: "info",
          body: `Some of the secondary equipment does not have a fixed height or
                a max height set which means that only one layer of cargoes will
                be loaded on these`,
          visible: this.anyOpenPallets,
        },
        {
          title:
            "You can pre-load cargoes into secondary equipment (like pallets)",
          level: "info",
          visible: !this.selectedPallets.length && !this.hasPalletizedCargoes,
        },
      ].filter((alert) => alert.visible);
    },
    primaryAlerts(): {
      title: string;
      level: string; //"info" | "warning" | "error";
      body?: string;
    }[] {
      return [
        {
          title: "Single layer loading",
          level: "info",
          body: `Some of the primary equipment does not have a fixed height or a
              max height set which means that only one layer of cargoes will be
              loaded on these`,
          visible: this.anyOpenContainers,
        },
        {
          title: "No equipment selected",
          level: "error",
          body: `You need to select some form of primary equipment in order to continue`,
          visible: !this.selectedHolds.length && !this.selectedSets.length,
        },
      ].filter((alert) => alert.visible);
    },
    hasPalletizedCargoes(): boolean {
      return this.loadlist.data?.some((i) => i.palletize);
    },
    containerHeights(): number[] {
      return [
        ...this.selectedHolds,
        ...this.selectedSets?.flatMap((i) => i.container_types),
      ]?.map((i) =>
        Math.max(i?.H > 0 ? i.H : 0, i?.max_height > 0 ? i.max_height : 0)
      );
    },
    palletHeights(): number[] {
      return this.selectedPallets?.map((i) =>
        Math.max(i?.H > 0 ? i.H : 0, i?.max_height > 0 ? i.max_height : 0)
      );
    },
    hasToTallSecondaryEquipment(): boolean {
      const palletHeightsWithFloor = this.selectedPallets?.map(
        (i) =>
          Math.max(i?.H > 0 ? i.H : 0, i?.max_height > 0 ? i.max_height : 0) +
          (i.floor_height ? i.floor_height : 0)
      );
      if (this.containerHeights.length && palletHeightsWithFloor.length) {
        const tallestSecondary = Math.max(...palletHeightsWithFloor);
        const tallestPrimary = Math.max(...this.containerHeights);
        if (tallestPrimary && tallestSecondary)
          return tallestSecondary > tallestPrimary;
      }

      return false;
    },
    anyOpenContainers(): boolean {
      return this.containerHeights.some((i) => !i);
    },
    anyOpenPallets(): boolean {
      return this.palletHeights.some((i) => !i);
    },
    active_tab(): {
      key: string;
      title: string;
      typename: string;
      visible_for: string[];
      mot: ListType;
      hide: boolean;
      is_set?: boolean;
    } {
      return this.tabs[this.active_tab_index];
    },
    tabs(): {
      key: string;
      title: string;
      typename: string;
      visible_for: string[];
      mot: ListType;
      hide: boolean;
      is_set?: boolean;
      disabledItems: number[];
      addMethod: Function;
      limit_exceeded: boolean;
      component: string;
      primary?: boolean;
    }[] {
      return [
        {
          key: "equipment",
          title: "Equipment library",
          typename: this.typeName,
          visible_for: ["SEA", "ROAD", "AIR", "PALL"],
          mot: this.loadlist.list_type,
          hide: false,
          disabledItems: this.selectedHolds.map((i) => i.id),
          addMethod: this.addContainer,
          limit_exceeded:
            this.selectedHolds.length >= this.maxSelectedContainers,
          component: "containerLibraryComponent",
          primary: true,
        },

        {
          key: "sets",
          title: `${this.setName} library`,
          typename: this.setName,
          visible_for: ["ROAD", "AIR", "SEA"],
          mot: this.loadlist.list_type,
          hide: !this.displaySets,
          is_set: true,
          disabledItems: this.selectedSets.map((i) => i.id),
          addMethod: this.addSet,
          limit_exceeded:
            this.selectedSets.length >= this.maxSelectedContainers,
          component: "setsLibraryComponent",
          primary: true,
        },
        {
          key: "pallets",
          title: "Pallet library",
          typename: "secondary",
          visible_for: ["SEA", "ROAD", "AIR", "PALL"],
          mot: "PALL" as ListType,
          hide: false,
          disabledItems: this.selectedPallets.map((i) => i.id),
          addMethod: this.addPallet,
          limit_exceeded:
            this.selectedPallets.length >= this.maxSelectedContainers,
          component: "containerLibraryComponent",
        },
      ].filter(
        (t) => !t.hide && t.visible_for.includes(this.loadlist.list_type)
      );
    },
    unloadedWithoutReason(): UnloadedItem[] {
      return this.unloaded_items.filter((i) => !i.reason);
    },
    allHolds(): HoldData[] {
      return (this.selectedHolds || [])
        .concat(this.selectedPallets || [])
        .concat((this.selectedSets || []).flatMap((st) => st.container_types));
    },
  },
  methods: {
    addContainer(data: HoldData): void {
      this.selectedHolds.push(JSON.parse(JSON.stringify(data)));
      this.setLoadplanProperty({
        key: "selected_holds",
        value: JSON.parse(JSON.stringify(this.selectedHolds)),
      });
    },
    removeContainer(index: number): void {
      this.selectedHolds.splice(index, 1);
      this.setLoadplanProperty({
        key: "selected_holds",
        value: JSON.parse(JSON.stringify(this.selectedHolds)),
      });
    },
    addPallet(pallet: HoldData): void {
      if (this.selectedPallets.findIndex((s) => s.id === pallet.id) === -1) {
        this.selectedPallets.push(JSON.parse(JSON.stringify(pallet)));
        this.setLoadplanProperty({
          key: "pallet_types",
          value: JSON.parse(JSON.stringify(this.selectedPallets)),
        });
      }
    },
    removePallet(index: number): void {
      this.selectedPallets.splice(index, 1);
      this.setLoadplanProperty({
        key: "pallet_types",
        value: JSON.parse(JSON.stringify(this.selectedPallets)),
      });
    },
    addSet(set: SetType): void {
      if (this.selectedSets.findIndex((s) => s.id === set.id) === -1) {
        this.selectedSets.push(JSON.parse(JSON.stringify(set)));
        this.setLoadplanProperty({
          key: "set_types",
          value: JSON.parse(JSON.stringify(this.selectedSets)),
        });
      }
    },
    removeSet(index: number): void {
      this.selectedSets.splice(index, 1);
      this.setLoadplanProperty({
        key: "set_types",
        value: JSON.parse(JSON.stringify(this.selectedSets)),
      });
    },
    calculate(): void {
      this.isLoading = true;
      this.setLoadplanProperty({
        key: "selected_holds",
        value: JSON.parse(JSON.stringify(this.selectedHolds)),
      });
      this.setLoadplanProperty({
        key: "pallet_types",
        value: JSON.parse(JSON.stringify(this.selectedPallets)),
      });
      this.setLoadplanProperty({
        key: "set_types",
        value: JSON.parse(JSON.stringify(this.selectedSets)),
      });
      const calcData: CalcData = {
        settings: this.loadplan.settings,
        items: this.loadlist.data,
        container_types: this.selectedHolds,
        pallet_types: this.loadplan.pallet_types,
        set_types: this.selectedSets,
        pol: this.loadlist.pol,
        pod: this.loadlist.pod,
      };

      this.calculateLoadplan(calcData);
    },
    addItemsCalculate() {
      this.isLoading = true;

      // Shallow copy
      let holds = Array.from(this.loadplan.holds);
      let sets = this.loadplan.sets
        .filter((i) => i.containers.length > 1)
        .map((set) => {
          for (let placedContainer of set.containers) {
            let holdIndex = holds.findIndex(
              (c) => c.uuid === placedContainer.uuid
            );

            if (holdIndex > -1) {
              placedContainer.container = holds[holdIndex];
              holds.splice(holdIndex, 1);
            }
          }
          return set;
        });

      const calcData: CalcData = {
        settings: this.loadplan.settings,
        items: this.unloadedWithoutReason,
        containers: holds,
        sets: sets,
      };
      this.calculateLoadplan(calcData);
    },
    saveLoadlistResult(): void {
      LoadlistStore.saveLoadlistResult();
    },
    calculateLoadplan(calcData: CalcData): void {
      LoadlistStore.calculateLoadplan(calcData).then(
        (solution: {
          containers: HoldData[];
          unloaded_items: UnloadedItem[];
          sets: any[];
        }) => {
          this.updateLoadplanHolds({
            replace: this.loadplan.holds.length,
            holds: solution.containers,
          });
          this.updateLoadplanSets({
            replace: this.loadplan.sets?.length || 0,
            sets: solution.sets,
          });
          this.saveLoadlistResult();
          this.$router.push({
            name: "workspace",
            params: { version: this.loadplan_version.toString() },
          });
          this.isLoading = false;
        }
      );
    },
    updateLoadplanHolds(options: { replace: number; holds: HoldData[] }): void {
      LoadlistStore.updateLoadplanHolds(options);
    },
    setLoadplanProperty(
      propertyInfo: { key: string; value: any },
      save = false
    ) {
      LoadlistStore.setLoadplanProperty(propertyInfo);
      if (save) this.saveLoadlistResult();
    },
    updateLoadplanSets(options: { replace: number; sets: Set[] }): void {
      LoadlistStore.updateLoadplanSets(options);
    },
  },
});
