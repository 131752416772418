























































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import API from "@/API";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import containerLibraryComponent from "@/components/Custom/ContainerLibrary.vue";
import loadSettingsModal from "@/components/Modals/LoadSettings/LoadSettings.vue";
import { DEFAULT_SETTINGS } from "@/components/Modals/LoadSettings/LoadSettings.vue";
import {
  HoldData,
  ListType,
  LoadConfigurationData,
  LoadConfiguration,
  Loadlist,
  Loadplan,
  HoldDataWithPosition,
} from "@/models/LoadlistModel";
import { UserPreferences, User } from "@/models/UserCompanyModel";
import { UserCompanyStore, LoadlistStore } from "@/store/index";
import { CalculationSettings } from "@/models/CalculationModel";
import InlineHoldEditComponent from "@/components/Custom/InlineHoldEdit.vue";
import setsLibraryComponent from "@/components/Custom/SetsLibrary.vue";
import { SetType, SetTypeData } from "@/models/SetsModel";
import containerUtils from "@/misc/containerUtils";
import InlineSetEditComponent from "@/components/Custom/InlineSetEdit.vue";
import { AugmentedSet } from "@/models/augmented/set";
import { getSerializerError } from "@/misc/errorUtils";

interface SelectedConfiguration {
  [listType: string]: number;
}

export default Vue.extend({
  name: "load-configurations-modal",
  components: {
    sceneComponent,
    containerLibraryComponent,
    loadSettingsModal,
    InlineHoldEditComponent,
    setsLibraryComponent,
    InlineSetEditComponent,
  },
  props: {
    visible: {
      type: Boolean,
    },
    list_type: {
      type: String as PropType<ListType>,
      default: null as ListType,
    },
    showUsePreset: {
      type: Boolean,
      default: false,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    typeName(): string {
      return this.$typeNames(this.list_type);
    },
    defaultConfiguration(): number | null {
      return this.preferences?.configuration?.[this.list_type];
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    preferences(): UserPreferences {
      return UserCompanyStore.preferences;
    },
    isAuthenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    user(): User {
      return UserCompanyStore.user;
    },
    presetLists(): any[] {
      return [
        { value: "holds", title: this.typeName },
        { value: "pallet_types", title: "pallet" },
        ...(this.displaySets
          ? [{ value: "set_types", title: this.$setNames(this.list_type) }]
          : []),
      ];
    },
    holdAndSetsPickerTitle(): string {
      switch (this.holdAndSetsPicker) {
        case "set_types":
          return "set";
        case "pallet_types":
          return "pallet";
        default:
          return this.typeName;
      }
    },
    allHolds(): HoldData[] {
      return (this.selectedConfiguration.data.holds || [])
        .concat(this.selectedConfiguration.data.pallet_types || [])
        .concat(
          (this.selectedConfiguration.data.set_types || []).flatMap(
            (st) => st.container_types
          )
        );
    },
    displaySets(): boolean {
      return UserCompanyStore.company.sets_enabled;
    },
  },
  data() {
    return {
      loading: false,
      showSettingsDialog: false,
      showDeleteDialog: false,
      showAddDialog: false,
      showSnackbar: false,
      showHoldsAndSetsPicker: false,
      holdAndSetsPicker: null,
      snackbarInfo: null,
      headers: [
        { text: "", value: "name", align: "left", sortable: false },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      configurations: [] as LoadConfiguration[],
      totalConfigurations: 0,
      selectedConfiguration: null as LoadConfiguration,
      pagination: {
        page: 1,
      },
      search: "",
      newConfiguration: {
        name: "",
        description: "",
        from_current_loadplan: false,
      },
      validForm: false,
      nameRules: [
        (v: string) => !!v || "Name is required",
        (v: string) =>
          (v && v.length <= 200) || "Name must be less than 200 characters",
      ],
    };
  },
  watch: {
    pagination: {
      handler(): void {
        this.getConfigurations();
      },
      deep: true,
    },
    list_type(): void {
      this.getConfigurations();
    },
    search(): void {
      this.getConfigurations();
    },
  },
  mounted(): void {
    this.getConfigurations();
  },
  methods: {
    isAddDisabled(data: any, id: any): boolean {
      return (
        data[this.holdAndSetsPicker] &&
        data[this.holdAndSetsPicker].some((h: any) => h.id == id)
      );
    },
    addToPreset(
      key: keyof LoadConfigurationData,
      value: HoldData | SetTypeData
    ): void {
      if (this.selectedConfiguration.data[key] == null) {
        this.selectedConfiguration.data[key] = [];
      }

      (this.selectedConfiguration.data[key] as any[]).push(
        JSON.parse(JSON.stringify(value))
      );
      this.updateConfiguration();
    },
    removeFromPreset(
      key: keyof LoadConfigurationData,
      value: HoldData | SetTypeData
    ): void {
      this.selectedConfiguration.data[key] = (this.selectedConfiguration.data[
        key
      ] as any[]).filter((h: HoldData) => h.id !== value.id);
      this.updateConfiguration();
    },

    updateSettings(newSettings: CalculationSettings): void {
      this.selectedConfiguration.data.settings = newSettings;
      this.updateConfiguration();
    },
    updateConfiguration(): void {
      this.loading = true;
      API.updateLoadConfiguration({
        ...this.selectedConfiguration,
      })
        .then((response) => {
          this.loading = false;
          this.getConfigurations();
          this.showDeleteDialog = false;
          this.snackbarInfo = "Preset updated";
          this.showSnackbar = true;
        })
        .catch((error: any) => {
          this.loading = false;
          if (error?.response?.data) {
            this.snackbarInfo = getSerializerError(error.response.data);
          } else {
            this.snackbarInfo = "Unknown error when saving";
            console.log(error);
          }
          this.showSnackbar = true;
        });
    },
    getConfigurations(): void {
      API.getLoadConfigurations({
        page: this.pagination.page,
        name: this.search,
        base_type: this.list_type,
      })
        .then((response) => {
          const count: number = response.data.count;
          const results: LoadConfiguration[] = response.data.results;
          this.totalConfigurations = count;
          this.configurations = results;
        })
        .catch((e: any) => {});
    },
    deleteConfiguration(): void {
      this.loading = true;
      this.pagination.page = 1;
      API.deleteLoadConfiguration(this.selectedConfiguration.id)
        .then((response) => {
          this.loading = false;
          this.getConfigurations();
          this.showDeleteDialog = false;
          this.selectedConfiguration = null;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    createConfiguration(): void {
      this.loading = true;
      API.createLoadConfiguration({
        ...this.newConfiguration,
        base_type: this.list_type,
        data: {
          holds: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.selected_holds || null
            : null,
          pallet_types: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.pallet_types || null
            : null,
          set_types: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.set_types || null
            : null,
          settings: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.settings || DEFAULT_SETTINGS
            : DEFAULT_SETTINGS,
        },
      })
        .then((response) => {
          this.loading = false;
          this.getConfigurations();
          this.showAddDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error?.response?.data) {
            this.snackbarInfo = getSerializerError(error.response.data);
          } else {
            this.snackbarInfo = "Unknown error when saving";
            console.log(error);
          }
          this.showSnackbar = true;
        });
    },
    setDefault(id: number): void {
      let configuration: SelectedConfiguration = JSON.parse(
        JSON.stringify(this.preferences["configuration"] || {})
      );
      configuration[this.list_type] = id;
      this.updateMe(configuration).then((response) => {
        this.showSnackbar = true;
        this.snackbarInfo = "Default preset updated";
      });
    },
    updateMe(configuration: SelectedConfiguration): Promise<undefined> {
      return UserCompanyStore.updateMe({
        preferences: {
          configuration: configuration,
        },
      });
    },
    usePreset(preset: LoadConfiguration): void {
      this.$emit("selectedPreset", preset);
      if (this.loadplan) LoadlistStore.useLoadConfiguration(preset);
      this.show = false;
    },
    indexOfSelected(
      preset_type: keyof LoadConfigurationData,
      id: number
    ): number {
      return (this.selectedConfiguration.data[preset_type] as any[])?.findIndex(
        (i) => i.id === id
      );
    },
  },
});
