


























































































































































import Vue, { PropType, set } from "vue";
import { SetType } from "@/models/SetsModel";
import { AugmentedSet } from "@/models/augmented/set";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import containerBuilderComponent from "../Custom/ContainerEditor/index.vue";
import { SceneManager } from "@/graphics/sceneManager";
import { HoldData } from "@/models/LoadlistModel";
export default Vue.extend({
  name: "set_overview",
  props: {
    set: Object as PropType<SetType>,
    displayConfiguration: Array as PropType<number[]>,
    isActive: Boolean,
  },
  components: {
    sceneComponent,
    containerBuilderComponent,
  },
  data() {
    return {
      validForm: false,
      nameRules: [
        (v: string) => !!v || "A name is required",
        (v: string) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      renderedSet: null as AugmentedSet,
      viewSettings: undefined,
      renderKey: 0,
      showContainerBuilderModal: false,
      editHoldIndex: undefined as number,
    };
  },
  computed: {
    usedContainers(): { holdData: HoldData; count: number }[] {
      return this.set.data.container_types.flatMap((ct) => {
        return {
          holdData: ct,
          count: this.set.data.slots
            .flatMap((slot) => slot.configurations.flatMap((c) => c.group))
            .filter((ht) => ht.container_type_id === ct.id).length,
        };
      });
    },
  },
  methods: {
    rerender() {
      this.viewSettings = SceneManager.getViewSettings();
      if (this.viewSettings.pos[0]) {
        this.viewSettings.view = "custom";
      }
      this.renderedSet = AugmentedSet.fromSetType(
        this.set.data,
        this.displayConfiguration
      );
      this.renderKey++;
    },
    goToBuilder() {
      this.$emit("goToBuilder");
    },
  },
  watch: {
    showContainerBuilderModal: {
      handler: function(val: boolean): void {
        if (!val) {
          this.rerender();
        }
      },
    },
    editHoldIndex: function() {
      this.renderKey++;
    },
    isActive: {
      handler: function(val: SetType): void {
        if (val) {
          setTimeout(() => {
            this.rerender();
          }, 200);
        }
      },
    },
    set: {
      handler: function(val: SetType): void {
        setTimeout(() => {
          this.rerender();
        }, 200);
      },
      deep: true,
      immediate: true,
    },
    validForm: {
      handler(val, old) {
        if (val !== old) {
          this.$emit("validForm", val);
        }
      },
    },
  },
});
