




































































































































































































































































































































































import Vue, { PropType } from "vue";
import ExcelService from "@/services/excelService";
import { Hold, HoldInputItem } from "@/models/LoadlistModel";
import { ColumnMappings } from "@/models/InputDataModel";
import { EquipmentStore } from "@/store";

interface ParseInfo {
  no: number | null;
  weight: number | null;
  volume: number | null;
  parsedItems: HoldInputItem[];
}

export default Vue.extend({
  name: "excel-parser",
  data() {
    return {
      isLoading: false,
      state: 1,
      showNeedAssistanceAlert: false,
      excelColumnMappings: {
        sheet_no: undefined,
        header_row_no: undefined,
        length_col: "",
        width_col: "",
        height_col: "",
        weight_col: "",
        label_col: "",
        qty_col: "",
        stackable_col: "",
        filter_col: "",
        filter_text: "",
        orientations_col: "",
        bottom_only_col: "",
        tiltable_col: "",
        longship_col: "",
        layers_col: "",
        shipment_id_col: "",
        priority_col: "",
        geometry_col: "",
        palletize_col: "",
        unitqty_col: "",
        class_col: "",
        load_in_col: "",
        destination_col: "",
      } as ColumnMappings,
      excelParseInfo: {
        no: null,
        weight: null,
        volume: null,
        parsedItems: [],
      } as ParseInfo,
      dimensions: null,
      excelParseReplaceCurrent: true,
      importedItems: [] as HoldInputItem[],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    files: {
      type: Array as PropType<File[]>,
      default: () => [] as File[],
    },
  },
  computed: {
    showModal: {
      get(): boolean {
        return this.show;
      },
      set(val: boolean): void {
        if (!val) {
          this.state = 1;
          this.excelColumnMappings = {
            sheet_no: undefined,
            header_row_no: undefined,
            length_col: "",
            width_col: "",
            height_col: "",
            weight_col: "",
            label_col: "",
            qty_col: "",
            stackable_col: "",
            bottom_only_col: "",
            tiltable_col: "",
            orientations_col: "",
            longship_col: "",
            layers_col: "",
            shipment_id_col: "",
            priority_col: "",
            geometry_col: "",
            palletize_col: "",
            unitqty_col: "",
            class_col: "",
            load_in_col: "",
            destination_col: "",
          } as ColumnMappings;
        }
        this.$emit("update:show", !!val);
      },
    },
    fileType(): string | null {
      if (!this.files.length) {
        return null;
      }

      return this.files[0].name
        .toLowerCase()
        .split(".")
        .pop();
    },
    holdsLibrary(): Hold[] {
      return EquipmentStore.holds;
    },
  },
  filters: {
    yesno: function(value: boolean): string {
      return value ? "Yes" : "No";
    },
  },
  methods: {
    readExcel: function(): void {
      const loadExcelJS = () => import("exceljs");
      this.isLoading = true;
      this.showNeedAssistanceAlert = false;
      loadExcelJS().then((exceljs) => {
        const workbook = new exceljs.Workbook();
        const arryBuffer = new Response(this.files[0]).arrayBuffer();
        arryBuffer
          .then((data) => {
            workbook.xlsx
              .load(data)
              .then(() => {
                const excelService = new ExcelService();
                const data = excelService.parseLoadlist(
                  workbook,
                  this.excelColumnMappings
                );
                this.isLoading = false;
                const inputItems = data.items.map(
                  (i) =>
                    ({
                      label: i.label || "",
                      l: this.parseNumber(i.l),
                      w: this.parseNumber(i.w),
                      h: this.parseNumber(i.h),
                      wt: this.parseNumber(i.wt),
                      qty: this.parseNumber(i.qty),
                      not_stackable: i.not_stackable,
                      not_rotatable: i.not_rotatable,
                      orientations:
                        parseInt(i.orientations) > 0
                          ? parseInt(i.orientations)
                          : undefined,
                      tiltable: i.tiltable,
                      bottom_only: i.bottom_only,
                      max_layers: this.parseNumber(i.layers),
                      shipment_id: i.shipment_id,
                      priority: this.parseNumber(i.priority),
                      geometry: i.geometry?.toLowerCase(),
                      palletize: i.palletize,
                      unit_qty: this.parseNumber(i.unit_qty),
                      destination: i.destination,
                      class_id: i.class,
                      allowed_containers: this.getIdsForLoadInContainers(
                        i.load_in
                      ),
                    } as HoldInputItem)
                );
                this.excelParseInfo.parsedItems = inputItems;

                this.excelColumnMappings = data.options;
                this.dimensions = data.dimensions;
                if (Array.isArray(this.excelParseInfo.parsedItems)) {
                  this.importedItems = [];
                  this.excelParseInfo.no = 0;
                  for (
                    let i = 0;
                    i < this.excelParseInfo.parsedItems.length;
                    i++
                  ) {
                    this.importedItems.push(this.excelParseInfo.parsedItems[i]);
                    this.excelParseInfo.no +=
                      parseInt(
                        (this.excelParseInfo.parsedItems[i] as any)["qty"]
                      ) || 1;
                  }
                  this.state = 3;
                } else if (this.fileType == "xlsx") {
                  this.state = 2;
                }
              })
              .catch((error) => {
                // console.error(error);
                this.isLoading = false;
                this.showNeedAssistanceAlert = true;
                this.state = 2;
              });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.state = 2;
          });
      });
    },
    invertBooleans(propertyName: string): void {
      this.importedItems.map((item) => {
        return {
          ...item,
          propertyName: (item as any)[propertyName] = !(item as any)[
            propertyName
          ],
        };
      });
    },

    parseNumber(value: string | undefined): number | undefined {
      return isNaN(Number(value)) ? undefined : Number(value);
    },

    getIdsForLoadInContainers(
      loadInString: string | undefined
    ): number[] | undefined {
      if (loadInString) {
        const containerNames = loadInString.trim().split(",");
        const containerIds = this.holdsLibrary
          .filter((hold) =>
            containerNames.some(
              (containerName) =>
                hold.name.toLowerCase() === containerName.trim().toLowerCase()
            )
          )
          .map((h) => h.id);
        return containerIds.length > 0 ? containerIds : undefined;
      }
      return undefined;
    },

    done(): void {
      this.$emit("input", [
        ...(this.excelParseReplaceCurrent ? [] : this.value),
        ...this.importedItems,
      ]);
      if (this.dimensions) {
        this.$emit("updateWeightDim", this.dimensions.weightDim.value);
        this.$emit("updateLengthDim", this.dimensions.lengthDim.value);
      }
      this.showModal = false;
    },
  },
});
