

























































































































































import Vue from "vue";
import API from "@/API";
import { Loadlist, LoadlistBase } from "@/models/LoadlistModel";
import { APIResponse } from "@/models/APIModel";
import newListModal from "@/components/Modals/NewList.vue";
import copyLoadlistModal from "@/components/Modals/CopyLoadlist.vue";
import deleteLoadlistModal from "@/components/Modals/DeleteLoadlist.vue";
import LoadlistPreview from "./LoadlistPreview.vue";

export default Vue.extend({
  name: "loadlists-table",
  components: {
    newListModal,
    copyLoadlistModal,
    deleteLoadlistModal,
    LoadlistPreview,
  },
  props: {
    groupId: Number,
    forceUpdate: Number,
    readonly: Boolean,
    searchString: String,
  },
  created() {
    if (this.$route.params.page)
      this.pagination.page = parseInt(this.$route.params.page) || 1;
    if (this.$route.query.sortBy)
      this.pagination.sortBy = [String(this.$route.query.sortBy)];
    if (this.$route.query.sortDesc)
      this.pagination.sortDesc = [
        String(this.$route.query.sortDesc) === "true",
      ];
  },
  computed: {},
  data() {
    return {
      showPreview: false,
      selectedLoadlist: null as Loadlist,
      loadlists: [] as LoadlistBase[],
      selectedList: {} as LoadlistBase,
      isLoading: false,
      totalItems: 0,
      showAddNewLoadlistModal: false,
      showCopyLoadlistModal: false,
      showDeleteLoadlistModal: false,
      pagination: {
        page: 1,
        sortBy: ["modified_date"],
        sortDesc: [false],
      },
      headers: [
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "list_type",
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "ETD",
          align: "left",
          sortable: true,
          value: "etd",
        },
        {
          text: "Modified",
          align: "left",
          sortable: true,
          value: "modified_date",
        },
        {
          text: "PoL",
          align: "left",
          sortable: true,
          value: "pol",
        },
        {
          text: "PoD",
          align: "left",
          sortable: true,
          value: "pod",
        },
        {
          text: "Actions",
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    dragStart(loadlist: LoadlistBase, e: any) {
      this.selectedList = loadlist;
      e.dataTransfer.setData("text", loadlist.id);
    },
    updateRoute(): void {
      if (!this.readonly)
        this.$router
          .push({
            name: "loadlists",
            params: { page: this.pagination.page.toString() },
            query: {
              sortBy: this.pagination.sortBy[0],
              sortDesc: String(this.pagination.sortDesc[0]),
              group: this.groupId?.toString(),
              search: this.searchString,
            },
          })
          .catch((error: any) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      this.fetchLoadlists();
    },
    fullLoadlist(item: LoadlistBase): void {
      this.selectedLoadlist = null;

      API.getLoadlist(item.id).then((data: APIResponse) => {
        this.selectedLoadlist = data.data;
      });
    },
    fetchLoadlists(): void {
      this.isLoading = true;
      API.getLoadlists({
        page: this.pagination.page,
        search: this.searchString,
        group: this.groupId,
        ordering:
          (this.pagination.sortDesc[0] ? "" : "-") + this.pagination.sortBy[0],
      })
        .then((data: APIResponse) => {
          this.totalItems = data.data.count;
          this.loadlists = data.data.results;
          this.isLoading = false;
        })
        .catch((error: any) => {
          console.log("Error fetching loadlists");
          this.isLoading = false;
        });
    },
  },
  watch: {
    forceUpdate: {
      handler(val, old) {
        this.fetchLoadlists();
      },
    },
    groupId: {
      handler(val, old) {
        this.pagination.page = 1;
        this.updateRoute();
      },
    },
    pagination: {
      handler(val, old) {
        this.updateRoute();
      },
      deep: false,
    },
    searchString: {
      handler(val, old) {
        this.pagination.page = 1;
        this.updateRoute();
      },
    },
  },
});
