



















































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { ItemProperty } from "@/misc/itemProperties";
import OrientationPicker from "@/components/Custom/OrientationPicker.vue";
import { MenuItem } from "./index.vue";
import { mapGetters } from "vuex";
import { Warning, shortDesc } from "@/misc/itemWarnings";
import {
  Cargo,
  LengthDim,
  WeightDim,
  HoldInputItem,
  Hold,
} from "@/models/LoadlistModel";
import { EquipmentStore } from "@/store/index";

// If we need a maximum amount of rows, this makes sense. This is an upper limit
const MAX_ROWS = 100000;

// The minimal amount of rows that we will have in internalObjects
const MIN_ROWS = 200;
// How close we need to be to the edge before we start adding more rows to internalObjects
const BUFFER = 50;

interface AutocompleteItem extends Cargo {
  focus: boolean;
  query_match?: number;
  prop: string;
}
interface UndoTransactionValue {
  index: number;
  key: string;
  value: any;
}
interface UndoTransaction {
  primaryCell: {
    row: number;
    key: string;
  };
  values: UndoTransactionValue[];
}

interface HoldInputItemWithWarnings extends HoldInputItem {
  warnings: Warning[];
}

export default Vue.extend({
  name: "sheet",
  components: { OrientationPicker },
  data() {
    return {
      internalObjects: [] as HoldInputItemWithWarnings[],
      primaryCell: {
        row: undefined,
        col: undefined,
      },
      selectedCells: {
        start: {
          row: undefined,
          col: undefined,
        },
        end: {
          row: undefined,
          col: undefined,
        },
        maxRow: undefined,
        minRow: undefined,
      },
      mouseIsDown: false,
      menu: {
        show: false,
        x: 0,
        y: 0,
      },
      isEditing: false,
      lastTouch: null as string,
      dragCopy: false,
      dragCopyMemory: new Map<string, any>(),
      autocompleteItems: [] as AutocompleteItem[],
      query: "",
      undoBuffer: [] as UndoTransaction[],
    };
  },
  props: {
    value: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    headers: {
      type: Array as PropType<ItemProperty[]>,
      default: () => [] as ItemProperty[],
    },
    pasteColumnSplitPattern: {
      type: RegExp,
      default: () => /[xX*]/,
    },
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => [] as MenuItem[],
    },
    firstColumnWidth: {
      type: Number,
      default: 40,
    },
    itemHeight: {
      type: Number,
      default: 30,
    },
    cargoes: {
      type: Array as PropType<Cargo[]>,
      default: () => [] as Cargo[],
    },
    warnings: {
      type: Array as PropType<{ id: Warning; indexes: number[] }[]>,
      default: () => [] as { id: Warning; indexes: number[] }[],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lengthDim: String as PropType<LengthDim>,
    weightDim: String as PropType<WeightDim>,
    disableAutocomplete: Boolean,
  },
  watch: {
    warnings: {
      handler(val) {
        this.internalObjects = JSON.parse(JSON.stringify(this.internalObjects));
        this.internalObjects.forEach((i) => (i.warnings = undefined));

        this.warnings
          .flatMap((w) =>
            w.indexes.map((i) => {
              return { i, ids: [w.id] };
            })
          )
          .filter(({ i, ids }, index, list) => {
            let first = list.findIndex((a) => a.i == i);
            if (first == index) {
              return true;
            } else {
              list[first].ids.push(...ids);
            }
          })
          .forEach(({ i, ids }) => {
            this.internalObjects[i].warnings = ids;
          });
      },
      immediate: false,
    },
    value: {
      handler(val) {
        this.internalObjects = JSON.parse(JSON.stringify(val)).slice(
          0,
          MAX_ROWS
        );
        this.extendInternalObjects();
      },
      immediate: true,
    },
    primaryCell: {
      handler: function(val, oldVal) {
        this.resetSelectedCells();
        if (oldVal.row !== undefined && oldVal.col !== undefined) {
          if (this.isEditing) this.stopEditMode();
        }

        this.selectedCells.start.col = val.col;
        this.selectedCells.start.row = val.row;
        this.selectedCells.end.col = val.col;
        this.selectedCells.end.row = val.row;
        this.$emit("selected-row", this.primaryCell.row);
      },
      deep: true,
    },
  },
  computed: {
    tableHeight(): number {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 500;
        case "xl":
          return 800;
      }
    },
    wrapperRef(): any {
      return this.$refs.wrapper;
    },
    virtualScrollerRef(): any {
      return this.$refs.virtualScroller;
    },
    eventDivRef(): any {
      return this.$refs.eventDiv;
    },
    scrollViewWidth(): number {
      // Starting at 15 to make room for the scroll-bar on the far right
      // The scroll bar is actually different sizes, but 15 seems to cover all of them
      // This leaves a visual bug where there is an empty gap to the scroll bar in some browsers.
      return (
        this.headers.reduce((prev, next) => prev + next.width, 15) +
        this.firstColumnWidth
      );
    },
    filledRows(): HoldInputItem[] {
      return this.internalObjects.filter((i) => i.l && i.w && i.h && i.wt);
    },
    holdsLibrary(): Hold[] {
      return EquipmentStore.holds;
    },
    ...mapGetters(["is_authenticated"]),
  },
  mounted(): void {
    document.addEventListener("mousemove", this.documentMouseMove);
  },
  beforeDestroy(): void {
    document.removeEventListener("mousemove", this.documentMouseMove);
  },
  methods: {
    virtualScrollin(): void {
      if (
        this.virtualScrollerRef._data.last + BUFFER >
        this.internalObjects.length
      ) {
        this.extendInternalObjects();
      }
    },
    extendInternalObjects(count?: number): void {
      const initialLength = this.internalObjects.length;
      for (
        let i = initialLength;
        i < Math.min(MAX_ROWS, initialLength + (count || MIN_ROWS));
        i++
      ) {
        this.internalObjects.push({ qty: 1 } as HoldInputItemWithWarnings);
      }
    },
    getObjects(): HoldInputItem[] {
      return JSON.parse(JSON.stringify(this.internalObjects));
    },
    warningDesc(w: Warning): string {
      return shortDesc(w);
    },
    isPrimary(row: number, column: number): boolean {
      return this.primaryCell.row == row && this.primaryCell.col == column;
    },
    showMenu(e: MouseEvent): void {
      e.preventDefault();
      this.menu.show = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.$nextTick(() => {
        this.menu.show = true;
      });
    },
    setPrimaryCell(col: number, row: number): void {
      this.autocompleteItems = [];
      this.primaryCell = {
        col: col === undefined ? this.primaryCell.col : col,
        row: row === undefined ? this.primaryCell.row : row,
      };
      this.$nextTick(() => {
        this.setVisibleCell();
      });
    },
    dragCopyStart() {
      this.dragCopy = true;
      this.stopEditMode();
      this.selectedCells.maxRow = this.selectedCells.minRow = this.primaryCell.row;
      this.dragCopyMemory.clear();
    },
    touchStart(e: TouchEvent, rowIndex: number, columnIndex: number): void {
      if (this.lastTouch !== "" + rowIndex + columnIndex) {
        this.lastTouch = "" + rowIndex + columnIndex;
        setTimeout(() => {
          this.lastTouch = null;
        }, 300);
        return;
      }
      e.preventDefault();
      if (this.headers[columnIndex].type === "text")
        this.startEditMode(this.primaryCell.col, this.primaryCell.row, false);
    },

    mouseDown(e: MouseEvent, rowIndex: number, columnIndex: number): void {
      if (e.which !== 1) return;
      if (
        !this.isEditing ||
        this.primaryCell.col !== columnIndex ||
        this.primaryCell.row !== rowIndex
      ) {
        this.selectedCells.start.col = columnIndex;
        this.selectedCells.start.row = rowIndex;
        this.selectedCells.end.col = columnIndex;
        this.selectedCells.end.row = rowIndex;
        this.setPrimaryCell(columnIndex, rowIndex);

        this.mouseIsDown = true;
      }
    },
    documentMouseMove(e: MouseEvent): void {
      if (!this.mouseIsDown) return;
      let rect = this.virtualScrollerRef.$el.getBoundingClientRect();
      if (e.clientY > rect.bottom) {
        this.virtualScrollerRef.$el.scrollTop += 10;
      } else if (e.clientY < rect.top) {
        this.virtualScrollerRef.$el.scrollTop -= 10;
      }
    },
    mouseMove(e: MouseEvent, rowIndex: number, columnIndex: number): void {
      if (!this.mouseIsDown) return;

      if (!this.dragCopy) {
        if (columnIndex < this.primaryCell.col) {
          this.selectedCells.start.col = columnIndex;
          this.selectedCells.end.col = this.primaryCell.col;
        } else if (columnIndex > this.primaryCell.col) {
          this.selectedCells.start.col = this.primaryCell.col;
          this.selectedCells.end.col = columnIndex;
        } else {
          this.selectedCells.start.col = columnIndex;
          this.selectedCells.end.col = columnIndex;
        }
      }

      if (rowIndex < this.primaryCell.row) {
        this.selectedCells.start.row = rowIndex;
        this.selectedCells.end.row = this.primaryCell.row;
      } else if (rowIndex > this.primaryCell.row) {
        this.selectedCells.start.row = this.primaryCell.row;
        this.selectedCells.end.row = rowIndex;
      } else {
        this.selectedCells.start.row = rowIndex;
        this.selectedCells.end.row = rowIndex;
      }

      if (this.dragCopy) {
        this.selectedCells.maxRow = Math.max(
          this.selectedCells.maxRow,
          this.selectedCells.end.row
        );
        this.selectedCells.minRow = Math.min(
          this.selectedCells.minRow,
          this.selectedCells.start.row
        );
        let undoTransaction = {
          primaryCell: {
            row: this.primaryCell.row,
            key: this.headers[this.primaryCell.col].key,
          },
          values: [] as UndoTransactionValue[],
        };

        for (
          let i = this.selectedCells.minRow;
          i <= this.selectedCells.maxRow;
          i++
        ) {
          if (!this.dragCopyMemory.has(i))
            this.dragCopyMemory.set(
              i,
              this.internalObjects[i][
                this.headers[this.primaryCell.col].key as keyof HoldInputItem
              ]
            );

          undoTransaction.values.push({
            index: i,
            key: this.headers[this.primaryCell.col].key,
            value: this.dragCopyMemory.get(i),
          });
          this.setValue(
            i,
            this.headers[this.primaryCell.col].key,
            this.selectedCells.start.row <= i && this.selectedCells.end.row >= i
              ? this.internalObjects[this.primaryCell.row][
                  this.headers[this.primaryCell.col].key as keyof HoldInputItem
                ]
              : this.dragCopyMemory.get(i),
            true
          );
        }
        this.addToUndoBuffer(undoTransaction);
      }
    },
    selectColumn(index: number): void {
      this.selectedCells.start.col = index;
      this.selectedCells.start.row = 0;
      this.selectedCells.end.col = index;
      this.selectedCells.end.row = this.internalObjects.length - 1;
    },
    dblClick(e: MouseEvent, rowIndex: number, colIndex: number): void {
      if (this.headers[colIndex].cellAction !== undefined) {
        this.headers[colIndex].cellAction();
        return;
      }
      if (this.headers[colIndex].input === "text")
        this.startEditMode(colIndex, rowIndex, false);
    },
    cellBlur(e: any, row: number, col: number): void {
      const textContent = e.target.textContent as string;
      this.setValue(row, this.headers[col].key, textContent);
    },
    cellKeyDown(e: KeyboardEvent): void {
      if (
        this.disabled ||
        this.primaryCell.col === undefined ||
        this.primaryCell === undefined
      )
        return;

      switch (e.key) {
        case "Tab":
          e.preventDefault();

          if (e.shiftKey && this.primaryCell.col > 0) {
            this.setPrimaryCell(this.primaryCell.col - 1, undefined);
          } else if (
            !e.shiftKey &&
            this.primaryCell.col < this.headers.length - 1
          ) {
            this.setPrimaryCell(this.primaryCell.col + 1, undefined);
          }

          break;

        case "Enter":
          e.preventDefault();

          if (this.autocompleteItems.length) {
            const item =
              this.autocompleteItems.find((item) => item.focus) ||
              this.autocompleteItems.find((_) => true);

            this.fillWithItem(item);
            return;
          }

          this.setPrimaryCell(undefined, this.primaryCell.row + 1);

          break;
        case "Escape":
          e.preventDefault();
          this.stopEditMode();
          this.resetSelectedCells();
          this.primaryCell.row = this.primaryCell.col = undefined;

          break;
        case "ArrowUp":
          e.preventDefault();
          if (this.autocompleteItems.length) {
            let index = this.autocompleteItems.findIndex((item) => item.focus);
            if (index >= 0) {
              this.autocompleteItems[index].focus = false;
              index -= 1;
              if (index >= 0) {
                this.autocompleteItems[index].focus = true;
              }
              return;
            }
          }
          if (e.shiftKey) {
            this.shiftSelectedArea(-1, 0);
            return;
          }
          this.setPrimaryCell(undefined, Math.max(this.primaryCell.row - 1, 0));
          break;
        case "ArrowDown":
          e.preventDefault();
          if (this.autocompleteItems.length) {
            let index = this.autocompleteItems.findIndex((item) => item.focus);
            if (index >= 0) {
              this.autocompleteItems[index].focus = false;
            }
            index = index < 0 ? 0 : index + 1;
            if (index < this.autocompleteItems.length) {
              this.autocompleteItems[index].focus = true;
              return;
            }
          }
          if (e.shiftKey) {
            this.shiftSelectedArea(1, 0);
            return;
          }
          this.setPrimaryCell(
            undefined,
            Math.min(this.primaryCell.row + 1, this.internalObjects.length - 1)
          );
          break;

        case "ArrowRight":
          if (this.isEditing) return;
          e.preventDefault();
          if (e.shiftKey) {
            this.shiftSelectedArea(0, 1);
            return;
          }
          this.setPrimaryCell(
            Math.min(this.primaryCell.col + 1, this.headers.length - 1),
            undefined
          );

          break;

        case "ArrowLeft":
          if (this.isEditing) return;
          e.preventDefault();
          if (e.shiftKey) {
            this.shiftSelectedArea(0, -1);
            return;
          }
          this.setPrimaryCell(Math.max(this.primaryCell.col - 1, 0), undefined);
          break;
        case "z":
          if (e.ctrlKey || e.metaKey) {
            if (this.isEditing) this.stopEditMode();
            this.undo();
            return;
          }

        default:
          if ((e.ctrlKey || e.metaKey) && !this.isEditing) {
            this.eventDivRef.focus({ preventScroll: true });
            return;
          }
          if (
            !this.isEditing &&
            (e.key === "Backspace" || e.key === "Delete")
          ) {
            this.clearFields();
            break;
          }

          if (this.headers[this.primaryCell.col].cellAction) {
            this.headers[this.primaryCell.col].cellAction();
            return;
          }
          switch (this.headers[this.primaryCell.col].input) {
            case "checkbox":
              e.preventDefault();

              if (e.code === "Space") this.toggleBooleanFields();
              break;

            case "select":
              if (e.code === "Space") {
                let cells = this.virtualScrollerRef.$el.getElementsByClassName(
                  "primaryCell"
                );
                cells[cells.length - 1].click();
              }
              break;
            default:
              if (!this.isEditing && e.key.length === 1) {
                this.startEditMode(
                  this.primaryCell.col,
                  this.primaryCell.row,
                  true
                );
              }
              break;
          }
      }
    },
    shiftSelectedArea(row: number, col: number): void {
      if (row < 0) {
        if (this.selectedCells.end.row > this.primaryCell.row) {
          this.selectedCells.end.row = Math.max(
            this.selectedCells.end.row + row,
            0
          );
        } else {
          this.selectedCells.start.row = Math.max(
            this.selectedCells.start.row + row,
            0
          );
        }
      } else if (row > 0) {
        if (this.selectedCells.start.row < this.primaryCell.row) {
          this.selectedCells.start.row += row;
        } else {
          this.selectedCells.end.row += row;
        }
      }
      if (col < 0) {
        if (this.selectedCells.end.col > this.primaryCell.col) {
          this.selectedCells.end.col = Math.max(
            this.selectedCells.end.col + col,
            0
          );
        } else {
          this.selectedCells.start.col = Math.max(
            this.selectedCells.start.col + col,
            0
          );
        }
      } else if (col > 0) {
        if (this.selectedCells.start.col < this.primaryCell.col) {
          this.selectedCells.start.col = Math.min(
            this.selectedCells.start.col + col,
            this.headers.length - 1
          );
        } else {
          this.selectedCells.end.col = Math.min(
            this.selectedCells.end.col + col,
            this.headers.length - 1
          );
        }
      }
    },
    startEditMode(col: number, row: number, clearData: boolean): void {
      let cells = this.virtualScrollerRef.$el.getElementsByClassName(
        "primaryCell"
      );
      let cell = cells[cells.length - 1];

      if (this.headers[col].input !== "text" || !cell) return;
      if (document.createRange) {
        let range = document.createRange();
        range.selectNodeContents(cell.firstChild);
        range.collapse(false);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
      if (clearData) cell.firstChild.textContent = "";
      cell.firstChild.contentEditable = "true";
      cell.style.overflow = "visible";
      cell.firstChild.style.zIndex = "100";
      cell.firstChild.style.position = "absolute";
      // cell.firstChild.style.backgroundColor = "white";
      cell.firstChild.focus({ preventScroll: true });
      this.isEditing = true;
    },
    stopEditMode(): void {
      if (!this.isEditing) return;
      let cells = this.virtualScrollerRef.$el.getElementsByClassName(
        "primaryCell"
      );
      let cell = cells[cells.length - 1];
      if (!cell || !cell.firstChild.style) return;
      cell.firstChild.contentEditable = "inherit";
      cell.style.overflow = "hidden";
      cell.firstChild.style.zIndex = "auto";
      cell.firstChild.style.position = "static";
      cell.firstChild.style.backgroundColor = "transparent";
      (this.$el as any).focus({ preventScroll: true });

      // Unselect any selected text
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      this.isEditing = false;
    },
    toggleBooleanFields(): void {
      for (
        let i = this.selectedCells.start.row;
        i <= this.selectedCells.end.row;
        i++
      ) {
        for (
          let j = this.selectedCells.start.col;
          j <= this.selectedCells.end.col;
          j++
        ) {
          if (this.headers[j].type === "bool")
            this.setValue(
              i,
              this.headers[j].key,
              !(this.internalObjects[i] as any)[this.headers[j].key]
            );
        }
      }
    },
    clearFields(): void {
      for (
        let i = this.selectedCells.start.row;
        i <= this.selectedCells.end.row;
        i++
      ) {
        for (
          let j = this.selectedCells.start.col;
          j <= this.selectedCells.end.col;
          j++
        ) {
          this.setValue(i, this.headers[j].key, "");
        }
      }
    },
    pasteData(e: ClipboardEvent): void {
      e.preventDefault();
      this.stopEditMode();
      this.resetSelectedCells();

      let paste = (e.clipboardData || window.clipboardData).getData("text");
      let lines = paste.split("\n");
      let maxCol = 0;
      let maxRow = 0;
      let undoTransaction = {
        primaryCell: {
          row: this.primaryCell.row,
          key: this.headers[this.primaryCell.col].key,
        },
        values: [] as UndoTransactionValue[],
      };

      // Only trigger autocompletes if this is a paste to labels and only labels
      const fetchAutocomplete =
        this.headers[this.primaryCell.col].key === "label" &&
        lines[0].split("\t").length === 1;

      this.extendInternalObjects(lines.length);

      for (
        let i = 0;
        i < lines.length && this.primaryCell.row + i < MAX_ROWS;
        i++
      ) {
        let cols = lines[i].split("\t");

        for (let colIndex = cols.length - 1; colIndex >= 0; colIndex--) {
          let dimArr = cols[colIndex]
            .toString()
            .split(this.pasteColumnSplitPattern);
          if (dimArr.length === 3) {
            cols.splice(colIndex, 1, ...dimArr);
          }
        }

        for (
          let j = 0;
          j < cols.length && this.primaryCell.col + j < this.headers.length;
          j++
        ) {
          if (cols[j].length > 0) {
            const val = this.internalObjects[this.primaryCell.row + i][
              this.headers[this.primaryCell.col + j].key as keyof HoldInputItem
            ];
            undoTransaction.values.push({
              index: this.primaryCell.row + i,
              key: this.headers[this.primaryCell.col + j].key,
              value: val,
            });

            let parsedData = cols[j];
            switch (this.headers[this.primaryCell.col + j].type) {
              case "bool":
                parsedData = ["true", "y", "yes"].includes(
                  cols[j].toLowerCase()
                );
                break;
              case "list":
                parsedData = JSON.parse(cols[j]);
                break;
              case "integer":
                const number = parseInt(cols[j]);
                parsedData = isNaN(number) ? undefined : number;
                break;
            }
            this.setValue(
              this.primaryCell.row + i,
              this.headers[this.primaryCell.col + j].key,
              parsedData,
              true
            );
            if (fetchAutocomplete) {
              const matches = this.queryAutocomplete(parsedData);
              // Only trigger auto-complete if we only have a single match for this item
              if (matches.length === 1) {
                let newItem = this.autoToInternal(matches[0]);
                this.internalObjects[this.primaryCell.row + i] = newItem;
              }
            }

            maxRow = Math.max(maxRow, i);
            maxCol = Math.max(maxCol, j);
          }
        }
      }
      this.addToUndoBuffer(undoTransaction);
      this.selectedCells.start.col = this.primaryCell.col;
      this.selectedCells.start.row = this.primaryCell.row;
      this.selectedCells.end.col = this.primaryCell.col + maxCol;
      this.selectedCells.end.row = this.primaryCell.row + maxRow;
    },
    copyOrCutData(isCut: boolean): void {
      let output = "";
      let undoTransaction = {
        primaryCell: {
          row: this.primaryCell.row,
          key: this.headers[this.primaryCell.col].key,
        },
        values: [] as UndoTransactionValue[],
      };
      for (
        let i = this.selectedCells.start.row;
        i <= this.selectedCells.end.row;
        i++
      ) {
        for (
          let j = this.selectedCells.start.col;
          j <= this.selectedCells.end.col;
          j++
        ) {
          const val =
            this.internalObjects[i][
              this.headers[j].key as keyof HoldInputItem
            ] || "";

          if (val !== Object(val)) output += val + "\t";
          else output += JSON.stringify(val) + "\t";

          if (isCut) {
            undoTransaction.values.push({
              index: i,
              key: this.headers[j].key,
              value: val,
            });
            this.setValue(i, this.headers[j].key, "", true);
          }
        }
        output = output.replace(/.$/, "\n");
      }
      if (isCut) this.addToUndoBuffer(undoTransaction);
      (navigator as any).clipboard.writeText(output);
      (this.$el as any).focus({ preventScroll: true });
    },

    resetSelectedCells(): void {
      this.selectedCells.start.col = undefined;
      this.selectedCells.start.row = undefined;
      this.selectedCells.end.col = undefined;
      this.selectedCells.end.row = undefined;
    },
    insertEmptyRow(row: number): void {
      this.internalObjects = [
        ...this.internalObjects.slice(0, row),
        {} as HoldInputItemWithWarnings,
        ...this.internalObjects.slice(row, this.internalObjects.length),
      ].slice(0, MAX_ROWS);
    },
    deleteSelectedRows(): void {
      this.internalObjects.splice(
        this.selectedCells.start.row,
        this.selectedCells.end.row - this.selectedCells.start.row + 1
      );
    },
    mergeSimilarRows(): void {
      const merged = [] as HoldInputItemWithWarnings[];

      this.internalObjects
        .filter((i) => i.label && i.l && i.w && i.h && i.wt)
        .forEach((item, itemIndex) => {
          const index = merged.findIndex(
            (b: HoldInputItem) =>
              item.label === b.label &&
              item.l == b.l &&
              item.w == b.w &&
              item.h == b.h &&
              item.wt == b.wt
          );

          if (index > -1) {
            if (!isNaN(item.qty) && !isNaN(merged[index].qty))
              merged[index].qty =
                parseInt(merged[index].qty as any) + parseInt(item.qty as any);
          } else {
            merged.push(item);
          }
        });

      this.internalObjects = merged;
      this.extendInternalObjects();
    },
    deleteAllRows(): void {
      this.internalObjects = [];
      this.extendInternalObjects();
    },
    setValue(
      index: number,
      key: string,
      value: string | number | boolean | number[],
      doNotAddToUndoBuffer: boolean = false
    ): void {
      if (!doNotAddToUndoBuffer) {
        this.addToUndoBuffer({
          primaryCell: {
            row: index,
            key: key,
          },
          values: [
            {
              index: index,
              key: key,
              value: this.internalObjects[index][key as keyof HoldInputItem],
            },
          ],
        });

        if (this.undoBuffer.length > 20) this.undoBuffer.shift();
      }

      this.$set(this.internalObjects[index], key, value);
    },
    addToUndoBuffer(o: UndoTransaction): void {
      this.undoBuffer.push(o);
      if (this.undoBuffer.length > 20) this.undoBuffer.shift();
    },
    undo() {
      if (this.undoBuffer.length) {
        let obj = this.undoBuffer.pop();
        obj.values.forEach((i) => {
          this.setValue(i.index, i.key, i.value, true);
        });

        this.primaryCell = {
          row: obj.primaryCell.row,
          col: this.headers.findIndex((i) => obj.primaryCell.key === i.key),
        };
        this.resetSelectedCells();
      }
    },
    setVisibleCell(): void {
      let cells = this.virtualScrollerRef.$el.getElementsByClassName(
        "primaryCell"
      );
      if (cells.length > 0) {
        let cellRect = cells[cells.length - 1].getBoundingClientRect();
        let parentRect = this.$el.getBoundingClientRect();
        // If at bottom
        if (cellRect.bottom > parentRect.bottom - cellRect.height)
          this.virtualScrollerRef.$el.scrollTop += cellRect.height;
        // If at top
        else if (cellRect.top < parentRect.top + cellRect.height)
          this.virtualScrollerRef.$el.scrollTop -= cellRect.height;
        // If at right
        if (cellRect.right > parentRect.right)
          this.wrapperRef.scrollLeft += cellRect.width;
        // If at left
        else if (cellRect.left < parentRect.left)
          this.wrapperRef.scrollLeft -= cellRect.width;
      }
    },
    sortByKey(key: string): void {
      const sortItems = require("vuetify/lib/util/helpers.js").sortItems;
      this.internalObjects = sortItems(
        [...this.internalObjects],
        [key],
        [true]
      );
    },
    inputActions(event: any, column: string, item: HoldInputItem): void {
      if (column === "label") {
        this.autocomplete(event, column);
      }
      if (item.geometry == "cylinder") {
        if (column === "l") item.w = event.target.textContent;
        if (column === "w") item.l = event.target.textContent;
      }
    },
    autocomplete(event: any, column: string): void {
      if (this.disableAutocomplete) {
        return;
      }
      this.autocompleteItems = [];
      this.query = event.target.textContent.trim();
      if (column === "label" && this.query.length) {
        this.autocompleteItems = this.queryAutocomplete(this.query)
          .sort((a, b) => a.query_match - b.query_match)
          .slice(0, 3);
      }
    },
    queryAutocomplete(query: String): AutocompleteItem[] {
      let lower = query.toLowerCase().trim();
      const cargosWithLabels = this.cargoes
        .map((item) => {
          return {
            ...item,
            focus: false,
            query_match: item.name.toLowerCase().indexOf(lower),
            prop: "name",
          };
        })
        .filter(({ query_match }) => query_match >= 0);
      return [
        ...cargosWithLabels,
        ...this.cargoes
          .filter((c) => c.sku && !cargosWithLabels.some((i) => i.id == c.id))
          .map((item) => {
            return {
              ...item,
              focus: false,
              query_match: item.sku.toLowerCase().indexOf(lower),
              prop: "sku",
            };
          })
          .filter(({ query_match }) => query_match >= 0),
      ];
    },
    fillWithItem(item: AutocompleteItem): void {
      this.autocompleteItems = [];

      const newItem = this.autoToInternal(item);
      if (this.internalObjects.length <= this.primaryCell.row) {
        this.internalObjects = [...this.internalObjects, newItem];
      } else {
        this.internalObjects[this.primaryCell.row] = newItem;
      }
    },
    autoToInternal(item: AutocompleteItem): HoldInputItemWithWarnings {
      let newItem = JSON.parse(JSON.stringify(item.data)) as HoldInputItem;

      let l = item.data.l;
      let w = item.data.w;
      let h = item.data.h;
      let wt = item.data.wt;
      const from_container = JSON.parse(
        JSON.stringify(item.data.from_container)
      );
      if (
        this.lengthDim &&
        item.length_dim &&
        this.lengthDim != item.length_dim
      ) {
        // convert lengths
        let lengthFactor =
          this.$toSI(item.length_dim) / this.$toSI(this.lengthDim);
        l *= lengthFactor;
        w *= lengthFactor;
        h *= lengthFactor;
      }
      if (
        this.weightDim &&
        item.weight_dim &&
        this.weightDim != item.weight_dim
      ) {
        // convert weights
        let weightFactor =
          this.$toSI(item.weight_dim) / this.$toSI(this.weightDim);
        wt *= weightFactor;
      }
      Object.keys(newItem).forEach((key) => {
        if (
          newItem[key as keyof HoldInputItem] ===
          Object(newItem[key as keyof HoldInputItem])
        )
          newItem[key as keyof HoldInputItem] = JSON.stringify(
            newItem[key as keyof HoldInputItem]
          ) as never;
      });
      newItem = {
        ...newItem,
        from_container,
        l,
        w,
        h,
        wt,
      };
      return newItem as HoldInputItemWithWarnings;
    },
  },
});
