import { Header, InputDataTable, ValueRow } from "@/models/InputDataModel";

export default {
  loadTableToJson(
    tableElement: HTMLTableElement,
    unloaded = false
  ): InputDataTable {
    const headers = [] as Header[][];
    const dataRows = [] as ValueRow[];
    const rows = Array.from(tableElement.rows);
    let totalRow = {} as ValueRow;

    rows
      .filter((row) => row.cells.length > 0 && row.cells[0].tagName == "TH")
      .forEach((row) => {
        const header = Array.from(row.cells).map((cell) => {
          const colspan = Number(cell.getAttribute("colspan"));
          return {
            value: cell.innerText.trim(),
            colspan: isNaN(colspan) ? 1 : colspan || 1,
          };
        });
        headers.push(header);
      });

    if (headers.length > 0) {
      rows
        .filter((row) => row.cells.length > 0 && row.cells[0].tagName == "TD")
        .forEach((row) => {
          const values = Array.from(row.cells).map((cell) => {
            const value = cell.innerText.trim();
            if (value === "" || value === "<!---->") {
              return null;
            } else {
              return Number.isNaN(Number(value)) ? value : Number(value);
            }
          });
          const rowData = { isFirstChild: false, values };
          if (row.parentElement.firstChild === row) {
            rowData.isFirstChild = true;
          }
          if (rowData.values[1] == null) {
            totalRow = rowData;
          } else {
            dataRows.push(rowData);
          }
        });
    }
    return {
      headerRows: headers,
      valuesRows: dataRows,
      totalRow: totalRow,
      unloaded,
    };
  },
};
