































































import Vue from "vue";
import API from "@/API";
import { LoadlistStore, UserCompanyStore } from "@/store/index";
import CalcModal from "@/components/Modals/Calculation.vue";
import unloadedItemsComponent from "@/components/Custom/UnloadedItems.vue";
import LoadlistInfoComponent from "@/components/LoadlistDetail/LoadlistInfo.vue";
import { Loadlist, Loadplan } from "@/models/LoadlistModel";

export default Vue.extend({
  name: "loadlist",
  components: {
    CalcModal,
    unloadedItemsComponent,
    LoadlistInfoComponent,
  },
  data() {
    return {
      isLoading: false,
      showShareDialog: false,
      sharableURL: null,
    };
  },
  computed: {
    requiredInput(): boolean {
      return !!(
        this.loadlist?.data?.length &&
        this.loadlist?.weight_dim &&
        this.loadlist?.length_dim
      );
    },
    has_loadplan(): boolean {
      return this.loadplan && this.loadplan.holds.length > 0;
    },
    showUnloadedItemsView(): boolean {
      return (
        !!this.loadplan?.holds &&
        ["detail", "workspace", "detail_set"].includes(this.$route.name)
      );
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    loadplan_version(): number {
      return LoadlistStore.loadplan_version;
    },
    loadlist_result_has_changed(): boolean {
      return LoadlistStore.loadlist_result_has_changed;
    },
    is_readonly(): boolean {
      return LoadlistStore.readonly || LoadlistStore.is_locked;
    },
    hide_navigation(): boolean {
      return !!this.$route.query.hide_navigation || this.is_readonly;
    },
    tabs(): {
      label: string;
      to: any;
      id: string;
      disabled: boolean;
      key: string;
    }[] {
      const tabs = [
        {
          label: "Data",
          to: { name: "loadlist" },
          id: "mainDataTab",
          key: "data",
          disabled: false,
        },
        {
          label: "Setup",
          disabled: !this.requiredInput,
          to: { name: "setup", params: { version: this.loadplan_version } },
          id: "mainEquipmentTab",
          key: "setup",
        },
        {
          label: "Workspace",
          disabled: !this.requiredInput,
          to: { name: "workspace", params: { version: this.loadplan_version } },
          id: "mainWorkspaceTab",
          key: "workspace",
        },
        {
          label: "Load plan",
          disabled: !this.has_loadplan,
          to: {
            name: "loadplan",
            params: { version: this.loadplan_version, page: 0 },
          },
          id: "mainLoadplanTab",
          key: "loadplan",
        },
      ];
      if (Vue.prototype.$navigation?.length > 0) {
        return tabs
          .filter((t) => Vue.prototype.$navigation.indexOf(t.key) >= 0)
          .map((t) => {
            return { ...t, to: { ...t.to } };
          });
      }
      return tabs;
    },
  },
  created(): void {
    this.isLoading = true;

    if (!this.$route.query.no_fetch)
      this.getLoadlist(this.$route.params.id)
        .then((success) => {
          this.isLoading = false;
          LoadlistStore.setLoadplanVersion(
            parseInt(this.$route.params.version) || 0
          );
          // Reroute to loadplan view if locked
          if (this.is_readonly && this.$route.name !== "loadplan")
            this.$router.replace({
              name: "loadplan",
              params: { version: String(this.loadplan_version), page: "0" },
            });

          if (!this.is_authenticated) {
            API.instance.defaults.headers.common["Loadlist"] = this.loadlist.id;
            UserCompanyStore.setUserPreference({
              key: "length_dim",
              value: this.$route.query.length_dim || "CM",
            });
            UserCompanyStore.setUserPreference({
              key: "weight_dim",
              value: this.$route.query.weight_dim || "KG",
            });
          }
        })
        .catch((_) => {
          this.logout();
        });
  },
  beforeDestroy() {
    if (this.loadlist_result_has_changed) this.saveLoadlistResult();
  },
  destroyed() {
    this.clearLoadlist();
    delete API.instance.defaults.headers.common["Loadlist"];
  },
  methods: {
    expandUnloadedItems(): void {
      (this.$refs.unloadedItemsComponent as any).expand();
    },
    getLoadlist(id: string): Promise<Loadlist> {
      return LoadlistStore.getLoadlist(id);
    },
    saveLoadlistResult(): void {
      LoadlistStore.saveLoadlistResult();
    },
    clearLoadlist(): void {
      LoadlistStore.clearLoadlist();
    },
    logout(): void {
      UserCompanyStore.logout(null);
    },
  },
});
