


























import { HoldData, HoldInputItem, LoadRule } from "@/models/LoadlistModel";
import Vue, { PropType } from "vue";
import LoadRuleComponent from "./LoadRule.vue";
export default Vue.extend({
  name: "custom-rules-editor",
  components: {
    LoadRuleComponent,
  },
  data: function() {
    return {};
  },
  props: {
    rules: Array as PropType<LoadRule[]>,
    typeName: String,
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
  },
  computed: {},
  mounted(): void {},
  methods: {
    addRule(): void {
      this.$emit("addRule", {
        items: {
          property: "label",
          comparison: "eq",
          value: 0,
        },
        condition: "cannot_be_loaded_in",
        value: null,
        in_containers_with_ids: [],
      } as LoadRule);
    },
    removeRule(i: number): void {
      this.$emit("removeRule", i);
    },
  },
});
