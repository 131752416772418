
















































import Vue, { PropType } from "vue";

import { AugmentedHold } from "@/models/augmented/hold";
import sceneComponent from "@/components/Custom/SceneComponent.vue";

export default Vue.extend({
  name: "loadplan-instructions",
  components: {
    sceneComponent,
  },
  props: {
    container: Object as PropType<AugmentedHold>,
  },
});
