





















































































































import { Configuration, SelectedContainerInfo, SetSlot, SetType } from "@/models/SetsModel";
import lengthInputFieldComponent from "@/components/Custom/LengthInputField.vue";
import inlineHoldEditComponent from "@/components/Custom/InlineHoldEdit.vue";
import selectContainerDropdownComponent from "@/components/SetBuilder/SelectContainerDropdown.vue";
import Vue, { PropType } from "vue";
import { VBtn, VCardActions, VCardText, VCol, VIcon, VRow, VSpacer, VTextField } from "vuetify/lib";
import { HoldData, HoldType } from "@/models/LoadlistModel";
export default Vue.extend({
  name: "set_container_details",
  props: {
    set: Object as PropType<SetType>,
    containerInfo: Object as PropType<SelectedContainerInfo>,
    displayConfiguration: Array as PropType<number[]>
  },
  components: {
    lengthInputFieldComponent,
    inlineHoldEditComponent,
    selectContainerDropdownComponent
  },
  data() {
    return {
      showInlineEdit: false,
      holdData: undefined as HoldData,
      slot: undefined as SetSlot,
      inlineEdit: undefined as {
        ht: HoldType;
        hold: HoldData;
      },
    }
  },
  computed: {
    typeName(): string {
      return this.$typeNames(this.set.base_type);
    },
  },
  methods: {
    deleteContainer() {
      this.$emit('delete', {
        slotIndex: this.containerInfo.slotIndex,
        holdType: this.containerInfo.holdType
      })
    },
    displayedSlotConfiguration(slot_index: number): Configuration {
      return this.set.data.slots[slot_index].configurations[
        this.displayConfiguration[slot_index]
      ];
    },
    updateContainerType(holdData: HoldData) {
      const holdType = this.displayedSlotConfiguration(this.containerInfo.slotIndex).group.find(ht => ht === this.containerInfo.holdType)
        if (holdType) {
          if (!this.set.data.container_types.find((ct) => ct.id === holdData.id)) {
            this.set.data.container_types = [...this.set.data.container_types, holdData]
          }
          holdType.container_type_id = holdData.id
        }
      this.holdData = holdData
      this.rerender()
    },
    openInlineEdit(ht: HoldType) {
      this.inlineEdit = {
        ht,
        hold: this.containerOf(ht),
      };
      this.showInlineEdit = true;
    },
    saveInlineEdit() {
      const original = this.set.data.container_types.find(
        (ct) => ct.id == this.inlineEdit.ht.container_type_id
      );

      if (!this.inlineEdit.ht.props) {
        this.inlineEdit.ht.props = {};
      }

      const hold = this.inlineEdit.hold;
      if (hold.L != original.L) {
        this.inlineEdit.ht.props.L = hold.L;
      }
      if (hold.W != original.W) {
        this.inlineEdit.ht.props.W = hold.W;
      }
      if (hold.H != original.H) {
        this.inlineEdit.ht.props.H = hold.H;
      }
      if (hold.payload != original.payload) {
        this.inlineEdit.ht.props.payload = hold.payload;
      }
      if (hold.max_height != original.max_height) {
        this.inlineEdit.ht.props.max_height = hold.max_height;
      }
      if (hold.max_length != original.max_length) {
        this.inlineEdit.ht.props.max_length = hold.max_length;
      }
      if (hold.max_width != original.max_width) {
        this.inlineEdit.ht.props.max_width = hold.max_width;
      }
      // Remove props if nothing has been changed
      if (Object.entries(this.inlineEdit.ht.props).length == 0) {
        this.inlineEdit.ht.props = undefined;
      }

      this.rerender()

      this.inlineEdit = undefined;
      this.showInlineEdit = false;
    },
    containerOf(ht: HoldType): HoldData {
      const hold = this.set.data.container_types.find(
        (ct) => ct.id === ht.container_type_id
      );
      if (hold) {
        return {
          ...this.set.data.container_types.find(
            (ct) => ct.id === ht.container_type_id
          ),
          ...(ht.props
            ? Object.fromEntries(
                Object.entries(ht.props).filter(([_, v]) => v != null)
              )
            : ht.props),
        };
      }
      return undefined;
    },
    updateSlot(newSlot: SetSlot) {
      this.$emit('updateSlot', {
        oldSlotIndex: this.containerInfo.slotIndex,
        newSlotIndex: this.set.data.slots.indexOf(newSlot),
        holdType: this.containerInfo.holdType
      })
    },
    rerender() {
      this.$emit('rerender')
    }
  },
  watch: {
    containerInfo: {
      handler: function(val: SelectedContainerInfo): void {
        if(val) {
          this.slot = this.set.data.slots[val.slotIndex]
          this.holdData = this.set.data.container_types.find(c => c.id === val.holdType.container_type_id)
        }
      },
      immediate: true
    },
  }
})

