





































































































































































































































































































import Vue, { PropType } from "vue";
import ItemProperties, {
  CustomItemProperty,
  ItemProperty,
} from "@/misc/itemProperties";
import dimTools from "@/misc/dimTools";
import {
  HoldDataWithIndices,
  HoldItem,
  Loadlist,
  Loadplan,
} from "@/models/LoadlistModel";
import { LoadlistStore } from "@/store/index";
import { AugmentedHold } from "@/models/augmented/hold";
import itemUtils from "@/misc/itemUtils";
import { CompanySettings } from "@/models/UserCompanyModel";

export default Vue.extend({
  name: "loadplan-table",
  props: {
    container: {
      type: Object as PropType<AugmentedHold>,
    },
    presentationSettings: {
      type: Object as PropType<CompanySettings>,
      default: null,
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    lengthDims: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    weightDims: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    additionalLengthDim: {
      type: String,
      default: null,
    },
    additionalWeightDim: {
      type: String,
      default: null,
    },
  },

  computed: {
    bundledItems(): HoldItem[][] {
      if (this.container.items_count > 0)
        return itemUtils.bundledItems(this.container.cargoesInLayerOrder);
      return itemUtils.bundledItems(this.container.cargoes);
    },
    defaultColumns(): ItemProperty[] {
      let columns = this.columns;
      this.lengthDims.forEach((dim) => {
        let additional = [
          "l#additional$" + dim,
          "w#additional$" + dim,
          "h#additional$" + dim,
        ];

        let index = columns.findIndex((i) => i.startsWith("wt#additional"));
        if (index < 0)
          index = columns.findIndex((i) => i.startsWith("h#additional"));
        if (index < 0) index = columns.indexOf("wt");
        if (index < 0) index = columns.indexOf("h");

        if (index >= 0) {
          columns = [
            ...columns.slice(0, index + 1),
            ...additional,
            ...columns.slice(index + 1),
          ];
        }
      });
      this.weightDims.forEach((dim) => {
        let additional = "wt#additional$" + dim;
        let index = columns.findIndex((i) => i.startsWith("h#additional"));
        if (index < 0) index = columns.indexOf("h");
        if (index >= 0) {
          columns = [
            ...columns.slice(0, index + 1),
            additional,
            ...columns.slice(index + 1),
          ];
        }
      });

      return columns
        .map((i) => {
          return (
            ItemProperties.props().find((j) => j.key == i) || {
              dim: i.substring(i.indexOf("$") + 1),
              ...ItemProperties.props().find(
                (j: any) => j.key.indexOf("#") >= 0 && i.startsWith(j.key)
              ),
            }
          );
        })
        .filter((i) => !!i);
    },
    customColumns(): CustomItemProperty[] {
      return this.presentationSettings?.extra_columns || [];
    },
    customMetrics(): any[] {
      return this.presentationSettings?.metrics || [];
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
  },
  filters: {
    uppercase(val: string): string {
      return val ? val.toUpperCase() : "";
    },
    roundDim(val: number, dim: string): number {
      return dimTools.roundForDim(val, dim);
    },
  },
  methods: {
    valueForDim(value: number, dim: string): number {
      return dimTools.roundForDim(value * dimTools.fromSI(dim), dim);
    },
    toLengthDim(value: number, dim: string): number {
      return this.valueForDim(
        dimTools.toSI(this.loadlist.length_dim) * value,
        dim
      );
    },
    toWeightDim(value: number, dim: string): number {
      return this.valueForDim(value, dim);
    },
    toLoadlistDim(value: number): number {
      return this.valueForDim(value, this.loadlist.weight_dim);
    },
    chargableWeight(item: HoldItem): number {
      return Math.max(
        item.WT,
        item.L * item.W * item.H * this.loadplan.settings.shipping_factor
      );
    },
  },
});
