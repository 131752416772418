










import Vue from "vue";
export default Vue.extend({
  name: "profile",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
});
