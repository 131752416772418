var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editableName)?_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.modified_hold.name),callback:function ($$v) {_vm.$set(_vm.modified_hold, "name", $$v)},expression:"modified_hold.name"}}):_vm._e(),_c('div',[(!_vm.hideQtyCost)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_vm._t("view",null,null,{ renderKey: _vm.renderKey })],2),_c('v-col',[_c('v-text-field',{attrs:{"label":"Quantity:","type":"number","hint":("Number of " + _vm.typeName + "s available"),"min":"0","value":_vm.modified_hold.qty},on:{"input":function($event){return _vm.setQty($event)}}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Cost [$, €, £, ...]:","type":"number","hint":("A number, relative to other " + _vm.typeName + "s"),"value":_vm.modified_hold.cost},on:{"change":function($event){_vm.$set(_vm.modified_hold, 'cost', parseFloat($event) || null)}}})],1)],1):_vm._e(),(!_vm.hideDimensions)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('length-input-field',{attrs:{"label":"Length","min":0,"value":_vm.modified_hold.L},on:{"change":function($event){_vm.modified_hold.L = $event !== null ? $event : _vm.modified_hold.L}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('length-input-field',{attrs:{"label":"Width","min":0,"value":_vm.modified_hold.W},on:{"change":function($event){_vm.modified_hold.W = $event === null ? _vm.modified_hold.W : $event}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('length-input-field',{attrs:{"label":"Height","disabled":_vm.modified_hold.no_side_walls &&
              _vm.modified_hold.no_end_walls &&
              _vm.modified_hold.no_roof,"min":0},on:{"change":function($event){_vm.modified_hold.H = $event === null ? _vm.modified_hold.H : $event}},model:{value:(_vm.modified_hold.H),callback:function ($$v) {_vm.$set(_vm.modified_hold, "H", $$v)},expression:"modified_hold.H"}})],1)],1):_vm._e(),(_vm.basetype === 'ROAD' && !_vm.hideAxles)?_c('div',[(
          _vm.modified_hold.axles &&
            !isNaN(_vm.modified_hold.axles.front_axle_x) &&
            !isNaN(_vm.modified_hold.axles.rear_axle_x)
        )?[_c('p',{staticClass:"py-0 my-0 subtitle-1"},[_vm._v("Axle group weight limitations:")]),_c('v-row',[_c('v-col',[_c('weight-input-field',{attrs:{"label":("Front " + (_vm.modified_hold.tare ? '' : '(net)')),"rules":[
                function (v) { return (_vm.modified_hold.tare || 0) <
                    (v || 0) +
                      (_vm.modified_hold.axles.rear_axle_max_weight || 0) ||
                  'Axle weights is less than tare'; } ],"min":0,"placemodified_holder":"Weight","value":_vm.modified_hold.axles.front_axle_max_weight,"persistent-hint":""},on:{"change":function($event){return _vm.$set(
                  _vm.modified_hold.axles,
                  'front_axle_max_weight',
                  $event || null
                )}}})],1),_c('v-col',[_c('weight-input-field',{attrs:{"label":("Rear " + (_vm.modified_hold.tare ? '' : ' (net)')),"min":0,"rules":[
                function (v) {
                  return (
                    (_vm.modified_hold.tare || 0) <
                      (v || 0) +
                        (_vm.modified_hold.axles.front_axle_max_weight || 0) ||
                    "Axle weights is less than tare"
                  );
                } ],"placemodified_holder":"Weight","value":_vm.modified_hold.axles.rear_axle_max_weight,"persistent-hint":""},on:{"change":function($event){return _vm.$set(
                  _vm.modified_hold.axles,
                  'rear_axle_max_weight',
                  $event || null
                )}}})],1)],1)]:_vm._e()],2):_vm._e(),(_vm.basetype === 'AIR' && !_vm.hideBottomSpacer)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('length-input-field',{attrs:{"label":"Auto bottom spacer height","min":0,"placemodified_holder":"Height","value":_vm.modified_hold.contours
              ? _vm.modified_hold.contours.auto_bottom_spacer_h
              : null,"hint":"The tool will check if more cargoes can be loaded by raising the floor to specified height"},on:{"change":function($event){_vm.$set(_vm.modified_hold, 'contours', _vm.modified_hold.contours || {});
            _vm.$set(
              _vm.modified_hold.contours,
              'auto_bottom_spacer_h',
              $event || null
            );}}})],1)],1):_vm._e(),(!_vm.hideMaxValues)?_c('div',[_c('v-row',[_c('v-col',[_c('length-input-field',{attrs:{"label":"Max Length","min":0,"value":_vm.modified_hold.max_length},on:{"change":function($event){return _vm.$set(_vm.modified_hold, 'max_length', $event || null)}}})],1),_c('v-col',[_c('length-input-field',{attrs:{"label":"Max Width","min":0,"value":_vm.modified_hold.max_width},on:{"change":function($event){return _vm.$set(_vm.modified_hold, 'max_width', $event || null)}}})],1),_c('v-col',[_c('length-input-field',{attrs:{"label":"Max Height","min":0,"value":_vm.modified_hold.max_height},on:{"change":function($event){return _vm.$set(_vm.modified_hold, 'max_height', $event || null)}}})],1)],1),_c('v-row',[_c('v-col',[_c('weight-input-field',{attrs:{"label":"Payload","min":0},model:{value:(_vm.modified_hold.payload),callback:function ($$v) {_vm.$set(_vm.modified_hold, "payload", $$v)},expression:"modified_hold.payload"}})],1),_c('v-col',[_c('length-input-field',{attrs:{"label":"Max Volume","sup":3,"min":0,"value":_vm.modified_hold.max_volume},on:{"change":function($event){_vm.$set(_vm.modified_hold, 'max_volume', parseFloat($event) || null)}}})],1)],1)],1):_vm._e(),(_vm.basetype === 'ROAD')?_c('v-row',[_c('v-col',[_c('weight-input-field',{attrs:{"label":"Legal weight","min":0,"placemodified_holder":"Weight","value":(_vm.modified_hold.legal_limits || {}).weight,"hint":("If one cargo exceeds legal weight then no other items will be co loaded on the same " + _vm.typeName)},on:{"change":function($event){_vm.$set(
              _vm.modified_hold,
              'legal_limits',
              _vm.modified_hold.legal_limits || {}
            );
            _vm.$set(_vm.modified_hold.legal_limits, 'weight', $event || null);}}})],1),_c('v-col',[_c('length-input-field',{attrs:{"label":"Legal Height","min":0,"value":(_vm.modified_hold.legal_limits || {}).height,"hint":"Non-divisible loads can be loaded above the legal height, up to max height"},on:{"change":function($event){_vm.$set(
              _vm.modified_hold,
              'legal_limits',
              _vm.modified_hold.legal_limits || {}
            );
            _vm.$set(_vm.modified_hold.legal_limits, 'height', $event || null);}}})],1)],1):_vm._e(),(_vm.showSubHoldProps)?_c('v-row',_vm._l((_vm.subHoldPropertiesList),function(prop){return _c('v-col',{key:prop.key},[_c('v-checkbox',{attrs:{"label":prop.text,"input-value":_vm.modified_hold.properties
              ? !!_vm.modified_hold.properties[prop.key]
              : false},on:{"change":function($event){return _vm.setSubHoldProperty(prop.key, $event)}}})],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }