



























































































































































/* eslint-disable */
import Vue from "vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import { SceneManager } from "@/graphics/sceneManager";
import API from "@/API";
import { EquipmentStore, UserCompanyStore } from "@/store/index";
import { Hold, HoldData, ListType } from "@/models/LoadlistModel";
import { User } from "@/models/UserCompanyModel";
import ContainerEditor from "./Custom/ContainerEditor/index.vue";
import { getSerializerError } from "@/misc/errorUtils";

export default Vue.extend({
  name: "modify_hold",
  components: {
    sceneComponent,
    ContainerEditor,
  },
  data() {
    return {
      isLoading: false,
      nameRules: [
        (v: string) => !!v || "A name is required",
        (v: string) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      validForm: false,
      showDeleteModal: false,
      showCopyModal: false,
      showSupportModal: false,
      copyName: "",
      nameError: false,
      renderKey: 1,
      currentHold: {} as Hold,
      active_tab: 0,
      showSnackbar: false,
      error_message: undefined as string,
    };
  },
  computed: {
    base_type(): ListType {
      return (this.$route.params.type || "SEA") as ListType;
    },
    hold(): HoldData {
      return [...EquipmentStore.systemHolds, ...EquipmentStore.userHolds].find(
        (h) => h.id === parseInt(this.$route.params.hold_id)
      );
    },

    isEditable(): boolean {
      return (
        (this.user.is_editor && !this.currentHold?.default) ||
        this.$route.query["edit"] == "true"
      );
    },
    user(): User {
      return UserCompanyStore.user;
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    length_dim(): string {
      return UserCompanyStore.length_dim;
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
  },
  watch: {
    hold: {
      handler: function(hold: Hold): void {
        if (hold) {
          this.currentHold = JSON.parse(JSON.stringify(hold));
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    SceneManager.clearScene();
  },
  mounted() {},
  methods: {
    onSubmit(): void {
      if ((this.$refs.builderform as any).validate()) {
        this.isLoading = true;

        let finalAssembly = {
          base_type: this.base_type,
          ...this.currentHold,
        } as Hold;

        const crud_operation = !finalAssembly.id
          ? API.createHold
          : API.updateHold;
        crud_operation(finalAssembly)
          .then((success) => {
            return EquipmentStore.getUserHolds();
          })
          .then((_) => {
            this.isLoading = false;
            this.$router.go(-1);
          })
          .catch((error) => {
            if (error?.response?.data) {
              this.error_message = getSerializerError(error.response.data);
            } else {
              this.error_message = "Unknown error when saving";
              console.log(error);
            }
            this.showSnackbar = true;
            this.isLoading = false;
          });
      }
    },
    deleteHold(): void {
      this.isLoading = true;

      API.deleteHold(this.currentHold.id)
        .then(() => {
          return EquipmentStore.getUserHolds();
        })
        .then((_) => {
          this.isLoading = false;
          this.$router.go(-1);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    copyHold(): void {
      var newHold = JSON.parse(JSON.stringify(this.currentHold));

      newHold.id = null;
      newHold.priority = 10000;
      newHold.default = false;
      newHold.name = this.copyName;

      this.isLoading = true;

      API.createHold(newHold)
        .then((data: any) => {
          this.isLoading = false;

          this.currentHold = { ...this.currentHold, ...data.data };

          this.$router.replace({
            name: "modify_hold",
            params: { hold_id: data.data.id, type: data.data.base_type },
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error?.response?.data) {
            this.error_message = getSerializerError(error.response.data);
          } else {
            this.error_message = "Unknown error when saving";
            console.log(error);
          }
          this.showSnackbar = true;
        });
    },
  },
});
