































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable */
import Vue, { PropType } from "vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import { SceneManager } from "@/graphics/sceneManager";
import createHold from "@/misc/containerUtils";
import { HoldData, HoldPart, ListType } from "@/models/LoadlistModel";
import { UserCompanyStore } from "@/store";
import EditPartComponent from "./EditPart.vue";
export default Vue.extend({
  name: "modify_hold",
  components: {
    sceneComponent,
    EditPartComponent,
  },
  props: {
    value: Object as PropType<HoldData>,
    base_type: {
      type: String,
      default: () => {
        return "SEA";
      },
    },
  },

  data() {
    return {
      validForm: false,
      showSupportModal: false,
      renderKey: 1,
      updating: false,
      currentHold: (() => {
        let tmp = createHold.defaults(this.base_type as ListType);
        return {
          ...tmp,
          base_type: this.base_type as ListType,
          contours: tmp.contours || {},
          axles: tmp.axles || {},
          tables: tmp.tables || {},
          parts: tmp.parts || [],
          legal_limits: tmp.legal_limits || {},
        };
      })() as HoldData,
      renderedHold: null,
      active_tab: 0,
    };
  },
  computed: {
    tabs(): { key: string; title: string; base_types: string[] }[] {
      return [
        {
          key: "general",
          title: "General",
          base_types: ["SEA", "ROAD", "AIR", "PALL"],
        },
        {
          key: "road",
          title: "Road",
          base_types: ["ROAD"],
        },
        {
          key: "contours",
          title: "Contours",
          base_types: ["SEA", "ROAD", "AIR", "PALL"],
        },
        {
          key: "components",
          title: "Components",
          base_types: ["SEA", "ROAD", "AIR", "PALL"],
        },
      ].filter((t) => t.base_types.indexOf(this.base_type) > -1);
    },
    onlyFloor(): boolean {
      return (
        this.currentHold.no_side_walls &&
        this.currentHold.no_end_walls &&
        this.currentHold.no_roof &&
        !this.currentHold.contours?.front_top_contour_l &&
        !this.currentHold.contours?.rear_top_contour_l &&
        !this.currentHold.contours?.side1_top_contour_l &&
        !this.currentHold.contours?.side2_top_contour_l
      );
    },
    noSideWalls(): boolean {
      return this.currentHold.no_side_walls;
    },
    noEndWalls(): boolean {
      return this.currentHold.no_end_walls;
    },
    length_dim(): string {
      return UserCompanyStore.length_dim;
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
    customParts(): any[] {
      return [
        {
          type: "Corners",
          items: [
            {
              title: "Bottom rear corners",
              data: [
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ["floor", "rear", "side1"],
                  not_stackable: true,
                },
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ["floor", "rear", "side2"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Bottom front corners",
              data: [
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ["floor", "front", "side1"],
                  not_stackable: true,
                },
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ["floor", "front", "side2"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Top rear corners",
              data: [
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ["roof", "rear", "side1"],
                  not_stackable: true,
                },
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ["roof", "rear", "side2"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Top front corners",
              data: [
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ["roof", "front", "side1"],
                  not_stackable: true,
                },
                {
                  type: "corner",
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ["roof", "front", "side2"],
                  not_stackable: true,
                },
              ],
            },
          ],
        },
        {
          type: "Frames",
          items: [
            {
              title: "Bottom end frames",
              data: [
                {
                  type: "frame",
                  H: 0.1,
                  L: 0.1,
                  stick_to: ["floor", "front", "side1", "side2"],
                  not_stackable: true,
                },
                {
                  type: "frame",
                  H: 0.1,
                  L: 0.1,
                  full_width: true,
                  stick_to: ["floor", "rear", "side1", "side2"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Bottom side frames",
              data: [
                {
                  type: "frame",
                  H: 0.1,
                  W: 0.1,
                  stick_to: ["floor", "side1", "front", "rear"],
                  not_stackable: true,
                },
                {
                  type: "frame",
                  H: 0.1,
                  W: 0.1,
                  stick_to: ["floor", "side2", "front", "rear"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Top end frames",
              data: [
                {
                  type: "frame",
                  H: 0.1,
                  L: 0.1,
                  full_width: true,
                  stick_to: ["roof", "front", "side1", "side2"],
                  not_stackable: true,
                },
                {
                  type: "frame",
                  H: 0.1,
                  L: 0.1,
                  full_width: true,
                  stick_to: ["roof", "rear", "side1", "side2"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Top side frames",
              data: [
                {
                  type: "frame",
                  H: 0.1,
                  W: 0.1,
                  full_length: true,
                  stick_to: ["roof", "side1", "front", "rear"],
                  not_stackable: true,
                },
                {
                  type: "frame",
                  H: 0.1,
                  W: 0.1,
                  full_length: true,
                  stick_to: ["roof", "side2", "front", "rear"],
                  not_stackable: true,
                },
              ],
            },
          ],
        },
        {
          type: "Walls",
          items: [
            {
              title: "Crosswise wall",
              data: [
                {
                  type: "wall",
                  pos: { x: this.currentHold.L * 0.5 },
                  L: 0.01,
                  stick_to: ["floor", "side1", "side2", "roof"],
                  not_stackable: true,
                },
              ],
            },
            {
              title: "Lengthwise wall",
              data: [
                {
                  type: "wall",
                  pos: { y: this.currentHold.W * 0.5 },
                  W: 0.1,
                  stick_to: ["floor", "roof", "front", "rear"],
                  not_stackable: true,
                },
              ],
            },
          ],
        },
        {
          type: "Floor",
          items: [
            {
              title: "Floor",
              data: [
                {
                  type: "floor",
                  pos: { z: this.currentHold.H * 0.5 },
                  H: 0.1,
                  stick_to: ["side1", "side2", "front", "rear"],
                },
              ],
            },
          ],
        },
        {
          type: "Custom",
          items: [
            {
              title: "Custom part",
              data: [
                {
                  type: "custom",
                  pos: {
                    x: this.currentHold.L * 0.5,
                    y: this.currentHold.W * 0.5,
                    z: this.currentHold.H * 0.5,
                  },
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                },
              ],
            },
          ],
        },
      ];
    },
  },
  watch: {
    value: {
      handler: function(v: HoldData) {
        if(v) {
          this.currentHold.name = v.name
          if (this.currentHold.id != v.id) {
            this.updateCurrentHold(v);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    currentHold: {
      handler: function(val: HoldData): void {
        this.assembleHold(JSON.parse(JSON.stringify(val)));

        this.$emit("input", {
          ...this.renderedHold,
          cameraSettings: undefined,
        });
      },
      deep: true,
      immediate: true,
    },
    onlyFloor: {
      handler: function(val: boolean): void {
        if (val) this.$set(this.currentHold, "H", 0);
      },
    },
    "currentHold.no_end_walls": {
      handler: function(val: boolean): void {
        if (val && this.currentHold.contours) {
          this.$set(this.currentHold.contours, "front_bottom_contour_h", 0);
          this.$set(this.currentHold.contours, "front_bottom_contour_l", 0);
          this.$set(this.currentHold.contours, "rear_bottom_contour_h", 0);
          this.$set(this.currentHold.contours, "rear_bottom_contour_l", 0);
        }
      },
    },
    "currentHold.no_side_walls": {
      handler: function(val: boolean): void {
        if (val && this.currentHold.contours) {
          this.$set(this.currentHold.contours, "side1_bottom_contour_h", 0);
          this.$set(this.currentHold.contours, "side1_bottom_contour_l", 0);
          this.$set(this.currentHold.contours, "side2_bottom_contour_h", 0);
          this.$set(this.currentHold.contours, "side2_bottom_contour_l", 0);
        }
      },
    },
  },
  beforeDestroy() {
    SceneManager.clearScene();
  },
  mounted() {
    if (!localStorage.getItem("cplHasSeenHoldSupport")) {
      this.showSupportModal = true;
      localStorage.setItem("cplHasSeenHoldSupport", "true");
    }
  },
  methods: {
    assembleHold(hold: HoldData): void {
      let assembled_hold = createHold.assemble(hold);
      // Remove unused fields
      Object.keys(assembled_hold)
        .filter((key) => {
          let k = key as keyof HoldData;
          return (
            assembled_hold[k] &&
            assembled_hold[k].constructor === Object &&
            !Object.values(assembled_hold[k]).some((v) => {
              if (Array.isArray(v)) {
                return v.length;
              } else {
                return !!v;
              }
            })
          );
        })
        .forEach((key: keyof HoldData) => {
          delete assembled_hold[key];
        });
      this.renderedHold = assembled_hold;
      this.renderKey++;
    },
    updateCurrentHold(v: HoldData): void {
      // console.log("Updating current hold");
      let tmp = JSON.parse(JSON.stringify(v));
      this.currentHold = {
        ...tmp,
        contours: tmp.contours || {},
        axles: tmp.axles || {},
        tables: tmp.tables || {},
        parts: tmp.parts || [],
        legal_limits: tmp.legal_limits || {},
      };
    },
    async sleep(ms: number): Promise<void> {
      return new Promise((r) => setTimeout(r, ms));
    },
    createPart(part: Object): void {
      // return new HoldPart(data);
      if (!Array.isArray(this.currentHold.parts))
        this.$set(this.currentHold, "parts", this.currentHold.parts || []);
      this.currentHold.parts.push(new HoldPart(part));
    },
    async createParts(data: any): Promise<void> {
      for (let i = 0; i < data.length; i++) {
        this.createPart(data[i]);
        await this.sleep(1);
      }
    },
  },
});
