import API from "@/API";
import { HoldData, Hold } from "@/models/LoadlistModel";
import { SetType } from "@/models/SetsModel";
import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";

@Module({
  name: "EquipmentModule",
})
export default class EquipmentModule extends VuexModule {
  systemHolds: Hold[] = [];
  userHolds: Hold[] = [];
  isLoading = false;
  systemSets: SetType[] = [];
  userSets: SetType[] = [];

  @Mutation
  setSystemHolds(holds: Hold[]): void {
    this.systemHolds = holds;
  }
  @Mutation
  setUserHolds(holds: Hold[]): void {
    Object.freeze(holds)
    this.userHolds = holds;
  }
  @Mutation
  setSystemSets(sets: SetType[]): void {
    Object.freeze(sets)
    this.systemSets = sets;
  }
  @Mutation
  setUserSets(sets: SetType[]): void {
    Object.freeze(sets)
    this.userSets = sets;
  }
  @Mutation
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  get sets(): SetType[] {
    return [...this.userSets, ...this.systemSets];
  }

  get holds(): Hold[] {
    return [...this.userHolds, ...this.systemHolds]
  }

  @Action({ rawError: true })
  getUserHolds(): Promise<unknown> {
    this.setIsLoading(true);

    return new Promise((resolve, reject) => {
      API.getUserHolds()
        .then((holds) => {
          this.setUserHolds(holds.data);
          this.setIsLoading(false);
          resolve(undefined);
        })
        .catch((error) => {
          this.setIsLoading(false);
          reject(error);
        });
    });
  }


  @Action({ rawError: true })
  getUserSets(): Promise<unknown> {
    this.setIsLoading(true);

    return new Promise((resolve, reject) => {
      API.getUserSets()
        .then((sets) => {
          this.setUserSets(sets.data);
          this.setIsLoading(false);
          resolve(undefined);
        })
        .catch((error) => {
          this.setIsLoading(false);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  getAllUserEquipment(): Promise<unknown> {
    this.setIsLoading(true);

    return new Promise((resolve, reject) => {
      API.getUserAppData()
        .then((response) => {
          this.setUserHolds(response.data.holds)
          this.setUserSets(response.data.sets)
          this.setIsLoading(false);
          resolve(undefined);
        })
        .catch((error) => {
          this.setIsLoading(false);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  getAllSystemEquipment(): Promise<unknown> {
    this.setIsLoading(true);

    return new Promise((resolve, reject) => {
      API.getDefaultAppData()
        .then((response) => {
          this.setSystemHolds(response.data.holds)
          this.setSystemSets(response.data.sets)
          this.setIsLoading(false);
          resolve(undefined);
        })
        .catch((error) => {
          this.setIsLoading(false);
          reject(error);
        });
    });
  }

}

