


















































































































import Vue, { PropType } from "vue";
import {
  HoldData,
  Loadplan,
  Loadlist,
  UnloadedItem,
} from "../../models/LoadlistModel";
import { Set } from "../../models/SetsModel";
import AvailabilitySlider from "./AvailabilitySlider.vue";
import { AugmentedHold } from "@/models/augmented/hold";
import { AugmentedSet } from "@/models/augmented/set";
import { LoadlistStore } from "@/store";
import containerUtils from "@/misc/containerUtils";

interface Stats {
  hold_count: number;
  hold_types: Record<string, number>;
  set_count: number;
  set_types: Record<string, number>;
  payload: number;
  max_volume: number;
  oog_holds: number;
  loaded_items: number;
  volume: number;
  weight: number;
  volumetric_weight: number;
  chargable_weight: number;
  unloaded: number;
  locked: boolean;
}

export default Vue.extend({
  name: "loadlist-summary",
  components: {
    AvailabilitySlider,
  },
  props: {
    loadlist: Object as PropType<Loadlist>,
    noSliders: Boolean,
  },
  computed: {
    usedHolds(): HoldData[] {
      return (this.loadplan?.holds || []).filter(
        (h: HoldData) => h.items_count
      );
    },
    sets(): AugmentedSet[] {
      return [
        ...(this.loadplan.sets || []),
        ...this.usedHolds
          .filter((c) => !c.set_uuid)
          .map((c) => containerUtils.createSetFromHold(c)),
      ].map((s: Set) => new AugmentedSet(s, this.usedHolds));
    },

    stats(): Stats {
      let set_count = 0,
        total_payload = 0,
        total_max_volume = 0;
      const set_types = this.sets.reduce(
        (setTypes: Record<string, number>, s: AugmentedSet) => {
          if (s.set.containers.length > 1) {
            set_count += 1;
            setTypes[s.set.name as string] =
              (setTypes[s.set.name as string] || 0) + 1;
          }
          total_payload += s.payload;
          total_max_volume += s.maxVolume;
          return setTypes;
        },
        {}
      );
      let stats = this.usedHolds
        .map((h) => new AugmentedHold(h))
        .reduce(
          (prev, cur) => {
            let hold_types = prev.hold_types;
            hold_types[cur.name as string] =
              (hold_types[cur.name as string] || 0) + 1;
            return {
              ...prev,
              hold_count: prev.hold_count + 1,
              volume: prev.volume + cur.volume,
              weight: prev.weight + cur.weight,
              loaded_items: prev.loaded_items + cur.items_count,
              oog_holds: prev.oog_holds + (cur.oogValues.length ? 1 : 0),
              chargable_weight:
                prev.chargable_weight +
                (cur.chargableWeight(
                  this.loadplan?.settings?.shipping_factor
                ) || 0),
            } as Stats;
          },
          {
            hold_count: 0,
            hold_types: {},
            set_count,
            set_types: set_types,
            payload: total_payload,
            max_volume: total_max_volume,
            oog_holds: 0,
            loaded_items: 0,
            volume: 0,
            weight: 0,
            chargable_weight: 0,
            unloaded: this.total_unloaded_items,
            locked: !!this.loadlist.result?.locked,
          } as Stats
        );

      return stats;
    },
    loadplan(): Loadplan {
      return this.loadlist.result?.versions[0];
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    total_unloaded_items(): number {
      return this.unloadedItems
        .map((i) => i.qty || 0)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0);
    },
    unloadedItems(): UnloadedItem[] {
      return LoadlistStore.unloaded_items;
    },
  },
});
