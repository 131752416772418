var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.stats.locked)?_c('p',{staticClass:"pt-2"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-lock")]),_vm._v(" This list is locked ")],1):_vm._e(),_c('v-row',[(_vm.stats.set_count > 0)?_c('v-col',[_c('h4',{staticClass:"summary"},[_vm._v(" "+_vm._s(_vm.stats.set_count)+" "+_vm._s(_vm.setName)+"s used ")]),_vm._l((_vm.stats.set_types),function(count,name){return _c('div',{key:name,staticClass:"summary"},[_vm._v(" "+_vm._s(count)+" x "+_vm._s(name)+" ")])})],2):_vm._e(),_c('v-col',[_c('h4',{staticClass:"summary"},[_vm._v(" "+_vm._s(_vm.stats.hold_count)+" "+_vm._s(_vm.typeName)+"s used "),(_vm.stats.oog_holds > 0)?_c('span',[_vm._v("("+_vm._s(_vm.stats.oog_holds)+" OoG)")]):_vm._e()]),_vm._l((_vm.stats.hold_types),function(count,name){return _c('div',{key:name,staticClass:"summary"},[_vm._v(" "+_vm._s(count)+" x "+_vm._s(name)+" ")])})],2)],1),_c('div',{staticClass:"mt-2 summary"},[_c('b',[_vm._v(_vm._s(_vm.stats.loaded_items))]),_vm._v(" items loaded "),(_vm.stats.unloaded)?_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v("("+_vm._s(_vm.stats.unloaded)+" not loaded)")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")])],1),(_vm.stats.chargable_weight)?_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b',_vm._g(_vm._b({},'b',attrs,false),on),[_vm._v(_vm._s(_vm._f("toWeight")(_vm.stats.chargable_weight)))]),_vm._v(" chargable weight ")]}}],null,false,2091344548)},[_c('span',[_vm._v("The larger of "),_c('i',[_vm._v("volumetric weight")]),_vm._v(" and "),_c('i',[_vm._v("actual weight")]),_vm._v(" summed per cargo")])])],1):_vm._e(),(!_vm.noSliders)?_c('div',{staticClass:"mt-4"},[(_vm.stats.weight)?_c('availability-slider',{attrs:{"max":_vm.stats.payload,"used":_vm.stats.weight,"weightFilter":true,"color":"light-blue"}}):_vm._e(),_c('div',{staticClass:"mt-4"}),(_vm.stats.volume)?_c('availability-slider',{attrs:{"max":_vm.stats.max_volume,"used":_vm.stats.volume,"weightFilter":false,"color":"light-green"}}):_vm._e()],1):_c('div',[_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"summary"},[_c('b',[_vm._v(_vm._s(_vm._f("toWeight")(_vm.stats.weight))+" ")]),(_vm.stats.payload && _vm.stats.set_count)?_c('span',[_vm._v("( "),_c('span',{class:_vm.stats.weight / _vm.stats.payload > 1
                ? ['error--text', 'font-weight-bold']
                : []},[_vm._v(_vm._s(_vm._f("percentage")((_vm.stats.weight / _vm.stats.payload)))+"%")]),_vm._v(" of "),_c('span',[_vm._v(_vm._s(_vm._f("toWeight")(_vm.stats.payload)))]),_vm._v(") ")]):_vm._e()]),_c('div',{staticClass:"summary"},[_c('b',[_vm._v(_vm._s(_vm._f("toVolume")(_vm.stats.volume))+" ")]),(_vm.stats.max_volume && _vm.stats.set_count)?_c('span',[_vm._v(" ("),_c('span',{class:_vm.stats.volume / _vm.stats.max_volume > 1
                ? ['error--text', 'font-weight-bold']
                : []},[_vm._v(_vm._s(_vm._f("percentage")((_vm.stats.volume / _vm.stats.max_volume)))+"%")]),_vm._v(" of "),_c('span',[_vm._v(_vm._s(_vm._f("toVolume")(_vm.stats.max_volume)))]),_vm._v(") ")]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }