/* eslint-disable no-case-declarations */

import { Vector2, Vector3, Matrix4, Event, Object3D, Mesh, Intersection } from "three";
import { Item } from "./item/item";
import { NestedItem } from "./item/nestedItem";
import { SceneManager, physicsWorker, VIEWS } from "./sceneManager";
import { Container } from "./container";
import router from "@/router";

const STATES = {
  MOVE: "move",
  MEASURE_TAPE: "measure_tape",
  SELECT_BOX: "select_box",
};

const VERTEX_LOCK_LIMIT = 300;
let mousePressed = false

let mouseDownPos = {
  x: 0,
  y: 0,
};

function getIntersectedContainerIndex(e: Event): number | null {
  const dim = e.target.getBoundingClientRect();
  const x = e.clientX - dim.left;
  const y = e.clientY - dim.top;
  mouseDownPos = {
    x: x,
    y: y,
  };
  SceneManager.raycaster.setFromCamera(
    new Vector2(
      (x / e.target.clientWidth) * 2 - 1,
      -(y / e.target.clientHeight) * 2 + 1
    ),
    SceneManager.camera
  );

  const intersects = SceneManager.raycaster.intersectObjects(
    SceneManager.cargoScene.containers.children,
    false
  );

  return intersects.length > 0
    ? intersects[0].object.userData.container?.__indices?.start ||
    intersects[0].object.userData.containerIndex
    : null;
}

function containerDblClick(e: Event): void {
  const containerIndex = getIntersectedContainerIndex(e);
  if (containerIndex !== undefined) {
    SceneManager.eventBus.emit("open-container", containerIndex);
  }
}
function containerDrop(e: Event): void {
  const containerIndex = getIntersectedContainerIndex(e);
  if (containerIndex !== undefined) {
    SceneManager.eventBus.emit("drop-on-container", { containerIndex, e });
  }
}

function containerHover(e: Event): void {
  if(!mousePressed) {
    const dim = e.target.getBoundingClientRect();
    const x = e.clientX - dim.left;
    const y = e.clientY - dim.top;
    mouseDownPos = {
      x: x,
      y: y,
    };
    SceneManager.raycaster.setFromCamera(
      new Vector2(
        (x / e.target.clientWidth) * 2 - 1,
        -(y / e.target.clientHeight) * 2 + 1
      ),
      SceneManager.camera
    );

    const intersects = SceneManager.raycaster.intersectObjects(
      SceneManager.cargoScene.containers.children,
      false
    ).filter(intersected => intersected.object.userData.container?.preview !== true);
    

    if(intersects.length > 0) {
      const intersectedObject = intersects[0].object as Container;
      SceneManager.renderer.domElement.style.cursor = "pointer";
      if (intersectedObject === SceneManager.state.selectedContainer) { // proceed if intersected is not same as selectedContainer
        return
      }
      if(SceneManager.state.hoveredContainer !== SceneManager.state.selectedContainer) {
        SceneManager.state.hoveredContainer?.setSelectionVisibility(false)  
      }
      SceneManager.state.hoveredContainer = intersectedObject;
      SceneManager.state.hoveredContainer?.setSelectionVisibility(true)
    } else {
      if(SceneManager.state.hoveredContainer !== SceneManager.state.selectedContainer) {
        SceneManager.state.hoveredContainer?.setSelectionVisibility(false)
      }
      SceneManager.state.hoveredContainer = undefined
      SceneManager.renderer.domElement.style.cursor = "auto";
    }
  }
}

function containerPointerDown(e: Event): void {
  mousePressed = true
  if (e.button === 0) {
    const intersects = SceneManager.raycaster.intersectObjects(
      SceneManager.cargoScene.containers.children,
      false
    ).filter(intersect => intersect.object.userData.container?.preview !== true);
    
    const containerIndex = intersects.length > 0
    ? intersects[0].object.userData.container?.__indices?.start ||
    intersects[0].object.userData.containerIndex
    : null;
  
    if (containerIndex !== undefined) {
      SceneManager.eventBus.emit("select-container", {containerIndex, position: {x: e.layerX, y: e.layerY}, object: intersects.length > 0 ? intersects[0].object : null});
    } else {
      SceneManager.eventBus.emit("select-container", null)
    }
  }
}

function containerPointerUp(e: Event): void {
  mousePressed = false
}

function pointerDown(e: Event): void {
  mousePressed = true
  const dim = e.target.getBoundingClientRect();
  const x = e.clientX - dim.left;
  const y = e.clientY - dim.top;
  mouseDownPos = {
    x: x,
    y: y,
  };
  SceneManager.raycaster.setFromCamera(
    new Vector2(
      (x / e.target.clientWidth) * 2 - 1,
      -(y / e.target.clientHeight) * 2 + 1
    ),
    SceneManager.camera
  );
  const intersects = SceneManager.raycaster.intersectObjects(
    SceneManager.cargoScene.getCargoes(),
    false
  );

  let intersectedObject = undefined;
  if (intersects.length > 0) {
    intersectedObject = intersects[0].object as Item | NestedItem;
  }

  switch (SceneManager.state.interaction) {
    case STATES.SELECT_BOX:
      // eslint-disable-next-line no-case-declarations
      const selectBox = SceneManager.renderer.domElement.parentNode.querySelector(
        "#selectBox"
      ) as HTMLElement;
      if (selectBox) {
        SceneManager.eventBus.emit("select-cargoes", null);
        SceneManager.orbitControls.enableRotate = false;
        selectBox.style.left = `${x}px`;
        selectBox.style.top = `${y}px`;
        selectBox.style.display = "";
      }
      return;
    case STATES.MEASURE_TAPE:
      return;
    case STATES.MOVE:
      // if (!intersectedObject || e.ctrlKey) {
      //   break;
      // }

      if (!e.ctrlKey) {
        if (intersectedObject?.isSelected) {
          const closestVertex = getClosestVertexOnObject(
            intersectedObject,
            intersects[0].point,
            true
          );
          if (closestVertex) {
            SceneManager.cargoScene.updateSnapHelper(closestVertex, true);

            SceneManager.cargoScene.lockSnapHelper(closestVertex);
            SceneManager.orbitControls.enableRotate = false;
            return;
          }
        }
      }
  }

  if (intersectedObject) {
    if (!e.ctrlKey) {
      SceneManager.resetState();
    } else {
    }

    if (intersectedObject.isSelected) {
      intersectedObject.deselect();
      if (SceneManager.cargoScene.getSelectedItems().length === 0)
        SceneManager.resetState();
    } else {
      intersectedObject.select();
      SceneManager.eventBus.emit(
        "select-cargoes",
        SceneManager.cargoScene.getSelectedItems().map((i) => { return { ...i.userData.item, index: i.indexInContainer } })
      );
      SceneManager.setInteractionState(STATES.MOVE);
      SceneManager.renderer.domElement.style.cursor = "move";
    }
  }
}
function pointerMove(e: Event): void {
  const dim = e.target.getBoundingClientRect();
  const mouse = new Vector2(
    ((e.clientX - dim.left) / e.target.clientWidth) * 2 - 1,
    -((e.clientY - dim.top) / e.target.clientHeight) * 2 + 1
  );

  switch (SceneManager.state.interaction) {
    case STATES.SELECT_BOX: {
      SceneManager.renderer.domElement.style.cursor = "crosshair";
      const selectBox = SceneManager.renderer.domElement.parentNode.querySelector(
        "#selectBox"
      ) as HTMLElement;
      if (selectBox && selectBox.style.display !== "none") {
        const mouseX = e.clientX - dim.left;
        const mouseY = e.clientY - dim.top;
        const newWidth = mouseX - mouseDownPos.x;
        const newHeight = mouseY - mouseDownPos.y;
        if (newWidth < 0) {
          selectBox.style.left = `${mouseX}px`;
        } else {
          selectBox.style.width = `${newWidth}px`;
        }

        if (newHeight < 0) {
          selectBox.style.top = `${mouseY}px`;
        } else {
          selectBox.style.height = `${newHeight}px`;
        }

        selectBox.style.width = `${Math.abs(newWidth)}px`;
        selectBox.style.height = `${Math.abs(newHeight)}px`;

        const widthHalf = 0.5 * SceneManager.renderer.domElement.clientWidth;
        const heightHalf = 0.5 * SceneManager.renderer.domElement.clientHeight;
        const vector = new Vector3();
        let lastSelectedCargo: Item | NestedItem = null;

        SceneManager.cargoScene.getCargoes().forEach((child) => {
          vector.setFromMatrixPosition(child.matrixWorld);
          vector.project(SceneManager.camera);
          vector.x = vector.x * widthHalf + widthHalf;
          vector.y = -(vector.y * heightHalf) + heightHalf;

          if (
            vector.x >= parseInt(selectBox.style.left, 10) &&
            vector.x <=
            parseInt(selectBox.style.left, 10) +
            parseInt(selectBox.style.width, 10) &&
            vector.y >= parseInt(selectBox.style.top, 10) &&
            vector.y <=
            parseInt(selectBox.style.top, 10) +
            parseInt(selectBox.style.height, 10)
          ) {
            if (!child.isSelected) child.select();
          } else if (child.isSelected) child.deselect();

          lastSelectedCargo = child.isSelected ? child : lastSelectedCargo;
        });
      }

      return;
    }

    case STATES.MEASURE_TAPE: {
      SceneManager.renderer.domElement.style.cursor = "pointer";
      SceneManager.raycaster.setFromCamera(mouse, SceneManager.camera);
      const intersects = SceneManager.raycaster.intersectObjects(
        SceneManager.cargoScene.getPartsAndItems(),
        false
      );
      for (let i = 0; i < intersects.length; i++) {
        const intersectedObject = intersects[i];
        if (intersectedObject.object.type === "LineSegments") continue;

        const closestVertex = getClosestVertexOnObject(
          intersectedObject.object,
          intersectedObject.point,
          false
        );

        const screenSpaceVector = new Vector3().subVectors(
          intersectedObject.point.clone().project(SceneManager.camera),
          closestVertex.clone().project(SceneManager.camera)
        );
        const screenSize = SceneManager.renderer.getSize(new Vector2());
        screenSpaceVector.multiply(new Vector3(screenSize.x, screenSize.y, 0));

        let lockToVector = undefined;

        if (
          screenSpaceVector.length() *
          SceneManager.camera.position.distanceTo(closestVertex) <
          VERTEX_LOCK_LIMIT
        ) {
          lockToVector = closestVertex;
        } else if (SceneManager.cargoScene.snapHelperLocked)
          lockToVector = intersectedObject.point;

        if (lockToVector) {
          SceneManager.cargoScene.updateSnapHelper(lockToVector, false);
          return;
        }
      }

      if (!SceneManager.cargoScene.snapHelperLocked)
        SceneManager.cargoScene.hideSnapHelper();
      return;
    }
    case STATES.MOVE: {
      if (!SceneManager.cargoScene.snapHelperLocked) {
        SceneManager.raycaster.setFromCamera(mouse, SceneManager.camera);
        const intersects = SceneManager.raycaster.intersectObjects(
          SceneManager.cargoScene.getSelectedItems(),
          false
        );

        for (let i = 0; i < intersects.length; i++) {
          const intersect = intersects[i];

          const closestVertex = getClosestVertexOnObject(
            intersect.object,
            intersect.point,
            true
          );
          SceneManager.cargoScene.updateSnapHelper(closestVertex, true);
        }
      } else {
        SceneManager.raycaster.setFromCamera(mouse, SceneManager.camera);
        const intersects = SceneManager.raycaster.intersectObjects(
          [
            SceneManager.cargoScene.plane,
            ...SceneManager.cargoScene
              .getPartsAndItems()
              .filter((i) => !i.isSelected),
          ],
          false
        );

        for (let i = 0; i < intersects.length; i++) {
          const intersect = intersects[i];

          if (intersect.object.type === "LineSegments") continue;

          const closestVertex = getClosestVertexOnObject(
            intersect.object,
            intersect.point,
            !SceneManager.cargoScene.snapHelperLocked
          );

          const screenSpaceVector = new Vector3().subVectors(
            intersect.point.clone().project(SceneManager.camera),
            closestVertex.clone().project(SceneManager.camera)
          );
          const screenSize = SceneManager.renderer.getSize(new Vector2());
          screenSpaceVector.multiply(
            new Vector3(screenSize.x, screenSize.y, 0)
          );

          let lockToVector = intersect.point;

          if (
            screenSpaceVector.length() *
            SceneManager.camera.position.distanceTo(closestVertex) <
            VERTEX_LOCK_LIMIT
          ) {
            lockToVector = closestVertex;
          }
          if (lockToVector) {
            SceneManager.cargoScene.updateSnapHelper(lockToVector, true);
            return;
          }
        }
      }
    }
  }

  if (e.buttons) return;

  SceneManager.raycaster.setFromCamera(mouse, SceneManager.camera);
  const intersectedObjects = SceneManager.raycaster.intersectObjects(
    SceneManager.cargoScene.getCargoes(),
    false
  );

  if (!intersectedObjects.length) {
    if (!SceneManager.state.hoveredItem?.isSelected) {
      SceneManager.state.hoveredItem?.setColor();
      SceneManager.state.hoveredItem = undefined;
    }
    SceneManager.renderer.domElement.style.cursor = "auto";
    SceneManager.cargoScene.hideSnapHelper();
  } else {
    const intersectedObject = intersectedObjects[0].object as Item | NestedItem;

    if (intersectedObject.isSelected)
      SceneManager.renderer.domElement.style.cursor = "move";
    else {
      SceneManager.renderer.domElement.style.cursor = "auto";
      SceneManager.cargoScene.hideSnapHelper();
    }
    if (
      SceneManager.state.hoveredItem &&
      intersectedObject !== SceneManager.state.hoveredItem &&
      !SceneManager.state.hoveredItem.isSelected
    ) {
      SceneManager.state.hoveredItem.setColor();
    }
    SceneManager.state.hoveredItem = intersectedObject;
    if (!intersectedObject.isSelected)
      SceneManager.state.hoveredItem.setColor(0x444444);
  }
}
function pointerUp(): boolean {
  mousePressed = false
  switch (SceneManager.state.interaction) {
    case STATES.SELECT_BOX:
      const selectBox = SceneManager.renderer.domElement.parentNode.querySelector(
        "#selectBox"
      ) as HTMLElement;
      selectBox.style.display = "none";
      if (SceneManager.orbitControls && SceneManager.isPerspective())
        SceneManager.orbitControls.enableRotate = true;
      SceneManager.eventBus.emit(
        "select-cargoes",
        SceneManager.cargoScene.getSelectedItems().map((i) => { return { ...i.userData.item, index: i.indexInContainer } })
      );
      SceneManager.setInteractionState(STATES.MOVE);

      break;
    case STATES.MEASURE_TAPE:
      if (!SceneManager.cargoScene.snapHelperLocked) {
        SceneManager.cargoScene.lockSnapHelper();
        return true;
      }
      SceneManager.eventBus.emit(
        "measured-distance",
        SceneManager.cargoScene.getSnapLineDistance()
      );

      SceneManager.resetState();

      break;

    case STATES.MOVE:
      if (SceneManager.cargoScene.snapHelperLocked) {
        if (SceneManager.cargoScene.snapGhostObjects.userData.intersects)
          return;
        const deltaPos = SceneManager.cargoScene.getSnapLineVector();

        physicsWorker.postMessage({
          event: "addVector",
          vector: deltaPos,
        });
        SceneManager.cargoScene.hideSnapHelper();
        SceneManager.orbitControls.enableRotate = true;
        return;
      } else return;
  }
  SceneManager.renderer.domElement.style.cursor = "auto";

  SceneManager.cargoScene.hideSnapHelper();
}

function navHotkeys(e: Event): void {
  switch (e.keyCode) {
    /// Camera angles
    case 49: // 1
      SceneManager.eventBus.emit("set-view", { view: VIEWS.TOP });
      break;

    case 50: // 2
      SceneManager.eventBus.emit("set-view", { view: VIEWS.SIDE });
      break;

    case 51: // 3
      SceneManager.eventBus.emit("set-view", { view: VIEWS.THREED });

      break;
    case 52: // 4
      SceneManager.eventBus.emit("set-view", { view: VIEWS.FRONT });

      break;
    case 80: // P: Orthogonal/Perspective
      SceneManager.setCamera();
      break;
    case 83: // s & ctrl+s
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        SceneManager.eventBus.emit("save");
        break;
      }
      SceneManager.eventBus.emit("search");
      break;
    case 70:
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        SceneManager.eventBus.emit("search");
        break;
      }
    case 78: // n
      SceneManager.eventBus.emit("notes");
      break;
    case 69: // e
      SceneManager.eventBus.emit("select-multiple");
      break;
    case 84: // t
      SceneManager.eventBus.emit("tape");
      break;
  }
}

function keyDown(e: Event): void {
  switch (e.keyCode) {
    case 9: // Tab
      e.preventDefault();

      if (
        SceneManager.state.hoveredItem &&
        !SceneManager.state.hoveredItem.isSelected
      )
        SceneManager.state.hoveredItem.setColor();

      SceneManager.state.tabIndex =
        (SceneManager.state.tabIndex + 1) %
        SceneManager.cargoScene.getCargoes().length;
      SceneManager.state.hoveredItem = SceneManager.cargoScene.getCargoes()[
        SceneManager.state.tabIndex
      ];
      SceneManager.state.hoveredItem.setColor(0x888888);

      break;
    case 13: // Enter
      if (SceneManager.state.hoveredItem) {
        if (SceneManager.state.hoveredItem.isSelected)
          SceneManager.state.hoveredItem.deselect();
        else {
          SceneManager.state.hoveredItem.select();
          SceneManager.eventBus.emit(
            "select-cargoes",
            SceneManager.cargoScene
              .getSelectedItems()
              .map((i) => { return { ...i.userData.item, index: i.indexInContainer } })
          );
          SceneManager.setInteractionState(STATES.MOVE);
        }
      }
      break;
    case 32: // Space
      physicsWorker.postMessage({
        event: "move",
        direction: {
          x: 0,
          y: 0,
          z: 1,
        },
      });
      break;

    case 27: // Esc
      SceneManager.resetState();
      break;

    case 65: // Ctrl+A
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        SceneManager.cargoScene.selectAllCargoes();
      }

      break;
    case 90: // Ctrl+Z
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        SceneManager.undo();
      }
      break;

    case 38: // Arrow keys
    case 40:
    case 37:
    case 39:
      e.preventDefault();

      moveObjectWithArrowKey(e.keyCode);

      break;

    case 8: // Delete and backspace
    case 46: {
      if(router.currentRoute.params.item) return // prevents removing elements from nested items
      const unloadedItems = SceneManager.cargoScene.unloadSelectedItems();
      SceneManager.eventBus.emit("removed-cargo", unloadedItems);
      SceneManager.resetState();
      break;
    }
    case 82: // r
      SceneManager.eventBus.emit("rotate-cargo");
      break;
  }
}

function moveObjectWithArrowKey(key: number) {
  const m1 = new Matrix4();

  switch (key) {
    case 38:
      m1.makeRotationX(Math.PI / 2);
      break;
    case 40:
      m1.makeRotationX(-Math.PI / 2);
      break;
    case 37:
      m1.makeRotationY(Math.PI / 2);
      break;
    case 39:
      m1.makeRotationY(-Math.PI / 2);
      break;
  }

  let cameraCopy = SceneManager.camera.clone();
  cameraCopy.matrix.multiply(m1);
  cameraCopy.rotation.setFromRotationMatrix(cameraCopy.matrix);
  const dir = cameraCopy.getWorldDirection(new Vector3()).normalize();
  cameraCopy = null;

  const mainDir =
    Math.abs(dir.x) > Math.abs(dir.y)
      ? new Vector3(dir.x, 0, 0)
      : new Vector3(0, dir.y, 0);

  const translationDir = mainDir.clone().normalize();

  physicsWorker.postMessage({
    event: "move",
    direction: {
      x: translationDir.x,
      y: translationDir.y,
      z: translationDir.z,
    },
  });
}

function getObjectVertices(
  object: Object3D<Event>,
  only_bottom: boolean = true
) {
  const realObject = object.type === "NestedItem" ? object.children[0] : object;
  const pos = (realObject as Mesh).geometry.getAttribute("position");
  const midPoint = object.position;

  let vertices = [];
  for (let j = 0; j < pos.array.length; j += 3) {
    const a = object.localToWorld(
      new Vector3(pos.array[j], pos.array[j + 1], pos.array[j + 2])
    );
    if (only_bottom && a.z > midPoint.z) continue;
    vertices.push(a);
  }
  return vertices;
}

function getClosestVertexOnObject(
  object: Object3D<Event>,
  point: Vector3,
  only_bottom: boolean
) {
  const realObject = object.type === "NestedItem" ? object.children[0] : object;
  const pos = (realObject as Mesh).geometry.getAttribute("position");
  const midPoint = object.position;

  let closestVertex = undefined;
  let closestDistance = Infinity;

  for (let j = 0; j < pos.array.length; j += 3) {
    const a = object.localToWorld(
      new Vector3(pos.array[j], pos.array[j + 1], pos.array[j + 2])
    );

    if (only_bottom && a.z > midPoint.z) continue;

    const dist = a.distanceTo(point);
    if (dist < closestDistance) {
      closestVertex = a;
      closestDistance = dist;
    }
  }

  return closestVertex;
}

export {
  pointerDown,
  pointerMove,
  pointerUp,
  keyDown,
  containerPointerDown,
  containerPointerUp,
  containerDblClick,
  containerHover,
  containerDrop,
  navHotkeys,
  STATES,
};
