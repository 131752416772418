






import Vue from "vue";
import CargoLibraryComponent from "@/components/CargoLibrary/CargoLibrary.vue";
export default Vue.extend({
  name: "cargo-library-view",
  components: { "cargo-library": CargoLibraryComponent },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  filters: {},
});
