












































































































































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "terms-and-conditions-modal", //
  props: {
    visible: Boolean,
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  methods: {
    update() {},
  },
  filters: {
    date(timestamp: number): Date {
      console.log(timestamp, "timestamp");
      return new Date(timestamp);
    },
  },
});
