








































import Vue from "vue";

export default Vue.extend({
  name: "availability-slider",
  props: {
    used: Number,
    max: Number,
    color: String,
    weightFilter: Boolean,
    noSliders: Boolean,
  },
  computed: {
    available(): number {
      return Math.max(0, this.max - this.used);
    },
  },
});
