






































































import {
  HoldData,
  HoldInputItem,
  ItemSelector,
  LoadRule,
} from "@/models/LoadlistModel";
import Vue, { PropType } from "vue";
import ItemSelectorComponent from "./ItemSelector.vue";
export default Vue.extend({
  components: { ItemSelectorComponent },
  name: "load-rule",
  data: function() {
    return {
      rule: JSON.parse(JSON.stringify(this.value)) as LoadRule,
      item_rules: [
        {
          text: "Cannot exceed quantity...",
          value: "max_qty",
        },
        // {
        //   text: "Cannot be loaded with items...",
        //   value: "cannot_be_loaded_with_items",
        // },
        {
          text: "Cannot be loaded in...",
          value: "cannot_be_loaded_in",
        },
        {
          text: "Must be loaded in...",
          value: "must_be_loaded_in",
        },
        {
          text: "Can only support...",
          value: "can_only_support",
        },
        {
          text: "Must be loaded first",
          value: "must_be_loaded_first",
        },
      ],
    };
  },
  props: {
    value: Object as PropType<LoadRule>,
    ruleIndex: Number,
    typeName: String,
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
  },
  watch: {
    rule: {
      handler(o): void {
        this.$emit("input", o);
      },
      deep: true,
    },
  },
  computed: {},
  mounted(): void {},
  methods: {},
});
