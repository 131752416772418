import { CanvasTexture, Material, MeshLambertMaterial } from "three";

export function cloneMaterial(
  material: Material | Material[]
): Material | Material[] {
  return Array.isArray(material)
    ? material.map((m) => m.clone())
    : material.clone();
}

export function setMaterialOpacity(
  material: MeshLambertMaterial,
  opacity: number,
  isInteractive: boolean
): void {
  material.transparent = opacity < 1.0;
  material.opacity = opacity;
  if (isInteractive) material.needsUpdate = true;
}

export function createSnapPointTexture() {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const canvasWidth = 16;
  const canvasHeight = 16;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  ctx.fillStyle = "black";

  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(
    canvasWidth * 0.5,
    canvasHeight * 0.5,
    canvasWidth * 0.5,
    0,
    Math.PI * 2
  );

  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.fillStyle = "white";

  ctx.arc(
    canvasWidth * 0.5,
    canvasHeight * 0.5,
    6,
    0,
    Math.PI * 2
  );

  ctx.fill();

  ctx.closePath();
  ctx.fill();
  return new CanvasTexture(canvas);
}

