
















































































































































































import Vue, { PropType } from "vue";
import MoveItemsUtils from "@/misc/moveItemsUtils";

import {
  HoldData,
  HoldDataWithIndices,
  HoldItem,
  UnloadedItem,
} from "@/models/LoadlistModel";
import { AugmentedHold } from "@/models/augmented/hold";
import itemUtils from "@/misc/itemUtils";

export default Vue.extend({
  name: "planner-items",
  props: {
    hold: {
      default: null,
      type: Object as PropType<HoldDataWithIndices>,
    },
    listHeight: {
      default: null,
      type: Number,
    },
    hideItems: {
      default: null,
      type: Boolean,
    },
    externalItemsSelection: {
      default: null,
      type: Array as PropType<HoldItem[]>,
      required: false,
    },
  },

  data() {
    return {
      draggedItemIndex: null as number,
      draggedOverRow: null as number,
      draggedOverHold: null as boolean,
      selectedCargoIndices: [] as number[],
      showMoveError: null as number,
      showSnackBar: false,
    };
  },
  computed: {
    augmentedHold(): AugmentedHold {
      return new AugmentedHold(this.hold);
    },
    itemIndex(): number {
      return Number(this.$route.params.item)
    }
  },
  watch: {
    externalItemsSelection: {
      handler: function(a: HoldData[]): void {
        let selected: number[] = [];
        if (a) {
          for (let i = 0; i < a.length; i++) {
            if (i === 0 || !itemUtils.compareItems(a[i], a[i - 1])) {
              let index = this.augmentedHold.bundledItems.findIndex((b) =>
                itemUtils.compareItems(a[i], b[0])
              );
              if (index >= 0) {
                if (!selected.includes(index)) {
                  selected.push(index);
                }
              }
            }
          }
        }
        this.selectedCargoIndices = selected;
      },
      immediate: true,
    },
  },
  methods: {
    dragStart(
      item: HoldItem,
      itemIndex: number,
      hold: HoldData,
      holdIndex: number,
      e: any
    ): void {
      this.selectedCargoIndices = [];
      this.draggedItemIndex = itemIndex;
      e.dataTransfer.setData(
        "text",
        JSON.stringify({ item, itemIndex, hold, holdIndex })
      );
    },
    onDrag(e: MouseEvent): void {
      var rect = (this.$refs.plannerDiv as HTMLElement).getBoundingClientRect();
      if (e.clientY > window.innerHeight - 100) {
        window.scrollBy(0, 2);
      } else if (e.clientY < rect.top) window.scrollBy(0, -2);
    },
    toggleSelectedIndices(i: number): void {
      let index = this.selectedCargoIndices.indexOf(i);

      if (index >= 0) {
        this.selectedCargoIndices.splice(index, 1);
      } else this.selectedCargoIndices.push(i);
    },
    moveUnloadedItems(
      toHoldIndex: number,
      toPositionIndex: number,
      unloaded_items: UnloadedItem[]
    ): void {
      this.updateLoadingContainers([toHoldIndex]);
      MoveItemsUtils.loadFromUnloaded(
        toHoldIndex,
        toPositionIndex,
        unloaded_items
      )
        .then((status) => {
          if (status === -1) {
            this.showMoveError = toHoldIndex;
            setTimeout(() => {
              this.showMoveError = null;
            }, 2000);
          }
        })
        .finally(() => this.resetDragged());
    },

    moveLoadedItems(
      fromHoldIndex: number,
      fromItemIndices: number[],
      toHoldIndex: number,
      toPosition: number,
      force: boolean
    ): void {
      this.showSnackBar = false;

      this.updateLoadingContainers([fromHoldIndex, toHoldIndex]);
      MoveItemsUtils.moveLoadedItems(
        fromHoldIndex,
        fromItemIndices,
        toHoldIndex,
        toPosition,
        force
      )
        .then((status) => {
          if (status === -1) {
            this.showSnackBar = true;
            this.showMoveError = toHoldIndex;
            setTimeout(() => {
              this.showMoveError = null;
            }, 2000);
          }
        })
        .finally(() => this.resetDragged());
    },
    unloadLoadedCargoes(): void {
      if (!isNaN(this.itemIndex)) return
      if (!this.selectedCargoIndices.length) return;
      let fromIndices = this.selectedCargoIndices
        .reduce((prev, cur) => {
          return [...prev, this.augmentedHold.bundledItems[cur][0].itemIndices];
        }, [])
        .flat();

      this.updateLoadingContainers([this.hold.__indices.start]);
      MoveItemsUtils.removeLoadedItem(
        this.hold.__indices.start,
        fromIndices
      ).finally(() => this.resetDragged());
    },
    cargoDropOnContainer(
      e: any,
      toHoldIndex: number,
      positionIndex: number
    ): void {
      this.draggedOverHold = null;
      if (!isNaN(this.itemIndex)) return
      let droppedItem = JSON.parse(e.dataTransfer.getData("text"));
      if (!!droppedItem.unloaded) {
        this.moveUnloadedItems(
          toHoldIndex,
          positionIndex,
          droppedItem.unloaded
        );
      } else if (
        droppedItem.holdIndex !== toHoldIndex ||
        droppedItem.itemIndex !== positionIndex
      )
        this.moveLoadedItems(
          droppedItem.holdIndex,
          e.altKey
            ? droppedItem.item.itemIndices.slice(0, 1)
            : droppedItem.item.itemIndices,
          toHoldIndex,
          positionIndex,
          e.shiftKey
        );
    },
    cargoDropOnItem(e: any, toHoldIndex: number, positionIndex = 0): void {
      e.stopPropagation();
      this.draggedOverRow = null;
      if (!isNaN(this.itemIndex)) return
      let droppedItem = JSON.parse(e.dataTransfer.getData("text"));
      if (!!droppedItem.unloaded) {
        this.moveUnloadedItems(
          toHoldIndex,
          positionIndex,
          droppedItem.unloaded
        );
      } else if (
        droppedItem.holdIndex !== toHoldIndex ||
        droppedItem.itemIndex !== positionIndex
      )
        this.moveLoadedItems(
          droppedItem.holdIndex,
          e.altKey
            ? droppedItem.item.itemIndices.slice(0, 1)
            : droppedItem.item.itemIndices,
          toHoldIndex,
          positionIndex,
          e.shiftKey
        );
    },

    resetDragged(): void {
      this.updateLoadingContainers([]);
      this.selectedCargoIndices = [];
    },
    updateLoadingContainers(holdsIds: number[]): void {
      this.$emit("loadingContainers", holdsIds);
    },
  },
});
