var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-row',{staticClass:"rule py-4 mx-2",attrs:{"align":"center"}},[(_vm.rule.items)?_c('v-col',[_c('item-selector-component',{attrs:{"items":_vm.items},model:{value:(_vm.rule.items),callback:function ($$v) {_vm.$set(_vm.rule, "items", $$v)},expression:"rule.items"}})],1):_vm._e(),_c('v-col',{staticClass:"d-md-flex"},[_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.item_rules,"menu-props":{ maxHeight: '400' },"label":"Condition"},on:{"change":function () {
            _vm.rule.value = undefined;
          }},model:{value:(_vm.rule.condition),callback:function ($$v) {_vm.$set(_vm.rule, "condition", $$v)},expression:"rule.condition"}}),(_vm.rule.condition === 'max_qty')?_c('v-text-field',{attrs:{"label":"Quantity","min":"0","step":"1","type":"number"},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", _vm._n($$v))},expression:"rule.value"}}):_vm._e(),(_vm.rule.condition === 'can_only_support')?_c('item-selector-component',{attrs:{"items":_vm.items},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}}):_vm._e()],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.holds,"item-text":"name","item-value":"id","menu-props":{ maxHeight: '400' },"label":_vm.rule.in_containers_with_ids.length
            ? ("in " + _vm.typeName + "s")
            : ("in any " + _vm.typeName),"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index < _vm.rule.in_containers_with_ids.length - 2)?_c('span',[_vm._v(_vm._s(item.name)+", ")]):(index === _vm.rule.in_containers_with_ids.length - 2)?_c('span',[_vm._v(_vm._s(item.name)+" or ")]):_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.rule.in_containers_with_ids),callback:function ($$v) {_vm.$set(_vm.rule, "in_containers_with_ids", $$v)},expression:"rule.in_containers_with_ids"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$emit('removeRule', _vm.ruleIndex)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }