import API from "@/API";

import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";
import Vue from "vue";
import { SceneManager } from "@/graphics/sceneManager";
import {
  CompanySerializer,
  UserSerializer,
  User,
  UserPreferences,
  Company,
  CompanySettings,
} from "@/models/UserCompanyModel";
import { CargoStore, EquipmentStore, LoadlistStore } from "../index";
import router from "@/router";

const debug = process.env.NODE_ENV !== "production";

const RESTRICTED_USAGE_FROM_PLAN = new Map([
  ["1", false],
  ["2", true],
  ["3", false],
]);

@Module({
  name: "UserCompanyModule",
})
export default class UserCompanyModule extends VuexModule {
  private _user: User = new User();
  private _company: Company = new Company();

  @Mutation
  setCompany(data: CompanySerializer): void {
    this._company = new Company(data);
  }

  @Mutation
  setCompanySettings(data: CompanySettings): void {
    this._company.settings = data;
  }

  @Mutation
  setUser(data: UserSerializer): void {
    if (data) {
      this._user = new User(data);

      if (data?.preferences?.default_view)
        SceneManager.setDefaultView(data.preferences.default_view);
      if (data?.preferences?.hide_labels !== undefined)
        SceneManager.setHideLabels(data.preferences.hide_labels);
      if (data?.preferences?.darkMode)
        router.app.$vuetify.theme.dark = true
      // Vue.prototype.$populateChatUser(
      //   data.id,
      //   data.email || undefined,
      //   data.company?.name || undefined
      // );
      if (!debug)
        window.bugsnagClient.user = {
          id: this._user.id,
        };
    } else {
      this._user = new User();
    }
  }

  @Mutation
  setUserPreference(data: { key: string; value: string | string[] }): void {
    this._user.preferences[
      data.key as keyof UserPreferences
    ] = data.value as never;
  }

  get user(): User {
    return this._user;
  }

  get company(): Company {
    return this._company;
  }

  get company_settings(): CompanySettings {
    return this._company.settings;
  }

  get is_authenticated(): boolean {
    return !!this._user.id;
  }

  get lite_version(): boolean {
    return RESTRICTED_USAGE_FROM_PLAN.get(this._company.plan);
  }

  get trial_version(): boolean {
    return this._company.plan === "1";
  }

  get preferences(): UserPreferences {
    return this._user.preferences || {};
  }

  get length_dim(): string {
    return this.preferences.length_dim || "CM";
  }

  get weight_dim(): string {
    return this.preferences.weight_dim || "KG";
  }

  @Action({ rawError: true })
  getMe(): Promise<undefined> {
    return new Promise((resolve, reject) => {
      API.getMe()
        .then((response) => {
          this.setUser(response.data);
          this.setCompany(response.data.company);
          resolve(undefined);
          LoadlistStore.syncGroups();
          EquipmentStore.getAllUserEquipment();
          CargoStore.downloadCargoes();
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  @Action({ rawError: true })
  updateMe(data: { preferences: UserPreferences }): Promise<undefined> {
    return new Promise((resolve, reject) => {
      API.updateUser({ ...data, id: this._user.id })
        .then((response) => {
          this.setUser(response.data);
          resolve(undefined);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  @Action({ rawError: true })
  loginWithCredentials(userCredentials: {
    username: string;
    password: string;
  }): Promise<undefined> {
    return new Promise((resolve, reject) => {
      API.login(userCredentials.username, userCredentials.password)
        .then(() => {
          this.getMe()
            .then(() => {
              resolve(undefined);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  @Action({ rawError: true })
  logout(redirectRoute: string | null): void {
    this.setUser(null);
    this.setCompany(null);
    API.logout();
    LoadlistStore.clearLoadlistData();
    CargoStore.setCargoes([]);
    EquipmentStore.setUserHolds([]);
    EquipmentStore.setUserSets([]);

    if (router.currentRoute.name !== "login") {
      router.push({ name: "login", query: { redirect: redirectRoute } });
    }
  }

  @Action({ rawError: true })
  updateCompanySettings(data: CompanySettings): Promise<undefined> {
    return new Promise((resolve, reject) => {
      API.updateCompanySettings(this.company.id, data)
        .then((response) => {
          this.setCompanySettings(response.data)
          resolve(undefined);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
