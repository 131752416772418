var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"outlined":"","type":"info"}},[_vm._v(" The segregation table enables you to tell which cargoes can or cannot be loaded together in the same equipment. The classes used in the table refer to the \"Class\" column when entering cargo data. ")]),_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"hide-details":"","label":"New class"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addClassId.apply(null, arguments)}},model:{value:(_vm.newClassId),callback:function ($$v) {_vm.newClassId=$$v},expression:"newClassId"}}),_c('v-btn',{staticClass:"mt-5",attrs:{"icon":"","color":"green","disabled":_vm.disableAddNewClassId},on:{"click":_vm.addClassId}},[_c('v-icon',[_vm._v(" mdi-plus-circle ")])],1)],1)]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{},[_c('th',{staticClass:"text-left subtitle-1 font-weight-bold"},[_vm._v("Classes")]),_vm._l((_vm.class_ids),function(_,key){return _c('th',{key:key,staticClass:"text-center subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(key)+" ")])})],2)]),_c('tbody',_vm._l((_vm.class_ids),function(val,key,index1){return _c('tr',{key:key},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"subtitle-1 font-weight-bold primary--text"},'td',attrs,false),on),[_vm._v(" "+_vm._s(key)+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteClassId(key)}}},[_c('v-list-item-title',[_vm._v("Delete class")])],1)],1)],1),_vm._l((_vm.class_ids),function(val,key2,index2){return _c('td',{key:key2,staticClass:"text-center"},[(index1 === index2)?_c('span',[_vm._v("-")]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return _vm._l((_vm.cellValues),function(item){return _c('span',_vm._g(_vm._b({key:item.value},'span',attrs,false),on),[(
                      (_vm.class_ids[key][key2] || _vm.class_ids[key2][key]) ==
                        item.value
                    )?_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1)})}}],null,true)},[_c('v-list',_vm._l((_vm.cellValues),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.changeClassId(key, key2, item.value)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_vm._v("  "+_vm._s(item.text))],1)],1)}),1)],1)],1)})],2)}),0)]},proxy:true}])}),_c('v-btn',{staticClass:"my-4",on:{"click":function($event){return _vm.$emit('update', _vm.class_ids)}}},[_c('v-icon',[_vm._v("mdi-floppy")]),_vm._v("Save table")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }