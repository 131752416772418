





















































































import Vue from "vue";
import API from "@/API";
import { UserCompanyStore } from "@/store";

export default Vue.extend({
  name: "login",
  data() {
    return {
      showWrongLoginDetailsAlert: false,
      isLoading: false,
      username: "",
      password: "",
      errors: [],
    };
  },
  watch: {
    is_authenticated: {
      handler: function(authenticated: boolean): void {
        if (authenticated) {
          const redirect = this.$route.query.redirect
            ? (this.$route.query.redirect as string)
            : "/loadlists";
          this.$router.push(redirect);
        }
      },
      immediate: true,
    },
  },
  computed: {
    registerAdress(): string {
      return API.url + "/register/";
    },
    passwordResetAdress(): string {
      return API.url + "/pwr";
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
  },
  methods: {
    login(): void {
      this.isLoading = true;

      this.loginWithCredentials({
        username: this.username.trim(),
        password: this.password.trim(),
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((e) => {
          if (e?.response) {
            let errors = Object.values(e.response.data);
            if (!errors.length)
              errors.push("Could not login with provided details");
            this.errors = errors;
          }

          this.isLoading = false;
          this.showWrongLoginDetailsAlert = true;
        });
    },
    loginWithCredentials(details: {
      username: string;
      password: string;
    }): Promise<undefined> {
      return UserCompanyStore.loginWithCredentials(details);
    },
  },
});
