var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.readOnly)?_c('div',[_vm._v(_vm._s(_vm.text))]):_c('div',[_c('v-select',_vm._b({staticStyle:{"margin-top":"0px"},attrs:{"value":_vm.internalValue,"items":_vm.alternatives,"value-comparator":_vm.valueComperator},on:{"input":function($event){$event ? _vm.$emit('input', $event) : function () {}}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){item.value === 0 ? (_vm.showOrientationsDialog = true) : function () {}}}},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(item.text)+" ")])]}}])},'v-select',_vm.$attrs,false)),(_vm.showOrientationsDialog)?_c('v-dialog',{attrs:{"scrollable":""},model:{value:(_vm.showOrientationsDialog),callback:function ($$v) {_vm.showOrientationsDialog=$$v},expression:"showOrientationsDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary elevation-0"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showOrientationsDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Allowed orientations")])],1),_c('v-card-text',{staticClass:"py-4"},_vm._l((_vm.chunkedRotationItems),function(chunk,i){return _c('v-row',{key:i},_vm._l((chunk),function(h,j){return _c('v-col',{key:j},[_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"ma-1",attrs:{"dense":"","label":h.orientation.label,"hide-details":"","input-value":_vm.internalValue & h.orientation.value},on:{"change":function($event){_vm.$emit(
                      'input',
                      ($event
                        ? _vm.internalValue | h.orientation.value
                        : _vm.internalValue & ~h.orientation.value) & _vm.geometryMask
                    )}}}),_c('scene-component',{attrs:{"hold-object":h.hold,"customViewSettings":{
                    hideContainer: true,
                  },"canvas-width":200,"canvas-height":100}})],1)])}),1)}),1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.showOrientationsDialog = false}}},[_vm._v("Close")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.value},on:{"click":function($event){$event.stopPropagation();_vm.showOrientationsDialog = false}}},[_vm._v("Lock rotations")])],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }