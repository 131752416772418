




























































































































































































































































































import Vue from "vue";
import API from "@/API";
import LoadPresetsTableComponent from "@/components/Custom/LoadPresetsTable.vue";
import { LoadlistStore, UserCompanyStore } from "@/store/index";
import {
  LoadlistGroup,
  LoadConfiguration,
  PresetInfo,
} from "@/models/LoadlistModel";
import { APIResponse } from "@/models/APIModel";
import { RRule } from "rrule";
import { User } from "@/models/UserCompanyModel";
import { getSerializerError } from "@/misc/errorUtils";
export default Vue.extend({
  name: "projects",
  components: {
    LoadPresetsTableComponent,
  },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      activeGroupId: null as number | null,
      isLoading: false,
      showEditProjectModal: false,
      showDeleteProjectModal: false,
      showConfirmDeleteProjectModal: false,
      showSelectPresetModal: false,
      scrollheight: 600,
      rawRrule: null,
      showStartDatePicker: false,
      snackbarInfo: null,
      showSnackbar: false,
      projectTemplate: {
        id: null,
        name: "",
        private: false,
        owner: null,
        data: {
          color: null,
          rrule: null,
          default_type: null,
          default_preset: null,
        },
      } as LoadlistGroup,
      nameRules: [
        (v: string) => !!v || "Name is required",
        (v: string) => v.length <= 50 || "Name must be less than 50 characters",
      ],
    };
  },
  computed: {
    activeGroup(): LoadlistGroup {
      return this.loadlist_groups.find(
        (f: LoadlistGroup) => f.id === this.activeGroupId
      );
    },
    loadlist_groups(): LoadlistGroup[] {
      return LoadlistStore.loadlistGroups;
    },
    user(): User {
      return UserCompanyStore.user;
    },
  },
  mounted() {
    this.setScrollheight();
  },
  methods: {
    setScrollheight(): void {
      const e = (this.$refs.navigation as any).$el as HTMLElement;
      this.scrollheight = e.clientHeight - 60;
    },
    updateActiveGroup(groupId: number): void {
      this.activeGroupId = groupId;
      this.$emit("activeGroupChange", groupId);
    },
    updateDefaultPreset(preset: LoadConfiguration): void {
      this.projectTemplate.data.default_preset = {
        id: preset.id,
        name: preset.name,
      } as PresetInfo;
    },
    saveProject(): void {
      this.isLoading = true;
      this.showEditProjectModal = false;
      this.projectTemplate.owner = this.projectTemplate.private
        ? this.user.id
        : null;
      let func = this.projectTemplate.id
        ? API.updateLoadlistGroup
        : API.createLoadlistGroup;
      func(this.projectTemplate)
        .then((data: APIResponse) => {
          this.syncGroups();
          this.isLoading = false;
          this.setEmptyProjectTemplate();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.snackbarInfo = getSerializerError(error.response.data);
          } else {
            this.snackbarInfo = "Unknown error when saving";
            console.log(error);
          }
          this.showSnackbar = true;
          this.isLoading = false;
        });
    },
    deleteProject(): void {
      this.isLoading = true;
      this.showConfirmDeleteProjectModal = false;
      API.deleteLoadlistGroup(this.activeGroupId)
        .then((data: APIResponse) => {
          this.updateActiveGroup(null);
          this.syncGroups();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    dropList(event: DragEvent, groupId: number): void {
      this.isLoading = true;
      const droppedLoadlistId = event.dataTransfer.getData("text");
      API.patchLoadlist({
        id: droppedLoadlistId,
        group: groupId ? groupId.toString() : "",
      })
        .then((data: APIResponse) => {
          this.updateActiveGroup(this.activeGroupId);
          this.$emit("loadlistAddedToGroup");

          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
        });
    },
    syncGroups(): void {
      LoadlistStore.syncGroups();
    },
    toRruleText(): string {
      if (!this.rawRrule) return null;
      try {
        return this.rawRrule.toText();
      } catch (error) {
        return null;
      }
    },
    newProject(): void {
      this.setEmptyProjectTemplate();
      this.showEditProjectModal = true;
    },
    setEmptyProjectTemplate(): void {
      this.projectTemplate = {
        id: null,
        name: "",
        private: false,
        owner: null,
        data: {
          color: null,
          rrule: null,
          default_type: null,
          default_preset: null,
        },
      };
      this.rawRrule = null;
    },
    editProject(item: LoadlistGroup): void {
      this.projectTemplate = JSON.parse(JSON.stringify(item));
      this.showEditProjectModal = true;
      try {
        this.rawRrule = RRule.fromString(item?.data?.rrule);
      } catch (error) {
        this.rawRrule = null;
      }
    },
    toRruleString(input: string): string {
      try {
        return RRule.fromText(input).toString();
      } catch (error) {
        return null;
      }
    },
  },
  watch: {
    groupId: {
      handler: function(groupId: number): void {
        this.activeGroupId = groupId;
      },
      immediate: true,
    },
  },
});
