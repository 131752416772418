import { UserCompanyStore } from "@/store/index";
interface UnitConversion {
  MM: number;
  CM: number;
  DM: number;
  IN: number;
  FT: number;
  MT: number;
  LB: number;
}

const TO_SI: UnitConversion = {
  MM: 0.001,
  CM: 0.01,
  DM: 0.1,
  IN: 0.0254,
  FT: 0.3048,
  MT: 1000,
  LB: 0.4536,
};
const FROM_SI: UnitConversion = {
  MM: 1000,
  CM: 100,
  DM: 10,
  IN: 39.37,
  FT: 3.28084,
  MT: 0.001,
  LB: 2.2046,
};

export default {
  toSI: (dim: string): number => TO_SI[dim as keyof UnitConversion] || 1,
  fromSI: (dim: string): number => FROM_SI[dim as keyof UnitConversion] || 1,
  roundForDim: (value: number, dim: string): number => {
    let roundingPrecision = 1;
    switch (dim) {
      case "M":
        roundingPrecision = 1000;
        break;
      case "DM":
        roundingPrecision = 100;
        break;
      case "FT":
      case "CM":
      case "IN":
        roundingPrecision = 10;
        break;
    }
    return Math.round(value * roundingPrecision) / roundingPrecision;
  },
  toFtAndIn: (value: number): string => {
    const ft = Math.floor(value / 12);
    const inch = value % 12;
    return `${ft > 0 ? `${ft}' ` : ""}${
      inch > 0 ? `${Math.round(inch * 10) / 10}"` : ""
    }`;
  },
  getVolumeUnit(): string {
    const unit = UserCompanyStore.length_dim;
    if (unit !== "IN") {
      return "M" + "\u00B3";
    } else {
      return "FT" + "\u00B3";
    }
  },
};
