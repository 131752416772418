



























































































































import Vue, { VueConstructor } from "vue";
import API from "@/API";
import TermsAndConditionsModal from "@/components/Modals/TermsAndConditions.vue";

interface Errors {
  username: string[];
  password: string[];
  email: string[];
  company: string[];
}

interface NewUser {
  username: string;
  password: string;
  email: string;
  company: string;
  invitation_code?: string;
}

export default Vue.extend({
  name: "register",
  components: {
    TermsAndConditionsModal,
  },
  data() {
    return {
      isLoading: false,
      new_user: {
        username: "",
        password: "",
        email: "",
        company: "",
      } as NewUser,
      usernameRules: [
        (v: string) => !!v || "Username is required",
        (v: string) => v.indexOf(" ") < 0 || "No whitespace in username",
        (v: string) =>
          (v && v.length <= 50) || "Username must be less than 50 characters",
      ],
      passwordRules: [
        (v: string) => !!v || "Password is required",
        (v: string) =>
          (v && v.length >= 9) || "Password must have at least 9 characters",
      ],
      emailRules: [
        (v: string) => !!v || "Company E-mail is required",
        (v: string) => /.+@.+/.test(v) || "Company E-mail must be valid",
        (v: string) => !v.includes("qq") || "Company email please",
      ],
      companyRules: [
        (v: string) => !!v || "Company name is required",
        (v: string) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      acceptTermRules: [(v: string) => !!v || "You must agree to continue"],
      errors: {
        username: [],
        password: [],
        email: [],
        company: [],
      } as Errors,
      acceptTerms: false,
      showPrivacyPolicy: false,
      showTocModal: false,
      showSuccessRegisterModal: false,
      privacyPolicy: "",
      valid: true,
    };
  },
  computed: {},
  mounted() {
    API.getPrivacyPolicy().then((response) => {
      this.privacyPolicy = response.data.data;
    });

    if (this.$route.query.code)
      this.new_user.invitation_code = this.$route.query.code as string;
    this.new_user.company = this.$route.query.c
      ? (this.$route.query.c as string)
      : "";
    this.new_user.email = this.$route.query.e
      ? (this.$route.query.e as string)
      : "";
  },
  methods: {
    register(): void {
      for (let errorName in this.errors) {
        this.errors[errorName as keyof Errors] = [];
      }

      if ((this.$refs.form as any).validate()) {
        this.isLoading = true;

        API.register(this.new_user)
          .then((response) => {
            this.isLoading = false;
            if ("invitation_code" in this.new_user) {
              window.location.replace(window.location.origin);
            } else {
              this.showSuccessRegisterModal = true;
            }
          })
          .catch((error) => {
            this.isLoading = false;

            if (error.response && error.response.data) {
              for (let errorName in error.response.data) {
                if (errorName in this.errors) {
                  this.errors[errorName as keyof Errors] =
                    error.response.data[errorName];
                }
              }
            }
          });
      }
    },
  },
});
