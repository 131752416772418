































import { EquipmentStore } from "@/store/index";
import { ListType } from "@/models/LoadlistModel";
import { SetType } from "@/models/SetsModel";
import Vue, { PropType } from "vue";

const SETS_PER_PAGE = 10;

export default Vue.extend({
  name: "sets-library",
  data() {
    return {
      searchString: "",
      currentPage: 1,
    };
  },
  props: {
    setType: {
      type: String as PropType<ListType>,
      required: false,
    },
  },
  computed: {
    sets(): SetType[] {
      return EquipmentStore.sets.filter(
        (i) =>
          (!this.setType || i.base_type === this.setType) &&
          (this.searchString
            ? i.name.toLowerCase().includes(this.searchString.toLowerCase())
            : true)
      );
    },
    paginatedSets(): SetType[] {
      return this.sets.slice(
        (this.currentPage - 1) * SETS_PER_PAGE,
        (this.currentPage - 1) * SETS_PER_PAGE + SETS_PER_PAGE
      );
    },
    numberOfPages(): number {
      return Math.ceil(Math.max(this.sets.length / SETS_PER_PAGE, 1));
    },
    isLoading(): boolean {
      return EquipmentStore.isLoading;
    },
    is_authenticated(): boolean {
      return this.$store.getters.is_authenticated;
    },
  },
  watch: {
    setType() {
      this.currentPage = 1;
    },
    searchString() {
      this.currentPage = 1;
    },
  },
  created() {},
  methods: {},
});
