




















import Vue from "vue";
export default Vue.extend({
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
    description: {
      default: "",
      type: String,
    },
    confirmButton: {
      default: "Yes",
      type: String,
    },
    denyButton: {
      default: "No",
      type: String,
    },
    confirmColor: {
      default: "primary",
      type: String,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
});
