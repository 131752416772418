



































































import Vue from "vue";
import API from "@/API";
import { UserCompanyStore } from "@/store";

export default Vue.extend({
  name: "login",
  data() {
    return {
      isLoading: false,
      email: "",
      formSent: false,
    };
  },
  computed: {
    registerAdress(): string {
      return API.url + "/register/";
    },
    passwordResetAdress(): string {
      return API.url + "/pwr";
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
  },
  methods: {
    login() {
      this.isLoading = true;

      API.magicLink({
        email: this.email.trim(),
      })
        .then(() => {
          this.isLoading = false;
          this.formSent = true;
        })
        .catch(() => {
          this.formSent = true;

          this.isLoading = false;
        });
    },
  },
});
