











































































import Vue from "vue";

import { Loadlist, Loadplan, UnloadedItem } from "@/models/LoadlistModel";
import { User } from "@/models/UserCompanyModel";
import { LoadlistStore, UserCompanyStore } from "@/store/index";
import LoadlistSummary from "../Custom/LoadlistSummary.vue";

export default Vue.extend({
  name: "loadlist-info",
  components: { LoadlistSummary },
  data() {
    return {};
  },
  computed: {
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },

    total_unloaded_items(): number {
      return this.unloadedItems
        .map((i) => i.qty || 0)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0);
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    unloadedItems(): UnloadedItem[] {
      return LoadlistStore.unloaded_items;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    logoUrl(): string {
      return LoadlistStore.logo_url;
    },
    user(): User {
      return UserCompanyStore.user;
    },
    numberOfCustomRules(): number {
      return this.loadplan?.settings?.rules?.length;
    },
    segregationTableInUse(): boolean {
      return (
        !!this.loadplan?.settings?.segregation_table &&
        this.loadlist.data.some((i) => !!i.class_id)
      );
    },
  },
  methods: {},
});
