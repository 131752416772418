import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";
import API from "@/API";
import LoadlistModule from "@/store/modules/loadlist";
import EquipmentModule from "./modules/equipmentStore";
import Worker from "worker-loader!../workers/unloaded_items.worker.ts";
import { getModule } from "vuex-module-decorators";
import UserCompanyModule from "./modules/userCompany";
import CargoModule from "./modules/cargoStore";
import { UnloadedItem } from "@/models/LoadlistModel";
import { getSerializerError } from "@/misc/errorUtils";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

let previousHtml: string = undefined;

const store = new Vuex.Store({
  strict: debug,
  modules: {
    EquipmentModule,
    LoadlistModule,
    CargoModule,
    UserCompanyModule,
  },
  state: {
    error_message: null,
    showAccessCodeRequiredModal: false,
  },
  mutations: {
    setErrorMessage(state, message) {
      state.error_message = message;
    },
    setShowAccessCodeRequiredModal(state, value) {
      state.showAccessCodeRequiredModal = value;
    },
  },
  getters: {
    error_message: (state) => state.error_message,
  },
  actions: {
    checkForUpdate() {
      return new Promise((resolve, reject) => {
        const pathArray = location.href.split("/");
        const url = pathArray[0] + "//" + pathArray[2];

        axios
          .get(`${url}/index.html?timestamp=${new Date().getTime()}`)
          .then((response) => {
            const responseData = JSON.stringify(response.data);
            if (!previousHtml) {
              previousHtml = responseData;
              resolve(false);
            } else if (previousHtml !== responseData) {
              previousHtml = responseData;
              if (UserCompanyStore.is_authenticated) {
                resolve(true);
              } else {
                window.location.reload();
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default store;
export const LoadlistStore = getModule(LoadlistModule, store);
export const EquipmentStore = getModule(EquipmentModule, store);
export const UserCompanyStore = getModule(UserCompanyModule, store);
export const CargoStore = getModule(CargoModule, store);

API.instance.interceptors.response.use(null, (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401: {
        const auth_required = router.currentRoute.matched.length
          ? !!router.currentRoute.matched[0].meta.auth_required
          : true;
        if (auth_required)
          UserCompanyStore.logout(router.currentRoute.fullPath);
        break;
      }
      case 403: {
        // if (router.currentRoute.name !== "login") {
        //   store.commit("setErrorMessage", "Logged out");
        //   UserCompanyStore.logout(router.currentRoute.fullPath);
        // }
        let dataResponse = error?.response?.data?.toString()
        if (dataResponse &&
          dataResponse.indexOf("1020") >= 0 &&
          dataResponse.toUpperCase().indexOf("CLOUDFLARE") >= 0
        ) {
          store.commit("setShowAccessCodeRequiredModal", true);
        }
        break;
      }
      case 409: {
        const message = getSerializerError(error.response.data.data);
        store.commit("setErrorMessage", message);
        break;
      }
      case 429: {
        const retry_after = error?.response?.headers?.["retry-after"];
        const message = retry_after
          ? `There seems to be too many requests at the moment. Please wait for ${retry_after} seconds and try again`
          : "There seems to be too many requests at the moment. Please wait a minute and try again";
        store.commit("setErrorMessage", message);
        break;
      }
      case 500: {
        store.commit(
          "setErrorMessage",
          "Internal server error - Try refreshing this page"
        );
        break;
      }
    }
  } else {
    store.commit(
      "setErrorMessage",
      "There seems to be connection issues at the moment. Please try again later"
    );

    if (error.request) {
      console.log("Error - no response");
    } else {
      console.log("Error", error.message);
    }
  }

  return Promise.reject(error);
});
const urlParams = new URLSearchParams(window.location.search);

// Add a request interceptor

API.instance.interceptors.request.use(
  function (config) {
    if (!store.getters.is_authenticated && urlParams.get("sesame")) {
      config.params = config.params || {};
      config.params["sesame"] = urlParams.get("sesame");
      urlParams.delete("sesame"); //Query string is now: 'bar=2'
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

if (!urlParams.get("no_fetch")) {
  // Try to get profile and then download system holds
  UserCompanyStore.getMe().finally(() => {
    EquipmentStore.getAllSystemEquipment();
  });
}

store.watch(
  (_, getters) => getters.loadplan_holds, // TODO update
  (newValue) => {
    if (newValue)
      worker.postMessage({
        loadlist: store.getters?.loadlist?.data,
        holds: store.getters?.loadplan?.holds,
        reasons: store.getters?.unloaded_items_with_reasons,
      });
  }
);

store.watch(
  (_, getters) => getters?.loadlist?.data, // TODO update
  (newValue) => {
    if (newValue)
      worker.postMessage({
        loadlist: store.getters?.loadlist?.data,
        holds: store.getters?.loadplan?.holds,
        reasons: store.getters?.unloaded_items_with_reasons,
      });
  }
);

const worker = new Worker();
worker.onmessage = (e: { data: UnloadedItem[] }) => {
  LoadlistStore.setUnloadedItems(e.data);
  LoadlistStore.updateUnloadedItemsWithReasons([]);
};
