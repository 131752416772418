


























































import Vue from "vue";
import API from "@/API";

export default Vue.extend({
  name: "sso-login",
  data() {
    return {
      showWrongLoginDetailsAlert: false,
      isLoading: false,
      email: "",
      error: false,
    };
  },
  computed: {
    domain(): string | null {
      let index = this.email.indexOf("@");
      if (index >= 0)
        return this.email
          .slice(index + 1, this.email.length)
          .trim()
          .toLowerCase();
      return null;
    },
  },
  methods: {
    get_redirect_url(): void {
      this.isLoading = true;
      this.error = false;

      API.ssoGetAuthUrl(this.domain)
        .then((data) => {
          window.location.replace(data.data.url);

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.error = true;
        });
    },
  },
});
