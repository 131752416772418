












































































































































import Vue, { VueConstructor } from "vue";
import loadlistsTable from "./TableView.vue";
import loadlistsCalendar from "./CalendarView.vue";
import projects from "./Projects.vue";
import { LoadlistStore } from "@/store/index";
import { LoadlistGroup } from "@/models/LoadlistModel";
import { UserCompanyStore } from "@/store/index";
import { User } from "@/models/UserCompanyModel";
import NewListModal from "@/components/Modals/NewList.vue";
import { UsageSettings } from "../../store/usageSettings";
import { getExamples } from "@/misc/exampleLists";
import API from "@/API";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      listTable: InstanceType<typeof loadlistsTable>;
      projects: InstanceType<typeof projects>;
    };
  }
>).extend({
  name: "loadlists",
  components: {
    loadlistsTable,
    loadlistsCalendar,
    projects,
    NewListModal,
  },
  data() {
    return {
      usageSettings: UsageSettings.fetch(),
      activeGroupId: null as number | null,
      showNoEmailModal: false,
      forceUpdate: 0,
      searchFocused: false,
      searchString: "",
      search: "",
      lastEditTimeout: null as number | null,
      showAddNewLoadlistModal: false,
    };
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    searchString: function(val) {
      if (this.lastEditTimeout) clearTimeout(this.lastEditTimeout);
      if (!val) {
        this.search = val;
      } else {
        // Waiting to update the search result to avoid many queries when searching.
        let f: TimerHandler = () => {
          this.search = val;
        };
        this.lastEditTimeout = setTimeout(f, 500);
      }
    },
  },
  computed: {
    user(): User {
      return UserCompanyStore.user;
    },
    isAuthenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    tabs(): any[] {
      return [
        { value: "list", icon: "mdi-format-list-bulleted" },
        { value: "calendar", icon: "mdi-calendar" },
      ];
    },
    loadlist_groups(): LoadlistGroup[] {
      return LoadlistStore.loadlistGroups;
    },
  },
  created(): void {
    if (this.$route.query.group) {
      this.activeGroupId = parseInt(String(this.$route.query.group)) || null;
    }
    if (this.$route.query.search) {
      this.search = String(this.$route.query.search);
    }
  },
  mounted(): void {
    if (this.isAuthenticated && !this.user.email) this.showNoEmailModal = true;
  },
  methods: {
    generateExamples() {
      let groups = LoadlistStore.loadlistGroups;
      let exampleGroup = groups.find((g) => g.name === "Examples");
      if (exampleGroup) {
        // We already have an examples group. Switch to that instead
        this.activeGroupId = exampleGroup.id;
        return;
      }
      API.createLoadlistGroup({ name: "Examples" })
        .then((e) => {
          let group = e.data.id;
          getExamples()
            .then((lists) =>
              Promise.all(
                lists.map((loadlist) => {
                  loadlist.group = group;
                  return API.createLoadlist(loadlist as any);
                })
              )
            )
            .finally(() => {
              // "refresh"
              LoadlistStore.syncGroups().then(() => {
                this.activeGroupId = group;
                this.forceUpdate++;
              });
            })
            .catch((e) => console.error("creating loadlists", e));
        })
        .catch((e) => console.log("creating loadlist group", e));
    },
    updateActiveGroup(groupId: number): void {
      this.activeGroupId = groupId;
    },
    refreshLoadlists(): void {
      this.forceUpdate++;
    },
  },
});
