




















































































import Vue from "vue";
import FileSaver from "file-saver";
import ExcelService from "@/services/excelService.ts";
import TableUtils from "@/misc/tableUtils";
import { Loadlist, Loadplan, HoldInputItem } from "@/models/LoadlistModel";
import { Workbook } from "exceljs";
import { LoadlistStore } from "@/store/index";

interface SummaryResult {
  no: number;
  volume: number;
  weight: number;
}

export default Vue.extend({
  name: "loadlist",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    summary(): SummaryResult {
      const result: SummaryResult = {
        no: 0,
        volume: 0,
        weight: 0,
      };

      this.loadlist.data.forEach((item) => {
        if (item.qty > 0) {
          result.no += item.qty;
          result.weight +=
            item.wt * this.$toSI(this.loadlist.weight_dim) * item.qty;
          result.volume +=
            item.l *
            item.w *
            item.h *
            Math.pow(this.$toSI(this.loadlist.length_dim), 3) *
            item.qty;
        }
      });

      return result;
    },
    length_dim(): string {
      return this.loadlist.length_dim.toLowerCase();
    },
    weight_dim(): string {
      return this.loadlist.weight_dim.toLowerCase();
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
  },
  methods: {
    freightTonnes(item: HoldInputItem): number {
      const volume =
        item.l *
        item.w *
        item.h *
        Math.pow(this.$toSI(this.loadlist.length_dim), 3);
      const weight = (item.wt * this.$toSI(this.loadlist.weight_dim)) / 1000;
      return Math.max(volume, weight);
    },
    print(): void {
      this.$hideChatWidget();
      this.$nextTick(() => {
        setTimeout(() => {
          window.print();
        }, 200);
      });
    },
    getXlsx(): void {
      this.isLoading = true;
      const loadExcelJS = () => import("exceljs");
      loadExcelJS()
        .then((exceljs) => {
          const excelService = new ExcelService();
          const tables = document.getElementsByTagName("table");
          const tablesJson = [...tables].map((table) => {
            return TableUtils.loadTableToJson(table);
          });
          const workbook = excelService.loadlistToXlsx(new exceljs.Workbook(), {
            loadlist: this.loadlist,
            tablesJson,
          });
          this.saveLoadlistFile("Loadlist - " + this.loadlist.name, workbook);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    async saveLoadlistFile(
      fileName: string,
      workbook: Workbook
    ): Promise<void> {
      const xls64 = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(
        new Blob([xls64], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        fileName
      );
    },
  },
});
