






































































































































































































































































import Vue, { PropType } from "vue";
import createHold from "@/misc/containerUtils";
import { HoldData, ListType, SubHoldProperties } from "@/models/LoadlistModel";

export default Vue.extend({
  name: "modify-hold",
  data() {
    return {
      renderKey: 1,
      modified_hold: JSON.parse(JSON.stringify(this.value)) as HoldData,
    };
  },
  props: {
    value: Object as PropType<HoldData>,
    basetype: String as PropType<ListType>,
    minQty: {
      type: Number,
      default: 1,
    },
    editableName: Boolean,
    hideMaxValues: Boolean,
    hideDimensions: Boolean,
    hideAxles: Boolean,
    hideQtyCost: Boolean,
    hideBottomSpacer: Boolean,
    showSubHoldProps: Boolean,
  },
  computed: {
    typeName(): string {
      return this.$typeNames(this.basetype);
    },
    subHoldPropertiesList(): { text: string; key: keyof SubHoldProperties }[] {
      return [
        {
          text: "Not stackable",
          key: "not_stackable",
        },
        {
          text: "Bottom only",
          key: "bottom_only",
        },
        {
          text: "Longship",
          key: "not_rotatable",
        },
      ];
    },
  },
  watch: {
    modified_hold: {
      handler: function(val: HoldData): void {
        let modified_hold = createHold.assemble(
          JSON.parse(JSON.stringify(val))
        );
        this.$emit("input", modified_hold);
        this.renderKey++;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setQty($event: any): void {
      const qty = parseInt($event);
      let v = isNaN(qty) || qty < this.minQty ? null : qty;
      this.$set(this.modified_hold, "qty", v);
    },
    setSubHoldProperty(key: string, value: boolean) {
      if (!this.modified_hold.properties) {
        this.$set(this.modified_hold, "properties", {});
      }
      this.$set(this.modified_hold.properties, key, value);
    },
  },
});
