











































































import Vue, { PropType, set } from "vue";
import {
  AddContainersInfo,
  Configuration,
  ContainerClickInfo,
  SelectedContainerInfo,
  SetType,
} from "@/models/SetsModel";
import setLayoutComponent from "@/components/SetBuilder/LayoutSection.vue";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import containerDetailsComponent from "@/components/SetBuilder/ContainerDetails.vue";
import addContainerComponent from "@/components/SetBuilder/AddContainer.vue";
import { SceneManager } from "@/graphics/sceneManager";
import { AugmentedSet } from "@/models/augmented/set";
import {
  VContainer,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VIcon,
} from "vuetify/lib";
import {
  HoldData,
  HoldDataPreview,
  HoldType,
  ListType,
  Point,
} from "@/models/LoadlistModel";
export default Vue.extend({
  name: "set_builder",
  props: {
    set: Object as PropType<SetType>,
    displayConfiguration: Array as PropType<number[]>,
    isActive: Boolean,
  },
  components: {
    setLayoutComponent,
    sceneComponent,
    containerDetailsComponent,
    addContainerComponent,
  },
  data() {
    return {
      viewSettings: undefined,
      renderedSet: null as AugmentedSet,
      renderKey: 1,
      selectedContainer: null as SelectedContainerInfo,
      currentBaseType: this.$route.params.type as ListType,
      showPreviewContainer: false,
      previewContainers: [] as HoldDataPreview[],
      panels: 0,
    };
  },
  created() {
    SceneManager.eventBus.on("select-container", this.onContainerClick);
    SceneManager.eventBus.on("render-done", this.highlightContainer);
  },
  beforeDestroy() {
    SceneManager.eventBus.off("select-container", this.onContainerClick);
    SceneManager.eventBus.off("render-done", this.highlightContainer);
    SceneManager.clearScene();
  },
  methods: {
    openAddContainerFromLayout(slotIndex: number) {
      const lastContainer =
        this.getLastContainerInSlot(slotIndex) ||
        this.getLastSelectedContainer();
      if (lastContainer) {
        this.selectedContainer = { ...lastContainer, slotIndex };
      }
      this.panels = 1;
    },
    selectContainerFromLayout(slotIndex: number, holdType: HoldType) {
      this.selectedContainer = this.getSelectedContainerFromHoldType(
        slotIndex,
        holdType
      );
      this.highlightContainer();
      this.panels = 2;
    },
    getSelectedContainerFromHoldType(slotIndex: number, holdType: HoldType) {
      return {
        slotIndex,
        holdType,
        containerIndex: this.getContainerIndexFromPosition(holdType.position),
      };
    },
    setLatestSelectedContainer(slotIndex?: number) {
      this.selectedContainer = this.getLastSelectedContainer(slotIndex);
      this.highlightContainer();
    },
    reorder() {
      this.selectedContainer = undefined;
    },
    rerender() {
      this.viewSettings = SceneManager.getViewSettings();
      if (this.viewSettings.pos[0]) {
        this.viewSettings.view = "custom";
      }
      this.renderedSet = AugmentedSet.fromSetType(
        this.set.data,
        this.displayConfiguration
      );
      if (this.showPreviewContainer && this.previewContainers.length > 0) {
        this.renderedSet.rendering = [
          ...this.renderedSet.rendering,
          ...this.previewContainers.map((holdData) =>
            JSON.parse(JSON.stringify(holdData))
          ),
        ];
      }
      this.renderKey++;
    },
    // triggered when group is updated
    updateSlotForContainer(updateSlotInfo: {
      oldSlotIndex: number;
      newSlotIndex: number;
      holdType: HoldType;
    }) {
      // Remove from old
      this.displayedSlotConfiguration(
        updateSlotInfo.oldSlotIndex
      ).group = this.displayedSlotConfiguration(
        updateSlotInfo.oldSlotIndex
      ).group.filter((ht) => ht !== updateSlotInfo.holdType);
      // Add to new
      this.displayedSlotConfiguration(updateSlotInfo.newSlotIndex).group = [
        ...this.displayedSlotConfiguration(updateSlotInfo.newSlotIndex).group,
        updateSlotInfo.holdType,
      ];
      this.rerender();
      this.selectedContainer = {
        holdType: updateSlotInfo.holdType,
        slotIndex: updateSlotInfo.newSlotIndex,
        containerIndex: this.getContainerIndexFromPosition(
          updateSlotInfo.holdType.position
        ),
      };
      this.rerender();
    },
    highlightContainer() {
      if (this.isActive && this.selectedContainer) {
        SceneManager.eventBus.emit(
          "highlight-container",
          this.selectedContainer.containerIndex
        );
      }
    },
    onContainerClick(containerClickInfo: ContainerClickInfo) {
      if (containerClickInfo) {
        let i = 0;
        for (
          let slot_index = 0;
          slot_index < this.set.data.slots.length;
          slot_index++
        ) {
          const g = this.displayedSlotConfiguration(slot_index).group;
          for (let j = 0; j < g.length; j++) {
            if (i == containerClickInfo.containerIndex) {
              this.updateSelectedContainer(
                g[j],
                containerClickInfo.containerIndex,
                slot_index
              );
              return;
            }
            i++;
          }
        }
      } else {
        this.selectedContainer = null;
      }
    },
    displayedSlotConfiguration(slot_index: number): Configuration {
      return this.set.data.slots[slot_index].configurations[
        this.displayConfiguration[slot_index]
      ];
    },
    updateSelectedContainer(
      positionedHoldType: HoldType,
      containerIndex: number,
      slotIndex: number
    ) {
      this.selectedContainer = {
        holdType: positionedHoldType,
        containerIndex,
        slotIndex,
      };
      this.highlightContainer();
    },
    addPreviewContainers(holdDataPreviews: HoldDataPreview[]) {
      this.previewContainers = holdDataPreviews.map((holdDataPreview) => {
        const containerType = JSON.parse(
          JSON.stringify(holdDataPreview)
        ) as HoldDataPreview;
        if (!containerType.id) containerType.id = this.generateID();
        if (!containerType.base_type)
          containerType.base_type = this.currentBaseType;
        return containerType;
      });
      this.rerender();
    },
    addContainersToSet(containersInfo: AddContainersInfo) {
      containersInfo.holdDatas.forEach((holdDataPreview, index) => {
        const hd = {
          ...holdDataPreview,
          preview: false,
        };
        const holdType = {
          rotation: hd.rotation,
          position: hd.position,
          container_type_id: hd.id,
        };
        // add to container types if not yet present
        if (!this.containerOf(holdType)) {
          const containerType: HoldDataPreview = {
            ...JSON.parse(JSON.stringify(hd)),
            position: { x: 0, y: 0, z: 0 },
            rotation: [0, 0, 0, "XYZ"],
          };
          this.set.data.container_types.push(containerType);
        }
        this.displayedSlotConfiguration(containersInfo.slotIndex).group.push(
          holdType
        );
        this.rerender();
        if (index === 0) {
          this.setLatestSelectedContainer(containersInfo.slotIndex);
        }
      });
    },
    generateID(): number {
      return parseInt(String(new Date().getTime()).substr(-8));
    },
    getLastContainerInSlot(slotIndex: number): SelectedContainerInfo {
      const group = this.displayedSlotConfiguration(slotIndex).group;
      for (let j = group.length - 1; j >= 0; j--) {
        return this.getSelectedContainerFromHoldType(slotIndex, group[j]);
      }
      return undefined;
    },
    getContainerIndexFromPosition(position: Point) {
      if (this.renderedSet) {
        const containerIndex = this.renderedSet.rendering.findIndex(
          (hd) =>
            hd.position.x === position.x &&
            hd.position.y === position.y &&
            hd.position.z === position.z
        );
        return containerIndex > -1 ? containerIndex : undefined;
      }
      return undefined;
    },
    getLastSelectedContainer(slotIndex?: number): SelectedContainerInfo {
      if (slotIndex !== undefined) {
        return this.getLastContainerInSlot(slotIndex);
      }
      for (let i = this.set.data.slots.length - 1; i >= 0; i--) {
        const container = this.getLastContainerInSlot(i);
        if (container) {
          return container;
        }
      }
      return undefined;
    },
    getLastHoldType() {
      for (let i = this.set.data.slots.length - 1; i >= 0; i--) {
        let group = this.displayedSlotConfiguration(i).group;
        for (let j = group.length - 1; j >= 0; j--) {
          return group[j];
        }
      }
    },
    removeContainer(deleteInfo: { slotIndex: number; holdType: HoldType }) {
      this.displayedSlotConfiguration(
        deleteInfo.slotIndex
      ).group = this.displayedSlotConfiguration(
        deleteInfo.slotIndex
      ).group.filter((ht) => ht !== deleteInfo.holdType);

      this.setLatestSelectedContainer(deleteInfo.slotIndex);
    },
    containerOf(ht: HoldType): HoldData {
      const hold = this.set.data.container_types.find(
        (ct) => ct.id === ht.container_type_id
      );
      if (hold) {
        return {
          ...this.set.data.container_types.find(
            (ct) => ct.id === ht.container_type_id
          ),
          ...(ht.props
            ? Object.fromEntries(
                Object.entries(ht.props).filter(([_, v]) => v != null)
              )
            : ht.props),
        };
      }
      return undefined;
    },
  },
  watch: {
    isActive: {
      handler: function(val: SetType): void {
        if (val) {
          setTimeout(() => {
            this.rerender();
          }, 250);
        }
      },
      immediate: true,
    },
    set: {
      handler: function(val: SetType): void {
        this.rerender();
      },
      deep: true,
    },
    panels: {
      handler: function(newVal: number, oldVal: number): void {
        // show/hide preview containers based on if add container panel is open
        if (newVal === 1) {
          this.showPreviewContainer = true;
          this.rerender();
        } else if (oldVal === 1) {
          this.showPreviewContainer = false;
          this.rerender();
        }
      },
    },
  },
});
