















































import Vue, { PropType } from "vue";
import API from "@/API";
import { APIResponse } from "@/models/APIModel";
import { LoadlistBase, ListType } from "@/models/LoadlistModel";
export default Vue.extend({
  name: "copy-loadlist",
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    name: {
      default: "",
      type: String,
    },
    loadlistToCopy: Object as PropType<LoadlistBase>,
  },
  data() {
    return {
      isLoading: false,
      loadlistCopyId: null as string,
      newLoadlist: {
        name: "",
        list_type: (localStorage.getItem("cplLastLoadlistType") ||
          "SEA") as ListType,
        data: [],
        notes: "",
        with_loadplan: false,
      },
      newLoadlistValid: false,
      nameRules: [
        (v: string) => !!v || "Name is required",
        (v: string) => v.length <= 50 || "Name must be less than 50 characters",
      ],
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    loadlistToCopy: {
      handler: function(selectedList: LoadlistBase) {
        this.loadlistCopyId = selectedList.id;
        this.newLoadlist.name = selectedList.name + " copy";
      },
      immediate: true,
    },
  },
  methods: {
    copyList(): void {
      this.isLoading = true;
      API.copyLoadlist(this.loadlistCopyId, this.newLoadlist)
        .then((response: APIResponse) => {
          this.$router.push({ name: "loadlists", params: { page: "1" } });
          this.$emit("close", true);
        })
        .catch((error: any) => {
          console.log("Error copying loadlist");
          this.$emit("close", false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
