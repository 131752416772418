





























import { Loadplan } from "@/models/LoadlistModel";
import { LoadlistStore } from "@/store";
import Vue from "vue";

export default Vue.extend({
  name: "calculation-modal",
  watch: {
    calculation_info_message(a: string): void {
      if (a) {
        this.showSnackbar = true;
      }
    },
    is_calculating(a: boolean): void {
      if (a) {
        this.elapsedSeconds = 0;
        this.elapsedMillis = 0;
        this.calculationTimer = setInterval(() => {
          this.elapsedMillis += 100;
          if (this.elapsedMillis % 1000 === 0) {
            this.elapsedSeconds += 1;
            if (!this.is_calculating || this.elapsedSeconds >= this.timeout)
              clearInterval(this.calculationTimer);
          }
        }, 100);
      }
    },
  },
  computed: {
    progress(): number {
      return (this.elapsedMillis / (this.timeout * 1000)) * 100;
    },
    timeout(): number {
      if (this.loadplan && this.loadplan.settings)
        return this.loadplan.settings["timeout"] || 0;
      return 0;
    },
    is_calculating(): boolean {
      return LoadlistStore.is_calculating;
    },
    loadplan(): Loadplan {
      return LoadlistStore.loadplan;
    },
    calculation_info_message(): string {
      return LoadlistStore.calculation_info_message;
    },
  },
  data() {
    return {
      elapsedSeconds: 0,
      elapsedMillis: 0,
      calculationTimer: null as number,
      showSnackbar: false,
    };
  },
});
