

























































































// import createHold from "@/misc/containerUtils.js";
import Vue from "vue";
import inlineHoldEditComponent from "@/components/Custom/InlineHoldEdit.vue";
// TODO: turn into ts
export default Vue.extend({
  name: "modify-set",
  components: {
    inlineHoldEditComponent,
  },
  data() {
    return {
      renderKey: 1,
      modified_set: JSON.parse(JSON.stringify(this.value)),
    };
  },
  props: ["value", "basetype"],
  computed: {
    typeName(): string {
      return this.$typeNames(this.basetype);
    },
  },
  watch: {
    modified_set: {
      handler: function() {
        // let modified_set = createHold.assemble(
        //   JSON.parse(JSON.stringify(val))
        // );
        this.$emit("input", this.modified_set);
        // console.log(this.modified_set);

        this.renderKey++;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setName(): string {
      return this.$setNames(this.modified_set.base_type);
    },
  },
});
