
















































































































































































































































































































































































import Vue, { PropType } from "vue";

import { AugmentedHold } from "@/models/augmented/hold";
import sceneComponent from "@/components/Custom/SceneComponent.vue";
import annotationsComponent from "@/components/Custom/Annotations.vue";
import { VIEWS } from "@/graphics/sceneManager";
import { Views } from "@/models/GraphicsModel";
import { LoadlistStore, UserCompanyStore } from "@/store";
import { CompanySettings } from "@/models/UserCompanyModel";
import ContainerTable from "./Table.vue";
import setDisplay from "./SetDisplay.vue";
import loadplanInstructions from "./Instructions.vue";
import { CargoLayer, HoldItem, Loadplan } from "@/models/LoadlistModel";

export default Vue.extend({
  name: "loadplan-hold",
  components: {
    sceneComponent,
    ContainerTable,
    setDisplay,
    loadplanInstructions,
    annotationsComponent,
  },
  props: {
    simpleMode: Boolean,
    showRuler: Boolean,
    showQuotations: Boolean,
    currencySymbol: String,
    shipping_factor: Number,
    holdQuotations: Number,
    lengthDimensions: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    weightDimensions: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    presentationSettings: {
      type: Object as PropType<CompanySettings>,
      default: () => {},
    },
    container: Object as PropType<AugmentedHold>,
    showInstructions: Boolean,
    locked: Boolean,
  },
  data() {
    return {
      cargoLayers: [] as CargoLayer[],
      sceneRendered: false,
    };
  },
  computed: {
    views(): { [key: string]: Views } {
      return VIEWS;
    },
    cargoesInOrder(): HoldItem[] {
      return this.cargoLayers.flatMap(({ cargoes }) => {
        return cargoes.map((index) => this.container.hold.items[index]);
      });
    },
    uniquePallets(): {
      container: AugmentedHold;
      count: number;
      label: string;
    }[] {
      const distinct: {
        [key: string]: {
          container: AugmentedHold;
          count: number;
          label: string;
        };
      } = {};
      this.container.cargoes
        .map((item) => {
          return { pallet: item.from_container, label: item.label };
        })
        .filter((data) => data.pallet)
        .forEach((data) => {
          if (!distinct[data.pallet.uuid]) {
            distinct[data.pallet.uuid] = {
              container: new AugmentedHold(data.pallet),
              count: 1,
              label: data.label,
            };
          } else {
            distinct[data.pallet.uuid].count += 1;
          }
        });
      return Object.entries(distinct).map((entry) => entry[1]);
    },
    length_dim(): string {
      return UserCompanyStore.length_dim;
    },
    weight_dim(): string {
      return UserCompanyStore.weight_dim;
    },
    readonly(): boolean {
      return LoadlistStore.readonly;
    },
  },

  methods: {},
});
