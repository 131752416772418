
























import Vue from "vue";
import API from "@/API";
import { APIResponse } from "@/models/APIModel";
import { LoadlistStore } from "@/store";

export default Vue.extend({
  name: "delete-loadlist",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loadlistName: {
      default: "",
      type: String,
    },
    loadlistId: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    deleteList(): void {
      this.isLoading = true;
      API.deleteLoadlist(this.loadlistId)
        .then((success: APIResponse) => {
          this.clearLoadlist();
          this.$emit("deleted", true);
        })
        .catch((error: any) => {
          this.$emit("close", false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearLoadlist(): void {
      LoadlistStore.clearLoadlist();
    },
  },
});
