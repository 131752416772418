























































































































































import Vue from "vue";
import ItemProperties, {
  CustomItemProperty,
  ItemProperty,
} from "@/misc/itemProperties";
import {
  CompanySerializer,
  CompanySettings,
  UserPreferences,
} from "@/models/UserCompanyModel";
import { UserCompanyStore } from "@/store/index";
import API from "@/API";

export default Vue.extend({
  props: {
    visible: Boolean,
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    tabs(): { key: string; text: string }[] {
      return [
        { key: "visible_columns", text: "Visible Columns" },
        { key: "custom_columns", text: "Custom columns" },
      ];
    },
    defaultColumns(): ItemProperty[] {
      return ItemProperties.props().filter((i) => !i.required && !i.readOnly);
    },
    preferences(): UserPreferences {
      return UserCompanyStore.preferences;
    },
    companySettings(): CompanySettings {
      return UserCompanyStore.company_settings;
    },
    columnFormRef(): any {
      return this.$refs.columnForm;
    },
    company(): CompanySerializer {
      return UserCompanyStore.company;
    },
    isEditor(): boolean {
      return UserCompanyStore.user.is_editor;
    },
  },
  watch: {
    preferences: {
      handler(val: UserPreferences): void {
        if (val?.hidden_input_columns)
          this.hiddenColumns = JSON.parse(
            JSON.stringify(val.hidden_input_columns)
          );
      },
      immediate: true,
    },
    companySettings: {
      handler(val: CompanySettings): void {
        if (val?.extra_columns)
          this.customColumns = JSON.parse(JSON.stringify(val.extra_columns));
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      validColumnForm: false,
      showSnackbar: false,
      tab: null as number,
      customColumns: [] as CustomItemProperty[],
      hiddenColumns: [] as string[],
      newColumn: {
        name: "",
        summable: false,
        desc: "",
      } as CustomItemProperty,
      columnNameRules: [
        (v: string) => !!v || "Name is required",
        (v: string) =>
          (v && v.length <= 20) || "Name must be less than 20 characters",
        (v: string) =>
          (v &&
            !this.$data.customColumns
              .map((i: CustomItemProperty) => i.name)
              .includes(v)) ||
          "Name is already taken",
        (v: string) =>
          (v &&
            !ItemProperties.props()
              .map((i) => i.key)
              .includes(v)) ||
          "Reserved name",
      ],
    };
  },
  methods: {
    update(): void {
      if (this.tab === 0)
        UserCompanyStore.updateMe({
          preferences: {
            hidden_input_columns: this.hiddenColumns,
          },
        }).then((_) => (this.showSnackbar = true));
      else
        UserCompanyStore.updateCompanySettings({
          extra_columns: this.customColumns,
        }).then((_) => {
          this.showSnackbar = true;
        });
    },
    toggleColumn(key: string): void {
      const index = this.hiddenColumns.indexOf(key);
      if (index >= 0) {
        this.hiddenColumns.splice(index, 1);
      } else {
        this.hiddenColumns.push(key);
      }
    },
    addColumn(): void {
      if (this.columnFormRef[0].validate()) {
        this.customColumns.push(JSON.parse(JSON.stringify(this.newColumn)));
      }
    },
  },
});
