import {
  Cache,
  Mesh,
  Object3D,
  CylinderBufferGeometry,
  BoxBufferGeometry,
  BufferAttribute,
  BufferGeometry,
  MeshBasicMaterial,
  MeshLambertMaterial,
  LineBasicMaterial,
  Line,
  PlaneBufferGeometry,
  DoubleSide,
  Vector3,
  Box3,
  EdgesGeometry,
  LineSegments,
  Group,
  Scene,
  TextureLoader,
  Texture,
} from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import {
  mergeBufferGeometries,
  mergeVertices,
} from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { palette } from "@/misc/colorUtils";
import { Contours, HoldData } from "@/models/LoadlistModel";

const wallColor = palette.clouds.darkest;
const doorColor = palette.emerald.lighter;
const floorColor = palette.clouds.darker;
const helperColor = "red";
const maxHeightColor = 0xff0000;
const wheelColor = 0x444444;

function createContainerPlanePart(vertices: Float32Array[]) {
  const itemSize = 3;
  const a = new Float32Array(vertices.length * itemSize);
  const geometry = new BufferGeometry();

  vertices.forEach((v, index) => {
    a.set(v, index * itemSize);
  });

  geometry.setAttribute("position", new BufferAttribute(a, itemSize));
  geometry.computeVertexNormals();
  return geometry;
}

async function createContainerParts(
  container_data: HoldData,
  containerMesh: Group
): Promise<void> {
  container_data = {
    ...container_data,
    axles: container_data.axles || {},
    tables: container_data.tables || {},
    posts: container_data.posts || {},
    contours: container_data.contours || {},
    door: container_data.door || { H: 0, W: 0 },
  };

  function getContour(key: string) {
    return container_data.contours[key as keyof Contours] || 0;
  }

  const bedHeight = Math.max(container_data.floor_height || 0.0, 0.001);
  const topContoursMaxHeight = Math.max(
    getContour("front_top_contour_h"),
    getContour("rear_top_contour_h"),
    getContour("side1_top_contour_h"),
    getContour("side2_top_contour_h")
  );
  const hasTopContours = !!topContoursMaxHeight;
  const insideHeight = container_data.H;

  const extremePoints = {
    // Bottom level
    0: [
      new Float32Array([
        -container_data.L * 0.5 + getContour("front_bottom_contour_l"),
        -container_data.W * 0.5 + getContour("side1_bottom_contour_l"),
        -container_data.H * 0.5,
      ]),
      new Float32Array([
        -container_data.L * 0.5 + getContour("front_bottom_contour_l"),
        container_data.W * 0.5 - getContour("side2_bottom_contour_l"),
        -container_data.H * 0.5,
      ]),
      new Float32Array([
        container_data.L * 0.5 - getContour("rear_bottom_contour_l"),
        container_data.W * 0.5 - getContour("side2_bottom_contour_l"),
        -container_data.H * 0.5,
      ]),
      new Float32Array([
        container_data.L * 0.5 - getContour("rear_bottom_contour_l"),
        -container_data.W * 0.5 + getContour("side1_bottom_contour_l"),
        -container_data.H * 0.5,
      ]),
    ],
    1: [
      // First contour level
      new Float32Array([
        -container_data.L * 0.5,
        -container_data.W * 0.5,
        -container_data.H * 0.5 +
        Math.max(
          getContour("front_bottom_contour_h"),
          getContour("side1_bottom_contour_h")
        ),
      ]),
      new Float32Array([
        -container_data.L * 0.5,
        container_data.W * 0.5,
        -container_data.H * 0.5 +
        Math.max(
          getContour("front_bottom_contour_h"),
          getContour("side2_bottom_contour_h")
        ),
      ]),
      new Float32Array([
        container_data.L * 0.5,
        container_data.W * 0.5,
        -container_data.H * 0.5 +
        Math.max(
          getContour("rear_bottom_contour_h"),
          getContour("side2_bottom_contour_h")
        ),
      ]),
      new Float32Array([
        container_data.L * 0.5,
        -container_data.W * 0.5,
        -container_data.H * 0.5 +
        Math.max(
          getContour("rear_bottom_contour_h"),
          getContour("side1_bottom_contour_h")
        ),
      ]),
    ],
    2: [
      // Second contour level
      new Float32Array([
        -container_data.L * 0.5,
        -container_data.W * 0.5,
        container_data.H * 0.5 -
        Math.max(
          getContour("front_top_contour_h"),
          getContour("side1_top_contour_h")
        ),
      ]),
      new Float32Array([
        -container_data.L * 0.5,
        container_data.W * 0.5,
        container_data.H * 0.5 -
        Math.max(
          getContour("front_top_contour_h"),
          getContour("side2_top_contour_h")
        ),
      ]),
      new Float32Array([
        container_data.L * 0.5,
        container_data.W * 0.5,
        container_data.H * 0.5 -
        Math.max(
          getContour("rear_top_contour_h"),
          getContour("side2_top_contour_h")
        ),
      ]),
      new Float32Array([
        container_data.L * 0.5,
        -container_data.W * 0.5,
        container_data.H * 0.5 -
        Math.max(
          getContour("rear_top_contour_h"),
          getContour("side1_top_contour_h")
        ),
      ]),
    ],
    3: [
      // Top level
      new Float32Array([
        -container_data.L * 0.5 + getContour("front_top_contour_l"),
        -container_data.W * 0.5 + getContour("side1_top_contour_l"),
        container_data.H * 0.5,
      ]),
      new Float32Array([
        -container_data.L * 0.5 + getContour("front_top_contour_l"),
        container_data.W * 0.5 - getContour("side2_top_contour_l"),
        container_data.H * 0.5,
      ]),
      new Float32Array([
        container_data.L * 0.5 - getContour("rear_top_contour_l"),
        container_data.W * 0.5 - getContour("side2_top_contour_l"),
        container_data.H * 0.5,
      ]),
      new Float32Array([
        container_data.L * 0.5 - getContour("rear_top_contour_l"),
        -container_data.W * 0.5 + getContour("side1_top_contour_l"),
        container_data.H * 0.5,
      ]),
    ],
  };

  const geometries = [];

  // Top
  if (!container_data.no_roof && container_data.H) {
    geometries.push(
      createContainerPlanePart([
        extremePoints[3][1],
        extremePoints[3][3],
        extremePoints[3][0],
        extremePoints[3][1],
        extremePoints[3][2],
        extremePoints[3][3],
      ])
    );
  }

  if (!container_data.no_end_walls) {
    // Front bottom
    geometries.push(
      createContainerPlanePart([
        extremePoints[1][1],
        extremePoints[1][0],
        extremePoints[0][0],
        extremePoints[0][0],
        extremePoints[0][1],
        extremePoints[1][1],
      ])
    );

    // Rear bottom
    geometries.push(
      createContainerPlanePart([
        extremePoints[1][3],
        extremePoints[1][2],
        extremePoints[0][2],
        extremePoints[0][3],
        extremePoints[1][3],
        extremePoints[0][2],
      ])
    );

    if (insideHeight) {
      // Front wall
      geometries.push(
        createContainerPlanePart([
          extremePoints[1][1],
          extremePoints[2][0],
          extremePoints[1][0],
          extremePoints[2][1],
          extremePoints[2][0],
          extremePoints[1][1],
        ])
      );
      // Front top
      geometries.push(
        createContainerPlanePart([
          extremePoints[3][1],
          extremePoints[3][0],
          extremePoints[2][0],
          extremePoints[2][1],
          extremePoints[3][1],
          extremePoints[2][0],
        ])
      ); // Rear wall
      geometries.push(
        createContainerPlanePart([
          extremePoints[2][2],
          extremePoints[1][3],
          extremePoints[2][3],
          extremePoints[1][3],
          extremePoints[2][2],
          extremePoints[1][2],
        ])
      );

      // Rear top
      geometries.push(
        createContainerPlanePart([
          extremePoints[3][3],
          extremePoints[3][2],
          extremePoints[2][2],
          extremePoints[2][3],
          extremePoints[3][3],
          extremePoints[2][2],
        ])
      );
    }
  }

  if (!container_data.no_side_walls) {
    // Side1 bottom
    geometries.push(
      createContainerPlanePart([
        extremePoints[1][0],
        extremePoints[0][3],
        extremePoints[0][0],
        extremePoints[0][3],
        extremePoints[1][0],
        extremePoints[1][3],
      ])
    );

    // Side2 bottom
    geometries.push(
      createContainerPlanePart([
        extremePoints[0][1],
        extremePoints[0][2],
        extremePoints[1][1],
        extremePoints[1][2],
        extremePoints[1][1],
        extremePoints[0][2],
      ])
    );

    if (insideHeight) {
      // Side1 wall
      geometries.push(
        createContainerPlanePart([
          extremePoints[2][0],
          extremePoints[2][3],
          extremePoints[1][0],
          extremePoints[1][3],
          extremePoints[1][0],
          extremePoints[2][3],
        ])
      );
      // Side1 top
      geometries.push(
        createContainerPlanePart([
          extremePoints[3][0],
          extremePoints[3][3],
          extremePoints[2][0],
          extremePoints[2][3],
          extremePoints[2][0],
          extremePoints[3][3],
        ])
      );

      // Side2 wall
      geometries.push(
        createContainerPlanePart([
          extremePoints[1][1],
          extremePoints[1][2],
          extremePoints[2][1],
          extremePoints[2][2],
          extremePoints[2][1],
          extremePoints[1][2],
        ])
      );
      // Side2 top
      geometries.push(
        createContainerPlanePart([
          extremePoints[2][1],
          extremePoints[2][2],
          extremePoints[3][1],
          extremePoints[3][2],
          extremePoints[3][1],
          extremePoints[2][2],
        ])
      );
    }
  }

  // Doors
  if (
    container_data.H &&
    !container_data.no_end_walls &&
    !container_data.no_side_walls &&
    container_data.door.H &&
    container_data.door.W
  ) {
    const door_h = Math.min(container_data.door.H, container_data.H);
    const door_w = Math.min(
      container_data.door.W,
      container_data.door.longside
        ? container_data.L -
        Math.max(
          container_data.contours?.front_bottom_contour_l || 0,
          container_data.contours?.front_top_contour_l || 0
        ) -
        Math.max(
          container_data.contours?.rear_bottom_contour_l || 0,
          container_data.contours?.rear_top_contour_l || 0
        )
        : container_data.W -
        Math.max(
          container_data.contours?.side1_bottom_contour_l || 0,
          container_data.contours?.side1_top_contour_l || 0
        ) -
        Math.max(
          container_data.contours?.side2_bottom_contour_l || 0,
          container_data.contours?.side2_top_contour_l || 0
        )
    );
    const geometry = new PlaneBufferGeometry(door_h, door_w, 1);

    const material = new MeshBasicMaterial({
      color: doorColor,
      side: DoubleSide,
      transparent: true,
      opacity: 0.4,
    });
    const plane = new Mesh(geometry, material);

    const edges = new EdgesGeometry(geometry);
    edges.scale(1, 0.5, 1);
    const doorFrame1 = new LineSegments(
      edges,
      new LineBasicMaterial({ color: wallColor })
    );
    const doorFrame2 = new LineSegments(
      edges,
      new LineBasicMaterial({ color: wallColor })
    );

    plane.rotateY(Math.PI * 0.5);
    if (container_data.door.longside) {
      const offset =
        (Math.max(
          container_data.contours?.front_bottom_contour_l || 0,
          container_data.contours?.front_top_contour_l || 0
        ) -
          Math.max(
            container_data.contours?.rear_bottom_contour_l || 0,
            container_data.contours?.rear_top_contour_l || 0
          )) /
        2;
      plane.rotateX(Math.PI * 0.5);
      plane.position.set(
        0.0 + offset,
        -container_data.W * 0.5 - 0.01,
        -container_data.H * 0.5 + door_h * 0.5
      );
      doorFrame1.position.set(
        -door_w / 4 + offset,
        -container_data.W * 0.5,
        -container_data.H * 0.5 + door_h * 0.5
      );
      doorFrame2.position.set(
        door_w / 4 + offset,
        -container_data.W * 0.5,
        -container_data.H * 0.5 + door_h * 0.5
      );
    } else {
      const offset =
        (Math.max(
          container_data.contours?.side1_bottom_contour_l || 0,
          container_data.contours?.side1_top_contour_l || 0
        ) -
          Math.max(
            container_data.contours?.side2_bottom_contour_l || 0,
            container_data.contours?.side2_top_contour_l || 0
          )) /
        2;
      plane.position.set(
        container_data.L * 0.5 - 0.01,
        0.0 + offset,
        -container_data.H * 0.5 + door_h * 0.5
      );
      // Experiment to have the door slightly open
      // let doorRotation = -Math.PI * 0
      // doorFrame1.rotateX(doorRotation);
      // doorFrame1.position.set(
      //   container_data.L * 0.5 - Math.sin(doorRotation) * door_w / 4,
      //   (door_w - Math.cos(doorRotation) * door_w / 2) / 2,
      //   -container_data.H * 0.5 + door_h * 0.5 + bedHeight
      // );
      doorFrame1.position.set(
        container_data.L * 0.5,
        door_w / 4 + offset,
        -container_data.H * 0.5 + door_h * 0.5
      );
      doorFrame2.position.set(
        container_data.L * 0.5,
        -door_w / 4 + offset,
        -container_data.H * 0.5 + door_h * 0.5
      );
    }
    plane.name = "Door"
    doorFrame1.rotation.copy(plane.rotation);
    doorFrame2.rotation.copy(plane.rotation);
    containerMesh.add(doorFrame1);
    containerMesh.add(doorFrame2);
    containerMesh.add(plane);
  }

  if (geometries.length) {
    let mergeGeometry = mergeBufferGeometries(geometries);
    mergeGeometry = mergeVertices(mergeGeometry);
    mergeGeometry.setAttribute(
      "uv",
      new BufferAttribute(new Float32Array([]), 2)
    );
    const wallMaterial = new MeshLambertMaterial({
      color: wallColor,
      opacity: 0.1,
      transparent: true,
    });
    containerMesh.add(new Mesh(mergeGeometry, wallMaterial));
    const edges = new EdgesGeometry(mergeGeometry);
    const line = new LineSegments(
      edges,
      new LineBasicMaterial({ color: wallColor })
    );
    containerMesh.add(line);
  }

  // Max height helper
  if (
    container_data.max_height &&
    (container_data.no_roof ||
      !insideHeight ||
      container_data.max_height < insideHeight)
  ) {
    // Modify extremepoints for max height helpers
    extremePoints[3][0][2] = extremePoints[3][1][2] = extremePoints[3][2][2] = extremePoints[3][3][2] =
      -insideHeight * 0.5 + container_data.max_height;

    containerMesh.add(
      new Line(
        createContainerPlanePart([
          extremePoints[3][0],
          extremePoints[3][1],
          extremePoints[3][2],
          extremePoints[3][3],
          extremePoints[3][0],
        ]),
        new LineBasicMaterial({ color: maxHeightColor })
      )
    );

    if (hasTopContours && !container_data.H && container_data.max_height) {
      extremePoints[2][0][2] += -insideHeight * 0.5 + container_data.max_height;
      extremePoints[2][1][2] += -insideHeight * 0.5 + container_data.max_height;
      extremePoints[2][2][2] += -insideHeight * 0.5 + container_data.max_height;
      extremePoints[2][3][2] += -insideHeight * 0.5 + container_data.max_height;

      if (getContour("front_top_contour_h")) {
        containerMesh.add(
          new Line(
            createContainerPlanePart([
              extremePoints[2][0],
              extremePoints[2][1],
              extremePoints[3][1],
              extremePoints[3][0],
              extremePoints[2][0],
            ]),
            new LineBasicMaterial({ color: helperColor })
          )
        );
      }
      if (getContour("rear_top_contour_h")) {
        containerMesh.add(
          new Line(
            createContainerPlanePart([
              extremePoints[2][2],
              extremePoints[2][3],
              extremePoints[3][3],
              extremePoints[3][2],
              extremePoints[2][2],
            ]),
            new LineBasicMaterial({ color: helperColor })
          )
        );
      }
      if (getContour("side1_top_contour_h")) {
        containerMesh.add(
          new Line(
            createContainerPlanePart([
              extremePoints[2][3],
              extremePoints[2][0],
              extremePoints[3][0],
              extremePoints[3][3],
              extremePoints[2][3],
            ]),
            new LineBasicMaterial({ color: helperColor })
          )
        );
      }
      if (getContour("side2_top_contour_h")) {
        containerMesh.add(
          new Line(
            createContainerPlanePart([
              extremePoints[2][1],
              extremePoints[2][2],
              extremePoints[3][2],
              extremePoints[3][1],
              extremePoints[2][1],
            ]),
            new LineBasicMaterial({ color: helperColor })
          )
        );
      }
    }
  }

  const floorMaterial = new MeshLambertMaterial({
    color: floorColor,
    side: DoubleSide,
  });

  if (container_data?.contours?.bottom_spacer_h) {
    try {
      let item = container_data.items.find(i => i.qty === 0 && i.label === "spacer");
      if (item) {
        const pallet = await loadPalletModel(item.L, item.W, item.H, new Vector3((-container_data.L - item.L) * 0.5 + item.pos.x, (-container_data.W - item.W) * 0.5 + item.pos.y, 0));
        containerMesh.add(pallet);
      }
    } catch { }
  }


  if (container_data.base_type == "PALL" && container_data.floor_height > 0) {
    try {
      const pallet = await loadPalletModel(container_data.L, container_data.W, container_data.floor_height, new Vector3(-container_data.L / 2,
        -container_data.W / 2,
        -container_data.H / 2 - container_data.floor_height));
      floorMaterial.transparent = true;
      floorMaterial.opacity = 0.0;
      containerMesh.add(pallet);
    } catch { }
  }
  const floorGeometry = new BoxBufferGeometry(
    container_data.L -
    (container_data.tables.rear_table_l || 0) -
    (container_data.tables.front_table_l || 0) -
    (container_data.contours.front_bottom_contour_l || 0) -
    (container_data.contours.rear_bottom_contour_l || 0),
    container_data.W -
    (container_data.contours.side1_bottom_contour_l || 0) -
    (container_data.contours.side2_bottom_contour_l || 0),
    bedHeight
  );

  const floor = new Mesh(floorGeometry, floorMaterial);
  floor.name = "Floor";
  floor.position.set(
    (container_data.tables.front_table_l || 0) * 0.5 -
    (container_data.tables.rear_table_l || 0) * 0.5 +
    (container_data.contours.front_bottom_contour_l || 0) * 0.5 -
    (container_data.contours.rear_bottom_contour_l || 0) * 0.5,
    (container_data.contours.side1_bottom_contour_l || 0) * 0.5 -
    (container_data.contours.side2_bottom_contour_l || 0) * 0.5,
    -container_data.H * 0.5 - bedHeight * 0.5
  );

  containerMesh.add(floor);

  const tyreWidth = 0.3;
  let tyreRadius = container_data.clearence / 2;
  let frontTyreRadius = undefined;
  let rearTyreRadius = undefined;

  if (
    container_data.tables.front_table_h &&
    container_data.axles.front_axle_no
  ) {
    frontTyreRadius = Math.min(
      container_data.tables.front_table_h / 2 + bedHeight / 2,
      container_data.axles.front_axle_spacing / 2 - 0.1
    );
  }

  if (
    (container_data.tables.rear_table_h && container_data.axles.rear_axle_no) ||
    false
  ) {
    rearTyreRadius = Math.min(
      container_data.tables.rear_table_h / 2 + bedHeight / 2,
      container_data.axles.rear_axle_spacing / 2 - 0.1
    );
  }
  if (frontTyreRadius && rearTyreRadius) {
    tyreRadius = Math.min(frontTyreRadius, rearTyreRadius);
  } else if (frontTyreRadius) {
    tyreRadius = frontTyreRadius;
  } else if (rearTyreRadius) {
    tyreRadius = rearTyreRadius;
  }

  // Front axle set
  if (container_data.clearence > 0) {
    if (container_data.axles.front_axle_no >= 1) {
      const axleStartHeight =
        -container_data.H / 2 -
        container_data.clearence -
        bedHeight +
        tyreRadius -
        0.1;
      const axleWithWheels = await createAxleWithWheels(
        container_data,
        tyreRadius,
        tyreWidth
      );
      const frontAxleStartLength =
        -container_data.L / 2 +
        container_data.axles.front_axle_x -
        ((container_data.axles.front_axle_no - 1) *
          (container_data.axles.front_axle_spacing || 0)) /
        2;

      for (let i = 0; i < Math.round(container_data.axles.front_axle_no); i++) {
        const newAxle = axleWithWheels.clone();
        newAxle.position.set(
          frontAxleStartLength +
          i * (container_data.axles.front_axle_spacing || 0),
          0,
          axleStartHeight
        );
        containerMesh.add(newAxle);
      }
    } else if (container_data.axles.front_axle_x > 0) {
      const kingpinSize = 0.2;
      const kingpin = new Mesh(
        new CylinderBufferGeometry(
          kingpinSize,
          kingpinSize,
          kingpinSize,
          8,
          1,
          false
        ),
        new MeshLambertMaterial({
          color: wheelColor,
          depthTest: false,
          transparent: true,
          opacity: 0.5,
        })
      );
      kingpin.rotateX(Math.PI * 0.5);
      kingpin.position.set(
        -container_data.L * 0.5 + container_data.axles.front_axle_x,
        0,
        -container_data.H * 0.5 +
        (container_data.axles.front_axle_x <
          container_data.tables.front_table_l
          ? container_data.tables.front_table_h
          : -bedHeight) -
        kingpinSize * 0.5
      );
      containerMesh.add(kingpin);
    }
  }

  // Rear axle set
  if (container_data.axles.rear_axle_no >= 1 && container_data.clearence > 0) {
    const axleStartHeight =
      -container_data.H / 2 -
      container_data.clearence -
      bedHeight +
      tyreRadius -
      0.1;
    const axleWithWheels = await createAxleWithWheels(
      container_data,
      tyreRadius,
      tyreWidth
    );
    const rearAxleStartLength =
      -container_data.L / 2 +
      container_data.axles.rear_axle_x -
      ((container_data.axles.rear_axle_no - 1) *
        (container_data.axles.rear_axle_spacing || 0)) /
      2;

    for (let i = 0; i < Math.round(container_data.axles.rear_axle_no); i++) {
      const newAxle = axleWithWheels.clone();
      newAxle.position.set(
        rearAxleStartLength + i * (container_data.axles.rear_axle_spacing || 0),
        0,
        axleStartHeight
      );
      containerMesh.add(newAxle);
    }
  }

  containerMesh.updateMatrix();
}

async function createAxleWithWheels(
  container: HoldData,
  tyreRadius: number,
  tyreWidth: number
) {
  const axleWithWheels = new Object3D();
  const texture = await loadWheelTexture();
  const wheelMaterial = new MeshLambertMaterial({ map: texture })
  const wheel1 = new Mesh(
    new CylinderBufferGeometry(tyreRadius, tyreRadius, tyreWidth, 32, 1, false),
    [new MeshLambertMaterial({ color: wheelColor, side: DoubleSide }), wheelMaterial, wheelMaterial]
  );
  wheel1.position.setY(-container.W / 2 + tyreWidth / 2);
  const wheel2 = wheel1.clone();
  wheel2.position.setY(wheel2.position.y + tyreWidth + 0.05);
  const wheel3 = wheel1.clone();
  wheel3.position.setY(container.W / 2 - tyreWidth / 2);
  const wheel4 = wheel1.clone();
  wheel4.position.setY(wheel3.position.y - tyreWidth - 0.05);
  axleWithWheels.add(wheel1, wheel2, wheel3, wheel4);
  return axleWithWheels;
}


function loadPalletModel(L: number,
  W: number,
  H: number,
  pos: Vector3): Promise<Object3D> {
  let loader = new OBJLoader();
  const materialLoader = new MTLLoader();
  return new Promise((resolve, reject) => {
    const name = "eu_pallet.obj";

    const onMaterialLoad = (m: MTLLoader.MaterialCreator) => {
      loader = loader.setMaterials(m);

      loader.load("/static/obj/" + name, function (object) {
        resolve(createPallet(L, W, H, pos, object));
      });
    };

    materialLoader.load(
      "/static/obj/pallet.mtl",
      onMaterialLoad,
      undefined,
      () => {
        reject("Failed to load pallet.mtl");
      }
    );

  });
}

function loadWheelTexture(): Promise<Texture> {
  return new Promise((resolve, reject) => {

    new TextureLoader().load("/static/textures/truckwheel.png", function (t) {
      resolve(t);
    }, undefined, function (e) {
      reject(e)
    });

  });

}


function createPallet(
  L: number,
  W: number,
  H: number,
  pos: Vector3,
  object: Object3D,
) {
  if (!(L * W * H > 0)) {
    return;
  }

  object.name = "pallet";
  const partLengths = new Box3().setFromObject(object).getSize(new Vector3());

  const lengthRatio = L / partLengths.x;
  const widthRatio = W / partLengths.y;
  const heightRatio = H / partLengths.z;

  object.scale.set(lengthRatio, widthRatio, heightRatio);
  object.position.copy(pos);

  return object;
}

export { createContainerParts };
