





















































































import Vue, { PropType } from "vue";
import { HoldPart } from "@/models/LoadlistModel";

export default Vue.extend({
  name: "edit-part",
  data() {
    return {
      part: JSON.parse(JSON.stringify(this.value)) as HoldPart,
    };
  },
  props: {
    value: Object as PropType<HoldPart>,
    index: Number,
  },
  computed: {
    // title(): String {
    //   switch (this.part.type) {
    //     case "x_wall":
    //     case "y_wall":
    //       return "Wall";
    //     case "corner":
    //       return "Corner";
    //   }
    //   return "";
    // },
  },
  watch: {
    _part: {
      handler: function(a) {
        this.$emit("input", this.part);
      },
      immediate: true,
    },
  },
  created() {},
  methods: {},
});
