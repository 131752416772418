
















































































import Vue from "vue";
import { UserCompanyStore } from "@/store";
import { UserPreferences } from "@/models/UserCompanyModel";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    length_dim: {
      get(): string {
        return this.preferences.length_dim;
      },
      set(value: string): void {
        this.updateMe({ preferences: { length_dim: value } });
      },
    },
    default_length_dim: {
      get(): string {
        return this.preferences.default_loadlist_length_dim;
      },
      set(value: string): void {
        this.updateMe({
          preferences: { default_loadlist_length_dim: value },
        });
      },
    },
    weight_dim: {
      get(): string {
        return this.preferences.weight_dim;
      },
      set(value: string): void {
        this.updateMe({ preferences: { weight_dim: value } });
      },
    },
    default_weight_dim: {
      get(): string {
        return this.preferences.default_loadlist_weight_dim;
      },
      set(value: string): void {
        this.updateMe({
          preferences: { default_loadlist_weight_dim: value },
        });
      },
    },
    default_view: {
      get(): string {
        return this.preferences.default_view || "3d";
      },
      set(value: string): void {
        this.updateMe({ preferences: { default_view: value } });
      },
    },
    hideItemText: {
      get(): boolean {
        return !!this.preferences.hide_labels;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { hide_labels: value } });
      },
    },
    autoGroupColors: {
      get(): boolean {
        return !!this.preferences.autoGroupColors;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { autoGroupColors: value } });
      },
    },
    preferences(): UserPreferences {
      return UserCompanyStore.preferences;
    },
    darkMode: {
      get(): boolean {
        return !!this.preferences.darkMode;
      },
      set(value: boolean): void {
        this.$vuetify.theme.dark = !!value;
        this.updateMe({ preferences: { darkMode: value } });
      },
    },
  },
  methods: {
    updateMe(updateUserObject: any): void {
      UserCompanyStore.updateMe(updateUserObject);
    },
  },
});
