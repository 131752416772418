






























































































































































































































































































































































import Vue, { PropType } from "vue";
import { Configuration, SetSlot, SetType } from "@/models/SetsModel";
import { HoldData, HoldType, Rotation } from "@/models/LoadlistModel";
import rotationInputField from "@/components/Custom/RotationInputField.vue"
import lengthInputField from "@/components/Custom/LengthInputField.vue"
import draggable from 'vuedraggable'
export default Vue.extend({
  name: "set_layout",
  props: {
    set: Object as PropType<SetType>,
    displayConfiguration: Array as PropType<number[]>
  },
  components: {
    draggable,
    rotationInputField,
    lengthInputField,
  },
  data() {
    return {
      expandedSlots: [],
      selectedSlots: [],
      nameRules: [
        (v: string) => !!v || "A name is required",
        (v: string) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      expandedGroups: [0],
      editingName: undefined as number,
      defaultSpacing: 0.1,
      placementMode: "Row",
      showMoveModal: false,
      moveModel: { x: 0, y: 0, z: 0, slotIndex: 0 },
      dragSlot: false,
      dragContainer: false,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    };
  },
  methods: {
    reorder() {
      this.$emit("reorder")
    },
    rerender() {
      this.$emit("rerender")
    },
    updateName(input: string, slotIndex: number) {
      this.set.data.slots[slotIndex].name = input
    },
    blurSlotName(event: FocusEvent, slot: SetSlot) {
      slot.name = (event.target as any)._value
      this.editingName = undefined
    },
    keyDownSlotName(input: KeyboardEvent, slotIndex: number) {
      if (input.key == "Enter") {
        const refs = this.$refs as any
        const ref = refs["slot-name-"+slotIndex][0]
        ref.blur()
      }
    },
    changeConfiguration(slotIndex: number) {
      this.rerender()
    },
    removeContainer(slot_index: number, ht_index: number) {
      if (slot_index === null || ht_index === null) return;
      this.displayedSlot(slot_index).group.splice(ht_index, 1);
    },
    openContainerDetails(slotIndex: number, holdType: HoldType) {
      this.$emit("containerSelect", {slotIndex, holdType})
    },
    position_name(slot_index: number, ht_index: number): string {
      let ht = this.set.data.slots[slot_index].configurations[
        this.displayConfiguration[slot_index]
      ].group[ht_index];
      if (!!ht.position_name) {
        return ht.position_name;
      }
      let count = 0;
      for (let i = 0; i < slot_index; i++) {
        count += this.set.data.slots[i].configurations[
          this.displayConfiguration[i]
        ].group.length;
      }
      return `${count + ht_index + 1}`;
    },
    containerOf(ht: HoldType): HoldData {
      const hold = this.set.data.container_types.find(
        (ct) => ct.id === ht.container_type_id
      );
      if (hold) {
        return {
          ...this.set.data.container_types.find(
            (ct) => ct.id === ht.container_type_id
          ),
          ...(ht.props
            ? Object.fromEntries(
                Object.entries(ht.props).filter(([_, v]) => v != null)
              )
            : ht.props),
        };
      }
      return undefined;
    },
    newGroup() {
      this.set.data.slots.push({
        configurations: [{ group: [] }],
        name: `Group ${this.set.data.slots.length + 1}`,
      });
      this.displayConfiguration.push(0);
      this.editSlotName(this.set.data.slots.length - 1)
    },
    getLastSelectedGroup() {
      return this.displayedSlot(this.getLastSelectedSlotIndex());
    },
    getLastSelectedSlotIndex() {
      for (let i = this.set.data.slots.length - 1; i >= 0; i--) {
        if (
          this.selectedSlots.length > 0 &&
          this.selectedSlots.indexOf(i) < 0
        ) {
          continue;
        }
        return i;
      }
    },
    // calculatePosition(prev: HoldType, current: HoldData, rotation: Rotation) {
    //   let position = { x: 0, y: 0, z: 0 };
    //   if (!prev) {
    //     return position;
    //   }

    //   let direction = "x";
    //   switch (this.placementMode) {
    //     case "Row":
    //       direction = "x";
    //       break;
    //     case "Column":
    //       direction = "y";
    //       break;
    //     case "Side by side":
    //       let alt = this.getLastSelectedGroup();
    //       if (alt.group.length % 2 === 1) {
    //         direction = "y";
    //       } else if (alt.group.length > 1) {
    //         prev = alt.group[alt.group.length - 2];
    //       }
    //       break;
    //   }
    //   let prevContainer = this.containerOf(prev);
    //   let pl = this.calculateLengthOfContainer(prevContainer, prev.rotation[2]);
    //   let pw = this.calculateWidthOfContainer(prevContainer, prev.rotation[2]);

    //   if (direction === "x") {
    //     let l = this.calculateLengthOfContainer(current, rotation[2]);
    //     return {
    //       x:
    //         prev.position.x +
    //         pl / 2 +
    //         prevContainer.L / 2 +
    //         this.defaultSpacing -
    //         (current.L / 2 - l / 2),
    //       y: prev.position.y,
    //       z: prev.position.z,
    //     };
    //   } else {
    //     let w = this.calculateWidthOfContainer(current, rotation[2]);
    //     return {
    //       x: prev.position.x,
    //       y:
    //         prev.position.y +
    //         pw / 2 +
    //         prevContainer.W / 2 +
    //         this.defaultSpacing -
    //         (current.W / 2 - w / 2),
    //       z: prev.position.z,
    //     };
    //   }
    // },
    calculateLengthOfContainer(container: HoldData, rotationZ: number) {
      if (container) {
        return (
          Math.abs(Math.cos(rotationZ)) * container.L +
          Math.abs(Math.cos(Math.PI / 2 - rotationZ)) * container.W
        );
      }
    },
    calculateWidthOfContainer(container: HoldData, rotationZ: number) {
      if (container) {
        return (
          Math.abs(Math.cos(rotationZ)) * container.W +
          Math.abs(Math.cos(Math.PI / 2 - rotationZ)) * container.L
        );
      }
    },
    newConfiguration(slotIndex: number) {
      this.set.data.slots[slotIndex].configurations.push({ group: [] });
      this.displayConfiguration[slotIndex] =
        this.set.data.slots[slotIndex].configurations.length - 1;
      this.rerender()
    },
    splitGroup(slot_index: number) {
      let newGroups = this.displayedSlot(slot_index).group.map((ht, ht_index) => {
        return {
          name: `Group ${slot_index + 1 + ht_index }`,
          configurations: [{ group: [ht]}],
        };
      });
      this.set.data.slots.splice(slot_index, 1, ...newGroups);
      this.displayConfiguration.splice(
        slot_index,
        1,
        ...newGroups.map(() => 0)
      );
    },
    // TODO reimplement duplicate group?
    // duplicateGroup(slot_index: number) {
    //   const newSlot = JSON.parse(
    //     JSON.stringify(this.displayedSlot(slot_index))
    //   ) as Configuration;
    //   const old = newSlot.group.find((g) => g);
    //   let last_hold = this.getLastHoldType(false);
    //   let last_pos = this.calculatePosition(
    //     last_hold,
    //     this.containerOf(old),
    //     old.rotation
    //   );
    //   let old_pos = old.position;
    //   let diff = {
    //     x: last_pos.x - old_pos.x,
    //     y: last_pos.y - old_pos.y,
    //     z: last_pos.z - old_pos.z,
    //   };
    //   newSlot.group.forEach((ht: HoldType) => {
    //     ht.position.x += diff.x;
    //     // ht.position.y += diff.y;
    //     ht.position.z += diff.z;
    //   });
    //   this.set.data.slots.push({ configurations: [newSlot] });
    //   this.displayConfiguration.push(0);
    // },
    getLastHoldType(selected: boolean) {
      for (let i = this.set.data.slots.length - 1; i >= 0; i--) {
        if (
          selected &&
          this.selectedSlots.length > 0 &&
          this.selectedSlots.indexOf(i) < 0
        ) {
          continue;
        }
        let group = this.displayedSlot(i).group;
        for (let j = group.length - 1; j >= 0; j--) {
          return group[j];
        }
      }
    },
    deleteGroup(slot_index: number) {
      this.set.data.slots.splice(slot_index, 1);
      this.displayConfiguration.splice(slot_index, 1);
      const index = this.selectedSlots.indexOf(slot_index);
      if (index >= 0) {
        this.selectedSlots.splice(index, 1);
      }
      this.rerender()
    },
    displayedSlot(slot_index: number): Configuration {
      return this.set.data.slots[slot_index].configurations[
        this.displayConfiguration[slot_index]
      ];
    },
    moveGroup(slotIndex: number) {
      this.displayedSlot(slotIndex).group.forEach((ht) => {
        ht.position.x += this.moveModel.x;
        ht.position.y += this.moveModel.y;
        ht.position.z += this.moveModel.z;
      })
      this.moveModel.x = 0;
      this.moveModel.y = 0;
      this.moveModel.z = 0;
      this.rerender()
      // this.moveModel = { x: 0, y: 0, z: 0 };
    },
    shiftDown(slotIndex: number) {
      const slots = [...this.set.data.slots]
      const next = slots[slotIndex + 1];
      if (next) {
        slots[slotIndex + 1] = this.set.data.slots[slotIndex];
        slots[slotIndex] = next;
        this.set.data.slots = slots
      }
      this.rerender();
    },
    shiftUp(slotIndex: number) {
      const slots = [...this.set.data.slots]
      const prev = slots[slotIndex - 1];
      if (prev) {
        slots[slotIndex - 1] = this.set.data.slots[slotIndex];
        slots[slotIndex] = prev;
        this.set.data.slots = slots
      }
      this.rerender();
    },
    editSlotName(slotIndex: number) {
      this.editingName = slotIndex
      setTimeout(() => {
        const refs = this.$refs as any
        const ref = refs["slot-name-"+slotIndex][0]
        ref.focus()
      })
    }
  },
});
