import { HoldItem } from "@/models/LoadlistModel";
import {
    Mesh,
    BufferGeometry,
    Vector3,
    Quaternion,
    Points,
    BufferAttribute,
    PointsMaterial,
    Box3,
    Group,
} from "three";
import { physicsWorker } from "../sceneManager";
import { createSnapPointTexture } from "../utils"

class BaseItem extends Mesh {
    isCargo: boolean;
    isSelected = false;
    prePosition: Vector3;
    preQuaternion: Quaternion;
    indexInContainer: number;
    isInteractive: boolean;

    constructor(
        item_data: HoldItem,
        index: number,
        isInteractive: boolean,
    ) {
        super();

        this.type = "BaseItem";
        this.isCargo = item_data.qty > 0;
        this.isSelected = false;
        this.prePosition = null;
        this.indexInContainer = index;
        this.isInteractive = isInteractive;
        this.userData.item = item_data;
        this.matrixAutoUpdate = false;

    }
    select(): void {
        if (this.isSelected) return;
        this.matrixAutoUpdate = true;
        this.prePosition = this.position.clone();
        this.preQuaternion = this.quaternion.clone();
        this.isSelected = true;
        physicsWorker.postMessage({
            event: "select",
            mass: this.userData.item.WT,
            itemIndex: this.indexInContainer,
        });
    }

    deselect(): void {
        if (!this.isSelected) return;
        this.isSelected = false;
        this.matrixAutoUpdate = false;
        this.prePosition = null;
        this.preQuaternion = null;
        physicsWorker.postMessage({
            event: "deselect",
            itemIndex: this.indexInContainer,
        });
    }
}
export { BaseItem };

