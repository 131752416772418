






















































































































































































































































































































































import Vue from "vue";
import API from "@/API";
import { UserCompanyStore } from "@/store";
import {
  CompanyAdminUserSerializer,
  CompanyListSerializer,
  CompanyStoreTemplate,
  User,
} from "@/models/UserCompanyModel";

const MAX_NUMBER_OF_USERS = 5;

interface CompanyStats {
  month: string;
  total: number;
}
export default Vue.extend({
  name: "usermanagement",
  data() {
    return {
      isLoading: false,
      new_user: {
        username: "",
        password: "",
        email: "",
      },
      new_user_errors: {
        username: [],
        password: [],
        email: [],
      },
      error_messages: [] as string[],
      showAddUserModal: false,
      showInviteModal: false,
      showDeleteModal: false,
      showSnackbar: false,
      companies: [] as CompanyListSerializer[],
      users: [] as CompanyAdminUserSerializer[],
      selected_user: null as CompanyAdminUserSerializer,
      selected_company: null as CompanyListSerializer,
      company_stats: [] as CompanyStats[],
      selectedTransferUsers: [] as CompanyAdminUserSerializer[],
      transferTableHeaders: [
        { text: "User name", value: "username" },
        { text: "Email", value: "email" },
      ],
    };
  },
  mounted() {
    API.getCompanies().then((response) => {
      this.companies = response.data;
    });
  },
  computed: {
    maxNumberOfSeats(): number {
      return this.selected_company?.seats;
    },
    createUsersDisabled(): boolean {
      return this.users.length >= this.maxNumberOfSeats || this.lite_version;
    },
    transferTableUsers(): CompanyAdminUserSerializer[] {
      return this.users.filter((u) => u.email !== this.selected_user?.email);
    },
    deleteButtonText(): string {
      return this.selectedTransferUsers.length
        ? "Transfer load lists and delete user"
        : "Delete load lists and user";
    },
    company(): CompanyStoreTemplate {
      return UserCompanyStore.company;
    },
    user(): User {
      return UserCompanyStore.user;
    },
    lite_version(): boolean {
      return UserCompanyStore.lite_version;
    },
  },
  methods: {
    updateSelectedCompany(company: CompanyListSerializer): void {
      if (company !== null) {
        this.selected_company = company;
        this.getUsers();
        API.getCompanyStats(this.selected_company.id).then((response) => {
          this.company_stats = response.data.map((i: CompanyStats) => {
            let date = new Date(i.month);
            let short_month = date.toLocaleString("default", {
              month: "short",
            });
            return {
              total: i.total,
              month: short_month,
            };
          });
        });
      }
    },
    getUsers(): void {
      API.getUsers(this.selected_company.id).then((response) => {
        this.users = response.data;
      });
    },
    updateUser(user: CompanyAdminUserSerializer): void {
      API.updateUserFromCompany(this.selected_company.id, user.id, user)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(
      user: CompanyAdminUserSerializer,
      transferUsers: CompanyAdminUserSerializer[]
    ): void {
      const transferUserId = transferUsers.length ? transferUsers[0].id : null;
      console.log(user);
      API.deleteUserFromCompany(
        this.selected_company.id,
        user.id,
        transferUserId
      )
        .then((response) => {
          this.getUsers();
          this.showAddUserModal = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createUser(): void {
      API.addUser(this.selected_company.id, this.new_user)
        .then((response) => {
          this.getUsers();
          this.showAddUserModal = false;

          this.new_user_errors.username = [];
          this.new_user_errors.password = [];
          this.new_user_errors.email = [];
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.new_user_errors.username = [];
            this.new_user_errors.password = [];
            this.new_user_errors.email = [];

            if ("username" in error.response.data) {
              this.new_user_errors.username = error.response.data.username;
            }
            if ("password" in error.response.data) {
              this.new_user_errors.password = error.response.data.password;
            }
            if ("email" in error.response.data) {
              this.new_user_errors.email = error.response.data.email;
            }
          }
        });
    },
    inviteUser(): void {
      API.inviteUser({
        company: this.selected_company.id,
        email: this.new_user.email,
      })
        .then((response) => {
          this.showInviteModal = false;
          this.new_user_errors.email = [];
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.new_user_errors.email = [];
            if ("email" in error.response.data) {
              this.new_user_errors.email = error.response.data.email;
            }
          }
        });
    },
  },
});
