















































































































import Vue, { PropType } from "vue";
import { SegregationTable } from "@/models/CalculationModel";

export default Vue.extend({
  props: {
    input: {
      type: Object as PropType<SegregationTable>,
      default: {} as SegregationTable,
    },
  },
  watch: {
    input: {
      handler: function(val) {
        this.class_ids = JSON.parse(JSON.stringify(val)) || {};
      },
      immediate: true,
    },
  },
  computed: {
    cellValues() {
      return [
        {
          text: "Segregate",
          value: "segregate",
          icon: "mdi-cancel",
          color: "red",
        },
        {
          text: "Can be loaded together",
          value: undefined,
          icon: "mdi-check",
          hide: true,
          color: "green",
        },
      ];
    },
    disableAddNewClassId(): Boolean {
      return (
        this.class_ids &&
        (!this.newClassId || this.newClassId in this.class_ids)
      );
    },
  },
  data() {
    return {
      loading: false,
      class_ids: {} as SegregationTable,
      newClassId: "",
    };
  },
  methods: {
    addClassId(): void {
      if (this.disableAddNewClassId) return;
      if (!this.class_ids) this.class_ids = {};
      this.$set(this.class_ids, this.newClassId, {});
      this.newClassId = "";
    },
    changeClassId(a: string, b: string, val: string): void {
      this.$set(this.class_ids[a], b, val);
      this.$set(this.class_ids[b], a, val);
    },
    deleteClassId(key: string) {
      this.$delete(this.class_ids, key);
      for (let key2 in this.class_ids) {
        if (key in this.class_ids[key2])
          this.$delete(this.class_ids[key2], key);
      }
      if (Object.keys(this.class_ids).length === 0) this.class_ids = null;
    },
  },
});
