var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"8"}},[_c('v-select',{attrs:{"items":_vm.$list_types,"value":_vm.currentType,"hide-details":"","outlined":"","dense":""},on:{"input":_vm.changeListType},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(("$vuetify.icons." + (item.value))))]),_vm._v("   "+_vm._s(item.text)+" ")]}}])})],1),(_vm.sets_enabled)?_c('v-col',[_c('v-switch',{attrs:{"label":'Show sets'},model:{value:(_vm.showSets),callback:function ($$v) {_vm.showSets=$$v},expression:"showSets"}})],1):_vm._e(),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.user.is_editor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":!_vm.user.is_editor,"color":"success","ripple":false,"block":""},on:{"click":function($event){_vm.$route.name === 'sets'
                  ? _vm.$router.push({
                      name: 'modify_set',
                      params: { type: _vm.currentType },
                    })
                  : _vm.$router.push({
                      name: 'modify_hold',
                      params: { type: _vm.currentType },
                    })}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" Create new ")],1)],1)]}}])},[_c('span',[_vm._v("You need to be a Administrator to perform this action")])])],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }